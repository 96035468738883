/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/anchor-is-valid */
import { useUser } from '@sdk/hook/useUser';
import { useCheckout } from '@sdk/providers/CheckoutProvider';

import NiceModal from '@ebay/nice-modal-react';
import { CartModal } from '@components/overlays/Cart';
import { LoginModal } from '@components/overlays/Login';

import Link from 'next-translate-routes/link';
import { paths } from '@paths';

import { ReactSVG } from 'react-svg';

import { CheckoutLineFragment } from '@sdk/gqlTypes/generatedOperations';

import {
  LayoutContactFragment,
  LayoutHeaderNaviguationFragment,
} from '@prismic/gqlTypes/prismicOperations';

import closeImg from 'images/close-black.svg';
import logoMobile from 'images/logo-mobile.svg';
import logoImg from 'images/logo.svg';
import NavIconButton from '../MainMenu/NavIconButton';
import UserMenu from '../MainMenu/UserMenu';
import WishlistMenu from '../MainMenu/WishlistMenu';
import NavContact from './NavContact';
import NavPrismic from './NavPrismic';
import NavItem, { INavItem } from './NavItem';
import { NavLocales } from './NavLocales';

import * as S from './styles';

interface NavListProps {
  items: INavItem[];
  prismicNav?: LayoutHeaderNaviguationFragment[];
  contact?: LayoutContactFragment[];
  hideOverlay(): void;
}

export const NavList: React.FC<NavListProps> = ({
  hideOverlay,
  items,
  contact,
  prismicNav,
}) => {
  const { user } = useUser();
  const { checkout } = useCheckout();

  const cartItemsQuantity =
    checkout?.lines?.reduce(
      (amount: number, line?: CheckoutLineFragment | null) =>
        line ? amount + line.quantity : amount,
      0
    ) || 0;

  return (
    <S.SideNav>
      <S.Header divider>
        <S.CloseImg>
          <ReactSVG src={closeImg} onClick={hideOverlay} />
        </S.CloseImg>
        <S.HeadImg>
          <Link href={paths.home}>
            <a onClick={hideOverlay}>
              <ReactSVG src={logoImg} className="tablet" />
              <ReactSVG src={logoMobile} className="mobile" />
            </a>
          </Link>
        </S.HeadImg>
        <S.TopBar>
          {user ? (
            <WishlistMenu />
          ) : (
            <NavIconButton
              icon="wishlist"
              onClick={() => NiceModal.show(LoginModal)}
            />
          )}
          {user ? (
            <UserMenu />
          ) : (
            <NavIconButton
              icon="user"
              onClick={() => NiceModal.show(LoginModal)}
              className="main-menu__nav-link"
            />
          )}
          <NavIconButton
            icon="cart"
            onClick={() => NiceModal.show(CartModal)}
            counter={cartItemsQuantity}
          />
        </S.TopBar>
      </S.Header>
      <S.Body>
        <NavLocales hideOverlay={hideOverlay} />
        <S.MenuItem>
          {items.map((item) => (
            <NavItem key={item.id} hideOverlay={hideOverlay} {...item} />
          ))}
        </S.MenuItem>
        <NavPrismic items={prismicNav} hideOverlay={hideOverlay} />
        <NavContact contact={contact} />
      </S.Body>
    </S.SideNav>
  );
};
