import React from 'react';

import * as S from './styles';
import { IProps } from './types';

export const Loader: React.FC<IProps> = ({
  fullScreen,
  primary = false,
  inline = false,
}: IProps) => (
  <S.Wrapper fullScreen={!!fullScreen} inline={inline}>
    <S.Items color={primary ? 'primary' : 'secondary'}>
      <span />
      <span />
      <span />
    </S.Items>
  </S.Wrapper>
);
