import { relayStylePagination } from '@apollo/client/utilities';

import { TypedTypePolicies } from '@sdk/gqlTypes/generatedHooks';

export const typePolicies: TypedTypePolicies = {
  User: {
    fields: {
      orders: relayStylePagination(),
    },
  },
  Query: {
    fields: {
      products: relayStylePagination(['filter', 'sortBy']),
      reviews: relayStylePagination(),
    },
  },
};

export default typePolicies;
