import * as React from 'react';

import Image from 'next/future/image';

import { MainMenuItemFragment } from '@sdk/gqlTypes/generatedOperations';
import { translate } from '@utils/translations';

import { NavLink } from '@components/atoms/NavLink';

import * as S from './styles';

interface NavNestedItemProps extends MainMenuItemFragment {
  children?: Array<NavNestedItemProps | null> | null;
  imageUrl?: string;
  isScrolled?: boolean;
}

const SaleorNavItem: React.FC<NavNestedItemProps> = ({
  children,
  imageUrl,
  isScrolled,
  ...item
}) => {
  const content =
    children && children.length ? (
      <ul>
        {children.map(
          (subItem, i) => subItem && <SaleorNavItem key={i} {...subItem} />
        )}
      </ul>
    ) : null;

  return (
    <S.NavItem isScrolled>
      <NavLink item={item}>
        {imageUrl && !isScrolled && (
          <>
            <S.NavItemImage>
              <Image
                src={imageUrl}
                width="180"
                height="240"
                alt={translate(item, 'name')}
                style={{ maxWidth: '100%', height: '100%' }}
              />
              <S.NavItemOverlay />
            </S.NavItemImage>
            <S.NavItemTitle>{translate(item, 'name')}</S.NavItemTitle>
          </>
        )}
        {isScrolled && (
          <S.NavPillTitle>{translate(item, 'name')}</S.NavPillTitle>
        )}
      </NavLink>
      {content}
    </S.NavItem>
  );
};

export default SaleorNavItem;
