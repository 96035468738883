// Rework this functionality once slugs are in use
/* eslint-disable @typescript-eslint/ban-ts-comment, no-useless-escape */
/* @ts-ignore */
import { Base64 } from 'js-base64';

export const slugify = (text: string | number): string =>
  text
    .toString()
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/\-\-+/g, '-'); // Replace multiple - with single -

export const decodeKey = (graphqlId: string, schema?: string): string => {
  // This is temporary solution, we will use slugs in the future
  const rawId = Base64.decode(graphqlId);
  const regexp = /(\w+):(\d+)/;
  const [, expectedSchema, id] = regexp.exec(rawId)!;
  if (schema && schema !== expectedSchema) {
    throw new Error('Schema is not correct');
  }
  return id;
};

export const generateKey = (id: string, schema: string): string =>
  // This is temporary solution, we will use slugs in the future
  Base64.encode(`${schema}:${id}`);

export const generateCategoryUrl = (id: string, name: string): string =>
  `/category/${slugify(name)}/${generateKey(id, 'Category')}/`;

export const generateCollectionUrl = (id: string, name: string): string =>
  `/collection/${slugify(name)}/${generateKey(id, 'Collection')}/`;

export const generatePageUrl = (slug: string): string => `/page/${slug}/`;

export const generateGuestOrderDetailsUrl = (token: string): string =>
  `/order-history/${token}/`;

/**
 * The parameters that were parsed from the URL path.
 */
export type Params<Key extends string = string> = {
  readonly [key in Key]: string | undefined;
};

export function invariant(cond: any, message: string): asserts cond {
  if (!cond) throw new Error(message);
}

/**
 * Returns a path with params interpolated.
 *
 * @see https://reactrouter.com/docs/en/v6/utils/generate-path
 */
export function generatePath(path: string, params: Params = {}): string {
  return path
    .replace(/\[(\w+)\]/g, (_, key) => {
      invariant(params[key] != null, `Missing "${key}" param`);
      return params[key]!;
    })
    .replace(/\/*\*$/, (_) =>
      params['*'] == null ? '' : params['*'].replace(/^\/*/, '/')
    );
}
