import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { Button } from '@components/atoms/Button';
import { ErrorMessage } from '@components/atoms/ErrorMessage';
import { TextField } from '@components/molecules/TextField';
import { UserFragment } from '@sdk/gqlTypes/generatedOperations';
import { useSaleorAuthContext } from '@sdk/auth/SaleorAuthProvider';

import { maybe } from '@utils/misc';
import { groupErrorsByFields, NON_FIELD_ERROR } from '@utils/errors';

import { commonMessages, validationCommonMsg } from '../../../../intl';

import { IProps, IFormProps } from './types';
import * as S from './styles';

const showSuccessNotification = (
  onSuccess: () => void,
  user?: Partial<UserFragment>
) => {
  // @analitics
  if (user) {
    window?.rudderanalytics?.identify(user.id, {
      email: user.email,
      firstname: user.firstName,
      isLoggedIn: true,
      lastname: user.lastName,
    });
  }
  if (onSuccess) {
    onSuccess();
  }
};

export const LoginForm: React.FC<IProps> = ({ onSuccess }) => {
  const intl = useIntl();
  const { signIn } = useSaleorAuthContext();
  const [graphqlErrors, setGraphqlErrors] = useState([]);
  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const handleOnSubmit = async (values: IFormProps) => {
    const { data } = await signIn({
      email: values.email,
      password: values.password,
    });
    if (data?.tokenCreate?.errors.length) {
      setGraphqlErrors(data?.tokenCreate?.errors);
    } else if (data?.tokenCreate?.token) {
      setGraphqlErrors([]);
      showSuccessNotification(onSuccess, data?.tokenCreate?.user);
    }
  };

  const fieldErrors = groupErrorsByFields(
    maybe(() => graphqlErrors, []),
    'email',
    'password'
  );
  const nonFieldErrors = fieldErrors[NON_FIELD_ERROR];

  return (
    <S.Wrapper className="login-form">
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <S.Error className="form-error">
          <ErrorMessage errors={nonFieldErrors} />
        </S.Error>
        <Controller
          name="email"
          control={control}
          rules={{
            pattern: {
              message: intl.formatMessage(validationCommonMsg.invalidEmail),
              value:
                /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
            },
            required: intl.formatMessage(validationCommonMsg.required),
          }}
          render={({ field }) => (
            <TextField
              label={intl.formatMessage(commonMessages.shortEmail)}
              autoComplete="email"
              type="email"
              errors={
                errors.email
                  ? [{ message: errors.email.message }]
                  : undefined || fieldErrors?.email
              }
              {...field}
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          rules={{
            required: intl.formatMessage(validationCommonMsg.required),
          }}
          render={({ field }) => (
            <TextField
              label={intl.formatMessage(commonMessages.password)}
              autoComplete="password"
              type="password"
              errors={
                errors.password
                  ? [{ message: errors.password.message }]
                  : undefined || fieldErrors?.password
              }
              {...field}
            />
          )}
        />
        <S.SubmitButton className="login-form__button">
          <Button fullWidth type="submit" disabled={isSubmitting}>
            {isSubmitting
              ? intl.formatMessage(commonMessages.loading)
              : intl.formatMessage(commonMessages.signIn)}
          </Button>
        </S.SubmitButton>
      </form>
    </S.Wrapper>
  );
};
