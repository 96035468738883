import { Fetch } from '@sdk/auth/types';
import { API_URI } from '@temp/constants';
import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { useMemo } from 'react';

import { createUploadLink } from 'apollo-upload-client';

import extractFiles from 'extract-files/extractFiles.mjs';
import isExtractableFile from 'extract-files/isExtractableFile.mjs';

import { typePolicies } from './typePolicies';

const uploadAndBatchHTTPLink = (opts: any) =>
  ApolloLink.split(
    (operation) => extractFiles(operation, isExtractableFile).files.size > 0,
    createUploadLink(opts),
    new BatchHttpLink(opts)
  );

export const useAuthenticatedApolloClient = (fetchWithAuth: Fetch) => {
  const httpLink = uploadAndBatchHTTPLink({
    uri: API_URI,
    fetch: fetchWithAuth,
  });

  const apolloClient = useMemo(
    () =>
      new ApolloClient({
        link: httpLink,
        cache: new InMemoryCache({ typePolicies }),
      }),
    []
  );

  return { apolloClient, resetClient: () => apolloClient.resetStore() };
};
