const baseUrl = '/';
const accountBaseUrl = `${baseUrl}account/`;
const checkoutBaseUrl = `${baseUrl}checkout/`;

export const paths = {
  /**
   * Hone
   */
  home: baseUrl,
  /**
   * Login
   */
  accountConfirm: `${baseUrl}account-confirm`,
  login: `${baseUrl}login`,
  passwordReset: `${baseUrl}reset-password`,
  /**
   * Shopping
   */
  cart: `${baseUrl}cart`,
  category: `${baseUrl}category/[slug]/[id]/`,
  collection: `${baseUrl}collection/[slug]/[id]/`,
  guestOrderDetail: `${baseUrl}order-history/[token]`,
  orderFinalized: `${baseUrl}order-finalized`,
  product: `${baseUrl}product/[slug]/[id]/`,
  reviews: `${baseUrl}reviews/`,
  reviewDetail: `${baseUrl}reviews//[slug]`,
  search: `${baseUrl}search`,
  wishlist: `${baseUrl}wishlist`,
  /**
   * Pages
   */
  architect: `${baseUrl}architect/`,
  blog: `${baseUrl}blog/`,
  blogPost: `${baseUrl}blog/[slug]`,
  contacts: `${baseUrl}contacts/`,
  inspirations: `${baseUrl}inspirations/`,
  inspirationsPost: `${baseUrl}inspirations/[slug]`,
  page: `${baseUrl}page/[slug]`,
  /**
   * Checkout
   */
  checkout: checkoutBaseUrl,
  checkoutAddress: `${checkoutBaseUrl}address`,
  checkoutPayment: `${checkoutBaseUrl}payment`,
  checkoutPaymentConfirm: `${checkoutBaseUrl}payment-confirm`,
  checkoutReview: `${checkoutBaseUrl}review`,
  checkoutShipping: `${checkoutBaseUrl}shipping`,
  /**
   * Account section
   */
  account: accountBaseUrl,
  accountAddressBook: `${accountBaseUrl}address-book`,
  // FIXME: User order should be accessible via order id
  accountOrderDetail: `${accountBaseUrl}order-history/[token]`,
  accountOrderHistory: `${accountBaseUrl}order-history`,
};
