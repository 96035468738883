import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import { DefaultTheme, media } from '.';

export const GlobalStyle = createGlobalStyle<{ theme: DefaultTheme }>`

  ${reset}
  /* Fonts */

  @font-face {
    font-family: 'Inter';
    src: url('/fonts/Inter-Regular.woff2') format('woff2'),
      url('/fonts/Inter-Regular.woff') format('woff'),
      url('/fonts/Inter-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Sofia bold';
    src: url('/fonts/SofiaProBold.woff2') format('woff2'),
      url('/fonts/SofiaProBold.woff') format('woff'),
      url('/fonts/SofiaProBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Sofia thin';
    src: url('/fonts/SofiaProLight.woff2') format('woff2'),
      url('/fonts/SofiaProLight.woff') format('woff'),
      url('/fonts/SofiaProLight.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Sofia';
    src: url('/fonts/SofiaProRegular.woff2') format('woff2'),
      url('/fonts/SofiaProRegular.woff') format('woff'),
      url('/fonts/SofiaProRegular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  /* Base Style */

  html {
    font-size: calc(12px + 0.290625vw);
    box-sizing: border-box;
    &.hidden {
      -webkit-overflow-scrolling: touch;
    }
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    // margin: 0;
    // min-width: 320px;
    font-family: ${(props) => props.theme.typography.baseFontFamily};
    // font-size: ${(props) => props.theme.typography.baseFontSize};
    line-height: ${(props) => props.theme.typography.baseLineHeight};
    color: ${(props) => props.theme.colors.baseFont};
    ${(props) => media.smallScreen`
      font-size: ${(props) => props.theme.typography.smallFontSize};
    `}
  }

  input, textarea, button {
    font-family: inherit;
  }

  header {
    position: sticky;
    top: 0;
    width: 100vw;
    background-color: #ffffff;
    z-index: 100;
    border-bottom: 1px solid #e0e0e0;
  }

  .page-wrapper {
    margin-top: 100px;
    ${(props) => media.largeScreen`
      margin-top: 80px;
    `}
    ${(props) => media.mediumScreen`
      margin-top: 80px;
    `}
    ${(props) => media.smallScreen`
      margin-top: 60px;
    `}
    &.no-margin {
      margin-top: 0;
    }

  }

  .form-error {
    color: ${(props) => props.theme.colors.error};
  }

  h1 {
    font-size: ${(props) => props.theme.typography.h1FontSize};
    line-height: ${(props) => props.theme.typography.h1LineHeight};

    ${(props) => media.xSmallScreen`
      font-size: ${props.theme.typography.h2FontSize};
    `}
  }

  h3 {
    font-size: ${(props) => props.theme.typography.h3FontSize};
    line-height: 1.7rem;
  }

  h4 {
    font-size: ${(props) => props.theme.typography.h4FontSize};
  }

  a {
    text-decoration: none;
    font-weight: normal;
    color: inherit;
  }

  p {
    line-height: 1.5rem;
  }

  button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    outline: none;
    padding: 0;
  }

  ul {
    list-style: none;
  }

  table {
    width: 100%;
    margin: ${({ theme: { spacing } }) => `calc(${spacing.spacer} * 3) 0`};
    font-size: ${(props) => props.theme.typography.smallFontSize};

    th {
      color: ${(props) => props.theme.colors.lightFont};
      text-align: left;
      padding: ${({ theme: { spacing } }) =>
        `${spacing.spacer} calc(${spacing.spacer} / 2)`};
    }

    tr {
      color: ${(props) => props.theme.colors.baseFont};
      border-bottom: 1px solid ${(props) => props.theme.colors.light};
    }

    td {
      padding: ${({ theme: { spacing } }) =>
        `${spacing.spacer} calc(${spacing.spacer} / 2)`};
      vertical-align: middle;

      img {
        vertical-align: middle;
        margin-right: ${(props) => props.theme.spacing.spacer};
      }
    }
  }

  #root {
    display: flex;
    min-height: 100vh;
    flex-direction: column;

    & > div:first-of-type {
      flex: 1;
    }
  }

`;
