import { media, styled } from '@styles';

export const Container = styled.div`
  width: 80vw;
  max-width: 1491px;
  margin: 0 auto;
  ${media.mediumScreen`
    width: 85vw;
  `}
  ${media.smallScreen`
    width: 90vw;
  `}
`;

export const ContainerFlex = styled.div`
  max-width: 100vw;
  padding-left: 60px;
  padding-right: 60px;
  ${media.mediumScreen`
    padding-left: 30px;
    padding-right: 30px;
  `}
  ${media.smallScreen`
    padding-left: 24px;
    padding-right: 24px;
  `}
`;

export const CartContainer = styled.div`
  width: ${(props) => `${props.theme.container.width}px`};
  max-width: 100vw;
  padding: 0 ${(props) => props.theme.spacing.spacer};

  ${media.smMediumScreen`
    width: 100%;
  `}
`;
