import * as React from 'react';
import { useIntl } from 'react-intl';
import Link from 'next-translate-routes/link';

import { paths } from '@paths';

import { Button } from '@components/atoms/Button';
import cartRed from 'images/cart-red.svg';
import sadSmile from 'images/sad-smile.svg';

import * as S from './styles';

const Empty: React.FC<{ overlayHide(): void }> = ({ overlayHide }) => {
  const intl = useIntl();
  return (
    <S.Empty className="cart__empty">
      <S.EmptyImage className="cart__empty-img">
        <img src={sadSmile} alt="Sad" />
        <img src={cartRed} alt="Cart is empty" />
      </S.EmptyImage>
      <S.EmptyText className="cart__empty-text">
        {intl.formatMessage({
          defaultMessage: 'Oops... Your cart is empty!',
          id: 'QY2p4z',
        })}
      </S.EmptyText>
      <S.EmptyAction className="cart__empty__action">
        <Link href={paths.home}>
          <a>
            <Button size="lg" onClick={overlayHide} className="button">
              {intl.formatMessage({
                defaultMessage: 'shop now',
                id: 'MwnupR',
              })}
            </Button>
          </a>
        </Link>
      </S.EmptyAction>
    </S.Empty>
  );
};

export default Empty;
