import { media, styled } from '@styles';

export const Wrapper = styled.div<{
  showShipping: boolean;
  showDiscount: boolean;
}>`
  display: grid;
  font-size: ${(props) => props.theme.typography.h4FontSize};
  grid-template-areas:
    'subtotalText subtotalPrice'
    ${(props) => props.showShipping && `"shippingText shippingPrice"`}
    ${(props) => props.showDiscount && `"discountText discountPrice"`}
    'totalText totalPrice';
  grid-template-columns: 4fr 1.1fr;
  ${(props) => media.smallScreen`
    grid-template-areas:
      ". subtotalText subtotalPrice"
      ${props.showShipping && `"shippingText shippingPrice"`}
      ${props.showDiscount && `"discountText discountPrice"`}
      "totalText totalPrice";
    grid-template-columns: 3.5fr 2fr;
  `}
  border-bottom: 1px solid rgba(50, 50, 50, 0.1);
  margin-top: auto;
  padding-top: ${({ theme: { spacing } }) => `calc(${spacing.spacer} * 2)`};
`;

const Text = styled.div`
  font-family: ${({ theme: { typography } }) => typography.extraFontFamily};
  font-size: ${({ theme: { typography } }) => typography.labelFontSize};
  line-height: 1.5em;
  margin-bottom: 5px;
`;

export const SubtotalText = styled(Text)`
  grid-area: subtotalText;
`;
export const SubtotalPrice = styled(Text)`
  grid-area: subtotalPrice;
  text-align: right;
`;

export const ShippingText = styled(Text)`
  grid-area: shippingText;
`;
export const ShippingPrice = styled(Text)`
  grid-area: shippingPrice;
  text-align: right;
`;

export const DiscountText = styled(Text)`
  grid-area: discountText;
`;
export const DiscountPrice = styled(Text)`
  grid-area: discountPrice;
  text-align: right;
`;

export const TotalText = styled.div`
  grid-area: totalText;
  color: ${({ theme: { colors } }) => colors.baseFont};
  line-height: 1em;
  margin-top: 20px;
  margin-bottom: 20px;
  border-top: 1px solid rgba(#000, 0.1);
  font-size: ${({ theme: { typography } }) => typography.baseFontSize};
  ${media.smallScreen`
    padding-top: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
  `}
`;
export const TotalPrice = styled.div`
  grid-area: totalPrice;
  font-weight: bold;
  text-align: right;
  color: ${({ theme: { colors } }) => colors.red};
  line-height: 1em;
  margin-top: 20px;
  margin-bottom: 20px;
  border-top: 1px solid rgba(#000, 0.1);
  font-size: ${({ theme: { typography } }) => typography.baseFontSize};
  ${media.smallScreen`
    padding-top: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
  `}
`;

export const PromoCode = styled.div``;
