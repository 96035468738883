import { ShippingDateTypeEnum } from '@sdk/gqlTypes/generatedTypes';
import { ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { commonMessages } from '../../../../intl';

import { IProps } from './types';

interface Dictionary<T> {
  [index: string]: T;
}

const formatRelativeTimeUnits: Dictionary<'days' | 'weeks'> = {
  [ShippingDateTypeEnum.DAYS]: 'days',
  [ShippingDateTypeEnum.WEEKS]: 'weeks',
};

function EstimatedShipping({ productType, type, exact }: IProps): ReactElement {
  const intl = useIntl();

  const { shippingMax, shippingMin, shippingDateType, estimatedShipping } =
    productType;

  const estimatedShippingDaysLeft = Math.ceil(
    (Date.now() - Date.parse(estimatedShipping)) / (1000 * 60 * 60 * 24)
  );

  const unit =
    formatRelativeTimeUnits[shippingDateType || ShippingDateTypeEnum.DAYS];

  const translatedUnit = intl.formatMessage(commonMessages[unit]);

  const messages = {
    long: intl.formatMessage({
      defaultMessage: 'Estimated shipping time',
      id: 'RM/X/f',
    }),
    short: intl.formatMessage({
      defaultMessage: 'Est. ship',
      id: '6hz7sj',
    }),
  };

  const message = messages[type];

  if (exact && estimatedShipping) {
    /* tslint:disable:object-literal-sort-keys */
    return (
      <>{`${message} ${intl.formatDate(estimatedShipping, {
        month: 'short',
        day: 'numeric',
      })}`}</>
    );
    /* tslint:enable:object-literal-sort-keys */
  }
  if (shippingMax && shippingMin) {
    return (
      <>
        {`${message} ${intl.formatMessage(
          commonMessages.from
        )} ${shippingMin} ${intl.formatMessage(
          commonMessages.to
        )} ${shippingMax} ${translatedUnit}`}
      </>
    );
  }
  if (estimatedShipping) {
    return (
      <>{`${message} ${intl.formatRelativeTime(
        estimatedShippingDaysLeft,
        unit
      )}`}</>
    );
  }
  return null;
}

export { EstimatedShipping };
