/* eslint-disable global-require */
import { ReviewAvatarEnum } from '@sdk/gqlTypes/generatedTypes';

import blikIcon from 'images/pg-blik.svg';
import stripeIcon from 'images/pg-card.svg';
import klarnaIcon from 'images/pg-klarna.svg';
import p24Icon from 'images/pg-p24.svg';
import paypalIcon from 'images/pg-paypal.svg';

import userAvatar0 from 'images/reviews/user-avatar-0.svg';
import userAvatar1 from 'images/reviews/user-avatar-1.svg';
import userAvatar2 from 'images/reviews/user-avatar-2.svg';
import userAvatar3 from 'images/reviews/user-avatar-3.svg';
import userAvatar4 from 'images/reviews/user-avatar-4.svg';
import userAvatar5 from 'images/reviews/user-avatar-5.svg';
import userAvatar6 from 'images/reviews/user-avatar-6.svg';
import userAvatar7 from 'images/reviews/user-avatar-7.svg';
import userAvatar8 from 'images/reviews/user-avatar-8.svg';
import userAvatar9 from 'images/reviews/user-avatar-9.svg';

export const REVIEWS_PER_PAGE = 10;
export const REVIEW_AVATARS = new Map<ReviewAvatarEnum, string>([
  [ReviewAvatarEnum.AVATAR_0, userAvatar0],
  [ReviewAvatarEnum.AVATAR_1, userAvatar1], // TODO: add avatar
  [ReviewAvatarEnum.AVATAR_2, userAvatar2], // TODO: add avatar
  [ReviewAvatarEnum.AVATAR_3, userAvatar3], // TODO: add avatar
  [ReviewAvatarEnum.AVATAR_4, userAvatar4], // TODO: add avatar
  [ReviewAvatarEnum.AVATAR_5, userAvatar5], // TODO: add avatar
  [ReviewAvatarEnum.AVATAR_6, userAvatar6], // TODO: add avatar
  [ReviewAvatarEnum.AVATAR_7, userAvatar7], // TODO: add avatar
  [ReviewAvatarEnum.AVATAR_8, userAvatar8], // TODO: add avatar
  [ReviewAvatarEnum.AVATAR_9, userAvatar9], // TODO: add avatar
]);

export const DUMMY_GATEWAY = 'mirumee.payments.dummy';
export const KLARNA_GATEWAY = 'saleor.payments.klarna';
export const P24_GATEWAY = 'saleor.payments.przelewy24';
export const BLIK_GATEWAY = 'saleor.payments.blik';
export const PAYPAL_GATEWAY = 'saleor.payments.paypal';
export const STRIPE_GATEWAY = 'saleor.payments.stripe';

export const PROVIDERS = {
  'mirumee.payments.dummy': {
    id: DUMMY_GATEWAY,
    icon: stripeIcon,
    label: 'Dummy',
  },
  'saleor.payments.klarna': {
    id: KLARNA_GATEWAY,
    icon: klarnaIcon,
    label: 'Klarna',
  },
  'saleor.payments.przelewy24': {
    id: P24_GATEWAY,
    icon: p24Icon,
    label: 'Przelewy24',
  },
  'saleor.payments.blik': {
    id: BLIK_GATEWAY,
    icon: blikIcon,
    label: 'Blik',
  },
  'saleor.payments.paypal': {
    id: PAYPAL_GATEWAY,
    icon: paypalIcon,
    label: 'Paypal',
  },
  'saleor.payments.stripe': {
    id: STRIPE_GATEWAY,
    icon: stripeIcon,
    label: 'Visa|Mastercard',
  },
};
