// colors
export const baseFontColor = '#2c2c2c';
export const baseFontColorOpacityLight = 'rgba(44, 44, 44, 0.4)'; // #2c2c2c
export const baseFontColorOpacityDark = 'rgba(44, 44, 44, 0.8)'; // #2c2c2c

export const baseFontColorTransparent = 'rgba(50,50,50,0.1)'; // #323232

export const black = '#000';
export const white = '#fff';

export const blue = '#21125e'; // #21125e
export const blueOverlay = `rgba(33,18,94,0.15)`; // #21125e
export const blueOverlayDark = `rgba(33,18,94,0.25)`; // #21125e

export const red = '#c34c3e';
export const redLight = '#f5f1f2';
export const redHover = '#af382a';
export const redActive = '#a52e20';
export const redLightExtra = '#f1e3e1';

export const blueDark = '#3b5a68';
export const blueDarkActive = '#31505e';

export const blueLight = '#4f6e7c';
export const blueLightOpacitySemi = 'rgba(79, 110, 124, 0.5)'; // #4f6e7c
export const blueLightOpacityLight = 'rgba(79, 110, 124, 0.1)'; // #4f6e7c

export const borderGray = '#cfd7de'; // Only from strip element ?
export const gray = '#7d7d7d';
export const graySemi = '#839098';
export const grayMedium = '#c4c4c4';
export const grayDark = '#323232';
export const graySoft = '#e2e3e4';
export const grayLight = '#f5f1f2';

export const green = '#3ed256';
export const rose = '#c22d74';
export const turquoise = '#13bebb';
export const turquoiseDark = '#06a09e';
export const turquoiseLight = 'rgba(6, 132, 123, 0.25)'; // #06847b
export const turquoiseTransparent = 'rgba(6, 132, 123, 0.1)'; // #06847b
export const tabelGray = '#eaeaea';
export const darkGreen = '#06847b';

export const gold = '#ca9c66';

// theme colors
export const theme = {
  activeMenuOption: darkGreen,
  baseFont: baseFontColor,
  baseFontColorTransparent,
  baseFontColorOpacityDark,
  black,
  borderGray,
  blueLight,
  blueLightOpacityLight,
  grayDark,
  disabled: gray,
  divider: grayLight,
  dividerDark: grayMedium,
  error: rose,
  baseFontColorOpacityLight,
  graySemi,
  blueLightOpacitySemi,
  graySoft,
  hoverLightBackground: turquoiseLight,
  redHover,
  light: grayLight,
  lightFont: gray,
  listBullet: darkGreen,
  overlay: baseFontColorOpacityDark,
  primary: red,
  primaryDark: redHover,
  primaryDarkest: redActive,
  primaryLight: turquoiseLight,
  primaryTransparent: turquoiseTransparent,
  red,
  redLight,
  secondary: blueLight,
  secondaryDark: blueDark,
  secondaryDarkest: blueDarkActive,
  secondaryLight: blueLight,
  secondaryOverlay: blueOverlay,
  secondaryOverlayDark: blueOverlayDark,
  success: green,
  tabTitle: darkGreen,
  tableDivider: tabelGray,
  tabsBorder: baseFontColorTransparent,
  thumbnailBorder: darkGreen,
  white,
  gold,
};

// typography
export const baseFontFamily = "'Sofia', Arial, Helvetica, sans-serif";
export const thinFontFamily = "'Sofia thin', Arial, Helvetica, sans-serif";
export const boldFontFamily = "'Sofia bold', Arial, Helvetica, sans-serif";
export const extraFontFamily = "'Inter', Arial, Helvetica, sans-serif";
export const boldFontWeight = 600;
export const extraBoldFontWeight = 800;
export const baseLineHeight = '1.25rem'; // 20px
export const h1LineHeight = 1;

export const h1FontSize = '3rem'; // 48px
export const h2FontSize = '2.44rem'; // 39px
export const h3FontSize = '1.953rem'; // 31px
export const h4FontSize = '1.563rem'; // 25px
export const h5FontSize = '1.25rem'; // 20px
export const baseFontSize = '1rem'; // 16px
export const smallFontSize = '0.875rem'; // 14px
export const labelFontSize = '0.75rem'; // 12px
export const ultraBigFont = '6rem'; // 96px

// spacing
export const spacer = 1; // rem
export const fieldSpacer = '1.25rem';

// breakpoints
export const xLargeScreen = 1919;
export const largeScreen = 1599;
export const mediumScreen = 1279;
export const smMediumScreen = 992;
export const smallScreen = 767;
export const xSmallScreen = 540;
export const smallestScreen = 340;
