/* eslint-disable no-console */
import {
  ProductDetailsFragment,
  ProductCheckoutFragment,
} from '@sdk/gqlTypes/generatedOperations';

import NoPhoto from 'images/no-photo.svg';

import { translate } from '@utils/translations';
import { generatePath, slugify, decodeKey } from '@utils/core';
import { paths } from '@paths';

import pickBy from 'lodash/pickBy';
import queryString from 'query-string';

import { CONFIGURATOR_PRODUCT_TYPES } from '../types';

export const generateProductUrlWithAttributes = (
  product:
    | Pick<ProductDetailsFragment, 'id' | 'name' | 'translation'>
    | Pick<ProductCheckoutFragment, 'id' | 'name' | 'translation'>,
  attributes: Record<string, string>
): string => {
  const productUrl = generatePath(paths.product, {
    slug: slugify(translate(product, 'name')),
    id: decodeKey(product.id, 'Product'),
  });
  const filteredAttributes = pickBy(attributes);
  if (Object.values(filteredAttributes).length) {
    return `${window.location.origin}${productUrl}?${queryString.stringify(
      filteredAttributes
    )}`;
  }
  return `${window.location.origin}${productUrl}`;
};

export const generateProductImageWithAttributes = (
  product:
    | Pick<ProductDetailsFragment, 'productType' | 'thumbnail'>
    | Pick<ProductCheckoutFragment, 'productType' | 'thumbnail'>,
  attributes: Record<string, string>
): string => {
  const isConfiguratorType = CONFIGURATOR_PRODUCT_TYPES.includes(
    product.productType.slug
  );

  const filteredAttributes = pickBy(attributes);
  if (isConfiguratorType && Object.values(filteredAttributes).length) {
    return `${process.env.NEXT_PUBLIC_MEDIA_BUCKET_URL}render/${
      product.productType.slug
    }/?${queryString.stringify(filteredAttributes)}`;
  }
  const { thumbnail } = product;
  return thumbnail ? product.thumbnail.url : NoPhoto;
};
