import { styled } from '@styles';

export const FooterLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 12px 0 12px;
`;

export const FooterLinkItem = styled.a`
  color: ${({ theme: { colors } }) => colors.graySemi};
  font-family: ${({ theme: { typography } }) => typography.extraFontFamily};
  font-size: ${({ theme: { typography } }) => typography.smallFontSize};
  line-height: 1.6em;
  text-decoration: underline;
  transition: color 0.4s;
  &:hover {
    color: ${({ theme: { colors } }) => colors.red};
  }
`;
