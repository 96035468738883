import React from 'react';

import { PrismicRichText } from '@prismicio/react';

import { ContactsNav } from '@components/molecules/ContactsNav';

import * as S from './styles';
import { IProps } from './types';

export const TopBar: React.FC<IProps> = ({ message, contact, isScrolled }) => (
  <S.Wrapper hidden={isScrolled}>
    <S.Row>
      <S.Copyright>
        {message && <PrismicRichText field={message} />}
      </S.Copyright>
      <S.Contacts>
        <ContactsNav contact={contact} />
      </S.Contacts>
    </S.Row>
  </S.Wrapper>
);
