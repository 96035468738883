/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/anchor-is-valid */
import { useState } from 'react';

import { useUser } from '@sdk/hook/useUser';
import { useMainMenuQuery } from '@sdk/gqlTypes/generatedHooks';

import { useCheckout } from '@sdk/providers/CheckoutProvider';

import { ReactSVG } from 'react-svg';

import NiceModal from '@ebay/nice-modal-react';
import { LoginModal } from '@components/overlays/Login';
import { CartModal } from '@components/overlays/Cart';
import { SideNavModal } from '@components/overlays/SideNav';

import {
  SaleorNavDropdown,
  PrismicNavDropdown,
  LocaleDropdown,
} from '@components/molecules/NavDropdown';

import { paths } from '@paths';

import Link from 'next-translate-routes/link';

import { useRouter } from 'next/router';
import { localeToSaleorQuery } from '@utils/regions';

import {
  LayoutContactFragment,
  LayoutHeaderNaviguationFragment,
} from '@prismic/gqlTypes/prismicOperations';

import { CheckoutLineFragment } from '@sdk/gqlTypes/generatedOperations';

import { useMediaQuery } from '@hooks/useMediaQuery';
import { mediumScreen } from '@styles/constants';

import hamburgerHoverImg from 'images/hamburger-hover.svg';
import hamburgerImg from 'images/hamburger.svg';
import logoMobile from 'images/logo-mobile.svg';
import logoImg from 'images/logo.svg';

import UserMenu from './UserMenu';
import WishlistMenu from './WishlistMenu';
import NavIconButton from './NavIconButton';

import * as S from './styles';

interface MainMenuProps {
  prismicNav: LayoutHeaderNaviguationFragment[];
  contact: LayoutContactFragment[];
  isScrolled: boolean;
}

export const MainMenu: React.FC<MainMenuProps> = ({
  prismicNav,
  contact,
  isScrolled,
}) => {
  const { user } = useUser();
  const { checkout } = useCheckout();

  const { locale } = useRouter();
  const [activeDropdown, setActiveDropdown] = useState<string>(undefined);

  const [isMediumScreen] = useMediaQuery(`(max-width: ${mediumScreen}px)`);

  const { data } = useMainMenuQuery({
    variables: {
      ...localeToSaleorQuery(locale),
    },
  });

  const items = data?.menu?.items || [];

  const cartItemsQuantity =
    checkout?.lines?.reduce(
      (amount: number, line?: CheckoutLineFragment | null) =>
        line ? amount + line.quantity : amount,
      0
    ) || 0;

  const showDropdownHandler = (itemId: string, hasSubNavigation: boolean) => {
    if (hasSubNavigation) {
      setActiveDropdown(itemId);
    }
  };

  const hideDropdownHandler = () => {
    if (activeDropdown) {
      setActiveDropdown(undefined);
    }
  };

  return (
    <>
      <S.Nav as="nav" id="header" isScrolled={isScrolled}>
        <S.LogoWrapper>
          <Link href={paths.home}>
            <a>
              <S.Logo src={logoImg} alt="Reno" />
              <S.Logo
                src={logoMobile}
                width="24px"
                height="30px"
                alt="Reno"
                mobile
              />
            </a>
          </Link>
        </S.LogoWrapper>
        <S.LeftMenu>
          <S.NavWrapper>
            {isMediumScreen && (
              <S.Hamburger
                onClick={() =>
                  NiceModal.show(SideNavModal, {
                    items,
                    prismicNav,
                    contact,
                  })
                }
              >
                <ReactSVG src={hamburgerImg} />
                <ReactSVG
                  src={hamburgerHoverImg}
                  className="hamburger--hover"
                />
              </S.Hamburger>
            )}
            {items?.map((item) => {
              const hasSubNavigation = !!item?.children?.length;
              return (
                <SaleorNavDropdown
                  showDropdown={activeDropdown === item.id && hasSubNavigation}
                  onShowDropdown={() =>
                    showDropdownHandler(item.id, hasSubNavigation)
                  }
                  onHideDropdown={hideDropdownHandler}
                  key={item?.id}
                  isScrolled
                  {...item}
                />
              );
            })}
            {prismicNav?.map((item, i) => {
              const hasSubNavigation = !!item?.fields?.length;
              const itemId = `prismic-nav-${i}`;
              return (
                <PrismicNavDropdown
                  showDropdown={activeDropdown === itemId && hasSubNavigation}
                  onShowDropdown={() =>
                    showDropdownHandler(itemId, hasSubNavigation)
                  }
                  onHideDropdown={hideDropdownHandler}
                  key={itemId}
                  {...item}
                />
              );
            })}
          </S.NavWrapper>
        </S.LeftMenu>

        <S.RightMenu>
          <S.NavWrapper>
            <LocaleDropdown
              showDropdown={activeDropdown === 'locales-nav'}
              onShowDropdown={() => showDropdownHandler('locales-nav', true)}
              onHideDropdown={hideDropdownHandler}
              key="locales-nav"
            />
            {user ? (
              <WishlistMenu className="main-menu__nav-link" />
            ) : (
              <NavIconButton
                icon="wishlist"
                onClick={() => NiceModal.show(LoginModal)}
                className="main-menu__nav-link"
              />
            )}
            {user ? (
              <UserMenu />
            ) : (
              <NavIconButton
                icon="user"
                onClick={() => NiceModal.show(LoginModal)}
                className="main-menu__nav-link"
              />
            )}
            <NavIconButton
              icon="cart"
              onClick={() => NiceModal.show(CartModal)}
              counter={cartItemsQuantity}
              className="main-menu__nav-link"
            />
          </S.NavWrapper>
        </S.RightMenu>
      </S.Nav>
      <S.Collection id="collection-root" isScrolled />
    </>
  );
};
