import { useDeepEffect } from './useDeepEffect';
import useFirstRenderState from './useFirstRenderState';

/**
 * Run an effect only on updates.
 * Skip the first effect execution that occurrs after initial mount.
 */
export const useUpdateEffect: typeof useDeepEffect = (effect, deps): void => {
  const isFirstRender = useFirstRenderState();

  useDeepEffect(() => {
    if (!isFirstRender) {
      return effect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
