import { media, styled } from '@styles';

import { ContainerFlex } from '@components/templates/Container/Container';

interface WrapperProps {
  hidden: boolean | undefined;
}

export const Wrapper = styled(ContainerFlex)<WrapperProps>`
  background-color: ${(props) => props.theme.colors.secondaryDark};
  color: ${(props) => props.theme.colors.white};
  position: absolute;
  top: ${(props) => (props.hidden ? '-50px' : '0')};
  left: 0;
  right: 0;
  transition: all 0.2s ease;
  font-size: ${(props) => props.theme.typography.smallFontSize};
  display: flex;
  width: 100%;
  height: 40px;
  ${media.smallScreen`
      height: 30px;
  `}
`;
export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
`;

export const Copyright = styled.div`
  // display: flex;
  // justify-content: center;
`;

export const Contacts = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;
