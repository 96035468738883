import { useRef, useEffect, useState } from 'react';
import * as ReactDOM from 'react-dom';
import { Transition } from 'react-transition-group';

import * as S from './styles';
import { IProps } from './types';

export const NavOverlay: React.FC<IProps> = ({
  children,
  duration = 300,
  show,
  target,
}: IProps) => {
  const ref = useRef<Element | null>(null);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    ref.current = target || document.getElementById('collection-root');
    setMounted(true);
  }, []);

  const animationProps = {
    open: show,
  };
  return (
    mounted &&
    ref.current &&
    ReactDOM.createPortal(
      <Transition in={show} timeout={duration} unmountOnExit>
        {(state) => (
          <S.Overlay {...animationProps} state={state}>
            <S.OverlayInner>
              <S.Lightbox
                {...animationProps}
                state={state}
                onClick={(e) => e.stopPropagation()}
              >
                {children}
              </S.Lightbox>
            </S.OverlayInner>
          </S.Overlay>
        )}
      </Transition>,
      ref.current
    )
  );
};
