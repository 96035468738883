import React, { useContext } from 'react';

import Link from 'next-translate-routes/link';

import { FormattedMessage } from 'react-intl';

import { PrismicRichText } from '@prismicio/react';

import { linkResolver } from '@prismic/prismic-config';

import { ShopContext } from '@components/containers/ShopProvider/context';

import { BottomBar } from '@components/molecules/BottomBar';
import { ContactsNav } from '@components/molecules/ContactsNav';
import { SocialsNav } from '@components/molecules/SocialsNav';

import { PROVIDERS } from '@temp/core/config';

import * as S from './styles';
import { IProps } from './types';

export const Footer: React.FC<IProps> = ({
  title,
  contact,
  text,
  social,
  navGroups,
  copyright,
  terms,
}) => {
  const { availablePaymentGateways } = useContext(ShopContext);

  return (
    <S.Footer>
      <S.Wrapper>
        <S.NavGroup key="NavGroup_contact">
          <S.Title>{title && <PrismicRichText field={title} />}</S.Title>
          <S.Text>{text && <PrismicRichText field={text} />}</S.Text>
          <ContactsNav contact={contact} />
          <SocialsNav social={social} />
        </S.NavGroup>
        {navGroups?.map((group, i) => (
          <S.NavGroup key={`NavGroup_${i}`}>
            <S.Title>{group.primary?.title}</S.Title>
            <S.Nav>
              {group.fields?.map((item, ii) => (
                <S.NavItem key={`NavItem_${ii}`}>
                  <Link href={linkResolver(item.link)}>
                    <a>{item.label}</a>
                  </Link>
                </S.NavItem>
              ))}
            </S.Nav>
          </S.NavGroup>
        ))}
        <S.NavGroup key="payment-methods">
          <S.Title>
            <FormattedMessage defaultMessage="Payment we accept" id="Tsyo0g" />
          </S.Title>
          <S.Nav>
            {availablePaymentGateways.map(({ id, name }, index) => (
              <S.NavItem key={id}>
                <S.PaymentItem>
                  <img
                    src={PROVIDERS[id].icon}
                    width="20px"
                    height="20px"
                    alt=""
                  />
                  {PROVIDERS[id].label}
                </S.PaymentItem>
              </S.NavItem>
            ))}
          </S.Nav>
        </S.NavGroup>
      </S.Wrapper>
      <S.Bottom>
        <BottomBar copyright={copyright} terms={terms} />
      </S.Bottom>
    </S.Footer>
  );
};
