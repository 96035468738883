import { media, styled } from '@styles';
import { css } from 'styled-components';
import { Size, Font, ButtonTypes } from './types';

const padding = {
  lg: '1.25rem 2.44em',
  md: '1rem 3rem',
  sm: '0.9rem 1rem',
};

const fontSize = (fontSize: string, smallFontSize: string) => ({
  lg: fontSize,
  md: fontSize,
  sm: smallFontSize,
});

const fontFamily = (baseFontFamily: string, boldFontFamily: string) => ({
  base: baseFontFamily,
  bold: boldFontFamily,
});

const filled = css<ButtonTypes>`
  ${({ color, theme }) => css`
    background-color: ${theme.button.colors[color].background};
    color: ${theme.button.colors[color].color};
    border: none;
    &:hover,
    &.hover {
      background-color: ${theme.button.colors[color].hoverBackground};
      color: ${theme.button.colors[color].hoverColor};
    }
    &:active,
    &.active,
    &:active:focus {
      background-color: ${theme.button.colors[color].activeBackground};
    }
    &:disabled,
    &.disabled {
      background-color: ${theme.colors.disabled};
      &,
      &:hover {
        cursor: default;
      }
    }
  `}
`;

const outline = css<ButtonTypes>`
  ${({ color, size, theme }) => css`
    // background-color: ${theme.colors.white};
    color: ${theme.button.colors[color].background};
    border: 1px solid ${theme.button.colors[color].background};
    &:hover,
    &.hover {
      filter: brightness(0.9);
    }
    &:active,
    &.active,
    &:active:focus {
      filter: brightness(0.8);
    }
    &:disabled {
      filter: brightness(0.13);
    }
  `}
`;

const ghost = css<ButtonTypes>`
  ${({ color, theme }) => css`
    background: transparent;
    color: ${color === 'secondary'
      ? theme.link.secondary.color
      : theme.link.base.color};
    border: none;
    transform: none;
    text-decoration: none;
    &:hover,
    &.hover {
      color: ${color === 'secondary'
        ? theme.link.secondary.hoverColor
        : theme.link.base.hoverColor};
    }
  `}
`;

const appearances = {
  filled,
  ghost,
  outline,
};

const ButtonStyle = css<ButtonTypes>`
  ${({ theme, color, size, fullWidth, appearance }) => css`
    border: none;
    box-shadow: none;
    transition: ${theme.button.animation.transition};
    outline: none;
    cursor: pointer;
    border-radius: 4px;
    padding: ${padding[size]};
    ${fullWidth ? 'width:100%;' : 'width:auto;'}
    ${media.smallScreen`
      width: ${fullWidth ? '100%' : '88%'};
    `}
    ${appearance && appearances[appearance]}
  `}
`;

export const Button = styled.button<ButtonTypes>`
  ${ButtonStyle}
`;

export const ButtonLink = styled.a<ButtonTypes>`
  ${ButtonStyle}
`;

export const Text = styled.span<{ size: Size; font: Font }>`
  display: inline-block;
  font-size: ${({
    size,
    theme: {
      button: { typography },
    },
  }) => fontSize(typography.fontSize, typography.smallFontSize)[size]};
  font-family: ${({
    font,
    theme: {
      button: { typography },
    },
  }) => fontFamily(typography.baseFontFamily, typography.boldFontFamily)[font]};
  text-transform: ${(props) =>
    props.font === 'bold' ? 'uppercase' : undefined};
  letter-spacing: ${(props) => (props.font === 'bold' ? '1px' : undefined)};
  ${media.largeScreen`
    font-size: ${(props) => props.theme.button.typography.smallFontSize};
  `}
`;
