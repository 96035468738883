import { media, styled } from '@styles';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: ${({ theme: { typography } }) => typography.smallFontSize};
  height: auto;
  text-align: center;
  p {
    margin: 5px 0;
  }
  ${media.smallScreen`
    display: block;
  `}
`;
