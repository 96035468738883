import { useUserDetailsQuery } from '@sdk/gqlTypes/generatedHooks';

export const useUser = () => {
  const { data, loading } = useUserDetailsQuery({
    skip: typeof window === 'undefined',
  });

  const user = data?.user;

  const authenticated = !!user?.id;
  const loaded = !loading;

  return { user, loading, loaded, authenticated };
};
