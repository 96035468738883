import { LOCALES } from '@utils/regions';
import { ReactElement } from 'react';

import { useRouter } from 'next/router';
import Link from 'next-translate-routes/link';

import { paths } from '@paths';

import * as S from './styles';

interface Props {
  onPicked: () => void;
}

export function LocalePicker({ onPicked }: Props): ReactElement {
  const { locale } = useRouter();

  return (
    <S.Wrapper>
      {LOCALES.map((item) => (
        <S.Item
          active={locale === item.slug}
          key={item.name}
          onClick={() => onPicked()}
        >
          <Link href={paths.home} locale={item.slug}>
            <a>
              <img src={item.icon} alt="" />
              {item.name}
            </a>
          </Link>
        </S.Item>
      ))}
    </S.Wrapper>
  );
}
