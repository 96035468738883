import ReactDOM from 'react-dom';
import React, { useEffect, useState, useCallback, useMemo } from 'react';

import { getLogicalPosition } from '../Popoverlay/utils';

import { ToastrItem } from './ToastrItem';
import { ToastrContext } from './ToastrContext';

import * as S from './styles';

import {
  Position,
  Status,
  IToastrItemProps,
  IToastrProviderProps,
} from './types';

const defaultProps = {
  position: 'bottomRight' as Position,
  status: 'neutral' as Status,
  duration: 3000,
  hasIcon: true,
  destroyByClick: true,
  preventDuplicates: false,
};

export const ToastrProvider: React.FC<IToastrProviderProps> = ({
  children,
  ...props
}) => {
  const [items, setItems] = useState<IToastrItemProps[]>([]);
  const [createParents, setCreateParents] = useState<boolean>(false);

  useEffect(() => {
    setCreateParents(true);
  }, []);

  const show = useCallback(
    ({ message, title, ...option }: IToastrItemProps) => {
      const options: IToastrProviderProps = {
        ...defaultProps,
        ...props,
        ...option,
      };

      const newItems = [...items];
      let push = true;
      if (options.preventDuplicates) {
        push = !newItems.find(
          (item) => item.title === title && item.message === message
        );
      }

      if (push) {
        options.position = getLogicalPosition(
          'ltr',
          options.position as string
        ) as Position;

        newItems.push({
          ...options,
          title,
          message,
          icon: options.icons ? options.icons[options.status as Status] : '',
        });
        setItems(newItems);
      }
    },
    [items]
  );

  const contextValue = useMemo(() => ({ show }), [show]);

  return (
    <>
      <ToastrContext.Provider value={contextValue}>
        {children}
      </ToastrContext.Provider>
      {createParents &&
        ['topStart', 'topEnd', 'bottomStart', 'bottomEnd'].map((position) =>
          ReactDOM.createPortal(
            <S.ToastrContainer
              position={position}
              isTop={position === 'topEnd' || position === 'topStart'}
            >
              <div className="overlay-pane" id={`toastr${position}`} />
            </S.ToastrContainer>,
            document.getElementById('popover-root')!
          )
        )}
      {items.map((item, index) => (
        <ToastrItem key={index} {...item} />
      ))}
    </>
  );
};

ToastrProvider.displayName = 'ToastrProvider';
