import gql from 'graphql-tag';

export const accountErrorFragment = gql`
  fragment AccountErrorFragment on AccountError {
    code
    field
    message
  }
`;

export const TOKEN_REFRESH = gql`
  ${accountErrorFragment}
  mutation refreshToken($refreshToken: String!) {
    tokenRefresh(refreshToken: $refreshToken) {
      token
      errors: accountErrors {
        ...AccountErrorFragment
      }
    }
  }
`;

export const TOKEN_CREATE = gql`
  ${accountErrorFragment}
  mutation tokenCreate($email: String!, $password: String!) {
    tokenCreate(email: $email, password: $password) {
      token
      refreshToken
      user {
        email
        firstName
        lastName
      }
      errors: accountErrors {
        ...AccountErrorFragment
      }
    }
  }
`;

export const PASSWORD_RESET = gql`
  mutation passwordReset($email: String!, $password: String!, $token: String!) {
    setPassword(email: $email, password: $password, token: $token) {
      token
      refreshToken
      errors {
        message
        field
        code
      }
      errors {
        field
        message
      }
    }
  }
`;
