import React from 'react';
import { ReactSVG } from 'react-svg';

import closeImg from 'images/x.svg';
import * as S from './styles';
import { IProps } from './types';

export const CardHeader: React.FC<IProps> = ({
  children,
  customIcon,
  divider = false,
  formImage,
  onHide,
  textStyle = 'title',
  titleSize = 'lg',
}: IProps) => {
  const withCloseIcon = !!onHide && !customIcon;
  return (
    <S.Header divider={divider}>
      <S.CloseImg>
        {withCloseIcon && <ReactSVG src={closeImg} onClick={onHide} />}
        {customIcon}
      </S.CloseImg>
      <S.HeadImg>{formImage && <ReactSVG src={formImage} />}</S.HeadImg>
      {textStyle === 'title' ? (
        <S.Title size={titleSize}>{children}</S.Title>
      ) : (
        <S.Paragraph>{children}</S.Paragraph>
      )}
    </S.Header>
  );
};
