import { useCallback } from 'react';
import { useCheckout } from '@sdk/providers/CheckoutProvider';
import { QuantityInput } from '@components/molecules/QuantityInput';
import { CheckoutLineFragment } from '@sdk/gqlTypes/generatedOperations';

import * as S from './styles';

export const ProductListItemQuantity: React.FC<{
  line: CheckoutLineFragment;
}> = ({ line }) => {
  const { updateItem } = useCheckout();

  const quantityChange = useCallback(
    (val: number) => {
      updateItem(line.variant.id, Number(val), line.productAttributes);
    },
    [updateItem, line]
  );

  return (
    <S.Quantity>
      <QuantityInput
        quantity={line.quantity}
        maxQuantity={line.variant.quantityAvailable}
        disabled={false}
        onQuantityChange={quantityChange}
        hideErrors={!line.variant.id}
      />
    </S.Quantity>
  );
};
