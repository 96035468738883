import { clsx } from 'clsx';
import upperFirst from 'lodash/upperFirst';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import { commonMessages } from '../../../../intl';

import * as S from './styles';

export interface IAttribute {
  id?: string;
  name?: string | null;
  translatedName?: string;
  values: Array<{
    name?: string | null;
    translatedName?: string;
  }>;
}

interface Props {
  alwaysShowAll?: boolean;
  withBlockDisplayedAttributes?: boolean;
  attributes: IAttribute[];
}

export const ProductListItemAttributes: React.FC<Props> = ({
  alwaysShowAll = false,
  withBlockDisplayedAttributes = false,
  attributes,
}) => {
  const [showAllAttr, showAllAttrHandler] = useState(false);
  const intl = useIntl();

  return (
    <S.DetailsVariantWrapper
      className={clsx({
        attr: withBlockDisplayedAttributes,
        show: showAllAttr || alwaysShowAll,
      })}
    >
      <S.DetailsVariant>
        {attributes.map((item, itemI) => {
          const attributeName = upperFirst(
            item.translatedName || item.name
          ).replace('-', ' ');

          let attributeValue = item.values
            .map((value) =>
              upperFirst(
                (value.translatedName || value.name)
                  .replace(attributeName.toLowerCase(), '')
                  .replace('-', ' ')
                  .trim()
              )
            )
            .join(', ');

          if (attributeName === 'Height' || attributeName === 'Width') {
            attributeValue = `${attributeValue.slice(
              0,
              attributeValue.length - 1
            )} cm`;
          }

          return (
            <S.DetailsVariantItem key={itemI}>
              <S.ItemHint>{attributeName}: </S.ItemHint>
              <span>{attributeValue}</span>{' '}
            </S.DetailsVariantItem>
          );
        })}
      </S.DetailsVariant>
      {!alwaysShowAll && (
        <S.DetailsVariantMoreButton
          onClick={(e) => {
            e.stopPropagation();
            showAllAttrHandler(!showAllAttr);
          }}
          type="button"
        >
          {showAllAttr
            ? intl.formatMessage(commonMessages.less)
            : intl.formatMessage(commonMessages.more)}
        </S.DetailsVariantMoreButton>
      )}
    </S.DetailsVariantWrapper>
  );
};
