/* eslint-disable jsx-a11y/anchor-is-valid */
import { usePrevious } from '@hooks/usePrevious';
import { DiscountForm } from '@components/organisms/DiscountForm';
import { IFormError } from '@types';
import { useCheckout } from '@sdk/providers/CheckoutProvider';
import {
  useAddCheckoutPromoCodeMutation,
  useRemoveCheckoutPromoCodeMutation,
} from '@sdk/gqlTypes/generatedHooks';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { useRouter } from 'next/router';
import { localeToSaleorQuery } from '@utils/regions';

import * as S from './styles';
import { IProps } from './types';

export const CartPromoCode: React.FC<IProps> = ({
  setLoading,
  loading,
}: IProps) => {
  const intl = useIntl();
  const router = useRouter();
  const locale = router.query.locale?.toString();

  const { checkout } = useCheckout();
  const { subtotalPrice, voucherCode } = checkout || {};

  const [addCheckoutPromoCodeMutation, { data: dataAdd, error: errorAdd }] =
    useAddCheckoutPromoCodeMutation();
  const [removeCheckoutPromoCodeMutation] =
    useRemoveCheckoutPromoCodeMutation();

  const [active, setActive] = useState(!!voucherCode);
  const [promoCodeErrors, setPromoCodeErrors] = useState<IFormError[]>([]);

  const previousAddedVoucherCode = usePrevious(voucherCode);
  useEffect(() => {
    if (!previousAddedVoucherCode && voucherCode) {
      setActive(true);
    }
  }, [voucherCode, previousAddedVoucherCode]);

  const addCheckoutPromoCode = useCallback(
    async (promoCode: string): Promise<void> => {
      setLoading(true);
      await addCheckoutPromoCodeMutation({
        variables: {
          checkoutId: checkout?.id,
          promoCode,
          ...localeToSaleorQuery(locale),
        },
      });
      if (errorAdd?.graphQLErrors?.length) {
        setPromoCodeErrors(errorAdd?.graphQLErrors as any[]);
      } else if (dataAdd?.checkoutAddPromoCode?.errors.length) {
        setPromoCodeErrors(dataAdd?.checkoutAddPromoCode?.errors);
      } else {
        setPromoCodeErrors([]);
      }
      setLoading(false);
    },
    [addCheckoutPromoCodeMutation, setLoading]
  );

  const prevSubTotalPriceAmount = usePrevious(subtotalPrice?.gross.amount);
  const subtotalChanged =
    prevSubTotalPriceAmount !== subtotalPrice?.gross.amount;

  useEffect(() => {
    // update promo code on cart items change
    if (subtotalChanged && voucherCode) {
      addCheckoutPromoCode(voucherCode);
    }
  }, [voucherCode, addCheckoutPromoCode, subtotalChanged]);

  const handleAddPromoCode = useCallback(
    ({ promoCode }: { promoCode: string }) => {
      addCheckoutPromoCode(promoCode);
    },
    [addCheckoutPromoCode]
  );

  const handleRemovePromoCode = useCallback(async () => {
    setLoading(true);
    await removeCheckoutPromoCodeMutation({
      variables: {
        checkoutId: checkout?.id,
        promoCode: voucherCode,
        ...localeToSaleorQuery(locale),
      },
    });
    setLoading(false);
    setActive(false);
  }, [voucherCode, removeCheckoutPromoCodeMutation]);

  return (
    <S.FooterLink>
      {/* <S.FooterLinkItem href="" className="cart__footer__links-item">
        {intl.formatMessage({
          defaultMessage: 'I have referral from my friend',
        })}
      </S.FooterLinkItem> */}
      {!active && (
        <S.FooterLinkItem
          href=""
          onClick={(e) => {
            e.preventDefault();
            setActive((s) => !s);
          }}
        >
          {intl.formatMessage({
            defaultMessage: '+ Add promo code',
            id: 'y/OnEQ',
          })}
        </S.FooterLinkItem>
      )}
      {active && (
        <DiscountForm
          promoCodePending={loading}
          errors={promoCodeErrors}
          onClose={handleRemovePromoCode}
          discount={{
            promoCode: voucherCode,
          }}
          handleSubmit={handleAddPromoCode}
        />
      )}
    </S.FooterLink>
  );
};
