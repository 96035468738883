import {
  LinkableFragment,
  LayoutHeaderNaviguationFragment,
} from '@prismic/gqlTypes/prismicOperations';

import { useIntl } from 'react-intl';

import Link from 'next-translate-routes/link';
import { paths } from '@paths';
import { linkResolver } from '@prismic/prismic-config';

import { media, styled } from '@styles';

interface NavPrismicProps {
  items?: LayoutHeaderNaviguationFragment[];
  hideOverlay(): void;
}

interface NavPrismicItemProps {
  label: string | null;
  link: LinkableFragment | { url: string } | null;
  hideOverlay(): void;
}

const NavWrapper = styled.ul`
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 148px;
  display: flex;
  flex-wrap: wrap;
  ${media.smallScreen`
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 35px;
  `}
`;

const NavItem = styled.li`
  width: 50%;
  margin-bottom: 12px;
  ${media.smallScreen`
    margin-bottom: 20px;
  `}
`;

const NavLink = styled.a`
  cursor: pointer;
  align-items: center;
  color: ${(props) => props.theme.colors.baseFont};
  font-family: ${(props) => props.theme.typography.thinFontFamily};
  line-height: 2em;
  font-size: ${(props) => props.theme.typography.baseFontSize};
  text-decoration: none;
  text-transform: capitalize;
  transition: 0.3s;
  position: relative;
  ${media.smallScreen`
    line-height: 24px;
  `}
  &.active {
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -5px;
      height: 2px;
      background-color: ${(props) => props.theme.colors.red};
    }
  }
`;

function NavPrismicItem({ hideOverlay, label, link }: NavPrismicItemProps) {
  return (
    <NavItem>
      <Link href={linkResolver(link)} passHref>
        <NavLink>
          <span onClick={hideOverlay}>{label}</span>
        </NavLink>
      </Link>
    </NavItem>
  );
}

function NavPrismic({ items, hideOverlay }: NavPrismicProps) {
  const intl = useIntl();

  return (
    <NavWrapper>
      <NavPrismicItem
        label={intl.formatMessage({ defaultMessage: 'Home', id: 'ejEGdx' })}
        link={{ url: paths.home }}
        hideOverlay={hideOverlay}
        key="nav-prismic-home"
      />
      {items &&
        items?.map((item: LayoutHeaderNaviguationFragment, i) => {
          const { primary } = item;
          const { label, link } = primary;
          return (
            <NavPrismicItem
              label={label}
              link={link}
              hideOverlay={hideOverlay}
              key={`nav-prismic-${i}`}
            />
          );
        })}
    </NavWrapper>
  );
}

export default NavPrismic;
