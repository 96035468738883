/* eslint-disable react/destructuring-assignment */
import { Component, ReactNode } from 'react';

type IProps = {
  children: ReactNode;
};

export class ErrorBoundary extends Component<IProps> {
  static getDerivedStateFromError(/* error: Error */): { hasError: boolean } {
    return { hasError: true };
  }

  state = { hasError: false };

  componentDidCatch(/* error: Error, errorInfo: React.ErrorInfo */): void {
    // log the error to an error reporting service
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong :-/</h1>;
    }

    return <>{this.props.children}</>;
  }
}
