/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/anchor-is-valid */
import { useRouter } from 'next/router';

import { useWishlist } from '@sdk/hook/useWishlist';

import { paths } from '@paths';

import NavIconButton from './NavIconButton';

interface WishlistMenuProps {
  className?: string;
}

export const WishlistMenu: React.FC<WishlistMenuProps> = ({ className }) => {
  const { items: wishlistItems } = useWishlist();
  const { push } = useRouter();

  const wishlistItemsQuantity = (wishlistItems && wishlistItems?.length) || 0;

  return (
    <NavIconButton
      icon="wishlist"
      onClick={() => push(paths.wishlist)}
      counter={wishlistItemsQuantity}
      className={className}
    />
  );
};

export default WishlistMenu;
