import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { Loader } from '@components/atoms/Loader';
import { CartPromoCode } from '@components/molecules/CartPromoCode';

import { commonMessages } from '../../../../intl';

import * as S from './styles';
import { IProps } from './types';

/**
 * Cart footer to use with conjunction of cart rows
 */
const CartFooter: React.FC<IProps> = ({
  subtotalPrice,
  shippingPrice,
  discountPrice,
  totalPrice,
}: IProps) => {
  const intl = useIntl();
  const [promoCodeLoading, setPromoCodeLoading] = useState(false);

  return (
    <S.Wrapper showShipping={!!shippingPrice} showDiscount={!!discountPrice}>
      <S.SubtotalText>
        {intl.formatMessage({
          defaultMessage: 'Subtotal',
          id: 'L8seEc',
        })}
      </S.SubtotalText>
      <S.SubtotalPrice>{subtotalPrice}</S.SubtotalPrice>
      {shippingPrice && (
        <>
          <S.ShippingText>
            {intl.formatMessage(commonMessages.shipping)}
          </S.ShippingText>
          <S.ShippingPrice>{shippingPrice}</S.ShippingPrice>
        </>
      )}
      {discountPrice && (
        <>
          <S.DiscountText>
            {intl.formatMessage({
              defaultMessage: 'Promo Code',
              id: 'CyOkb5',
            })}
          </S.DiscountText>
          <S.DiscountPrice>
            {promoCodeLoading ? <Loader inline /> : <>{discountPrice}</>}
          </S.DiscountPrice>
        </>
      )}
      <S.TotalText>
        {intl.formatMessage({
          defaultMessage: 'Total',
          id: 'MJ2jZQ',
        })}
      </S.TotalText>
      <S.TotalPrice>
        {promoCodeLoading ? <Loader primary inline /> : <>{totalPrice}</>}
      </S.TotalPrice>
      <S.PromoCode>
        <CartPromoCode
          loading={promoCodeLoading}
          setLoading={setPromoCodeLoading}
        />
      </S.PromoCode>
    </S.Wrapper>
  );
};

export { CartFooter };
