import isEqual from 'lodash/isEqual';

import {
  CheckoutLineFragment,
  SelectedAttributeValueFragment,
  WishlistItemProductAttributeFragment,
} from '@sdk/gqlTypes/generatedOperations';

import { SelectedAttributeValueInput } from '@sdk/gqlTypes/generatedTypes';

export function compareAttributes(
  productAttributes: Array<
    SelectedAttributeValueFragment | WishlistItemProductAttributeFragment
  >,
  selectedProductAttributes: Array<SelectedAttributeValueInput>
): boolean {
  if (productAttributes.length !== selectedProductAttributes.length) {
    return false;
  }

  const productAttributesNormalized = productAttributes.reduce(
    (acc, cur) => ({
      ...acc,
      [cur.attribute.id]: cur.values,
    }),
    {}
  );

  const selectedProductAttributesNormalized = selectedProductAttributes.reduce(
    (acc, cur) => ({
      ...acc,
      [cur.attributeId]: cur.values,
    }),
    {}
  );

  return isEqual(
    productAttributesNormalized,
    selectedProductAttributesNormalized
  );
}

export function extractExistingLine(
  lines: CheckoutLineFragment[],
  variantId: string,
  productAttributes: Array<SelectedAttributeValueFragment>
): [CheckoutLineFragment | undefined, CheckoutLineFragment[]] {
  let found: CheckoutLineFragment | undefined;
  const alteredLines: CheckoutLineFragment[] = [];

  lines.forEach((line) => {
    if (
      line.variant.id === variantId &&
      isEqual(line.productAttributes, productAttributes)
    ) {
      found = line;
    } else {
      alteredLines.push(line);
    }
  });

  return [found, alteredLines];
}
