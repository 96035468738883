import { IFormError } from '@types';

export const NON_FIELD_ERROR = 'nonFieldError';

export function groupErrorsByFields(
  errors: IFormError[],
  ...keys: string[]
): { [key: string]: IFormError[] } {
  return errors.reduce((o, error) => {
    const key =
      error.field && keys.includes(error.field) ? error.field : NON_FIELD_ERROR;
    (o[key] = o[key] || []).push(error);
    return o;
  }, {});
}
