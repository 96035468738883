// import './scss/index.scss';

import React from 'react';

import { useIntl } from 'react-intl';
import { useModal } from '@ebay/nice-modal-react';

import { CardHeader } from '@components/molecules/CardHeader';
import { Button } from '@components/atoms/Button';

import Link from 'next-translate-routes/link';

import { paths } from '@paths';

import accountCreated from 'images/account-created.svg';
import mailSend from 'images/mail-send.svg';

import { commonMessages } from '../../../../intl';

import * as S from './styles';

export const RegisterConfirmation: React.FC<{ data?: any }> = ({ data }) => {
  const intl = useIntl();
  const modal = useModal();

  const title = data.requiresConfirmation
    ? intl.formatMessage(commonMessages.success)
    : intl.formatMessage(commonMessages.congratulations);
  const image = data.requiresConfirmation ? mailSend : accountCreated;
  const text = data.requiresConfirmation
    ? intl.formatMessage({
        defaultMessage:
          'A link to confirm your email address is knocking at your door! Just one step to your account!',
        id: 'eh0pHa',
      })
    : intl.formatMessage({
        defaultMessage:
          'That’s one small step for a mankind - one giant leap for doorkind. Your account has now been created.',
        id: 'YgRLgs',
      });
  const content = data.requiresConfirmation ? (
    <Link href={paths.home}>
      <a>
        <Button fullWidth type="button" onClick={modal.remove}>
          {intl.formatMessage({
            defaultMessage: 'go to homepage',
            id: 'Oj28K/',
          })}
        </Button>
      </a>
    </Link>
  ) : (
    <>
      <Link href={paths.home}>
        <a>
          <Button type="button" onClick={modal.remove}>
            {intl.formatMessage({
              defaultMessage: 'go to homepage',
              id: 'Oj28K/',
            })}
          </Button>
        </a>
      </Link>
      <Link href={paths.account}>
        <a>
          <Button appearance="outline" type="button" onClick={modal.remove}>
            {intl.formatMessage({
              defaultMessage: 'go to your account',
              id: 'tcCsSQ',
            })}
          </Button>
        </a>
      </Link>
    </>
  );

  return (
    <S.Wrapper>
      <CardHeader divider formImage={image} onHide={modal.remove}>
        {title}
      </CardHeader>
      {text && <S.Text>{text}</S.Text>}
      {content && <S.Content>{content}</S.Content>}
    </S.Wrapper>
  );
};
