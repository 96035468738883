import { Chip } from '@components/atoms/Chip';
import { ErrorMessage } from '@components/atoms/ErrorMessage';
import { Input } from '@components/atoms/Input';

import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import Spinner from 'images/spinner.svg';
import * as S from './styles';
import { IProps } from './types';

export const DiscountForm: React.FC<IProps> = ({
  handleSubmit: handleSubmitDiscount,
  discount,
  errors,
  formId,
  formRef,
  promoCodePending,
  onClose,
}: IProps) => {
  const intl = useIntl();
  const promoCode = discount?.promoCode;

  const {
    control,
    handleSubmit,
    reset: resetForm,
    watch,
  } = useForm({
    defaultValues: {
      formikPromoCode: '',
    },
  });

  const handleOnSubmit = async (values) => {
    if (handleSubmitDiscount) {
      handleSubmitDiscount({
        promoCode: values.formikPromoCode,
      });
      resetForm();
    }
  };

  const hasErrors = !!(errors && errors.length);
  const formikPromoCode = watch('formikPromoCode');

  return (
    <S.DiscountForm
      id={formId}
      ref={formRef}
      onSubmit={handleSubmit(handleOnSubmit)}
    >
      {!promoCode && (
        <S.Input>
          <S.InputWithButton>
            <S.InputWrapper>
              <Controller
                name="formikPromoCode"
                control={control}
                render={({ field }) => (
                  <Input
                    data-cy="checkoutPaymentPromoCodeInput"
                    error={hasErrors}
                    label={intl.formatMessage({
                      defaultMessage: 'Enter promo code',
                      id: '86RjOu',
                    })}
                    {...field}
                  />
                )}
              />
            </S.InputWrapper>
            <S.ButtonWrapper>
              <S.StyledButton
                type="submit"
                data-cy="checkoutPaymentPromoCodeBtn"
                active={formikPromoCode?.length > 0}
              >
                <S.Spinner src={Spinner} cHidden={promoCodePending} alt="" />
                {!promoCodePending
                  ? intl.formatMessage({
                      defaultMessage: 'Apply',
                      id: 'EWw/tK',
                    })
                  : null}
              </S.StyledButton>
            </S.ButtonWrapper>
          </S.InputWithButton>
          <ErrorMessage errors={errors} />
        </S.Input>
      )}
      {promoCode && (
        <S.PromoCodeValue>
          <span>
            {intl.formatMessage({
              defaultMessage: 'Promo code:',
              id: 'KWRMF1',
            })}
          </span>
          <S.ChipsWrapper>
            <Chip onClose={onClose}>
              <span data-cy="checkoutPaymentPromoCodeChip">{promoCode}</span>
            </Chip>
          </S.ChipsWrapper>
        </S.PromoCodeValue>
      )}
    </S.DiscountForm>
  );
};
