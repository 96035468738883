import { ReactElement } from 'react';
import Link from 'next-translate-routes/link';

import { LinkableFragment } from '@prismic/gqlTypes/prismicOperations';

import { linkResolver } from '@prismic/prismic-config';

interface Props {
  label: string | null;
  link: LinkableFragment | null;
  // image?: PrismicImage;
  // hideOverlay?(): void;
}

export function PrismicNavItem(props: Props): ReactElement {
  const { label, link /* , image, hideOverlay */ } = props;
  return (
    <Link href={linkResolver(link)}>
      <a className="main-menu__nav-link">{label}</a>
    </Link>
  );
}
