import { styled } from '@styles';

export const Checkbox = styled.div`
  margin-bottom: 1.25rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  position: relative;
  margin-left: -4px;
  font-family: Inter;
  color: ${(props) => props.theme.colors.graySemi};
`;

export const Label = styled.label<{ color: 'primary' | 'secondary' }>`
  display: flex;
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;
  padding-right: 0.5rem;
  input[type='checkbox'] {
    display: none;
    position: relative;
    right: -999em;
  }
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 26px;
    width: 26px;

    span {
      border: 1px solid ${(props) => props.theme.colors.dividerDark};
      border-radius: 4px;
      width: 20px;
      height: 20px;
      display: inline-block;
    }

    :focus {
      border: none;
      outline: none;
    }
  }

  input:checked + div {
    span {
      background-color: ${(props) =>
        props.theme.button.colors[props.color].background};
      background-clip: content-box;
      border-color: ${(props) =>
        props.theme.button.colors[props.color].background};
      &:after {
        color: ${(props) => props.theme.button.colors[props.color].color};
        content: '\\2713';
        position: absolute;
        top: 3px;
        left: 5px;
      }
    }
  }
`;
