import { createContext } from 'react';

import { IToastrItemProps } from './types';

export type IMessageContext = (props: IToastrItemProps) => void;

export interface IToastrContext {
  show: IMessageContext;
}

export const ToastrContext = createContext<IToastrContext>(null);
