import ReactDOM from 'react-dom';
import React, { useEffect } from 'react';

import { Message } from '@components/atoms/Message';

import { isRightPosition } from '../Popoverlay/utils';

// import { ToastrIcon } from './ToastrIcon';

import * as S from './styles';
import { IToastrItemProps } from './types';

export const ToastrItem: React.FC<IToastrItemProps> = (props) => {
  const [show, setShow] = React.useState(true);
  const [animation, setAnimation] = React.useState('animation');

  const destroyItem = () => {
    setAnimation('animation');
    setTimeout(() => {
      setShow(false);
    }, 100);
  };

  const onClick = () => {
    if (props.destroyByClick) {
      destroyItem();
    }
  };

  useEffect(() => {
    if (props?.duration && props.duration > 0) {
      setTimeout(() => {
        destroyItem();
      }, props.duration);
    }
    if (animation === 'animation') {
      setTimeout(() => {
        setAnimation('');
      }, 10);
    }
  }, []);

  return show
    ? ReactDOM.createPortal(
        <S.Toastr
          isRight={isRightPosition('ltr', props.position!)}
          className={animation}
          onClick={onClick}
          {...props}
        >
          <Message status={props.status} title={props.title} onClick={onClick}>
            {props.message}
          </Message>
          {/* props.icon && props.hasIcon && <ToastrIcon icon={props.icon} className="icon" />
          <div className="content-container">
            {props.title && <span className="title">{props.title}</span>}
            <div className="message">{props.message}</div>
          </div>
          */}
        </S.Toastr>,
        document.getElementById(`toastr${props.position}`)!
      )
    : null;
};
