import {
  analyticsDataPlaneUrl,
  apiUrl,
  mediaUri,
  prismicUrl,
} from '../constants';

export const PreconnectManifest = () => (
  <>
    <link rel="preconnect" href={apiUrl} />
    <link rel="preconnect" href={mediaUri} />
    <link rel="preconnect" href={analyticsDataPlaneUrl} />
    <link rel="preconnect" href={prismicUrl} />
  </>
);
