import { media, styled } from '@styles';
import { css } from 'styled-components';

import checkImage from 'images/check.svg';

export const DropdownWrapper = styled.ul<{ active: boolean }>`
  ${media.mediumScreen`
    display: none;
  `}
`;

export const DropdownItem = styled.li`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin: 0 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-transform: capitalize;
  ${media.largeScreen`
    margin: 0 20px;
  `}
  ${media.mediumScreen`
    & li {
      display: none;
    }
  `}
`;

export const DropdownBody = styled.li<{ active: boolean }>`
  background: ${(props) => props.theme.colors.white};
  left: 0;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 101;
  display: flex;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.2s ease, opacity 0.2s ease;
  margin: auto;
  ${(props) =>
    props.active &&
    css`
      border-top-color: ${props.theme.colors.light};
      border-top-style: solid;
      border-top-width: 1px;
      background-color: #f6f7f8;
      max-height: 400px;
      opacity: 1;
      overflow: visible;
      transition: max-height 0.2s ease, opacity 0.6s ease;
    `}

  li *,
  ul * {
    display: block;
  }

  > ul > li {
    align-self: flex-start;
    position: relative;
    width: 140px;
    ${media.largeScreen`
      width: 120px;
    `}

    // 2nd level name
    > a {
      font-size: ${(props) => props.theme.typography.baseFontSize}
      position: relative;
      text-transform: uppercase;
      padding-bottom: 10px;
      margin-bottom: 3px;
      ${media.largeScreen`
        padding-bottom: 8px;
      `}
    }
    > ul > li:first-child {
      position: relative;

      &:before {
        background-color: ${(props) => props.theme.colors.primaryLight};
        content: '';
        height: 1px;
        left: 0;
        position: absolute;
        top: -3px;
        width: 100%;
      }
    }

    ul {
      // 3rd+ level names
      a {
        font-size: ${(props) => props.theme.typography.smallFontSize};
        font-weight: normal;
        padding: 6px 0;
        text-transform: capitalize;
      }

      li > ul {
        margin-left: 10px;
      }
    }
  }
`;

export const Collection = styled.ul`
  padding: 1rem;
  display: inline-flex;
  justify-content: center;
  gap: 1.5em;
  width: 100%;
`;

export const NavItemImage = styled.div`
  width: 140px;
  height: 182px;
  margin-bottom: 10px;
  background-position: center;
  background-size: cover;
  position: relative;
  ${media.largeScreen`
    margin-bottom: 8px;
    width: 120px;
    height: 156px;
  `}
  ${media.smallScreen`
    width: 100px;
    height: 130px;
    margin-bottom: 13px;
  `}
  &::before {
    content: '';
    font-size: 30px;
    border-radius: 50%;
    width: 66px;
    height: 66px;
    ${media.largeScreen`
      width: 46px;
      height: 46px;
    `}
    ${media.smallScreen`
      width: 30px;
      height: 30px;
    `}
    z-index: 1;
    opacity: 0;
    bottom: 50%;
    right: 50%;
    position: absolute;
    transform: translate(50%, 50%);
  }
  &::after {
    content: '';
    display: block;
    width: 15px;
    height: 11px;
    ${media.largeScreen`
      width: 11px;
      height: 8px;
    `}
    ${media.smallScreen`
      width: 10px;
      height: 7px;
    `}
    background-image: url(${checkImage});
    background-size: cover;
    background-repeat: no-repeat;
    bottom: 50%;
    right: 50%;
    position: absolute;
    transform: translate(50%, 50%);
    z-index: 2;
    opacity: 0;
  }
`;
export const NavItemOverlay = styled(NavItemImage)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #2c2c2c;
  opacity: 0;
  transition: 0.3s;
`;

export const NavItemTitle = styled.span`
  padding-left: 21px;
  text-transform: capitalize;
  font-family: ${(props) => props.theme.typography.thinFontFamily};
  font-size: ${(props) => props.theme.typography.smallFontSize};
  display: block;
  ${media.largeScreen`
    padding-left: 18px;
  `}
`;

export const NavPillTitle = styled(NavItemTitle)`
  border-radius: 30px;
  padding: 9px 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  transition: all 0.25s ease;
  white-space: nowrap;
`;

export const NavItem = styled.li<{ isScrolled?: boolean }>`
  a {
    transition: color 0.6s;
    background-color: #f0e9eb;
    &:hover {
      color: ${(props) => props.theme.colors.red};
      background-color: #ffffff;
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.07);
      ${NavItemImage} {
        &::before {
          opacity: 0.2;
          border: 2px solid #ffffff;
        }
        &::after {
          opacity: 0.2;
        }
      }
      ${NavItemOverlay} {
        opacity: 0.6;
      }
    }
    &.active {
      background-color: #ffffff;
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.07);
      ${NavItemImage} {
        &::before {
          opacity: 1;
          background-color: #${(props) => props.theme.colors.gold};
        }
        &::after {
          opacity: 1;
        }
      }
      ${NavItemOverlay} {
        opacity: 0.6;
      }
      ${NavItemTitle} {
        color: ${(props) => props.theme.colors.red};
      }
      ${NavPillTitle} {
        color: ${(props) => props.theme.colors.white};
        background-color: ${(props) => props.theme.colors.red};
      }
      &:hover {
        ${NavItemImage}::before {
          border: none;
        }
      }
    }
  }
`;

export const StackedNavItem = styled.li`
  display: flex !important;
  flex-direction: column !important;
  gap: 1em !important;
  span {
    padding-top: 10px;
    padding-bottom: 10px;
    ${media.largeScreen`
      padding-top: 8px;
      padding-bottom: 8px;
    `}
  }
`;

export const LocaleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const LocaleRegion = styled.div`
  padding: 28px 299px 36px 143px;
  flex-grow: 1;
  ${media.largeScreen`
    padding: 18px 36px 18px 64px;
  `}
  ${media.mediumScreen`
    padding: 16px 36px 20px 64px;
    background-color: #ffffff;
  `}
  ${media.smallScreen`
    background-color: transparent;
    padding: 0;
  `}
`;

export const LocaleLanguage = styled.div`
  padding: 28px 143px 36px 299px;
  background-color: #f6f7f8;
  flex-basis: 50%;
  ${media.largeScreen`
    padding: 18px 64px 18px 36px;
  `}
  ${media.mediumScreen`
    padding: 16px 64px 20px 36px;
  `}
`;

export const LocaleItemTop = styled.div`
  margin-bottom: 38px;
  height: 80px;
  ${media.largeScreen`
    margin-bottom: 18px;
  `}
  ${media.mediumScreen`
    height: auto;
  `}
`;

export const LocaleItemTitle = styled.h5`
  margin-bottom: 9px;
  font-family: ${(props) => props.theme.typography.thinFontFamily};
  font-size: ${(props) => props.theme.typography.baseFontSize};
  font-weight: 300;
  line-height: 100%;
  ${media.largeScreen`
    margin-bottom: 13px;
  `}
`;

export const LocaleItemSubTitle = styled.p`
  max-width: 330px;
  font-family: ${(props) => props.theme.typography.extraFontFamily};
  font-size: ${(props) => props.theme.typography.smallFontSize};
  line-height: 160%;
  color: ${(props) => props.theme.spacing.spacer};
  ${media.largeScreen`
    max-width: 280px;
  `}
`;
