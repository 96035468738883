import { media, styled } from '@styles';
import { css } from 'styled-components';

export const Wrapper = styled.ul`
  flex-flow: column wrap;
  align-items: flex-start !important;
  max-height: 285px;
`;

export const Item = styled.li<{ active: boolean }>`
  ${({ theme, active }) => css`
    flex-basis: 20%;

    margin-bottom: 20px;
    ${media.largeScreen`
      margin-bottom: 14px;
    `}
    ${media.smallScreen`
      margin-bottom: 24px;
    `}

    a {
      display: flex;
      align-items: center;
      font-size: ${(props) => props.theme.typography.smallFontSize};
      font-family: ${(props) => props.theme.typography.thinFontFamily};
      font-weight: 300;
      line-height: 22px;
      color: rgba(44, 44, 44, 0.7);

      ${media.largeScreen`
        line-height: 18px;
      `}

      ${media.smallScreen`
        line-height: 15px;
      `}

      img {
        max-width: 20px;
        max-height: 20px;
        display: inline-block;
        border-radius: 50%;
        margin-right: 12px;
        border: 2px solid transparent;
        transition: all 0.2s ease-in-out;
      }
    }

    &:hover {
      img {
        border: 2px solid #ecedee;
        transition: all 0.2s ease-in-out;
      }
    }

    ${active &&
    css`
      display: flex;
      position: relative;

      &:after {
        content: '';
        display: 'block';
        position: absolute;
        height: 7px;
        width: 10px;
        right: -50px;
        top: 0;
        border-style: solid;
        border-width: 2px;
        border-color: transparent transparent ${theme.colors.red}
          ${theme.colors.red};
        transform: translate(0, 50%) rotate(-50deg);
      }

      a {
        color: ${theme.colors.baseFont};
      }
    `}
  `}
`;
