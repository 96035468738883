import { useWishlistQuery } from '@sdk/gqlTypes/generatedHooks';
import { useRouter } from 'next/router';
import { localeToSaleorQuery } from '@utils/regions';

export const useWishlist = () => {
  const { locale } = useRouter();

  const { data, loading } = useWishlistQuery({
    // fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
    variables: {
      first: 100,
      ...localeToSaleorQuery(locale),
    },
    skip: typeof window === 'undefined',
  });

  const items = data?.wishlist?.items.edges.map(({ node }) => node);

  return { items, loading };
};
