/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { ReactSVG } from 'react-svg';

import { getBackgroundColor } from '@utils/styles';

import eyeHide from 'images/eye-hide.svg';
import eyeShow from 'images/eye-show.svg';

import { InputLabel } from '../InputLabel';

import * as S from './styles';
import { IProps } from './types';

export const Input: React.FC<IProps> = ({
  onBlur,
  onFocus,
  contentLeft = null,
  contentRight = null,
  error = false,
  disabled = false,
  placeholder,
  label,
  value,
  onChange,
  maxLength = 50,
  type,
  ...props
}: IProps) => {
  const elementRef = React.useRef(null);
  const [active, setActive] = React.useState(false);
  const [securePassword, setSecurePassword] = React.useState(true);
  const [labelBackground, setColor] = React.useState<string>('transparent');

  React.useEffect(() => {
    if (elementRef) {
      const color = getBackgroundColor(elementRef.current);
      setColor(color);
    }
  }, []);

  const handleFocus = React.useCallback(
    (e) => {
      setActive(true);
      if (onFocus) {
        onFocus(e);
      }
    },
    [setActive, onFocus]
  );
  const handleBlur = React.useCallback(
    (e) => {
      setActive(false);
      if (onBlur) {
        onBlur(e);
      }
    },
    [setActive, onBlur]
  );
  return (
    <S.Wrapper
      active={active}
      error={error}
      disabled={disabled}
      ref={elementRef}
      whiteBg
    >
      {contentLeft && <S.Content>{contentLeft}</S.Content>}
      <S.InputWrapper>
        <S.Input
          {...props}
          type={securePassword ? type : 'text'}
          value={value}
          onFocus={handleFocus}
          onBlur={handleBlur}
          disabled={disabled}
          onChange={onChange}
          maxLength={maxLength}
          placeholder={placeholder && !label ? placeholder : ''}
        />
        {type === 'password' && (
          <span onClick={() => setSecurePassword(!securePassword)}>
            {securePassword ? (
              <ReactSVG src={eyeHide} />
            ) : (
              <ReactSVG src={eyeShow} />
            )}
          </span>
        )}
        {label && (
          <InputLabel
            labelBackground={labelBackground}
            active={active || !!value}
          >
            {label}
          </InputLabel>
        )}
      </S.InputWrapper>
      {}
    </S.Wrapper>
  );
};
