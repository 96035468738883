// import { media, styled } from '@styles';
// import { keyframes, css } from 'styled-components';

import { DropdownMenu } from '@components/atoms/DropdownMenu';

import { useSaleorAuthContext } from '@sdk/auth/SaleorAuthProvider';

import Link from 'next-translate-routes/link';
import { paths } from '@paths';

import { useIntl } from 'react-intl';
import NavIconButton from './NavIconButton';

function UserMenu() {
  const intl = useIntl();
  const { signOut } = useSaleorAuthContext();

  const handleSignOut = () => {
    // @analitics
    window?.rudderanalytics?.identify('', {
      isLoggedIn: false,
    });
    signOut();
  };

  return (
    <DropdownMenu
      type="hoverable"
      header={<NavIconButton icon="loggedin" className="main-menu__nav-link" />}
      items={[
        {
          content: (
            <Link href={paths.accountOrderHistory}>
              <a>
                {intl.formatMessage({
                  defaultMessage: 'My orders',
                  id: 'cA7xQl',
                })}
              </a>
            </Link>
          ),
        },
        {
          content: (
            <Link href={paths.account}>
              <a>
                {intl.formatMessage({
                  defaultMessage: 'Personal details',
                  id: 'AEzWZu',
                })}
              </a>
            </Link>
          ),
        },
        {
          content: (
            <Link href={paths.accountAddressBook}>
              <a>
                {intl.formatMessage({
                  defaultMessage: 'Billing & Shipping address',
                  id: 'o/l1eB',
                })}
              </a>
            </Link>
          ),
        },
        {
          content: (
            <div>
              {intl.formatMessage({
                defaultMessage: 'Log Out',
                id: 'H0JBH6',
              })}
            </div>
          ),
          onClick: handleSignOut,
        },
      ]}
    />
  );
}

export default UserMenu;
