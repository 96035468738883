import { media, styled } from '@styles';

export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 40rem;
  max-width: 100vw;
  ${media.xSmallScreen`
    // margin-top: 140px;
    margin-top: 40px;
  `}
  ${media.smallestScreen`
    margin-top: 30px;
  `}
`;

export const Wrapper = styled.div``;

export const Text = styled.p`
  line-height: 1em;
  padding: ${({ theme: { spacing } }) =>
    `1rem ${spacing.gutter} 1.8rem ${spacing.gutter}`};
`;

export const Content = styled.div`
  padding: ${({ theme: { spacing } }) =>
    `${spacing.spacer} calc(${spacing.spacer} * 5)`};
  ${media.smallScreen`
    padding: ${({ theme: { spacing } }) =>
      `${spacing.spacer} calc(${spacing.spacer} * 2)`};
  `}
`;
