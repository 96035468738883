import * as React from 'react';

import { LayoutHeaderNaviguationFragment } from '@prismic/gqlTypes/prismicOperations';

import { PrismicNavItem } from './PrismicNavItem';

import * as S from './styles';

interface INavDropdown extends LayoutHeaderNaviguationFragment {
  showDropdown: boolean;
  onShowDropdown: () => void;
  onHideDropdown: () => void;
}

export const PrismicNavDropdown: React.FC<INavDropdown> = (props) => {
  const {
    onHideDropdown,
    onShowDropdown,
    showDropdown,
    fields: subItems,
    primary,
  } = props;

  return (
    <S.DropdownWrapper
      active={showDropdown}
      onMouseOver={onShowDropdown}
      onMouseLeave={onHideDropdown}
    >
      <S.DropdownItem>
        <PrismicNavItem label={primary.label} link={primary.link} />
      </S.DropdownItem>
      <S.DropdownBody active={showDropdown}>
        {subItems?.map(
          (subItem, i) =>
            subItem && (
              <PrismicNavItem
                key={i}
                // hideOverlay={this.hideOverlayHandler}
                // image={subItem.image}
                link={subItem.link}
                label={subItem.label}
              />
            )
        )}
      </S.DropdownBody>
    </S.DropdownWrapper>
  );
};
