import { media, styled } from '@styles';

export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 40rem;
  max-width: 100vw;
  ${media.xSmallScreen`
    // margin-top: 140px;
    margin-top: 40px;
  `}
  ${media.smallestScreen`
    margin-top: 30px;
  `}
`;

export const Wrapper = styled.div``;

export const Tab = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${({ theme: { spacing } }) => spacing.gutter};
  span {
    width: 50%;
    text-align: center;
    position: relative;
    padding: ${({ theme: { spacing } }) => spacing.gutter};
    color: ${({ theme: { colors } }) => colors.baseFontColorOpacityLight};
    text-transform: capitalize;
    cursor: pointer;
    font-size: 30px;
    &:first-of-type {
      border-right: 1px solid ${({ theme: { colors } }) => colors.white};
    }

    &:last-of-type {
      border-left: 1px solid ${({ theme: { colors } }) => colors.white};
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 11px;
        left: -3px;
        width: 2px;
        height: 35px;
        background-color: ${({ theme: { colors } }) => colors.light};
      }
    }

    &.active-tab {
      color: ${({ theme: { colors } }) => colors.baseFont};
    }
    ${media.mediumScreen`
      width: 20%;
    `}
    ${media.smallScreen`
      font-size: 20px;
      width: 30%;
    `}
  }
  ${media.smallScreen`
    margin-bottom: 0;
  `}
`;

export const Text = styled.p`
  padding: ${({ theme: { spacing } }) =>
    `1rem ${spacing.gutter} 1.8rem ${spacing.gutter}`};
`;

export const Content = styled.div`
  padding: ${({ theme: { spacing } }) =>
    `${spacing.spacer} calc(${spacing.spacer} * 5)`};
  ${media.smallScreen`
    padding: ${({ theme: { spacing } }) =>
      `${spacing.spacer} calc(${spacing.spacer} * 2)`};
  `}
`;

export const Button = styled.div`
  margin: ${({ theme: { spacing } }) => `calc(${spacing.spacer} * 2) 0`};
  text-align: center;
  ${media.smallScreen`
    margin:0;
  `}
`;

export const Reminder = styled.div`
  color: ${({ theme: { colors } }) => colors.dividerDark};
  font-size: ${({ theme: { typography } }) => typography.smallFontSize};
  margin: ${({ theme: { spacing } }) => `calc(${spacing.spacer} * 2) 0`};
  text-align: center;
  ${media.smallScreen`
    margin: ${({ theme: { spacing } }) => `${spacing.spacer} 0`};
  `}
`;

export const Checkbox = styled.div`
  display: flex;
  span {
    top: 3px;
    font-weight: 500;
    position: relative;
    margin-left: 7px;
    color: ${({ theme: { colors } }) => colors.graySemi};
    font-family: 'Inter';
    &::after {
      content: '';
      display: block;
      height: 1px;
      width: 100%;
      background-color: ${({ theme: { colors } }) => colors.graySemi};
    }
  }
  ${media.mediumScreen`
    width: 50%;
    margin: 0 auto 1.25rem auto;
  `}
  ${media.smallScreen`
    width: 100%;
    margin: 0 auto;
    font-size: 14px;
  `}
`;

export const Link = styled.span`
  &link {
    color: ${({ theme: { colors } }) => colors.graySemi};
    border-bottom: 1px solid ${({ theme: { colors } }) => colors.graySemi};
    cursor: pointer;
    font-family: ${({ theme: { typography } }) =>
      typography.smallFontSize} !important;
    font-size: ${({ theme }) => theme.typography.baseFontSize};
    margin-bottom: 2rem;
    ${media.mediumScreen`
      margin-bottom: 1rem;
    `}
  }
`;
