import { media, styled } from '@styles';

export const NumberField = styled.div`
  display: flex;
  position: relative;
`;

export const ErrorMessages = styled.div`
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  bottom: 0;
  white-space: nowrap;
  bottom: -6px;
  ${media.largeScreen`
    bottom: -8px;
  `}
`;

export const Input = styled.input`
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  -moz-appearance: textfield;
  line-height: 27px;
  width: 30px;
  text-align: center;
  font-family: ${(props) => props.theme.typography.extraFontFamily};
  font-size: ${(props) => props.theme.typography.baseFontSize};
  & {
    border: none;
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    background-color: transparent;
    opacity: 0.5;
  }
`;

export const Button = styled.button`
  font-size: ${(props) => props.theme.typography.h5FontSize};
  width: 42px;
  opacity: 0.5;
  ${media.largeScreen`
    width: 20px;
  `}
`;

export const Middle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
