import * as React from 'react';

import { useRouter } from 'next/router';

import { useGetShopQuery } from '@sdk/gqlTypes/generatedHooks';
import { maybe } from '@utils/misc';
import { localeToSaleorQuery } from '@utils/regions';
import { defaultContext, ShopContext } from './context';

type IProps = {
  children: React.ReactNode;
};

export const ShopProvider: React.FC<IProps> = ({ children }) => {
  const { locale } = useRouter();

  const { data } = useGetShopQuery({
    variables: {
      ...localeToSaleorQuery(locale),
    },
  });

  return (
    <ShopContext.Provider value={maybe(() => data.shop, defaultContext)}>
      {children}
    </ShopContext.Provider>
  );
};
