import { useRef, useEffect, useState } from 'react';
import * as ReactDOM from 'react-dom';
import { Transition } from 'react-transition-group';

import * as S from './styles';
import { IProps } from './types';

export const Overlay: React.FC<IProps> = ({
  children,
  duration = 300,
  hide,
  position = 'center',
  show,
  transparent = false,
  target,
}: IProps) => {
  const ref = useRef<Element | null>(null);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    ref.current = target || document.getElementById('modal-root');
    setMounted(true);
  }, []);

  const animationProps = {
    open: show,
    position,
  };
  return (
    mounted &&
    ref.current &&
    ReactDOM.createPortal(
      <Transition in={show} timeout={duration} unmountOnExit>
        {(state) => (
          <S.Overlay
            {...animationProps}
            state={state}
            transparent={transparent}
            onClick={hide}
          >
            <S.OverlayInner>
              <S.Lightbox
                {...animationProps}
                state={state}
                onClick={(e) => e.stopPropagation()}
              >
                {children}
              </S.Lightbox>
            </S.OverlayInner>
          </S.Overlay>
        )}
      </Transition>,
      ref.current
    )
  );
};
