import { media, styled } from '@styles';
import { ContainerFlex } from '@components/templates/Container';

export const Nav = styled(ContainerFlex)<{ isScrolled: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => props.theme.colors.white};
  font-size: ${(props) => props.theme.typography.baseFontSize};
  position: absolute;
  top: ${(props) => (props.isScrolled ? '0px' : '40px')};
  left: 0;
  right: 0;
  transition: all 0.2s ease;
  height: 80px;
  ${media.mediumScreen`
    padding-left: 0;
  `}
  ${media.smallScreen`
    top: ${(props) => (props.isScrolled ? '0px' : '30px')};
    height: 60px;
  `}
  .main-menu__nav-link {
    padding: 15px;
    position: relative;
    font-size: ${(props) => props.theme.typography.baseFontSize};
    line-height: 0.875rem;
    white-space: nowrap;
    ${media.smallScreen`
      padding: 10px;
    `}
    ${media.smallestScreen`
      padding: 10px;
    `}
    img {
      height: 22px;
      width: 22px;
      border-radius: 50%;
      border: 2px solid transparent;
      transition: all 0.2s ease-in-out;
      ${media.largeScreen`
        height: 19px;
        width: 19px;
      `}
    }
    &:before {
      content: '';
      display: block;
      height: 2px;
      width: 0;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      background-color: ${(props) => props.theme.colors.red};
      transition: width 0.6s;
    }
    &.active,
    &:hover {
      cursor: pointer;
      &:before {
        width: 75%;
      }
      img {
        border: 2px solid #ecedee;
        transition: all 0.2s ease-in-out;
      }
    }
  }
`;

export const Collection = styled(ContainerFlex)<{ isScrolled: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => props.theme.colors.white};
  // font-size: 1.125rem; // 18px
  font-size: ${(props) => props.theme.typography.baseFontSize};
  position: absolute;
  top: ${(props) => (props.isScrolled ? '80px' : '120px')};
  left: 0;
  right: 0;
  transition: all 0.2s ease;
  ${media.mediumScreen`
    padding-left: 0;
  `}
  ${media.smallScreen`
    top: ${(props) => (props.isScrolled ? '60px' : '90px')};
  `}
  .main-menu__nav-link {
    padding: 15px;
    position: relative;
    // font-size: 1.125rem;
    font-size: ${(props) => props.theme.typography.baseFontSize};
    line-height: 0.875rem;
    white-space: nowrap;
    ${media.smallScreen`
      padding: 10px;
    `}
    img {
      height: 22px;
      width: 22px;
      border-radius: 50%;
      border: 2px solid transparent;
      transition: all 0.2s ease-in-out;
      ${media.largeScreen`
        height: 19px;
        width: 19px;
      `}
    }
    &:before {
      content: '';
      display: block;
      height: 2px;
      width: 0;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      background-color: ${(props) => props.theme.colors.red};
      transition: width 0.6s;
    }
    &.active,
    &:hover {
      cursor: pointer;
      &:before {
        width: 75%;
      }
      img {
        border: 2px solid #ecedee;
        transition: all 0.2s ease-in-out;
      }
    }
  }
`;

export const NavWrapper = styled.ul`
  display: flex;
`;

export const LogoWrapper = styled.div`
  ${media.mediumScreen`
    order: 1;
    margin-right: auto;
    padding: 10px 0;
  `}
  ${media.smallScreen`
    margin-left: auto;
  `}
`;

export const Logo = styled.img<{ mobile?: boolean }>`
  max-width: 116px;
  height: auto;
  display: ${(props) => (props.mobile ? 'none' : 'block')};
  ${media.mediumScreen`
    max-width: 72px;
  `}
  ${media.smallScreen`
    display: ${(props) => (props.mobile ? 'block' : 'none')};
  `}
`;

export const LeftMenu = styled.div`
  ${media.mediumScreen`
    order: 0;
    flex: 1 1 25%;
  `}
`;

export const RightMenu = styled.div`
  ${media.mediumScreen`
    order: 2;
    flex: 1 1 25%;
  `}
  ul {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`;

export const Hamburger = styled.li`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  padding: ${(props) => props.theme.colors.graySemi};
  ${media.mediumScreen`
    padding: 28px;
    background-color: ${(props) => props.theme.colors.gold};
  `}
  ${media.smallScreen`
    padding: 18px;
  `}

  .hamburger--hover {
    display: none;
  }

  svg {
    vertical-align: bottom;
    ${media.mediumScreen`
      path {
        fill: #ffffff;
      }
    `}
    ${media.smallScreen`
      transform: scale(0.8);
    `}
  }
`;
