/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { useIntl } from 'react-intl';

import { Controller, useForm } from 'react-hook-form';

import { useResetPasswordRequestMutation } from '@sdk/gqlTypes/generatedHooks';

import { paths } from '@paths';
import { maybe } from '@utils/misc';
import { groupErrorsByFields, NON_FIELD_ERROR } from '@utils/errors';

import { Button } from '@components/atoms/Button';
import { ErrorMessage } from '@components/atoms/ErrorMessage';
import { TextField } from '@components/molecules/TextField';

import { IFormError } from '@types';
import { commonMessages, validationCommonMsg } from '../../../../intl';

import { IProps } from './types';

import * as S from '../LoginForm/styles';

const showSuccessNotification = (onSuccess: (() => void) | undefined) => {
  // @analitics
  // TODO: track password reset requet
  if (onSuccess) {
    onSuccess();
  }
};

export const PasswordForgottenForm: React.FC<IProps> = ({ onSuccess }) => {
  const intl = useIntl();
  const [resetPasswordRequestMutation, { data, error }] =
    useResetPasswordRequestMutation();
  const [graphqlErrors, setGraphqlErrors] = React.useState([]);

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm();

  const handleOnSubmit = async (values: any) => {
    const redirectUrl = `${window.location.origin}${paths.passwordReset}`;
    await resetPasswordRequestMutation({
      variables: {
        email: values.email,
        redirectUrl,
      },
    });
    if (error?.graphQLErrors?.length) {
      setGraphqlErrors(error?.graphQLErrors as never[]);
    } else if (data?.requestPasswordReset?.accountErrors.length) {
      setGraphqlErrors(data?.requestPasswordReset?.accountErrors as never);
    } else if (data?.requestPasswordReset) {
      setGraphqlErrors([]);
      showSuccessNotification(onSuccess);
    }
  };

  const fieldErrors = groupErrorsByFields(
    maybe(() => graphqlErrors, []),
    'email'
  );
  const nonFieldErrors = fieldErrors[NON_FIELD_ERROR];

  return (
    <S.Wrapper className="password-reset-form">
      <S.Text className="password-reset-form__text">
        {intl.formatMessage({
          defaultMessage:
            'Please provide us your email address so we can share you a link to reset your password',
          id: 'v7NldV',
        })}
      </S.Text>
      <form
        className="password-reset-form__form"
        onSubmit={handleSubmit(handleOnSubmit)}
      >
        <S.Error className="form-error">
          <ErrorMessage errors={nonFieldErrors} />
        </S.Error>
        <Controller
          name="email"
          control={control}
          rules={{
            pattern: {
              message: intl.formatMessage(validationCommonMsg.invalidEmail),
              value:
                /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
            },
            required: intl.formatMessage(validationCommonMsg.required),
          }}
          render={({ field }) => (
            <TextField
              label={intl.formatMessage(commonMessages.shortEmail)}
              autoComplete="email"
              errors={
                errors.email
                  ? [{ message: errors.email.message } as IFormError]
                  : fieldErrors?.email
              }
              {...field}
            />
          )}
        />
        <S.SubmitButton className="password-reset-form__button">
          <Button type="submit" disabled={isSubmitting} fullWidth>
            {isSubmitting
              ? intl.formatMessage(commonMessages.loading)
              : intl.formatMessage(commonMessages.send)}
          </Button>
        </S.SubmitButton>
      </form>
    </S.Wrapper>
  );
};
