import { media, styled } from '@styles';
import { Container } from '../../templates/Container/Container';

export const Footer = styled.footer`
  background-color: ${(props) => props.theme.colors.secondaryDark};
  color: ${(props) => props.theme.colors.white};
`;

export const Wrapper = styled(Container)`
  padding-top: 70px;
  // padding-bottom: 25px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  ${media.smallScreen`
    padding-top: 60px;
  `}
  a {
    font-family: ${(props) => props.theme.typography.extraFontFamily};
    font-size: ${(props) => props.theme.typography.baseFontSize};
    line-height: 2.4em;
  }
`;

export const Bottom = styled(Container)`
  padding: 30px 0;
  background-color: rgba(${(props) => props.theme.colors.secondary}, 0.5);
  ${media.largeScreen`
    padding: 20px 0;
  `}
`;

export const Title = styled.div`
  font-family: ${(props) => props.theme.typography.thinFontFamily};
  font-size: ${(props) => props.theme.typography.h5FontSize};
  line-height: 2em;
  margin-bottom: 25px;
  color: #7796a4;
  ${media.largeScreen`
    margin-bottom: 20px;
  `}
  ${media.smallScreen`
    margin-bottom: 10px;
  `}
  * {
    font: inherit;
  }
`;

export const Text = styled.div`
  margin-bottom: 10px;
  font-family: ${(props) => props.theme.typography.extraFontFamily};
  font-size: ${(props) => props.theme.typography.baseFontSize};
  line-height: 2.4em;
  ${media.largeScreen`
    margin-bottom: 5px;
  `}
  * {
    line-height: inherit;
    font: inherit;
  }
`;

export const NavGroup = styled.div`
  margin-bottom: 70px;
  ${media.smallScreen`
    margin-bottom: 30px;
  `}
`;

export const Nav = styled.ul``;

export const NavItem = styled.li`
  margin-bottom: 10px;
  ${media.largeScreen`
    margin-bottom: 5px;
  `}
`;

export const PaymentItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
`;
