/* eslint-disable react/jsx-no-constructed-context-values */
import { clsx } from 'clsx';
import { useEffect, useMemo, ReactNode } from 'react';
import { useRouter } from 'next-translate-routes/router';

import { PrismicLayoutContext } from '@prismic/context';
import { usePrismicLayoutQuery } from '@prismic/gqlTypes/prismicHooks';

import { ErrorBoundary } from '@components/containers/ErrorBoundary';
import { ShopProvider } from '@components/containers/ShopProvider';
import { TopBar } from '@components/molecules/TopBar';
import { MainMenu } from '@components/organisms/MainMenu';
import { Footer } from '@components/organisms/Footer';
// import { ContactsWidget } from '@components/organisms/ContactsWidget';
import { ContactsWidgetWhatsapp as ContactsWidget } from '@components/organisms/ContactsWidgetWhatsapp';

import { useScrolled } from '@hooks';

import NiceModal from '@ebay/nice-modal-react';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import { trackPage } from './analytics';

import Notifications from './Notifications';

type IProps = {
  children: ReactNode;
};

const App: React.FC<IProps> = ({ children }) => {
  // const location = useLocation();
  const { events, pathname, asPath, isReady } = useRouter();

  // const { tokenRefreshing, tokenVerifying } = useAuth();

  // if (tokenRefreshing || tokenVerifying) {
  //  return <Loader />;
  // }

  const { /* loading, error, */ data } = usePrismicLayoutQuery({});

  const isCheckout = pathname.includes('checkout');
  // const isThankYouPage = pathname.includes('order-finalized');
  const isCollectionPage = pathname.includes('collection');
  const isHomePage = pathname === '/';
  const hideMenu = isCheckout;
  const hideTopBar = !isHomePage;

  const layout = useMemo(() => data?.layout, [data?.layout]);

  const {
    header_message,
    header_navigation,
    // header_navigation_all_types_image,
    footer_contact_title,
    footer_contact_text,
    footer_copyright,
    footer_navigation,
    footer_terms,
    footer_social,
    contact,
  } = layout || {};

  useEffect(() => {
    if (isReady) {
      // console.log('page_view isReady');
      trackPage(asPath);
    }
  }, [isReady]);

  useEffect(() => {
    const handleRouteChange = (path, { shallow }) => {
      // console.log('page_view handleRouteChange');
      // console.log( `App is changing to ${path} ${ shallow ? 'with' : 'without'} shallow routing`);
      trackPage(path);
    };
    events.on('routeChangeComplete', handleRouteChange);
    return () => {
      events.off('routeChangeComplete', handleRouteChange);
    };
  }, [events]);

  const [isScrolled] = useScrolled(isCollectionPage ? 250 : 350, 600);

  return (
    <ShopProvider>
      <PrismicLayoutContext.Provider value={{ layout }}>
        <NiceModal.Provider>
          <ErrorBoundary>
            <header>
              {!hideMenu && (
                <>
                  {!hideTopBar && (
                    <TopBar
                      contact={contact}
                      message={header_message}
                      isScrolled={isScrolled}
                    />
                  )}
                  <MainMenu
                    prismicNav={header_navigation}
                    contact={contact}
                    isScrolled={isScrolled || hideTopBar}
                  />
                </>
              )}
            </header>
            <div
              className={clsx('page-wrapper', {
                'no-margin': hideMenu,
                topbar: !hideTopBar,
              })}
            >
              {children}
            </div>
            {!hideMenu && <ContactsWidget />}
            {!hideMenu && (
              <Footer
                title={footer_contact_title}
                contact={contact}
                text={footer_contact_text}
                social={footer_social}
                navGroups={footer_navigation}
                copyright={footer_copyright}
                terms={footer_terms}
              />
            )}
          </ErrorBoundary>
          <Notifications />
        </NiceModal.Provider>
      </PrismicLayoutContext.Provider>
    </ShopProvider>
  );
};

export default App;
