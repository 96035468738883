import { ReactNode } from 'react';
import { ApolloError } from '@apollo/client';
import { useRouter } from 'next/router';

import { useLocalStorage } from '@hooks/useLocalStorage';
import { localeToSaleorQuery } from '@utils/regions';
import createSafeContext from '@hooks/useSafeContext';

import {
  useCheckoutDetailsByTokenQuery,
  useCreateCheckoutMutation,
  useAddCheckoutLineMutation,
  useRemoveCheckoutLineMutation,
  useUpdateCheckoutLineMutation,
} from '@sdk/gqlTypes/generatedHooks';
import {
  CheckoutDetailsFragment,
  SelectedAttributeValueFragment,
} from '@sdk/gqlTypes/generatedOperations';
import { SelectedAttributeValueInput } from '@sdk/gqlTypes/generatedTypes';

import { DEFAULT_PAYMENT_METHODS } from '@pages/CheckoutPage/utils';

import { extractExistingLine } from './utils';

export interface CheckoutConsumerProps {
  checkoutToken: string;
  setCheckoutToken: (token: string) => void;
  checkoutGateway: string;
  setCheckoutGateway: (gateway: string) => void;
  resetCheckoutToken: () => void;
  checkout: CheckoutDetailsFragment | undefined | null;
  checkoutError: ApolloError | undefined;
  loading: boolean;
  addItem: (
    variantId: string,
    quantity: number,
    productAttributes: SelectedAttributeValueInput[]
  ) => void;
  removeItem: (
    variantId: string,
    productAttributes: SelectedAttributeValueFragment[]
  ) => void;
  updateItem: (
    variantId: string,
    quantity: number,
    productAttributes: SelectedAttributeValueFragment[]
  ) => void;
}

export const CHECKOUT_TOKEN = 'saleor_checkout_token';
export const CHECKOUT_GATEWAY = 'saleor_checkout_gateway';

export const [useCheckout, Provider] =
  createSafeContext<CheckoutConsumerProps>();

export function CheckoutProvider({ children }: { children: ReactNode }) {
  const router = useRouter();
  const locale = router.query.locale?.toString();

  const [checkoutToken, setCheckoutToken] = useLocalStorage(
    CHECKOUT_TOKEN,
    '',
    { sync: true }
  );
  const [checkoutGateway, setCheckoutGateway] = useLocalStorage(
    CHECKOUT_GATEWAY,
    DEFAULT_PAYMENT_METHODS,
    { sync: true }
  );

  const [addCheckoutLineMutation] = useAddCheckoutLineMutation();
  const [createCheckoutMutation] = useCreateCheckoutMutation();
  const [removeCheckoutLineMutation] = useRemoveCheckoutLineMutation();
  const [updateCheckoutLineMutation] = useUpdateCheckoutLineMutation();

  const {
    data,
    loading,
    error: checkoutError,
  } = useCheckoutDetailsByTokenQuery({
    variables: { token: checkoutToken, ...localeToSaleorQuery(locale) },
    skip: !checkoutToken || typeof window === 'undefined',
  });

  const resetCheckoutToken = () => setCheckoutToken('');

  const addItem = async (
    variantId: string,
    quantity: number,
    productAttributes: SelectedAttributeValueInput[]
  ) => {
    const checkoutId = data?.checkout?.id;

    if (checkoutId) {
      const { data: addToCartData } = await addCheckoutLineMutation({
        variables: {
          checkoutId,
          lines: [
            {
              productAttributes: productAttributes || [],
              quantity,
              variantId,
            },
          ],
          ...localeToSaleorQuery(locale),
        },
      });

      return {
        data: addToCartData?.checkoutLinesAdd?.checkout,
        error: addToCartData?.checkoutLinesAdd?.errors,
      };
    }

    // const { data, error } = await createCheckoutMutation({
    const { data: createCheckoutData } = await createCheckoutMutation({
      variables: {
        checkoutInput: {
          lines: [
            {
              productAttributes: productAttributes || [],
              quantity,
              variantId,
            },
          ],
        },
        ...localeToSaleorQuery(locale),
      },
    });

    if (createCheckoutData?.checkoutCreate?.checkout?.token) {
      setCheckoutToken(createCheckoutData?.checkoutCreate?.checkout?.token);
    }

    return {
      data: createCheckoutData?.checkoutCreate?.checkout,
      error: createCheckoutData?.checkoutCreate?.errors,
    };
  };

  const removeItem = async (
    variantId: string,
    productAttributes: SelectedAttributeValueFragment[]
  ) => {
    const checkoutId = data?.checkout?.id;
    const lines = data?.checkout?.lines || [];
    const [existingLine] = extractExistingLine(
      lines,
      variantId,
      productAttributes
    );

    // Find the CheckoutLineId to remove from the Checkout
    if (existingLine && checkoutId) {
      // const { data, error } = removeCheckoutLineMutation({
      await removeCheckoutLineMutation({
        variables: {
          checkoutId,
          lineId: existingLine.id,
          ...localeToSaleorQuery(locale),
        },
      });
      // return {
      //   data: data?.checkoutLineDelete?.checkout,
      //   error: error || data?.checkoutLineDelete?.errors,
      // };
    }
    // return {
    //   error: new Error('The item do not exist in the current cart.'),
    // };
  };

  const updateItem = async (
    variantId: string,
    quantity: number,
    productAttributes: SelectedAttributeValueFragment[]
  ) => {
    const checkoutId = data?.checkout?.id;
    const lines = data?.checkout?.lines;
    if (checkoutId && lines) {
      const alteredLines = {
        productAttributes: productAttributes?.map((attr) => ({
          attributeId: attr.attribute.id,
          values: attr.values,
        })),
        quantity,
        variantId,
      };

      // const { data, error } = await updateCheckoutLineMutation({
      await updateCheckoutLineMutation({
        variables: {
          checkoutId,
          lines: [alteredLines],
          ...localeToSaleorQuery(locale),
        },
      });

      // return {
      //   data: data?.checkoutLinesUpdate?.checkout,
      //   error: error || data?.checkoutLinesUpdate?.errors,
      // };
    }

    // return {
    //   error: new Error('The current cart is empty.'),
    // };
  };

  const providerValues: CheckoutConsumerProps = {
    checkoutToken,
    checkoutGateway,
    setCheckoutToken,
    setCheckoutGateway,
    resetCheckoutToken,
    checkout: data?.checkout,
    loading,
    checkoutError,
    addItem,
    removeItem,
    updateItem,
  };

  return <Provider value={providerValues}>{children}</Provider>;
}
