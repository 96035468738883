import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import Link from 'next-translate-routes/link';
import { ReactSVG } from 'react-svg';

import { translate } from '@utils/translations';

import { TaxedMoney } from '@components/containers/TaxedMoney';

import { Loader } from '@components/atoms/Loader';
import { EstimatedShipping } from '@components/molecules/EstimatedShipping';

import { useCartProductEstimatedShippingQuery } from '@sdk/gqlTypes/generatedHooks';
import {
  generateProductImageWithAttributes,
  generateProductUrlWithAttributes,
} from '@temp/views/Product/utils';

import { CONFIGURATOR_PRODUCT_TYPES } from '@temp/views/Product/types';

import { useRouter } from 'next/router';
import { localeToSaleorQuery } from '@utils/regions';

import editImg from 'images/edit.svg';
import removeImg from 'images/garbage.svg';
import { commonMessages } from '../../../../intl';
import { ProductListItemAttributes } from './ProductListItemAttributes';
import { ProductListItemQuantity } from './ProductListItemQuantity';

import * as S from './styles';
import { IItemProps } from './types';

export const ProductListItem: React.FC<IItemProps> = ({
  line,
  remove,
  readonly = false,
}) => {
  const intl = useIntl();
  const { locale } = useRouter();

  const { product } = line.variant;
  const isConfiguratorType = CONFIGURATOR_PRODUCT_TYPES.includes(
    product.productType.slug
  );

  const { data, loading } = useCartProductEstimatedShippingQuery({
    variables: {
      id: line.variant.product.id,
      ...localeToSaleorQuery(locale),
    },
  });

  const productAttributesWithData =
    line.productAttributes.map((item) => {
      const valuesData = item.attribute.values.filter((value) =>
        item.values.includes(value.name)
      );
      return {
        id: item.attribute.id,
        name: item.attribute.name,
        slug: item.attribute.slug,
        translatedName: item.attribute.translation?.name,
        values: valuesData?.map((value) => ({
          name: value.name,
          slug: value.slug,
          translatedName: value.translation?.name,
        })),
      };
    }) || [];

  const variantAttributesWithData =
    line.variant.attributes.map((item) => ({
      id: item.attribute.id,
      name: item.attribute.name,
      slug: item.attribute.slug,
      translatedName: item.attribute.translation?.name,
      values: item.values.map((value) => ({
        name: value.name,
        slug: value.slug,
        translatedName: value.translation?.name,
      })),
    })) || [];

  const allAttributes = useMemo(
    () => [...productAttributesWithData, ...variantAttributesWithData],
    [productAttributesWithData, variantAttributesWithData]
  );
  const editUrl = useMemo(() => {
    const attributes = allAttributes.reduce((acc, cur) => {
      const value = cur.values && cur.values[0];
      acc[cur.slug] = value?.slug;
      return acc;
    }, {});
    return generateProductUrlWithAttributes(line.variant.product, attributes);
  }, [allAttributes, line.variant.product]);

  const imageUrl = useMemo(() => {
    const attributes = allAttributes.reduce((acc, cur) => {
      const value = cur.values && cur.values[0];
      acc[cur.slug] = value?.slug;
      return acc;
    }, {});
    return generateProductImageWithAttributes(line.variant.product, attributes);
  }, [allAttributes, line.variant.product]);

  if (loading) {
    return <Loader />;
  }

  if (!data) {
    return <div>no data</div>;
  }

  return (
    <S.Item className="cart__list__item">
      <Link href={editUrl}>
        <a className="cart__list__item-img">
          <img
            src={imageUrl}
            alt="product thumbnail"
            width="200"
            loading="lazy"
          />
        </a>
      </Link>
      <S.Details className="cart__list__item__details">
        <Link href={editUrl} passHref>
          <S.ProductName className="cart__list__item-name">
            {isConfiguratorType
              ? product.productType.name
              : translate(product, 'name')}
          </S.ProductName>
        </Link>
        <ProductListItemAttributes attributes={allAttributes} alwaysShowAll />
        <S.ProductQuantity className="cart__list__item-bottom">
          {!readonly && <ProductListItemQuantity line={line} />}
          {readonly && (
            <S.QuantityText className="cart__list__item-shipping">
              {`${intl.formatMessage(commonMessages.quantity)}: ${
                line.quantity
              }`}
            </S.QuantityText>
          )}
        </S.ProductQuantity>
      </S.Details>
      <S.Summary>
        {!readonly && (
          <S.Action className="cart__list__item-actions">
            <Link href={editUrl}>
              <a className="cart__list__item-icon">
                <img src={editImg} alt="edit" />
              </a>
            </Link>
            <ReactSVG
              src={removeImg}
              className="cart__list__item-icon"
              onClick={() => remove(line)}
            />
          </S.Action>
        )}
        <S.ProductPrice>
          <TaxedMoney taxedMoney={line.totalPrice} />
        </S.ProductPrice>
        <S.EstimatedShipping>
          <EstimatedShipping
            productType={data.product.productType}
            type="short"
            exact
          />
        </S.EstimatedShipping>
      </S.Summary>
    </S.Item>
  );
};
