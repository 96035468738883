import React from 'react';

import * as S from './styles';
import { IButtonProps, IAnchorProps } from './types';

/**
 * The basic button
 */
export const Button: React.FC<IButtonProps> = ({
  color = 'primary',
  appearance = 'filled',
  btnRef,
  children,
  fullWidth = false,
  size = 'md',
  font = 'bold',
  ...props
}: IButtonProps) => (
  <S.Button
    color={color}
    fullWidth={fullWidth}
    size={size}
    appearance={appearance}
    ref={btnRef}
    {...props}
  >
    <S.Text size={size} font={font}>
      {children}
    </S.Text>
  </S.Button>
);

/**
 * The basic button
 */
export const ButtonLink: React.FC<IAnchorProps> = ({
  color = 'primary',
  appearance = 'filled',
  children,
  fullWidth = false,
  size = 'md',
  font = 'bold',
  ...props
}: IAnchorProps) => (
  <S.ButtonLink
    color={color}
    fullWidth={fullWidth}
    size={size}
    appearance={appearance}
    {...props}
  >
    <S.Text size={size} font={font}>
      {children}
    </S.Text>
  </S.ButtonLink>
);
