import { styled } from '@styles';
import { css } from 'styled-components';

import { Status } from './types';

interface ToastrProps {
  status?: Status;
  destroyByClick?: boolean;
  hasIcon?: boolean;
  isRight?: boolean;
}

export const Toastr = styled.div<ToastrProps>`
  ${({ theme, status, destroyByClick, hasIcon, isRight }) => css`
    display: flex;
    align-items: center;
    width: 25rem;
    margin: 0.5rem;
    transition: all 150ms ease-out;
    &&.animation {
      transform: translateX(${!isRight && '-'}110%);
      height: 0;
      margin: 0;
    }
    ${destroyByClick &&
    css`
      cursor: pointer;

      &:hover {
        opacity: 1;
      }
    `}
  `}
`;

interface ToastrContainerProps {
  position: string;
  isTop: boolean;
}

export const ToastrContainer = styled.div<ToastrContainerProps>`
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  position: absolute;
  z-index: 1000;
  ${({ position }) => {
    switch (position) {
      case 'topStart':
        return 'justify-content: flex-start;align-items: flex-start;';
      case 'topEnd':
        return 'justify-content: flex-end;align-items: flex-start;';
      case 'bottomStart':
        return 'justify-content: flex-start;align-items: flex-end;';
      case 'bottomEnd':
        return 'justify-content: flex-end;align-items: flex-end;';
      // no default
    }
  }}
  .overlay-pane {
    pointer-events: auto;
    position: static;
    box-sizing: border-box;
    z-index: 1000;
    display: flex;
    max-width: 100%;
    max-height: 100%;
    flex-direction: ${({ isTop }) => (isTop ? 'column-reverse' : 'column')};
  }
`;
