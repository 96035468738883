/* eslint-disable */
let lastPath;

const pathnameMap = {
  '^/$': 'homepage',
  '^/(inspirations|inspirationen|inspiracje)/$': 'inspiration-main',
  'inspirations|inspirationen|inspiracje': 'inspiration',
  '^/blog/$': 'blog-main',
  '/blog/': 'blog',
  '/product/': 'product',
  '/c/': 'collection',
  '/(avis|bewertungen|opinie)/': 'review',
};

function remap(map: Record<string, string>, str: string) {
  for (const [key, value] of Object.entries(map)) {
    if (str.match(new RegExp(key, 'i'))) return value;
  }
  return 'other';
}

// export async function gtmAnalyticsInitialize() {
//   if (analyticsGTM) {
//     const TagManager = await import('react-gtm-module');
//     TagManager.initialize({ gtmId: analyticsGTM });
//   }
//   return 'other';
// }

/**
 * Sends analytics information about the pages visited.
 *
 * @param url (Dynamic) Path name as given by the react router.
 * @returns void
 */
export async function trackPage(url: string) {
  // Make sure to avoid double tracking.

  const splitted = url.split('?');
  const path = splitted[0];
  const contentGroup = remap(pathnameMap, path);

  // Debug
  // console.log('trackPage');
  // console.log({ url, path, lastPath, contentGroup });
  if (path === lastPath) return;
  lastPath = path;

  if (window?.gtag) {
    window.gtag('set', 'content_group', contentGroup);
  } else {
    window.dataLayer = window.dataLayer || [];
    window.gtag =
      window.gtag ||
      function gt() {
        // eslint-disable-next-line prefer-rest-params
        window.dataLayer.push(arguments);
      };
    // window.gtag('js', new Date());
    window.gtag('set', 'content_group', contentGroup);
  }

  window?.rudderanalytics?.page({ path: path });
}
