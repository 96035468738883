import { media, styled } from '@styles';
import { TransitionState } from './types';

interface IStyleProps {
  open: boolean;
  state: TransitionState;
}

const opacity = {
  entered: 1,
  entering: 0,
  exited: 0,
  exiting: 0,
  unmounted: 0,
};

export const OverlayInner = styled.div`
  max-width: 100%;
  max-height: 100%;
`;

export const Lightbox = styled.div<IStyleProps>`
  display: flex;
  position: relative;
  margin: 0 auto;
  background-color: ${(props) => props.theme.colors.white};
  ${media.xSmallScreen`
    width: 100%;
  `}
`;
Lightbox.displayName = 'S.Lightbox';

export const Overlay = styled.div<IStyleProps>`
  display: flex;
  justify-content: center;
  overflow-x: auto;
  border-bottom: 1px solid #e0e0e0;
  width: 100%;
  transition: opacity 0.2s ease;
  transition-delay: ${({ open }) => (open ? 0 : '.4s')};
  opacity: ${({ state }) => opacity[state]};
  padding: 0px 30px 0px 30px;
  ${media.mediumScreen`
    padding: 0px;
  `}
`;
Overlay.displayName = 'S.Overlay';
