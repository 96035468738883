// import './scss/index.scss';

import React from 'react';

import { Controller, useForm } from 'react-hook-form';

import { useRouter } from 'next/router';

import { useIntl } from 'react-intl';

import { Button } from '@components/atoms/Button';
import { Checkbox } from '@components/atoms/Checkbox';
import { ErrorMessage } from '@components/atoms/ErrorMessage';
import { TextField } from '@components/molecules/TextField';

import { maybe } from '@utils/misc';
import { groupErrorsByFields, NON_FIELD_ERROR } from '@utils/errors';

import { commonMessages, validationCommonMsg } from '../../../../intl';

import { IProps, IFormProps } from './types';
import * as S from './styles';

const showSuccessNotification = (
  onSuccess: (data?: any) => void,
  data?: any
) => {
  // @analitics
  // TODO: track subscription request
  if (onSuccess) {
    onSuccess(data);
  }
};

export const SubscribeForm: React.FC<IProps> = ({ onSuccess }) => {
  const intl = useIntl();
  const { locale } = useRouter();

  const [isConditionChecked, setConditionChecked] = React.useState(false);
  const [graphqlErrors, setgraphqlErrors] = React.useState([]);

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm({
    defaultValues: {
      email: '',
    },
  });

  const handleOnSubmit = async (values: IFormProps) => {
    const response = await fetch('/api/subscribeUser', {
      body: JSON.stringify({
        email: values.email,
        locale,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    });
    if (response.status !== 201) {
      const error = await response.json();
      setgraphqlErrors([{ message: error.error }]);
    } else {
      setgraphqlErrors([]);
      showSuccessNotification(onSuccess);
    }
  };
  const fieldErrors = groupErrorsByFields(
    maybe(() => graphqlErrors, []),
    'email'
  );
  const nonFieldErrors = fieldErrors[NON_FIELD_ERROR];

  return (
    <S.Wrapper className="subscription-form">
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <S.Error className="form-error">
          <ErrorMessage errors={nonFieldErrors} />
        </S.Error>
        <Controller
          name="email"
          control={control}
          rules={{
            pattern: {
              message: intl.formatMessage(validationCommonMsg.invalidEmail),
              value:
                /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
            },
            required: intl.formatMessage(validationCommonMsg.required),
          }}
          render={({ field }) => (
            <TextField
              label={intl.formatMessage(commonMessages.shortEmail)}
              autoComplete="email"
              type="email"
              errors={
                errors.email
                  ? [{ message: errors.email.message }]
                  : undefined || fieldErrors?.email
              }
              {...field}
            />
          )}
        />
        <S.Checkbox className="subscribe__checkbox">
          <Checkbox
            name="conditions"
            checked={isConditionChecked}
            onChange={() => {
              setConditionChecked(!isConditionChecked);
            }}
          >
            {intl.formatMessage({
              defaultMessage: 'I have read',
              id: 'pupJlG',
            })}
          </Checkbox>
          <span className="login__checkbox__text">
            {intl.formatMessage({
              defaultMessage: 'Terms and Conditions',
              id: '6qF/vN',
            })}
          </span>
        </S.Checkbox>
        <S.SubmitButton className="subscribe__content__button">
          <Button
            fullWidth
            type="submit"
            disabled={!isConditionChecked || isSubmitting}
          >
            {isSubmitting
              ? intl.formatMessage(commonMessages.loading)
              : intl.formatMessage(commonMessages.subscribe)}
          </Button>
        </S.SubmitButton>
      </form>
    </S.Wrapper>
  );
};
