import { defineMessages } from 'react-intl';

export const commonMessages = defineMessages({
  account: {
    defaultMessage: 'Account',
    id: 'TwyMau',
  },
  accept: {
    defaultMessage: 'I accept',
    id: '+fnRDB',
  },
  add: {
    defaultMessage: 'Add',
    id: '2/2yg+',
  },
  addAddress: {
    defaultMessage: 'Add address',
    id: 'v0xaYB',
  },
  addressBook: {
    defaultMessage: 'Address book',
    id: '5zZZ90',
  },
  allTopics: {
    defaultMessage: 'All topics',
    id: 'V4mLb8',
  },
  addToCart: {
    defaultMessage: 'add to cart',
    id: 'n34Hfs',
  },
  cancel: {
    defaultMessage: 'Cancel',
    id: '47FYwb',
  },
  category: {
    defaultMessage: 'Category',
    id: 'ccXLVi',
  },
  charactersLeft: {
    defaultMessage: 'characters left',
    id: 'SbzsQf',
  },
  checkout: {
    defaultMessage: 'Checkout',
    id: 'BJ2TKX',
  },
  clearFilterHeader: {
    defaultMessage: 'CLEAR FILTERS',
    id: 'FGJNIh',
  },
  collection: {
    defaultMessage: 'collection',
    id: 'FjIE73',
  },
  collectionCapitalized: {
    defaultMessage: 'Collection',
    id: 'phAZoj',
  },
  congratulations: {
    defaultMessage: 'Congratulations!',
    id: 'lOzDTt',
  },
  contactUs: {
    defaultMessage: 'contact us',
    id: 'cZ6IMP',
  },
  days: {
    defaultMessage: 'days',
    id: 'Bc20la',
  },
  delete: {
    defaultMessage: 'Delete',
    id: 'K3r6DQ',
  },
  download: {
    defaultMessage: 'Download',
    id: '5q3qC0',
  },
  eMail: {
    defaultMessage: 'Email Address',
    id: 'xxQxLE',
  },
  editAddress: {
    defaultMessage: 'Edit address',
    id: 'ex+q9+',
  },
  filterHeader: {
    defaultMessage: 'FILTERS',
    id: 'uqEtJ1',
  },
  firstName: {
    defaultMessage: 'First Name',
    id: 'Q6wcZ5',
  },
  free: {
    defaultMessage: 'Free',
    id: 'tf1lIh',
  },
  from: {
    defaultMessage: 'from',
    id: 'G9uF7o',
  },
  itemsMinMax: {
    defaultMessage:
      'Please, choose between {minItems} and {maxItems} items to continue',
    id: 'AI4rYj',
  },
  itemsSelected: {
    defaultMessage: '{selectedNumber} items selected',
    id: '2aCfIz',
  },
  lastName: {
    defaultMessage: 'Last Name',
    id: 'aheQdn',
  },
  less: {
    defaultMessage: 'Less',
    id: 'mFYgAX',
  },
  loading: {
    defaultMessage: 'Loading',
    id: 'iFsDVR',
  },
  loadMore: {
    defaultMessage: 'Load more',
    id: '00LcfG',
  },
  logOut: {
    defaultMessage: 'Log Out',
    id: 'H0JBH6',
  },
  lowStock: {
    defaultMessage: 'Low stock',
    id: '2xjjlC',
  },
  maxQtyIs: {
    defaultMessage: 'Maximum quantity is {maxQuantity}',
    id: 'XkwMjW',
  },
  more: {
    defaultMessage: 'More',
    id: 'I5NMJ8',
  },
  myAccount: {
    defaultMessage: 'My Account',
    id: '2bGejb',
  },
  noItemsAvailable: {
    defaultMessage: 'No items available',
    id: 'nJCVT0',
  },
  noPurchaseAvailable: {
    defaultMessage: 'Not available for purchase',
    id: 'KorORN',
  },
  purchaseAvailableOn: {
    defaultMessage: 'Will become available for purchase on {date} at {time}',
    id: 'jvgIPx',
  },
  orderHistory: {
    defaultMessage: 'Order history',
    id: 'vQt5XU',
  },
  outOfStock: {
    defaultMessage: 'Out of stock',
    id: 'WHgdsk',
  },
  password: {
    defaultMessage: 'Password',
    id: '5sg7KC',
  },
  phone: {
    defaultMessage: 'Phone',
    id: 'O95R3Z',
  },
  phoneNumber: {
    defaultMessage: 'Phone number: {phone}',
    id: 'WmzATS',
  },
  price: {
    defaultMessage: 'Price',
    id: 'b1zuN9',
  },
  priceRange: {
    defaultMessage: 'Price range',
    id: '+kMQfn',
  },
  products: {
    defaultMessage: 'Products',
    id: '7NFfmz',
  },
  promoCode: {
    defaultMessage: 'Promo code',
    id: '/J2mVO',
  },
  quantity: {
    defaultMessage: 'Quantity',
    id: 'qVGRIE',
  },
  readOn: {
    defaultMessage: 'Read on',
    id: 'tQk32X',
  },
  ReadMore: {
    defaultMessage: 'Read more',
    id: 'S++WdB',
  },
  register: {
    defaultMessage: 'Register',
    id: 'deEeEI',
  },
  subscribe: {
    defaultMessage: 'Subscribe & Get 50€',
    id: 'pssNG/',
  },
  reviewCount: {
    defaultMessage: 'based on {count} reviews',
    id: 'F3lCaN',
  },
  save: {
    defaultMessage: 'Save',
    id: 'jvo0vs',
  },
  saveProject: {
    defaultMessage: 'Save project',
    id: '+Di5s8',
  },
  search: {
    defaultMessage: 'search',
    id: 'qyQ7t4',
  },
  seeAllArticles: {
    defaultMessage: 'See all articles',
    id: 'fWc2Mv',
  },
  SeeAndFeelTheDifference: {
    defaultMessage: 'See and feel the difference',
    id: 'Z161uL',
  },
  send: {
    defaultMessage: 'Send',
    id: '9WRlF4',
  },
  shipping: {
    defaultMessage: 'Shipping',
    id: 'PRlD0A',
  },
  shortEmail: {
    defaultMessage: 'Email',
    id: 'sy+pv5',
  },
  showEmail: {
    defaultMessage: 'Email: {email}',
    id: 'uPNlBw',
  },
  showMore: {
    defaultMessage: 'Show more',
    id: 'aWpBzj',
  },
  signIn: {
    defaultMessage: 'Sign in',
    id: 'SQJto2',
  },
  sku: {
    defaultMessage: 'SKU',
    id: 'k4brJy',
  },
  smallEmail: {
    defaultMessage: 'email',
    id: 'yUVl6+',
  },
  status: {
    defaultMessage: 'Status',
    id: 'tzMNF3',
  },
  subject: {
    defaultMessage: 'Subject',
    id: 'LLtKhp',
  },
  subtotal: {
    defaultMessage: 'Subtotal',
    id: 'L8seEc',
  },
  success: {
    defaultMessage: 'Success!',
    id: 'UIDXdt',
  },
  termsOfService: {
    defaultMessage: 'Terms of Service',
    id: '32rBNK',
  },
  to: {
    defaultMessage: 'to',
    id: 'NLeFGn',
  },
  total: {
    defaultMessage: 'Total',
    id: 'MJ2jZQ',
  },
  totalPrice: {
    defaultMessage: 'Total Price:',
    id: '73BD0e',
  },
  variant: {
    defaultMessage: 'Variant',
    id: 'OK5+Fh',
  },
  view: {
    defaultMessage: 'View',
    id: 'FgydNe',
  },
  viewAllPhotos: {
    defaultMessage: 'View all photos',
    id: 'JNOr9z',
  },
  weeks: {
    defaultMessage: 'weeks',
    id: 'umNSDp',
  },
  youMayAlsoLike: {
    defaultMessage: 'You may also like',
    id: 'CQHqS0',
  },
  yourEmailAddress: {
    defaultMessage: 'Your email address',
    id: 'tRxNl9',
  },
  yourMessage: {
    defaultMessage: 'Your message...',
    id: 'VC3tEU',
  },
});

export const checkoutMessages = defineMessages({
  addNewAddress: {
    defaultMessage: 'Add new address',
    id: 'cQiUt4',
  },
  addressNextActionName: {
    defaultMessage: 'Continue to Shipping',
    id: 'bsM94i',
  },
  billingAddress: {
    defaultMessage: 'BILLING ADDRESS',
    id: 'itE2ni',
  },
  continueShopping: {
    defaultMessage: 'CONTINUE SHOPPING',
    id: 'VsSdhk',
  },
  paymentMethod: {
    defaultMessage: 'PAYMENT METHOD',
    id: 'tlBXu3',
  },
  paymentNextActionName: {
    defaultMessage: 'Place order',
    id: 'JItzoH',
  },
  reviewNextActionName: {
    defaultMessage: 'Continue to Review',
    id: 'BS3FlI',
  },
  reviewOrder: {
    defaultMessage: 'REVIEW ORDER',
    id: 'hHQcs/',
  },
  shippingAddress: {
    defaultMessage: 'Shipping Address',
    id: 'DP5VOH',
  },
  shippingMethod: {
    defaultMessage: 'SHIPPING METHOD',
    id: 'RvuE8K',
  },
  shippingNextActionName: {
    defaultMessage: 'Continue to Payment',
    id: '0g/1TG',
  },
  stepNameAddress: {
    defaultMessage: 'Address',
    id: 'e6Ph5+',
  },
  stepNamePayment: {
    defaultMessage: 'Payment',
    id: 'NmK6zy',
  },
  stepNameReview: {
    defaultMessage: 'Review',
    id: 'R+J5ox',
  },
  stepNameShipping: {
    defaultMessage: 'Shipping',
    id: 'PRlD0A',
  },
});

export const prodListHeaderCommonMsg = defineMessages({
  sortOptionsClear: {
    defaultMessage: 'Clear...',
    id: 'xCzQG8',
  },
  sortOptionsName: {
    defaultMessage: 'Name Increasing',
    id: 'n8Z9ys',
  },
  sortOptionsNameDsc: {
    defaultMessage: 'Name Decreasing',
    id: 'yLRtkC',
  },
  sortOptionsPrice: {
    defaultMessage: 'Price Low-High',
    id: 'x2/i6X',
  },
  sortOptionsPriceDsc: {
    defaultMessage: 'Price High-Low',
    id: 'n6M10I',
  },
  sortOptionsUpdatedAt: {
    defaultMessage: 'Last updated Ascending',
    id: 'WCcbn6',
  },
  sortOptionsUpdatedAtDsc: {
    defaultMessage: 'Last updated Descending',
    id: 'x/OgEF',
  },
});

export const validationCommonMsg = defineMessages({
  confirmPassword: {
    defaultMessage: 'Passwords do not match',
    id: '7Chrsf',
  },
  passwordRetype: {
    defaultMessage: 'Please retype password',
    id: '3vU0+B',
  },
  passwordRetypeMatch: {
    defaultMessage: 'Retyped password does not match',
    id: 'gcnw6w',
  },
  passwordShort: {
    defaultMessage: 'Password is to short!',
    id: '6PIQNP',
  },
  required: {
    defaultMessage: 'Required field',
    id: 'a/4SMm',
  },
  invalidEmail: {
    defaultMessage: 'The email is invalid!',
    id: 'ywpJuM',
  },
});

export const errorCommonMsg = defineMessages({
  choose_payment: {
    defaultMessage: 'Please choose payment method.',
    id: 'LkCowx',
  },
  provide_billing_address: {
    defaultMessage: 'Please provide billing address.',
    id: 'jHKLNU',
  },
  provide_email_address: {
    defaultMessage: 'Please provide email address.',
    id: 'YM709/',
  },
  provide_shipping_address: {
    defaultMessage: 'Please provide shipping address.',
    id: '1KGwKk',
  },
});

export const realizationMsg = defineMessages({
  clear_filter: {
    defaultMessage: 'Clear filter',
    id: 'OhgOkH',
  },
  category: {
    defaultMessage: 'Style',
    id: '7mL9QE',
  },
  colors: {
    defaultMessage: 'Colors',
    id: 'U+dGE5',
  },
  tags: {
    defaultMessage: 'Room',
    id: '2Ml/7i',
  },
  width: {
    defaultMessage: 'Width',
    id: '5IP7AP',
  },
});
