export const apiUrl = process.env.NEXT_PUBLIC_SALEOR_API_URI;
export const API_URI = process.env.NEXT_PUBLIC_SALEOR_API_URI;

export const apiMountUri = process.env.NEXT_PUBLIC_APP_MOUNT_URI || '/';

export const prismicUri =
  process.env.NEXT_PUBLIC_PRISMIC_API_URI || 'renodoors';

export const prismicUrl = `https://${prismicUri}.cdn.prismic.io/graphql`;

export const mediaUri = process.env.NEXT_PUBLIC_MEDIA_BUCKET_URL;

export const analyticsDataPlaneUrl =
  process.env.NEXT_PUBLIC_ANALYTICS_DATA_PLANE_URL;
export const analyticsWriteKey = process.env.NEXT_PUBLIC_ANALYTICS_WRITE_KEY;

export const analyticsHJID = process.env.NEXT_PUBLIC_ANALYTICS_HJID;
export const analyticsHJSV = process.env.NEXT_PUBLIC_ANALYTICS_HJSV;

export const analyticsGTM = process.env.NEXT_PUBLIC_ANALYTICS_GTM;

export const serviceWorkerTimeout =
  parseInt(process.env.SERVICE_WORKER_TIMEOUT, 10) || 60 * 1000;

export const subscribeNotification =
  process.env.NEXT_PUBLIC_SUBSCRIBE_NOTIFICATION === 'true';

export const defaultLocale = (
  process.env.NEXT_PUBLIC_DEFAULT_LOCALE || 'EN'
).toUpperCase();

export const ssrMode = typeof window === 'undefined';

export const exportMode = process.env.NEXT_EXPORT === 'true';

export const incrementalStaticRegenerationRevalidate = parseInt(
  process.env.INCREMENTAL_STATIC_REGENERATION_REVALIDATE!,
  10
);

export const staticPathsFetchBatch = 50;

// export const staticPathsFallback = (
//   exportMode ? false : process.env.NEXT_PUBLIC_STATIC_PATHS_FALLBACK
// ) as boolean | 'blocking';
export const staticPathsFallback = 'blocking';
