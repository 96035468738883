import { LanguageCodeEnum } from '@sdk/gqlTypes/generatedTypes';

// import deIcon from '../../images/languages/deIcon.svg';
// import enIcon from '../../images/languages/enIcon.svg';
// import plIcon from '../../images/languages/plIcon.svg';

// import daIcon from '../../images/languages/daIcon.svg';
// import svIcon from '../../images/languages/svIcon.svg';
// import noIcon from '../../images/languages/noIcon.svg';

// import itIcon from '../../images/languages/itIcon.svg';
// import esIcon from '../../images/languages/esIcon.svg';

import frIcon from '../../images/languages/frIcon.svg';
// import nlIcon from '../../images/languages/nlIcon.svg';

import { defaultLocale } from '../../constants';

export const LOCALES = [
  /* {
    slug: 'en',
    code: 'EN' as LanguageCodeEnum,
    cms: 'en-us',
    icon: enIcon,
    name: 'English',
  },
  {
    slug: 'de',
    code: 'DE' as LanguageCodeEnum,
    cms: 'de-de',
    icon: deIcon,
    name: 'Deutsch',
  },
  {
    slug: 'pl',
    code: 'PL' as LanguageCodeEnum,
    cms: 'pl',
    icon: plIcon,
    name: 'Polski',
  }, */
  {
    slug: 'fr',
    code: 'FR' as LanguageCodeEnum,
    cms: 'fr-fr',
    icon: frIcon,
    name: 'Francais',
  },
  /*
  {
    slug: 'nl',
    code: 'NL' as LanguageCodeEnum,
    cms: 'nl-nl',
    icon: nlIcon,
    name: 'Nederlands',
  },
  {
    slug: 'es',
    code: 'ES' as LanguageCodeEnum,
    cms: 'es',
    icon: esIcon,
    name: 'Español',
  },
  {
    slug: 'it',
    code: 'IT' as LanguageCodeEnum,
    cms: 'it',
    icon: itIcon,
    name: 'Italiano',
  },
  {
    slug: 'da',
    code: 'DA' as LanguageCodeEnum,
    cms: 'da',
    icon: daIcon,
    name: 'Dansk',
  },
  {
    slug: 'sv',
    code: 'SV' as LanguageCodeEnum,
    cms: 'sv',
    icon: svIcon,
    name: 'Svenska',
  },
  {
    slug: 'no',
    code: 'NO' as LanguageCodeEnum,
    cms: 'no',
    icon: noIcon,
    name: 'Norsk',
  }, */
];

export const localeToEnum = (localeSlug: string): LanguageCodeEnum => {
  const chosenLocale = LOCALES.find(({ slug }) => slug === localeSlug)?.code;
  if (chosenLocale) {
    return chosenLocale;
  }
  return (
    LOCALES.find(({ slug }) => slug === defaultLocale)?.code ||
    ('FR' as LanguageCodeEnum)
  );
};

export const localeToPrismic = (localeSlug: string): string => {
  const chosenLocale = LOCALES.find(({ slug }) => slug === localeSlug)?.cms;
  if (chosenLocale) {
    return chosenLocale;
  }
  return LOCALES.find(({ slug }) => slug === defaultLocale)?.slug || 'fr-fr';
};

export const localeToFlag = (localeSlug: string): string => {
  const chosenLocale = LOCALES.find(({ slug }) => slug === localeSlug)?.icon;
  if (chosenLocale) {
    return chosenLocale;
  }
  return LOCALES.find(({ slug }) => slug === defaultLocale)?.icon || frIcon;
};

export const prismicToLocale = (localeSlug: string | undefined): string => {
  const chosenLocale = LOCALES.find(({ cms }) => cms === localeSlug)?.slug;
  if (chosenLocale) {
    return chosenLocale;
  }
  return 'fr';
};

export const localeToSaleorQuery = (localeSlug: string | undefined) => ({
  languageCode: localeToEnum(localeSlug || defaultLocale),
});

export const localeToPrismicQuery = (localeSlug: string | undefined) => ({
  lang: localeToPrismic(localeSlug || defaultLocale),
});
