import { useIntl } from 'react-intl';
import { clsx } from 'clsx';

import Link from 'next-translate-routes/link';

import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { Button } from '@components/atoms/Button';
import { CartFooter } from '@components/atoms/CartFooter';
import { CartProductList } from '@components/molecules/CartProductList';
import { TaxedMoney } from '@components/containers/TaxedMoney';
import { Overlay } from '@components/organisms/Overlay';

import { paths } from '@paths';

import { useCheckout } from '@sdk/providers/CheckoutProvider';

import closeImg from 'images/x.svg';

// import { CartUpsale } from "./CartUpsale";
import Empty from './Empty';

import * as S from './styles';

const Cart: React.FC = () => {
  const intl = useIntl();
  const modal = useModal();

  const { checkout, removeItem } = useCheckout();
  const {
    lines: items,
    subtotalPrice,
    shippingPrice,
    discount,
    totalPrice,
  } = checkout || {};

  const discountPrice = discount && {
    gross: discount,
    net: discount,
  };

  const count =
    items?.reduce((prevVal, currVal) => prevVal + currVal.quantity, 0) || 0;

  const removeProductItem = (item) => {
    // @ analytics
    window?.rudderanalytics?.track('Product Removed', {
      name: item.variant?.product?.productType?.slug,
      product_id: item.variant?.product?.slug,
      variant: item.variant.id,
    });
    // @ graphql
    removeItem(item.variant.id, item.productAttributes);
  };

  return (
    <Overlay position="right" show={modal.visible} hide={modal.remove}>
      <S.Modal className="cart">
        <S.Header className="overlay__header">
          <S.HeaderText className="overlay__header-text">
            <S.HeaderTextTitle className="overlay__header-text-title">
              {intl.formatMessage({
                defaultMessage: 'Shopping cart',
                id: 'wjh10D',
              })}
            </S.HeaderTextTitle>
            <S.HeaderTextItem
              className={clsx('overlay__header-text-items', {
                'not-empty': !!count,
              })}
            >
              {count}
            </S.HeaderTextItem>
          </S.HeaderText>
          <S.HeaderIcon
            src={closeImg}
            onClick={modal.remove}
            className="overlay__header__close-icon"
          />
        </S.Header>
        {items?.length ? (
          <>
            <CartProductList lines={items} remove={removeProductItem} />
            <CartFooter
              subtotalPrice={
                <TaxedMoney
                  data-cy="cartPageSubtotalPrice"
                  taxedMoney={subtotalPrice}
                />
              }
              totalPrice={
                <TaxedMoney
                  data-cy="cartPageTotalPrice"
                  taxedMoney={totalPrice}
                />
              }
              shippingPrice={
                shippingPrice && (
                  <TaxedMoney
                    data-cy="cartPageShippingPrice"
                    taxedMoney={shippingPrice}
                  />
                )
              }
              discountPrice={
                discountPrice &&
                discountPrice.gross.amount !== 0 && (
                  <TaxedMoney
                    data-cy="cartPageShippingPrice"
                    taxedMoney={discountPrice}
                  />
                )
              }
            />
            <S.FooterButton className="cart__button">
              <Link href={paths.checkout}>
                <a>
                  <Button className="button" size="lg" onClick={modal.remove}>
                    {intl.formatMessage({
                      defaultMessage: 'Go To Checkout',
                      id: 'YzFp2d',
                    })}
                  </Button>
                </a>
              </Link>
            </S.FooterButton>
          </>
        ) : (
          <Empty overlayHide={modal.remove} />
        )}
      </S.Modal>
    </Overlay>
  );
};

export default NiceModal.create(Cart);
