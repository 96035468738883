import { media, styled } from '@styles';

export const List = styled.ul``;

export const ItemImage = styled.div`
  display: block;
  flex-shrink: 0;
  width: 148px;
  height: 148px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme: { colors } }) => colors.light};
  border-radius: 6px;
  ${media.largeScreen`
    width: 100px;
    height: 100px;
  `}
  ${media.smallScreen`
    width: 64px;
    height: 64px;
  `}
`;

export const ProductName = styled.a`
  font-family: ${(props) => props.theme.typography.thinFontFamily};
  font-size: ${(props) => props.theme.typography.h5FontSize};
  margin-bottom: 8px;
  line-height: 1em;
  text-transform: uppercase;
  display: inline-block;
  ${media.largeScreen`
    margin-bottom: 6px;
  `}
  ${media.smallScreen`
    margin-bottom: 0;
  `}
  * {
    font: inherit;
  }
`;

export const ItemCategory = styled.p`
  color: ${({ theme: { colors } }) => colors.blueLight};
  font-family: ${({ theme: { typography } }) => typography.extraFontFamily};
  font-size: ${({ theme: { typography } }) => typography.smallFontSize};
  line-height: 1.6em;
`;

export const ItemHint = styled.span`
  color: ${({ theme: { colors } }) => colors.graySemi};
`;

export const ProductQuantity = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const QuantityText = styled.div`
  color: ${({ theme: { colors } }) => colors.graySemi};
  font-family: ${({ theme: { typography } }) => typography.extraFontFamily};
  font-size: ${({ theme: { typography } }) => typography.smallFontSize};
  line-height: 1.6em;
`;

export const Action = styled.div`
  display: flex;
  justify-content: flex-end;
  opacity: 1;
  transition: opacity 0.3s;
  .cart__list__item-icon {
    margin-left: 6px;
    background-color: rgba(#ecedee, 0.6);
    cursor: pointer;
    transition: background-color 0.2s;
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    ${media.smallScreen`
      width: 24px;
      height: 24px;
    `}
    svg,
    img {
      display: block;
      ${media.smallScreen`
        transform: scale(0.6);
      `}
    }
    path {
      fill: #c4c4c5;
    }
    &:hover {
      background-color: #ecedee;
    }
  }
`;

export const Item = styled.li`
  padding: 30px 0;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.light};
  display: flex;
  align-content: stretch;
  position: relative;
  ${media.largeScreen`
    padding: 20px 0;
  `}
  img {
    max-width: 90%;
    max-height: 90%;
    // width: auto;
    height: auto;
  }
`;

export const Quantity = styled.div`
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const QuantityButton = styled.button`
  font-family: ${({ theme: { typography } }) => typography.extraFontFamily};
  font-size: ${({ theme: { typography } }) => typography.baseFontSize};
  line-height: 1.25em;
  opacity: 0.3;
  transition: opacity 0.6s;
  display: inline-block;
  padding: 3px;
  &:hover {
    opacity: 0.6;
  }
`;

export const QuantityInput = styled.input`
  display: inline-block;
  font-family: ${({ theme: { typography } }) => typography.extraFontFamily};
  font-size: ${({ theme: { typography } }) => typography.baseFontSize};
  line-height: 1.25em;
  height: 1.25em;
  padding: 0;
  width: 50px;
  text-align: center;
  border: none;
  background: transparent;
`;

export const QuantityError = styled.div`
  font-size: ${({ theme: { typography } }) => typography.labelFontSize};
  color: ${({ theme: { colors } }) => colors.red};
`;

export const Details = styled.div`
  width: 70%;
  padding: ${({ theme: { spacing } }) =>
    `${spacing.spacer} 0 0 ${spacing.spacer}`};
  ${media.smallScreen`
    padding-top: 0;
  `}
`;

export const Summary = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ProductPrice = styled.p`
  text-transform: uppercase;
  font-family: ${({ theme: { typography } }) => typography.extraFontFamily};
  font-size: ${({ theme: { typography } }) => typography.smallFontSize};
  line-height: 1.6em;
  color: ${({ theme: { colors } }) => colors.red};
  text-align: right;
`;

export const EstimatedShipping = styled.div`
  color: ${({ theme: { colors } }) => colors.graySemi};
  font-family: ${({ theme: { typography } }) => typography.extraFontFamily};
  font-size: ${({ theme: { typography } }) => typography.smallFontSize};
  line-height: 1.6em;
  text-align: right;
`;

export const DetailsVariant = styled.div`
  white-space: nowrap;
  width: 220px;
  font-family: ${({ theme: { typography } }) => typography.extraFontFamily};
  font-size: ${({ theme: { typography } }) => typography.smallFontSize};
  color: ${({ theme: { colors } }) => colors.blueLight};
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.6em;
  ${media.largeScreen`
    width: 150px;
  `}
  ${media.smallScreen`
    width: 90px;
  `}
`;

export const DetailsVariantItem = styled.span`
  &:not(:last-child) {
    margin-right: 5px;
    &:after {
      content: '|';
      color: ${({ theme: { colors } }) => colors.graySemi};
    }
  }
`;

export const DetailsVariantWrapper = styled.div`
  display: flex;
  align-items: center;
  &.show {
    display: block;
    ${DetailsVariant} {
      white-space: initial;
      width: 100%;
    }
  }

  &.attr.show {
    ${DetailsVariantItem} {
      margin-bottom: 3px;
      display: block;
      text-align: left;
    }
  }
`;

export const DetailsVariantMoreButton = styled.button`
  margin-bottom: 3px;
  display: block;
  font-family: ${({ theme: { typography } }) => typography.extraFontFamily};
  font-size: ${({ theme: { typography } }) => typography.smallFontSize};
  text-decoration: underline;
`;
