/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useState } from 'react';
import { useIntl } from 'react-intl';

import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { CardHeader } from '@components/molecules/CardHeader';
import { Overlay } from '@components/organisms/Overlay';

import { LoginForm } from '@components/molecules/LoginForm';
import { RegisterForm } from '@components/molecules/RegisterForm';
import signIn from 'images/sign-in.svg';
import { PasswordForgotten } from './PasswordForgotten';
import { RegisterConfirmation } from './RegisterConfirmation';

import { commonMessages } from '../../../../intl';

import * as S from './styles';
import { IProps } from './types';

const Login: React.FC<IProps> = ({ active }) => {
  const modal = useModal();
  const intl = useIntl();
  const [activeTab, setActiveTab] = useState<string>(active || 'login');
  const [data, setData] = useState<any>({});

  const handleRegisterSuccess = (data?: any) => {
    setData(data);
    setActiveTab('success');
  };

  return (
    <Overlay position="fit" show={modal.visible} hide={modal.remove}>
      <S.Modal>
        {activeTab === 'success' && <RegisterConfirmation data={data} />}
        {activeTab === 'forgotten' && (
          <>
            <PasswordForgotten />
            <S.Reminder>
              <S.Link onClick={() => setActiveTab('login')}>
                {intl.formatMessage({
                  defaultMessage: 'Back to Sign In',
                  id: 'zpY1ie',
                })}
              </S.Link>
            </S.Reminder>
          </>
        )}
        {(activeTab === 'login' || activeTab === 'register') && (
          <>
            <CardHeader divider formImage={signIn} onHide={modal.remove}>
              <S.Tab className="login__tabs">
                <span
                  onClick={() => setActiveTab('login')}
                  className={activeTab === 'login' ? 'active-tab' : ''}
                >
                  {intl.formatMessage(commonMessages.signIn)}
                </span>
                <span
                  onClick={() => setActiveTab('register')}
                  className={activeTab === 'register' ? 'active-tab' : ''}
                >
                  {intl.formatMessage(commonMessages.register)}
                </span>
              </S.Tab>
            </CardHeader>
            <S.Content>
              {activeTab === 'login' ? (
                <>
                  <LoginForm onSuccess={modal.remove} />
                  <S.Reminder>
                    <S.Link onClick={() => setActiveTab('forgotten')}>
                      {intl.formatMessage({
                        defaultMessage: 'Forgot your password ?',
                        id: 'LDPFW0',
                      })}
                    </S.Link>
                  </S.Reminder>
                </>
              ) : (
                <RegisterForm onSuccess={handleRegisterSuccess} />
              )}
            </S.Content>
          </>
        )}
      </S.Modal>
    </Overlay>
  );
};

export default NiceModal.create(Login);
