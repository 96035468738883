import React from 'react';

// import Link from 'next-translate-routes/link';

import { linkResolver } from '@prismic/prismic-config';
import email from 'images/email.svg';
import phone from 'images/phone.svg';
import * as S from './styles';
import { IProps } from './types';

export const ContactsNav: React.FC<IProps> = ({ contact }) => {
  const contactsList = contact?.filter((z) => z.show_in_nav);
  return (
    <S.Wrapper>
      {contactsList?.map((c, i) => (
        <S.Item key={`nav-contact-${i}`}>
          {c.type === 'phone' && (
            <img src={phone} width="16px" height="16px" alt="phone" />
          )}
          {c.type === 'email' && (
            <img src={email} width="16px" height="16px" alt="email" />
          )}
          <S.Link href={linkResolver(c.link)}>{c.label}</S.Link>
        </S.Item>
      ))}
    </S.Wrapper>
  );
};
