import { useRef, useEffect } from 'react';

/**
 * Returns the previous value stored in state.
 */
export function usePrevious<T>(value: T) {
  const ref = useRef<T | undefined>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current as T;
}
