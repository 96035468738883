import * as React from 'react';

import { useRouter } from 'next/router';

import { useMediaQuery } from '@hooks/useMediaQuery';
import { mediumScreen } from '@styles/constants';

import { MainMenuItemFragment } from '@sdk/gqlTypes/generatedOperations';

import { NavLink } from '@components/atoms/NavLink';
import { NavOverlay } from '@components/molecules/NavOverlay';
import { translate } from '@utils/translations';

import NoPhoto from 'images/no-photo.svg';

import SaleorNavItem from './SaleorNavItem';
import * as S from './styles';

const COLLECTIONS_WITH_PICTURE = 8;

interface INavDropdown extends MainMenuItemFragment {
  // overlay: OverlayContextInterface;
  children?: MainMenuItemFragment[];
  showDropdown: boolean;
  onShowDropdown: () => void;
  onHideDropdown: () => void;
  isScrolled?: boolean;
}

export const SaleorNavDropdown: React.FC<INavDropdown> = (props) => {
  const { onHideDropdown, onShowDropdown, showDropdown, children } = props;
  const { pathname } = useRouter();

  const isCollectionPage = pathname.includes('collection');

  const [isMediumScreen] = useMediaQuery(`(max-width: ${mediumScreen}px)`);

  React.useEffect(() => {
    onHideDropdown();
  }, [isCollectionPage]);

  const collections = (
    <S.Collection>
      {children
        ?.slice(0, COLLECTIONS_WITH_PICTURE)
        ?.map(
          (subItem, i) =>
            subItem && (
              <SaleorNavItem
                key={i}
                imageUrl={
                  subItem.collection?.backgroundImage?.url ||
                  subItem.category?.backgroundImage?.url ||
                  NoPhoto
                }
                {...subItem}
              />
            )
        )}
      {children?.length > COLLECTIONS_WITH_PICTURE && (
        <S.StackedNavItem>
          {children?.slice(COLLECTIONS_WITH_PICTURE, children?.length)?.map(
            (subItem, i) =>
              subItem && (
                <S.NavItem>
                  <NavLink item={subItem}>
                    <S.NavItemTitle>
                      {translate(subItem, 'name')}
                    </S.NavItemTitle>
                  </NavLink>
                </S.NavItem>
              )
          )}
        </S.StackedNavItem>
      )}
    </S.Collection>
  );

  const collectionsShrinked = (
    <S.Collection>
      {children?.map(
        (subItem, i) =>
          subItem && (
            <SaleorNavItem
              key={i}
              imageUrl={
                subItem.collection?.backgroundImage?.url ||
                subItem.category?.backgroundImage?.url ||
                NoPhoto
              }
              isScrolled
              {...subItem}
            />
          )
      )}
    </S.Collection>
  );

  return (
    <S.DropdownWrapper
      active={showDropdown}
      onMouseOver={onShowDropdown}
      onMouseLeave={onHideDropdown}
    >
      <S.DropdownItem>
        <NavLink
          className="main-menu__nav-link"
          item={props}
          onClick={onHideDropdown}
        />
      </S.DropdownItem>
      <S.DropdownBody active={showDropdown}>
        {/* isCollectionPage && !isScrolled && (
          <NavOverlay show>{collections}</NavOverlay>
        ) */}
        {/* isCollectionPage && isScrolled && (
          <NavOverlay show>{collectionsShrinked}</NavOverlay>
        ) */}
        {isCollectionPage && !isMediumScreen && (
          <NavOverlay show>{collectionsShrinked}</NavOverlay>
        )}
        {!isCollectionPage && collections}
      </S.DropdownBody>
    </S.DropdownWrapper>
  );
};
