import { styled } from '@styles';

export const ErrorMessage = styled.div`
  color: ${(props) => props.theme.colors.error};
  font-size: ${(props) => props.theme.input.labelFontSize};
`;

export const ErrorParagraph = styled.p`
  margin: 0 !important;
  color: #fe6e76 !important;
  font-size: ${(props) => props.theme.input.labelFontSize};
`;

ErrorMessage.displayName = 'S.ErrorMessage';
