import { media, styled } from '@styles';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  img {
    display: block;
    margin-right: 15px;
    width: auto;
    height: auto;
    max-width: 20px;
    max-height: 20px;
    ${media.largeScreen`
      max-width: 16px;
      max-height: 16px;
    `}
    ${media.smallScreen`
      margin-right: 10px;
    `}
  }
`;

export const Link = styled.a`
  position: relative;
  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    height: 2px;
    background-color: ${(props) => props.theme.colors.secondary};
  }
`;
