export function maybe(exp: any, d?: any): any {
  try {
    const result = exp();
    return result === undefined ? d : result;
  } catch {
    return d;
  }
}

interface Dict {
  [key: string]: string[];
}

export const convertToAttributeScalar = (attributes: Dict) =>
  Object.entries(attributes)
    .map(([key, value]) =>
      value.map((attribute: any) => ({ slug: key, value: attribute }))
    )
    .reduce((prev, curr) => [...prev, ...curr], []);

export function filterNotEmptyArrayItems<TValue>(
  value: TValue | null | undefined
): value is TValue {
  return value !== null && value !== undefined;
}

export const getValueOrEmpty = <T>(value: T): T | string =>
  value === undefined || value === null ? '' : value;

export const removeEmptyProps: any = (obj) => {
  const newObj = {};
  Object.keys(obj).forEach((prop) => {
    if (obj[prop] && obj[prop] !== '') {
      newObj[prop] = obj[prop];
    }
  });
  return newObj;
};
