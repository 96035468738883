import React from 'react';

import { ssrMode } from '../../constants';

interface Naviguator {
  onLine?: boolean;
}

export const useNetworkStatus = (callBack?: (online?: boolean) => void) => {
  const nav: Naviguator = !ssrMode ? navigator : {};

  const [online, setOnline] = React.useState(
    'onLine' in nav ? nav.onLine : true
  );

  const updateOnlineStatus = () => {
    const status = nav.onLine;

    if (callBack) {
      callBack(status);
    }
    setOnline(nav.onLine);
  };

  React.useEffect(() => {
    addEventListener('offline', updateOnlineStatus);
    addEventListener('online', updateOnlineStatus);

    return () => {
      removeEventListener('offline', updateOnlineStatus);
      removeEventListener('online', updateOnlineStatus);
    };
  }, []);

  return { online };
};
