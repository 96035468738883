import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { useCheckout } from '@sdk/providers/CheckoutProvider';

import {
  CHECKOUT_STEPS,
  checkIfShippingRequiredForProducts,
} from '@pages/CheckoutPage/utils';

import { useCheckoutStepState } from './useCheckoutStepState';

export const useRedirectToCorrectCheckoutStep = (checkoutLoaded: boolean) => {
  const { pathname, replace } = useRouter();
  const { checkout, checkoutGateway } = useCheckout();
  const { lines: items } = checkout || {};
  const { recommendedStep, maxPossibleStep } = useCheckoutStepState(
    items,
    checkout,
    checkoutGateway
  );

  useEffect(() => {
    const stepFromPath = CHECKOUT_STEPS.find(
      ({ link }) => link === pathname
    )?.step;
    const isShippingRequired = checkIfShippingRequiredForProducts(items);
    const isIncorrectStep =
      !stepFromPath ||
      stepFromPath > maxPossibleStep ||
      (pathname === CHECKOUT_STEPS[1].link && !isShippingRequired);

    const getStepLink = () =>
      CHECKOUT_STEPS.find((stepObj) => stepObj.step === recommendedStep)
        ?.link || CHECKOUT_STEPS[0].link;

    if (checkoutLoaded && isIncorrectStep) {
      replace(getStepLink());
    }
  }, [pathname, checkoutLoaded]);
};
