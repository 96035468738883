import { media, styled } from '@styles';

export const Wrapper = styled.div`
  display: flex;
  margin-top: 65px;
  ${media.mediumScreen`
    margin-top: 30px;
  `}
`;

export const Link = styled.a`
  margin-right: 15px;
`;
