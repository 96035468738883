import { media, styled } from '@styles';

export const Wrapper = styled.div`
  .input {
    margin: 0 auto ${(props) => props.theme.spacing.spacer} auto;
  }
`;

export const Text = styled.p`
  margin-bottom: ${(props) => props.theme.spacing.spacer};
`;

export const Error = styled.span``;

export const SubmitButton = styled.div`
  text-align: center;
  margin: calc(${(props) => props.theme.spacing.spacer} * 2) auto;
  ${media.smallScreen`
    margin: ${(props) => props.theme.spacing.spacer} auto;
  `}
`;

export const Reminder = styled.div`
  color: ${({ theme: { colors } }) => colors.dividerDark};
  font-size: ${(props) => props.theme.typography.smallFontSize};
  ${({ theme: { typography } }) => typography.smallFontSize};
  margin: ${({ theme: { spacing } }) => `calc(${spacing.spacer} * 2) 0`};
  text-align: center;
  ${media.smallScreen`
    margin: ${({ theme: { spacing } }) => `${spacing.spacer} 0`};
  `}
`;

export const Checkbox = styled.div`
  display: flex;
  font-size: ${(props) => props.theme.typography.baseFontSize};
  span {
    top: 3px;
    position: relative;
    margin-left: 7px;
    color: ${({ theme: { colors } }) => colors.graySemi};
    font-family: 'Inter';
    &::after {
      content: '';
      display: block;
      height: 1px;
      width: 100%;
      background-color: ${({ theme: { colors } }) => colors.graySemi};
    }
  }
  ${media.mediumScreen`
    width: 50%;
    margin: 0 auto 1.25rem auto;
  `}
  ${media.smallScreen`
    width: 100%;
    margin: 0 auto;
  `}
`;
