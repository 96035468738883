import { media, styled } from '@styles';
import { css } from 'styled-components';

export const SideNav = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f6f7f8;
`;

export const Header = styled.div<{ divider: boolean }>`
  display: flex;
  justify-content: space-between;
  // flex-direction: row;
  align-items: center;
  position: relative;
  background-color: ${(props) => props.theme.colors.white};
`;

export const CloseImg = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  height: 15px;
  width: 15px;
`;

export const HeadImg = styled.div`
  margin: 0rem auto 0rem auto;
  .tablet {
    ${media.mediumScreen`
      display: none;
    `}
    img {
      width: 72px;
      height: auto;
      display: block;
    }
  }
  .mobile {
    display: none;
    ${media.mediumScreen`
      display: block;
    `}
  }
`;

export const TopBar = styled.div``;

export const Body = styled.div`
  overflow-y: auto;
`;

export const MenuItem = styled.ul`
  position: relative;
  padding-top: 90px;
  padding-bottom: 60px;
  ${media.mediumScreen`
    padding-top: 60px;
    padding-bottom: 60px;
  `}
  ${media.smallScreen`
    padding-top: 40px;
    padding-bottom: 40px;
  `}
`;

export const NavItemImage = styled.div`
  width: 150px;
  height: 173px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: ${(props) => props.theme.colors.light};
  position: relative;
  ${media.smallScreen`
    height: 50vw;
    width: 100%;
    background-size: cover;
  `}
`;

export const NavItemTitle = styled.span`
  line-height: 200%;
  font-family: ${(props) => props.theme.typography.thinFontFamily};
  font-size: ${(props) => props.theme.typography.baseFontSize};
  transition: color 0.6s;
  text-align: center;
  text-transform: capitalize;
`;

const arrowStyle = css`
  display: flex;
  flex-direction: column;
  padding-left: 57px;
  padding-right: 30px;
  ${media.smallScreen`
    padding-left: 14px;
    padding-right: 14px;
  `}
  }
  .side-nav__menu-item-link {
    color: ${(props) => props.theme.colors.baseFont};
    font-family: ${(props) => props.theme.typography.thinFontFamily};
    font-size: ${(props) => props.theme.typography.h4FontSize};
    line-height: 64px;
    margin-bottom: 10px;
    text-decoration: none;
    text-transform: none;
    transition: 0.3s;
    position: relative;
    ${media.mediumScreen`
      line-height: 64px;
      margin-bottom: 17px;
    `}
    ${media.smallScreen`
      line-height: 120%;
      margin-bottom: 0;
    `}
  }
`;

const parentStyle = css`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 5px;
  padding-left: 57px;
  padding-right: 30px;
  ${media.smallScreen`
    padding-left: 14px;
    padding-right: 14px;
  `}
  .side-nav__menu-item-link {
    font-size: 0;
    line-height: 1;
    margin: 30px 10px 0 10px;
    padding-left: 0;
    padding-right: 0;
    // padding-bottom: 16px;
    font-size: 0;
    line-height: 1;
    text-align: center;
    background-color: #f0e9eb;
    ${media.smallScreen`

      // padding-bottom: 14px;
      flex-basis: calc(50% - 20px);
    `}
  }
`;

export const NavItem = styled.div<{ arrow: boolean }>`
  ${({ arrow }) =>
    arrow === true &&
    css`
      ${arrowStyle}
    `}
  ${({ arrow }) =>
    arrow === false &&
    css`
      ${parentStyle}
    `}
  }}
`;

export const LocaleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 80px;
  padding-left: 64px;
  padding-right: 100px;
  ${media.smallScreen`
    padding: 16px 0 10px;
    margin: 0 25px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  `}
`;

export const LocaleContainer = styled.div`
  position: absolute;
  display: flex;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f6f7f8;
  z-index: 3;
  ${media.smallScreen`
    display: block;
  `}
  margin: 40px 40px 40px 84px;
  ${media.largeScreen`
    margin: 35px 40px;
  `}
  ${media.smallScreen`
    margin: 20px;
  `}
`;

export const LocaleRegion = styled.div`
  padding: 28px 299px 36px 143px;
  flex-grow: 1;
  ${media.largeScreen`
    padding: 18px 36px 18px 64px;
  `}
  ${media.mediumScreen`
    padding: 16px 36px 20px 64px;
    background-color: #ffffff;
  `}
  ${media.smallScreen`
    background-color: transparent;
    padding: 0;
  `}
`;

export const LocaleLanguage = styled.div`
  padding: 28px 143px 36px 299px;
  background-color: #f6f7f8;
  flex-basis: 50%;
  ${media.largeScreen`
    padding: 18px 64px 18px 36px;
  `}
  ${media.mediumScreen`
    padding: 16px 64px 20px 36px;
  `}
`;

export const LocaleItemTop = styled.div`
  margin-bottom: 38px;
  height: 80px;
  ${media.largeScreen`
    margin-bottom: 18px;
  `}
  ${media.mediumScreen`
    height: auto;
  `}
`;

export const LocaleItemTitle = styled.h5`
  margin-bottom: 9px;
  font-family: ${(props) => props.theme.typography.thinFontFamily};
  font-size: ${(props) => props.theme.typography.baseFontSize};
  font-weight: 300;
  line-height: 100%;
  ${media.largeScreen`
    margin-bottom: 13px;
  `}
`;

export const LocaleItemSubTitle = styled.p`
  max-width: 330px;
  font-family: ${(props) => props.theme.typography.extraFontFamily};
  font-size: ${(props) => props.theme.typography.smallFontSize};
  line-height: 160%;
  color: ${(props) => props.theme.spacing.spacer};
  ${media.largeScreen`
    max-width: 280px;
  `}
`;

export const LocaleImage = styled.div`
  img {
    width: 24px;
    height: 24px;
    display: inline-block;
    border-radius: 50%;
    margin-right: 12px;
    border: 2px solid transparent;
    transition: all 0.2s ease-in-out;
  }
`;

export const LocaleTabsHeader = styled.div`
  padding: 18px 0;
  margin: 0 25px;
  display: flex;
  justify-content: center;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

export const LocaleTabsButton = styled.button<{ active: boolean }>`
  font-family: ${(props) => props.theme.typography.thinFontFamily};
  font-size: ${(props) => props.theme.typography.smallFontSize};
  font-weight: 300;
  line-height: 16px;
  letter-spacing: -0.5px;
  color: ${({ active, theme }) =>
    active ? theme.colors.baseFont : `rgba(44, 44, 44, 0.4)`};

  &:not(:first-child) {
    margin-left: 25px;
  }
`;

export const LocaleTabsBackButton = styled.button`
  position: absolute;
  transform: translate(0, -50%);
  ${media.mediumScreen`
    top: 47px;
    left: 33px;
  `}
  ${media.smallScreen`
    top: 50%;
    left: 0;
  `}

  img {
    height: 10px;
    width: 5px;
  }
`;

export const LocaleTabsContent = styled.div`
  padding: 15px 24px;
`;

export const LocaleTabsDescription = styled.div`
  margin-bottom: 27px;
  text-align: center;
  font-family: ${(props) => props.theme.typography.extraFontFamily};
  font-size: ${(props) => props.theme.typography.labelFontSize};
  line-height: 160%;
  color: ${(props) => props.theme.colors.graySemi};
`;
