import React from 'react';

import { PrismicRichText } from '@prismicio/react';
import { linkResolver } from '@prismic/prismic-config';

import * as S from './styles';
import { IProps } from './types';

export const BottomBar: React.FC<IProps> = ({ copyright, terms }) => (
  <S.Wrapper>
    {copyright && <PrismicRichText field={copyright} />}
    {terms && <PrismicRichText field={terms} linkResolver={linkResolver} />}
  </S.Wrapper>
);
