import React from 'react';
import { ErrorBoundary } from '@components/containers/ErrorBoundary';

import { ProductListItem } from './ProductListItem';

import * as S from './styles';
import { IProps } from './types';

export const CartProductList: React.FC<IProps> = ({
  lines,
  remove,
  readonly,
}) => (
  <S.List>
    {lines.map((line) => {
      const key = `${line.variant.id}${line.productAttributes
        .map((attr) => attr.attribute.id)
        .join()}`;

      return (
        <ErrorBoundary key={key}>
          <ProductListItem line={line} remove={remove} readonly={readonly} />
        </ErrorBoundary>
      );
    })}
  </S.List>
);
