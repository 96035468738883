import { useCallback } from 'react';

import { ErrorMessage } from '@components/atoms/ErrorMessage';
import * as S from './styles';
import { IProps } from './types';

export const NumberField: React.FC<IProps> = ({
  errors,
  onChange,
  value,
  disabled,
}: IProps) => (
  <S.NumberField>
    <S.Button
      type="button"
      onClick={useCallback(() => {
        onChange(value - 1);
      }, [onChange, value])}
      disabled={disabled}
    >
      <span>&#65293;</span>
    </S.Button>
    <S.Middle>
      <S.Input
        onChange={(e) => onChange(parseInt(e.target.value, 10))}
        type="number"
        value={value}
        disabled={disabled}
      />
      <S.ErrorMessages>
        <ErrorMessage errors={errors} />
      </S.ErrorMessages>
    </S.Middle>
    <S.Button
      type="button"
      onClick={useCallback(() => {
        onChange(value + 1);
      }, [onChange, value])}
      disabled={disabled}
    >
      <span>&#65291;</span>
    </S.Button>
  </S.NumberField>
);
