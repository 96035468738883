import { media, styled } from '@styles';
import { css, keyframes, Keyframes } from 'styled-components';
import { Position, TransitionState } from './types';

interface IStyleProps {
  open: boolean;
  position: Position;
  state: TransitionState;
  transparent?: boolean;
}

const getTranslate = (side: 'left' | 'right'): string =>
  side === 'left' ? '-100%' : '100%';

const slideAnimation = (open: boolean, side: 'left' | 'right'): Keyframes => {
  const initialValue = open ? getTranslate(side) : 0;
  const endValue = open ? 0 : getTranslate(side);
  return keyframes`
    from {
      transform: translateX(${initialValue});
    }
    to {
      transform: translateX(${endValue});
    }`;
};

const opacity = {
  entered: 1,
  entering: 0,
  exited: 0,
  exiting: 0,
  unmounted: 0,
};

const justify = {
  bottom: 'center',
  center: 'center',
  fit: 'center',
  left: 'flex-start',
  right: 'flex-end',
};

const lightboxHeight = {
  bottom: '85%',
  center: 'auto',
  fit: 'auto',
  left: '100vh',
  right: '100vh',
};

const lightboxWidth = (width: number): Record<string, string> => ({
  bottom: '100vw',
  center: `${width}px`,
  fit: 'fit-content',
  left: 'auto',
  right: 'auto',
});

export const OverlayInner = styled.div`
  max-width: 100%;
  max-height: 100%;
  overflow-y: auto;
`;

export const Lightbox = styled.div<IStyleProps>`
  display: flex;
  position: relative;
  margin: 0 auto;
  width: ${({ position, theme: { modal } }) =>
    lightboxWidth(modal.modalWidth)[position]};
  // min-height: ${(props) => props.theme.modal.modalMinHeight}px;
  height: ${({ position }) => lightboxHeight[position]};
  background-color: ${(props) => props.theme.colors.white};
  ${({ open, position }) => {
    if (position === 'left' || position === 'right') {
      return css`
        ${position}: 0;
        transform: translateX(${getTranslate(position)});
        animation: ${slideAnimation(open, position)} 0.4s both;
        animation-delay: ${open ? '.1s' : 0};
      `;
    }
  }}
  ${media.xSmallScreen`
    width: 100%;
  `}
`;
Lightbox.displayName = 'S.Lightbox';

export const Overlay = styled.div<IStyleProps>`
  display: flex;
  position: fixed;
  overflow-y: hidden;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  top: 0;
  z-index: 101;
  transition: opacity 0.2s ease;
  transition-delay: ${({ open }) => (open ? 0 : '.4s')};
  background-color: ${({ transparent, theme }) =>
    transparent ? '' : theme.colors.overlay};
  align-items: ${({ position }) =>
    position === 'bottom' ? 'flex-end' : 'center'};
  justify-content: ${({ position }) => justify[position]};
  opacity: ${({ state }) => opacity[state]};
`;
Overlay.displayName = 'S.Overlay';
