/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useState } from 'react';
import { useIntl } from 'react-intl';

import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { CardHeader } from '@components/molecules/CardHeader';
import { Overlay } from '@components/organisms/Overlay';

import { SubscribeForm } from '@components/molecules/SubscribeForm';

import mailSend from 'images/mail-send.svg';
import signIn from 'images/sign-in.svg';

import { commonMessages } from '../../../../intl';

import * as S from './styles';

const Subscribe: React.FC = () => {
  const modal = useModal();
  const intl = useIntl();
  const [success, setSuccess] = useState<boolean>(false);

  return (
    <Overlay position="fit" show={modal.visible} hide={modal.remove}>
      <S.Modal>
        {success ? (
          <S.Wrapper>
            <CardHeader divider formImage={mailSend} onHide={modal.remove}>
              {intl.formatMessage(commonMessages.success)}
            </CardHeader>
            <S.Text>
              {intl.formatMessage({
                defaultMessage:
                  'The gift code is knocking at your door! Just open your e-mail!',
                id: '2W3yUq',
              })}
            </S.Text>
          </S.Wrapper>
        ) : (
          <S.Wrapper>
            <CardHeader divider formImage={signIn} onHide={modal.remove}>
              {intl.formatMessage({
                defaultMessage:
                  'Inspiration, special offers and more Sign up and get a 50€ gift before it’s gone',
                id: 'ehmbMu',
              })}
            </CardHeader>
            <S.Content>
              <SubscribeForm onSuccess={() => setSuccess(true)} />
            </S.Content>
          </S.Wrapper>
        )}
      </S.Modal>
    </Overlay>
  );
};

export default NiceModal.create(Subscribe);
