import { media, styled } from '@styles';
import { ReactSVG } from 'react-svg';

export const Modal = styled.div`
  // width: 792px;
  width: 100%;
  height: 100%;
  max-width: 100vw;
  padding: 40px 40px 40px 84px;
  max-height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
  ${media.largeScreen`
    // width: 515px;
    padding: 35px 40px;
  `}
  ${media.mediumScreen`
    // width: 596px;
  `}
  ${media.smallScreen`
    padding: 20px;
  `}
`;

export const Header = styled.div`
  height: auto;
  flex: 0;
`;

export const HeaderText = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderTextTitle = styled.span`
  font-family: ${({ theme: { typography } }) => typography.thinFontFamily};
  font-size: ${({ theme: { typography } }) => typography.h4FontSize};
  line-height: 1.1em;
`;

export const HeaderTextItem = styled.span`
  font-size: ${({ theme: { typography } }) => typography.smallFontSize};
  display: inline-block;
  width: 36px;
  height: 36px;
  line-height: 38px;
  background-color: rgba(${({ theme: { colors } }) => colors.baseFont}, 0.2);
  border-radius: 50%;
  color: ${({ theme: { colors } }) => colors.white};
  margin-left: 20px;
  &.not-empty {
    background-color: ${({ theme: { colors } }) => colors.baseFont};
  }
  ${media.largeScreen`
    width: 30px;
    height: 30px;
    line-height: 32px;
    margin-left: 10px;
  `}
  ${media.smallScreen`
    width: 24px;
    height: 24px;
    line-height: 26px;
  `}
`;

export const HeaderIcon = styled(ReactSVG)`
  margin-left: auto;
  margin-right: 0;
`;

export const Content = styled.div``;

export const ProductImage = styled.div`
  height: 366px;
  width: 366px;
  background-color: ${({ theme: { colors } }) => colors.redLight};
  display: flex;
  padding: 62px;
  img {
    mix-blend-mode: darken;
    height: 100%;
    margin: auto;
  }
  ${media.largeScreen`
    height: 270px;
    width: 270px;
    padding: 28px 42px 32px;
  `}
`;

export const Button = styled.div`
  margin-top: 29px;
  width: 366px;
  display: flex;
  ${media.largeScreen`
    width: 270px;
  `}
  ${media.xSmallScreen`
    margin-top: 15px;
  `}
  .cancel {
    padding-left: 0;
    padding-right: 0;
  }
  .button:last-child {
    flex-grow: 1;
    margin-left: 70px;
  }
`;

export const Empty = styled.div`
  padding: 20vh 0 5vh;
  text-align: center;
`;

export const EmptyImage = styled.div`
  margin-bottom: 70px;
  ${media.largeScreen`
    margin-bottom: 46px;
  `}
  ${media.smallScreen`
    margin-bottom: 40px;
  `}
  img {
    vertical-align: middle;
    height: auto;
    &:first-child {
      ${media.largeScreen`
        max-width: 76px;
      `}
      ${media.smallScreen`
        width: 56px;
      `}
    }
    &:last-child {
      transform: translateX(-15px);
      ${media.largeScreen`
        max-width: 30px;
      `}
      ${media.smallScreen`
        width: 22px;
        transform: translateX(-10px);
      `}
    }
  }
`;

export const EmptyText = styled.p`
  font-family: ${({ theme: { typography } }) => typography.thinFontFamily};
  font-size: ${({ theme: { typography } }) => typography.h4FontSize};
  line-height: 1.1em;
  margin-bottom: 35px;
  ${media.largeScreen`
    margin-bottom: 26px;
  `}
`;

export const EmptyAction = styled.div`
  text-align: center;
  .button {
    width: 384px;
    ${media.smallScreen`
      width: 244px;
    `}
    ${media.smallScreen`
      width: 100%;
    `}
  }
`;

export const Footer = styled.div`
  margin-top: auto;
  background-color: ${({ theme: { colors } }) => colors.white};
  padding-top: ${({ theme: { spacing } }) => `calc(${spacing.spacer} * 2)`};
`;

export const FooterPrice = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: ${({ theme: { typography } }) => typography.extraFontFamily};
  font-size: ${({ theme: { typography } }) => typography.smallFontSize};
  line-height: 1.5em;
  margin-bottom: 5px;
`;

export const FooterPriceName = styled.span`
  opacity: 0.8;
`;

export const FooterPriceTotal = styled(FooterPrice)`
  font-size: ${({ theme: { typography } }) => typography.baseFontSize};
  color: ${({ theme: { colors } }) => colors.red};
  line-height: 1em;
  padding-top: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-top: 1px solid rgba(#000, 0.1);
  ${media.smallScreen`
    padding-top: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
  `}
  .${FooterPriceName} {
    opacity: 1;
    color: ${({ theme: { colors } }) => colors.baseFont};
  }
  .cart__footer__price-name {
    opacity: 1;
    color: ${({ theme: { colors } }) => colors.baseFont};
  }
`;

export const FooterButton = styled.div`
  margin: 1rem auto 1rem;
  text-align: center;

  button {
    width: 100%;
  }
`;
