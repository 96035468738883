import { useEffect, useState } from 'react';

export function useScrolled(
  thresholdTop: number,
  thresholdBottom: number
): [boolean, boolean] {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);

  useEffect(() => {
    const scrollHandler = (e: any) => {
      const { scrollHeight, scrollTop, clientHeight } =
        e.target.scrollingElement;

      if (scrollTop > thresholdTop) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }

      if (scrollHeight - scrollTop - thresholdBottom < clientHeight) {
        setIsScrolledToBottom(true);
      } else {
        setIsScrolledToBottom(false);
      }
    };
    document.addEventListener('scroll', scrollHandler);
    return () => {
      document.removeEventListener('scroll', scrollHandler);
    };
  }, [thresholdBottom, thresholdTop]);

  return [isScrolled, isScrolledToBottom];
}
