import { GetShopQuery } from '@sdk/gqlTypes/generatedOperations';
import { createContext } from 'react';

type Shop = GetShopQuery['shop'];

export const defaultCountry = {
  __typename: 'CountryDisplay' as const,
  code: 'US',
  country: 'United States of America',
};

export const defaultContext: Shop = {
  __typename: 'Shop',
  countries: [],
  defaultCountry,
  description: '',
  displayGrossPrices: true,
  geolocalization: { __typename: 'Geolocalization', country: defaultCountry },
  availablePaymentGateways: [],
  name: '',
};

export const ShopContext = createContext<Shop>(defaultContext);

ShopContext.displayName = 'ShopContext';
