import React from 'react';
import { useIntl } from 'react-intl';

import { useNotifier } from '@hooks';

import { useUser } from '@sdk/hook/useUser';

// import { ServiceWorkerContext } from '@components/containers';

const Notifications: React.FC = () => {
  const intl = useIntl();
  const alert = useNotifier();

  // const { updateAvailable } = React.useContext(ServiceWorkerContext);

  /* React.useEffect(() => {
    if (updateAvailable) {
      alert.show(
        {
          actionText: intl.formatMessage({
            defaultMessage: 'Refresh',
            id: 'rELDbB',
          }),
          content: intl.formatMessage({
            defaultMessage:
              'To update the application to the latest version, please refresh the page!',
            id: 'DClFoP',
          }),
          title: intl.formatMessage({
            defaultMessage: 'New version is available!',
            id: '5qDwFV',
          }),
        },
        {
          onClose: () => {
            window.location.reload();
          },
          timeout: 0,
          type: 'success',
        }
      );
    }
  }, [updateAvailable]); */

  const { authenticated } = useUser();
  const [prevAuthenticated, setPrevAuthenticated] = React.useState<
    boolean | undefined
  >();

  React.useEffect(() => {
    if (prevAuthenticated !== undefined && authenticated !== undefined) {
      if (!prevAuthenticated && authenticated) {
        alert.show({
          message: intl.formatMessage({
            defaultMessage: 'You are now logged in',
            id: 'jrEpw7',
          }),
          status: 'success',
        });
      } else if (prevAuthenticated && !authenticated) {
        alert.show({
          message: intl.formatMessage({
            defaultMessage: 'You are now logged out',
            id: '/NZV5a',
          }),
          status: 'success',
        });
      }
      setPrevAuthenticated(authenticated);
    } else if (authenticated !== undefined) {
      setPrevAuthenticated(authenticated);
    }
  }, [authenticated]);

  return null;
};

export default Notifications;
