import { ReactSVG } from 'react-svg';

import { media, styled } from '@styles';

import { LayoutContactFragment } from '@prismic/gqlTypes/prismicOperations';

import EmailImage from 'images/email-nav-menu.svg';
import PhoneImage from 'images/phone-nav-menu.svg';

interface NavContactProps {
  contact?: LayoutContactFragment[];
}

const ContactWrapper = styled.ul`
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 88px;
  ${media.smallScreen`
    padding-left: 21px;
    padding-right: 25px;
    padding-bottom: 28px;
  `}
`;

const ContactItem = styled.li`
  margin-bottom: 18px;
  ${media.smallScreen`
    margin-bottom: 10px;
  `}
  &:last-of-type {
    margin-bottom: 0;
  }
  a {
    display: flex;
    align-items: flex-end;
    ${media.smallScreen`
      align-items: flex-start;
    `}
    span {
      font-family: ${(props) => props.theme.typography.baseFontFamily};
      font-size: ${(props) => props.theme.typography.baseFontSize};
      color: ${(props) => props.theme.colors.baseFont};
      line-height: 38px;
      margin-left: 10px;
      text-decoration: none;
      position: relative;
      ${media.smallScreen`
        line-height: 24px;
        margin-left: 13px;
      `}
      &:after {
        content: '';
        display: inline-block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: #9fbecc;
        height: 2px;
      }
    }
  }
`;

const ContactIcon = styled(ReactSVG)`
  height: 30px;
`;

function NavContact({ contact }: NavContactProps) {
  const email = contact.find((o) => o.type === 'email');
  const phone = contact.find((o) => o.type === 'phone');

  return (
    <ContactWrapper>
      {phone && (
        <ContactItem>
          <a href={`tel:${phone.label}`}>
            <ContactIcon src={PhoneImage} />
            <span>{phone.label}</span>
          </a>
        </ContactItem>
      )}
      {email && (
        <ContactItem>
          <a href={`mailto:${email.label}`}>
            <ContactIcon src={EmailImage} />
            <span>{email.label}</span>
          </a>
        </ContactItem>
      )}
    </ContactWrapper>
  );
}

export default NavContact;
