import { usePrismicLayout } from '@prismic/context';
import { imageResolver, linkResolver } from '@prismic/prismic-config';

import React from 'react';

import * as S from './styles';

function getUrlHostMatch(url: string): boolean {
  const { host } = window.location;
  // const { host: urlHost } = new URL(url | "");
  // FIXME: the regionpicker is failing
  const { host: urlHost } = new URL(url);
  return host === urlHost;
}

export const RegionPicker: React.FC = () => {
  const { layout } = usePrismicLayout();

  return (
    <S.Wrapper className="locales__regions-list">
      {layout?.regions?.map((region, index) => {
        const link = linkResolver(region.link);
        const isRegionActive = getUrlHostMatch(link);
        return (
          <S.Item
            active={isRegionActive}
            key={index}
            className="locales__regions-list-item"
          >
            <a href={link} target="_blank" rel="noreferrer">
              <img
                src={imageResolver(region.flag?.url)}
                alt={`${region.region_title} flag`}
              />
              {region.region_title}
            </a>
          </S.Item>
        );
      })}
    </S.Wrapper>
  );
};
