import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { IntlProvider } from 'react-intl';

// import sourceOfTruth from '@locale/defaultMessages.json';
// import de from '@locale/de.json';
// import pl from '@locale/pl.json';

export type LocaleMessages = Record<string, string>;

const LOCALE_CACHE: Record<string, LocaleMessages> = {};

const localeData: Record<string, string | undefined> = {
  // Default language
  en: undefined,
  da: 'da',
  de: 'de',
  es: 'es',
  fr: 'fr',
  it: 'it',
  nl: 'nl',
  no: 'no',
  pl: 'pl',
  sv: 'sv',
};

export const importMessages = async (locale: string | undefined) => {
  const filename = localeData[locale];
  let localeJson = LOCALE_CACHE[locale];

  if (!localeJson && filename !== undefined) {
    localeJson = await import(`../../../../../locale/${filename}.json`);
    LOCALE_CACHE[locale] = localeJson;
  }

  return localeJson;
};

type IProps = {
  children: React.ReactNode;
};

const LocaleProvider: React.FC<IProps> = ({ children }) => {
  const { locale, defaultLocale } = useRouter();
  const [msgs, setMsgs] = React.useState<LocaleMessages | undefined>(undefined);

  useEffect(() => {
    const fetchData = async () => {
      const data = await importMessages(locale);
      setMsgs(data);
    };
    fetchData();
  }, [locale]);

  return (
    <IntlProvider
      defaultLocale={defaultLocale}
      locale={locale}
      messages={msgs}
      key={locale}
    >
      {children}
    </IntlProvider>
  );
};

export { LocaleProvider };
