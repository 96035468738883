import { media, styled } from '@styles';

export const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
`;

export const Content = styled.div`
  position: absolute;
  z-index: 2;
  right: -3rem;
  left: auto;
  // top: 3.8rem;
  box-shadow: ${(props) => props.theme.input.selectMenuShadow};
  background-color: ${(props) => props.theme.colors.white};
  width: 18rem;
  border-radius: 7px;
  ${media.smallScreen`
    top: 2.5rem;
  `}
  ul {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    font-style: normal;
    font-weight: normal;
    line-height: 2rem;
    align-items: flex-start;
    li {
      width: 100%;
    }
  }
`;
