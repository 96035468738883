import { useState } from 'react';
import { useIntl } from 'react-intl';

import Link from 'next-translate-routes/link';

import { useModal } from '@ebay/nice-modal-react';

import { Button } from '@components/atoms/Button';
import { PasswordForgottenForm } from '@components/molecules/PasswordForgottenForm';
import { CardHeader } from '@components/molecules/CardHeader';

import { paths } from '@paths';

import passwordResetIcon from 'images/remember-password.svg';
import mailSend from 'images/mail-send.svg';

import { commonMessages } from '../../../../intl';

import * as S from './styles';

export const PasswordForgotten = () => {
  const modal = useModal();
  const intl = useIntl();
  const [success, setSuccess] = useState<boolean>(false);

  return success ? (
    <S.Wrapper>
      <CardHeader divider formImage={mailSend} onHide={modal.remove}>
        {intl.formatMessage(commonMessages.success)}
      </CardHeader>
      <S.Text>
        {intl.formatMessage({
          defaultMessage:
            'A link to change your password is knocking at your door! Just open your e-mail!',
          id: '+QRFrd',
        })}
      </S.Text>
      <S.Content>
        <Link href={paths.home}>
          <a>
            <Button type="button" onClick={modal.remove}>
              {intl.formatMessage({
                defaultMessage: 'go to homepage',
                id: 'Oj28K/',
              })}
            </Button>
          </a>
        </Link>
      </S.Content>
    </S.Wrapper>
  ) : (
    <S.Wrapper>
      <CardHeader divider formImage={passwordResetIcon} onHide={modal.remove}>
        {intl.formatMessage({
          defaultMessage: 'Can’t remember your password?',
          id: 'lVAJQY',
        })}
      </CardHeader>
      <S.Content>
        <PasswordForgottenForm onSuccess={() => setSuccess(true)} />
      </S.Content>
    </S.Wrapper>
  );
};
