import baseStyled, { ThemedStyledInterface } from 'styled-components';

import * as C from './constants';

export const defaultTheme = {
  breakpoints: {
    smMediumScreen: '992px',
    smallScreen: '767px',
    xSmallScreen: '540px',
    mediumScreen: '1279px',
    largeScreen: '1599px',
    xLargeScreen: '1919px',
  },
  button: {
    animation: {
      transition: '0.3s',
    },
    colors: {
      primary: {
        activeBackground: C.theme.primaryDarkest,
        background: C.theme.primary,
        color: C.white,
        hoverBackground: C.theme.primaryDark,
        hoverColor: C.white,
      },
      secondary: {
        activeBackground: C.theme.secondaryDarkest,
        background: C.theme.secondary,
        color: C.white,
        hoverBackground: C.theme.secondaryDark,
        hoverColor: C.white,
      },
    },
    padding: {
      lg: '1.25rem 2.5em',
      md: '0.9rem 2rem',
      sm: '0.9rem 1rem',
    },
    typography: {
      fontSize: '1.125rem',
      fontWeight: '600',
      lineHeight: '1.25rem',
      smallFontSize: '0.8rem',
      textTransform: 'uppercase',
      baseFontFamily: C.baseFontFamily,
      boldFontFamily: C.boldFontFamily,
    },
  },
  carousel: {
    carouselControlPadding: '0.2rem 0.5rem',
    carouselControlShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.25)',
  },
  chip: {
    colors: {
      primary: {
        activeBackground: C.theme.primaryTransparent,
        background: C.theme.primaryLight,
        color: C.theme.primaryDark,
        hoverBackground: 'none',
        hoverColor: C.theme.primaryDark,
      },
      secondary: {
        activeBackground: C.theme.primaryTransparent,
        background: C.theme.secondaryLight,
        color: C.theme.secondaryDark,
        hoverBackground: 'none',
        hoverColor: C.theme.secondaryDark,
      },
    },
    typography: {
      fontSize: '1rem',
      smallFontSize: '0.75rem',
    },
  },
  colors: {
    ...C.theme,
  },
  container: {
    width: 1140,
    paddingLeft: 44,
  },
  dropdown: {
    backgroundColor: C.theme.white,
    boxShadow: '0px 6px 10px 0px rgba(0, 0, 0, 0.15)',
  },
  grid: {
    containerWidth: 1140,
  },
  iconButton: {
    backgroundColor: C.theme.white,
    hoverBackgroundColor: C.theme.secondary,
    hoverForegroundColor: C.theme.white,
    size: 36,
  },
  input: {
    border: C.grayDark,
    labelColor: C.grayDark,
    /**
     * 12px in default theme
     */
    labelFontSize: '0.75rem',
    selectMenuShadow: '0px 6px 10px 0px rgba(0, 0, 0, 0.15)',
  },
  link: {
    base: {
      color: C.gray,
      hoverColor: C.grayMedium,
    },
    secondary: {
      color: C.blue,
      hoverColor: C.blueLight,
    },
  },
  popover: {
    borderWidth: '1px',
    borderRadius: '0.25rem',
    borderColor: 'transparent',
    arrowSize: '6px',
    shadow: 'shadow',
    backgroundColor: C.baseFontColorOpacityDark,
    padding: '5px 9px',
  },
  message: {
    backgroundColor: C.white,
    contentMargin: `${C.spacer}rem 0 0`,
    letterSpacing: '0.5px',
    padding: '1rem 1.5rem',
    titleMargin: `0 ${C.spacer * 1.5}rem 0 0`,
    titleTransform: 'uppercase',
    titleWeight: C.extraBoldFontWeight,
    width: '25rem',
  },
  modal: {
    modalMinHeight: 455,
    modalWidth: 555,
  },
  productItem: {
    productItemCategoryColor: C.gray,
    productItemPriceFontWeight: C.boldFontWeight,
    productItemPriceMargin: `${C.spacer}rem 0 0`,
    productItemTitleFontWeight: C.boldFontWeight,
    productItemTitleHeight: '2.5rem',
    productItemTitleMargin: `${C.spacer / 2}rem 0 0`,
    productItemTitleTextTransform: 'uppercase',
  },
  spacing: {
    /**
     * 30px in default theme
     */
    fieldSpacer: C.fieldSpacer,
    /**
     * 30px in default theme
     */
    gutter: '1.875rem',
    /**
     * 16px in default theme
     */
    spacer: `${C.spacer}rem`,
  },
  tile: {
    backgroundColor: C.grayLight,
    divisionLine: C.grayMedium,
    hoverBorder: C.blueLight,
  },
  typography: {
    baseFontFamily: C.baseFontFamily,

    /**
     * 16px in default theme
     */
    baseFontSize: C.baseFontSize,

    /**
     * 20px in default theme
     */
    baseLineHeight: C.baseLineHeight,
    boldFontFamily: C.boldFontFamily,
    boldFontWeight: C.boldFontWeight,
    extraBoldFontWeight: C.extraBoldFontWeight,
    extraFontFamily: C.extraFontFamily,

    /**
     * 96px in default theme
     */
    ultraBigFont: C.ultraBigFont,

    /**
     * 48px in default theme
     */
    h1FontSize: C.h1FontSize,
    h1LineHeight: C.h1LineHeight,

    /**
     * 39px in default theme
     */
    h2FontSize: C.h2FontSize,

    /**
     * 31px in default theme
     */
    h3FontSize: C.h3FontSize,

    /**
     * 25px in default theme
     */
    h4FontSize: C.h4FontSize,

    /**
     * 20px in default theme
     */
    h5FontSize: C.h5FontSize,

    /**
     * 14px in default theme
     */
    smallFontSize: C.smallFontSize,
    thinFontFamily: C.thinFontFamily,

    /**
     * 12px in default theme
     */
    labelFontSize: C.labelFontSize,

    /**
     * 96px in default theme
     */
    ultraBigFontSize: C.ultraBigFont,
  },
};

export type DefaultTheme = typeof defaultTheme;
export const styled = baseStyled as ThemedStyledInterface<DefaultTheme>;
