import { media, styled } from '@styles';

interface ActiveProps {
  active: boolean | undefined;
}

export const Wrapper = styled.div<ActiveProps>`
  position: fixed;
  bottom: 60px;
  right: 50px;
  z-index: ${({ active }) => (active ? 100 : 95)};
  display: flex;
  align-items: flex-end;
  ${media.largeScreen`
    bottom: 40px;
    right: 30px;
  `}
`;

interface IconWrapperProps {
  icon: string | undefined;
}

const handleIconType = (icon) => {
  switch (icon) {
    case 'messenger':
      return '#2f80ed';
    case 'whatsapp':
      return '#40c351';
    case 'help':
      return '#4f6e7c';
    default:
      return '#ca9c66';
  }
};

export const LinkWrapper = styled.a<IconWrapperProps>`
  display: flex;
  align-items: center;
  display: ${({ icon }) => (!(icon === 'help') ? 'flex' : 'none')};
  margin-bottom: 40px;
  &:last-child {
    margin-bottom: 0 !important;
  }
  ${media.largeScreen`
    margin-bottom: 30px;
  `}
`;

export const IconWrapper = styled.div<IconWrapperProps>`
  display: block;
  width: 60px;
  height: 60px;
  flex: 0 0 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ icon }) => handleIconType(icon)};
  img {
    display: block;
    ${media.mediumScreen`
      max-width: 40px;
      max-height: 40px;
      width: auto;
      height: auto;
    `}
  }
`;

export const Text = styled.div`
  position: relative;
  line-height: 1.5em;
  fon-familyt: ${(props) => props.theme.typography.thinFontFamily};
  font-size: ${(props) => props.theme.typography.baseFontSize};
  white-space: nowrap;
  text-transform: capitalize;
`;

export const Hint = styled.div`
  text-transform: none;
  font-size: ${(props) => props.theme.typography.smallFontSize};
  opacity: 0.8;
  margin-top: 5px;
`;
