/* eslint-disable no-console */
import {
  ProductDetailsFragment,
  ProductVariantDetailsFragment,
  SelectedAttributeFragment,
} from '@sdk/gqlTypes/generatedOperations';

import { SelectedAttributeValueInput } from '@sdk/gqlTypes/generatedTypes';

//
const getProductVariantsAttributes = (
  productVariants: ProductVariantDetailsFragment[]
): Record<string, SelectedAttributeFragment> => {
  const variantsAttributes = {};

  productVariants.forEach((productVariant) => {
    productVariant.attributes.forEach((productVariantAttribute) => {
      const productVariantAttributeId = productVariantAttribute.attribute.id;
      const variantsAttributeExists =
        productVariantAttributeId in variantsAttributes;

      if (variantsAttributeExists) {
        const variantsAttributeValueExists = variantsAttributes[
          productVariantAttributeId
        ].values
          .map((node) => node.id)
          .includes(productVariantAttribute.values[0].id);

        if (!variantsAttributeValueExists) {
          variantsAttributes[productVariantAttributeId].values.push(
            productVariantAttribute.values[0]
          );
        }
      } else {
        variantsAttributes[productVariantAttributeId] = {
          attribute: productVariantAttribute.attribute,
          values: [productVariantAttribute.values[0]],
        };
      }
    });
  });

  return variantsAttributes;
};

//
export const getProductAttributes = (
  product: ProductDetailsFragment
): SelectedAttributeFragment[] => {
  const productVariantsAttributes = getProductVariantsAttributes(
    product.variants
  );
  return [...product.attributes, ...Object.values(productVariantsAttributes)];
};

//
export const getSelectedProductAttributes = (
  attributesFormData: Record<string, string>,
  productAttributes: SelectedAttributeFragment[]
): SelectedAttributeValueInput[] =>
  Object.entries(attributesFormData)
    .map(([slug, value]) => {
      const attribute = productAttributes.find(
        (item) => item.attribute.slug === slug
      )?.attribute;

      return attribute
        ? {
            attributeId: attribute.id,
            values: Array.isArray(value) ? value : [value],
          }
        : null;
    })
    .filter((x) => !!x);

//
const getDefaultProductAttributes = (
  defaultAttributes: Array<Record<string, any> | null>
): Record<string, string> =>
  defaultAttributes.reduce(
    (obj, item) => ({
      ...obj,
      [item.attribute.slug]: item.defaultValue?.name,
    }),
    {}
  );

//
const getTrackValues = (
  width: string,
  configuration: string
): {
  defaultValue: string;
  maxValue: string;
  minValue: string;
} => {
  const multiplier = configuration === 'configuration-single' ? 1 : 2;
  return {
    defaultValue: String(Number(width) * 2 * multiplier),
    maxValue: String(Number(width) * 3 * multiplier),
    minValue: String(Number(width) * 1.5 * multiplier),
  };
};

//
export const getDefaultValues = (
  product: ProductDetailsFragment,
  queryParam: Record<string, string>
): Record<string, string> => {
  const defaultValues = {
    ...getDefaultProductAttributes(product.defaultAttributes),
    ...queryParam,
  };
  if (defaultValues.width && !defaultValues.track) {
    const { defaultValue: defaultTrackValue } = getTrackValues(
      defaultValues.width,
      defaultValues.configuration
    );
    defaultValues.track = String(defaultTrackValue);
  }
  return defaultValues;
};
