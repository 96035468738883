import { usePrismicLayout } from '@prismic/context';

import { LocalePicker } from '@components/molecules/LocalePicker';
import { RegionPicker } from '@components/molecules/RegionPicker';

import { useRouter } from 'next/router';
import { localeToFlag } from '@utils/regions';

import { useIntl } from 'react-intl';

import { useMediaQuery } from '@hooks/useMediaQuery';
import { smallScreen } from '@styles/constants';

import * as S from './styles';

interface INavDropdown {
  showDropdown: boolean;
  onShowDropdown: () => void;
  onHideDropdown: () => void;
}

export const LocaleDropdown: React.FC<INavDropdown> = (props) => {
  const intl = useIntl();
  const { locale } = useRouter();
  const { layout } = usePrismicLayout();
  const { onHideDropdown, onShowDropdown, showDropdown } = props;
  const [isSmallScreen] = useMediaQuery(`(min-width: ${smallScreen}px)`);

  return isSmallScreen ? (
    <S.DropdownWrapper
      active={showDropdown}
      onMouseOver={onShowDropdown}
      onMouseLeave={onHideDropdown}
      onFocus={onShowDropdown}
      onBlur={onHideDropdown}
    >
      <S.DropdownItem>
        <div className="main-menu__nav-link">
          <img src={localeToFlag(locale)} alt="" />
        </div>
      </S.DropdownItem>
      <S.DropdownBody active={showDropdown}>
        <S.LocaleWrapper>
          <S.LocaleRegion>
            <S.LocaleItemTop>
              <S.LocaleItemTitle>{layout?.region_title}</S.LocaleItemTitle>
              <S.LocaleItemSubTitle>
                {layout?.region_description}
              </S.LocaleItemSubTitle>
            </S.LocaleItemTop>
            <RegionPicker />
          </S.LocaleRegion>
          <S.LocaleLanguage>
            <S.LocaleItemTop>
              <S.LocaleItemTitle>
                {intl.formatMessage({
                  defaultMessage: 'Language',
                  id: 'y1Z3or',
                })}
              </S.LocaleItemTitle>
              <S.LocaleItemSubTitle>
                {/* layout?.language_description */}
              </S.LocaleItemSubTitle>
            </S.LocaleItemTop>
            <LocalePicker onPicked={onHideDropdown} />
          </S.LocaleLanguage>
        </S.LocaleWrapper>
      </S.DropdownBody>
    </S.DropdownWrapper>
  ) : null;
};
