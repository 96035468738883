import { useEffect, useState } from 'react';

import {
  CheckoutDetailsFragment,
  CheckoutLineFragment,
} from '@sdk/gqlTypes/generatedOperations';

import {
  CheckoutStep,
  checkIfShippingRequiredForProducts,
} from '@pages/CheckoutPage/utils';

interface StepState {
  recommendedStep: CheckoutStep;
  maxPossibleStep: CheckoutStep;
}

export const useCheckoutStepState = (
  items?: CheckoutLineFragment[],
  checkout?: CheckoutDetailsFragment,
  checkoutGateway?: string
): StepState => {
  const isShippingRequiredForProducts =
    checkIfShippingRequiredForProducts(items);

  const getMaxPossibleStep = () => {
    if (!checkout?.id && items) {
      // we are creating checkout during address set up
      return CheckoutStep.Address;
    }

    const isShippingAddressSet =
      !isShippingRequiredForProducts || !!checkout?.shippingAddress;
    const isBillingAddressSet = !!checkout?.billingAddress;
    const isShippingMethodSet =
      !isShippingRequiredForProducts || !!checkout?.shippingMethod;
    const isPaymentMethodSet = !!checkoutGateway;

    if (!isShippingAddressSet || !isBillingAddressSet) {
      return CheckoutStep.Address;
    }
    if (!isShippingMethodSet) {
      return CheckoutStep.Address;
    }
    if (!isPaymentMethodSet) {
      return CheckoutStep.Payment;
    }
    return CheckoutStep.Payment;
  };

  const getRecommendedStep = (newMaxPossibleStep: CheckoutStep) => {
    const isPaymentRecreateRequired = newMaxPossibleStep > CheckoutStep.Address;

    if (isPaymentRecreateRequired && isShippingRequiredForProducts) {
      return CheckoutStep.Address;
    }
    if (isPaymentRecreateRequired) {
      return CheckoutStep.Payment;
    }
    return newMaxPossibleStep;
  };

  const [maxPossibleStep, setMaxPossibleStep] = useState(getMaxPossibleStep());
  const [recommendedStep, setRecommendedStep] = useState(
    getRecommendedStep(maxPossibleStep)
  );

  useEffect(() => {
    const newMaxPossibleStep = getMaxPossibleStep();
    const newRecommendedStep = getRecommendedStep(newMaxPossibleStep);
    if (maxPossibleStep !== newMaxPossibleStep) {
      setMaxPossibleStep(newMaxPossibleStep);
    }
    if (recommendedStep !== newRecommendedStep) {
      setRecommendedStep(newRecommendedStep);
    }
  }, [checkout, items]);

  return { recommendedStep, maxPossibleStep };
};
