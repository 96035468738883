import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NextRouter } from 'next/router';
import { ParsedUrlQueryInput } from 'querystring';

import type { CartSummaryProps } from '@components/organisms/CartSummary';
import { CheckoutLineFragment } from '@sdk/gqlTypes/generatedOperations';

import { checkoutMessages } from '@temp/intl';

import { paths } from '@paths';

export const checkIfShippingRequiredForProducts = (
  items?: CheckoutLineFragment[]
) =>
  items?.some(({ variant }) => variant.product?.productType.isShippingRequired);

export enum CheckoutStep {
  Address = 1,
  // Shipping = 1,
  Payment,
  PaymentConfirm,
}

export interface CheckoutStepDefinition {
  index: number;
  link: string;
  name: string;
  step: CheckoutStep;
  nextActionName?: string;
  onlyIfShippingRequired?: boolean;
  withoutOwnView?: boolean;
}

export const CHECKOUT_STEPS: CheckoutStepDefinition[] = [
  {
    index: 0,
    link: paths.checkoutAddress,
    name: 'Address',
    nextActionName: 'Continue to Payment',
    onlyIfShippingRequired: false,
    step: CheckoutStep.Address,
  },
  // {
  //   index: 0,
  //   link: paths.checkoutShipping,
  //   name: 'Shipping',
  //   nextActionName: 'Continue to Payment',
  //   onlyIfShippingRequired: true,
  //   step: CheckoutStep.Shipping,
  // },
  {
    index: 1,
    link: paths.checkoutPayment,
    name: 'Payment',
    nextActionName: 'Place order',
    onlyIfShippingRequired: false,
    step: CheckoutStep.Payment,
  },
  {
    index: 2,
    link: paths.checkoutPaymentConfirm,
    name: 'Payment confirm',
    onlyIfShippingRequired: false,
    step: CheckoutStep.PaymentConfirm,
    withoutOwnView: true,
  },
];

// export const DEFAULT_PAYMENT_METHODS = 'mirumee.payments.dummy';
// export const DEFAULT_PAYMENT_METHODS = 'saleor.payments.paypal';
// export const DEFAULT_PAYMENT_METHODS = 'saleor.payments.przelewy24';
// export const DEFAULT_PAYMENT_METHODS = 'saleor.payments.blik';
// export const DEFAULT_PAYMENT_METHODS = 'saleor.payments.klarna';
export const DEFAULT_PAYMENT_METHODS = 'saleor.payments.stripe';

export const prepareCartSummaryProducts = (
  items?: CheckoutLineFragment[]
): CartSummaryProps['products'] | undefined =>
  items?.map(({ id, variant, totalPrice, quantity }) => ({
    id: id || '',
    name: variant.product?.name || '',
    price: {
      gross: {
        amount: totalPrice?.gross.amount || 0,
        currency: totalPrice?.gross.currency || '',
      },
      net: {
        amount: totalPrice?.net.amount || 0,
        currency: totalPrice?.net.currency || '',
      },
    },
    quantity,
    sku: variant.sku || '',
    thumbnail: {
      alt: variant.product?.thumbnail?.alt || undefined,
      url: variant.product?.thumbnail?.url,
      url2x: variant.product?.thumbnail2x?.url,
    },
    title: (variant.product || {}).name,
  }));

const continueButtonTextMap: Partial<Record<CheckoutStep, JSX.Element>> = {
  [CheckoutStep.Address]: (
    <FormattedMessage {...checkoutMessages.addressNextActionName} />
  ),
  // [CheckoutStep.Shipping]: (
  //   <FormattedMessage {...checkoutMessages.shippingNextActionName} />
  // ),
  [CheckoutStep.Payment]: (
    <FormattedMessage {...checkoutMessages.paymentNextActionName} />
  ),
};
export const getContinueButtonText = (step: CheckoutStep) =>
  continueButtonTextMap[step];

export const getAvailableSteps = (
  items: CheckoutLineFragment[] | undefined
) => {
  const isShippingRequired = checkIfShippingRequiredForProducts(items);
  const stepsWithViews = CHECKOUT_STEPS.filter(
    ({ withoutOwnView }) => !withoutOwnView
  );
  return isShippingRequired
    ? stepsWithViews
    : stepsWithViews.filter(
        ({ onlyIfShippingRequired }) => !onlyIfShippingRequired
      );
};

export const getCurrentStep = (
  pathname: string,
  steps: CheckoutStepDefinition[]
) => {
  const activeStepIndex = (() => {
    const matchingStepIndex = steps.findIndex(({ link }) => link === pathname);
    // return matchingStepIndex !== -1 ? matchingStepIndex : steps.length - 1;
    return matchingStepIndex !== -1 ? matchingStepIndex : 0;
  })();
  const activeStep = steps[activeStepIndex];

  return { activeStepIndex, activeStep };
};

export const stepSubmitSuccessHandler =
  (
    push: NextRouter['push'],
    steps: CheckoutStepDefinition[],
    activeStepIndex: number
  ) =>
  (currentStep: CheckoutStep, data?: object) => {
    if (currentStep === CheckoutStep.Payment) {
      push(
        {
          pathname: paths.orderFinalized,
          query: data as ParsedUrlQueryInput,
        },
        /**
         * Passing orderFinalized path as an `as` param makes query data hidden and
         * behaves simillar to history push state.
         */
        paths.orderFinalized
      );
    } else {
      push(steps[activeStepIndex + 1].link);
    }
  };
