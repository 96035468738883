import React from 'react';

import { useScrolled } from '@hooks';

import { linkResolver } from '@prismic/prismic-config';
import whatsapp from 'images/contact-widget/whatsapp-widget.svg';

import { usePrismicLayout } from '@prismic/context';

import * as S from './styles';

export function ContactsWidgetWhatsapp(): React.ReactElement {
  const { layout } = usePrismicLayout();

  const [isScrolled] = useScrolled(250, 600);

  const contact = layout?.contact.filter((c) => c.type === 'whatsapp');

  return isScrolled ? (
    <S.Wrapper active>
      {contact?.map((c, i) => (
        <S.LinkWrapper
          key={`contact-widget-${i}`}
          href={linkResolver(c.link)}
          icon={c.type}
          target="_blank"
          rel="noreferrer"
        >
          <S.IconWrapper icon={c.type}>
            <>
              {c.type === 'whatsapp' && <img src={whatsapp} alt="whatsapp" />}
            </>
          </S.IconWrapper>
        </S.LinkWrapper>
      ))}
    </S.Wrapper>
  ) : null;
}
