import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';

import { onError } from '@apollo/client/link/error';
import { createPrismicLink } from 'apollo-link-prismic';

import { prismicUri } from '@temp/constants';

import { paths } from '@paths';
import { generatePath } from '@utils/core';

import { LinkableFragment } from './gqlTypes/prismicOperations';
import possibleTypesIntrospection from './graphql/prismic.possibleTypes.json';

const onErrorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  }

  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
});

const prismicLink = createPrismicLink({
  repositoryName: prismicUri,
});

const link = ApolloLink.from([onErrorLink, prismicLink]);

export const prismicApolloClient = new ApolloClient({
  cache: new InMemoryCache({
    possibleTypes: possibleTypesIntrospection.possibleTypes,
  }),
  link,
});

export const linkResolver = (
  linkable: Partial<LinkableFragment> | { url?: string } | null
): string => {
  if (linkable && 'url' in linkable) {
    return linkable.url || '';
  }
  if (linkable && '_meta' in linkable && linkable?._meta?.type === 'contacts') {
    return paths.contacts;
  }
  if (
    linkable &&
    '_meta' in linkable &&
    linkable?._meta?.type === 'architect'
  ) {
    return paths.architect;
  }
  if (linkable && '_meta' in linkable && linkable?._meta?.type === 'reviews') {
    return paths.reviews;
  }
  if (
    linkable &&
    '_meta' in linkable &&
    linkable?._meta?.type === 'blog_home'
  ) {
    return paths.blog;
  }
  if (
    linkable &&
    '_meta' in linkable &&
    linkable?._meta?.type === 'blog_post' &&
    linkable?._meta?.uid
  ) {
    return generatePath(paths.blogPost, { slug: linkable._meta.uid });
  }
  if (
    linkable &&
    '_meta' in linkable &&
    linkable?._meta?.type === 'realisation'
  ) {
    return paths.inspirations;
  }
  if (
    linkable &&
    '_meta' in linkable &&
    linkable?._meta?.type === 'project' &&
    linkable?._meta?.uid
  ) {
    return generatePath(paths.inspirationsPost, { slug: linkable._meta.uid });
  }
  if (linkable && '_meta' in linkable && linkable?._meta?.uid) {
    return generatePath(paths.page, { slug: linkable._meta.uid });
  }
  return '';
};

export const imageResolver = (
  url: string | undefined,
  origin: boolean | null = false
) => {
  if (origin) {
    return url;
  }

  const MEDIA_BUCKET_URL = process.env.NEXT_PUBLIC_MEDIA_BUCKET_URL || url;
  const newUrl = new URL(url || '');
  const mediaUrl = new URL(MEDIA_BUCKET_URL || '');
  newUrl.hostname = mediaUrl.hostname;
  return newUrl.href;
};
