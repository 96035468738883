import * as React from 'react';
import Link from 'next-translate-routes/link';

import { clsx } from 'clsx';

import { useRouter } from 'next/router';

import { generatePath, slugify, decodeKey } from '@utils/core';
import { translate } from '@utils/translations';

import { paths } from '@temp/paths';

import { MainMenuItemFragment } from '@sdk/gqlTypes/generatedOperations';

interface NavLinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  item: MainMenuItemFragment;
}
export const NavLink: React.FC<NavLinkProps> = ({
  item,
  children,
  className,
  ...props
}) => {
  const { url, category, collection, page } = item;
  const { query } = useRouter();

  const link = (url: string, active: boolean) => (
    <Link passHref href={url}>
      <a {...props} className={clsx(className, active && 'active')}>
        {children}
      </a>
    </Link>
  );

  if (url) {
    return (
      <a href={url} {...props} className={clsx(className)}>
        {translate(item, 'name')}
      </a>
    );
  }
  if (category) {
    const id = decodeKey(category.id, 'Category');
    const slug = slugify(translate(category, 'name'));
    return link(generatePath(paths.category, { slug, id }), id === query.id);
  }
  if (collection) {
    const id = decodeKey(collection.id, 'Collection');
    const slug = slugify(translate(collection, 'name'));
    return link(generatePath(paths.collection, { slug, id }), id === query.id);
  }
  if (page) {
    return link(generatePath(paths.page, { slug: page.slug }), false);
  }

  return (
    <span {...props} className={clsx(className)}>
      {translate(item, 'name')}
    </span>
  );
};
