import { createContext, useContext } from 'react';

import { PrismicLayoutQuery } from '@prismic/gqlTypes/prismicOperations';

export const PrismicLayoutContext = createContext<PrismicLayoutQuery>({
  layout: null,
});

export const usePrismicLayout = (): PrismicLayoutQuery => {
  const result = useContext(PrismicLayoutContext);
  if (!result) {
    throw new Error('Context used outside of its Provider!');
  }
  return result;
};
