import * as React from 'react';

// import Image from 'next/future/image';
// import NoPhoto from 'images/no-photo.svg';

import { MainMenuItemFragment } from '@sdk/gqlTypes/generatedOperations';
import { translate } from '@utils/translations';

import { NavLink } from '@components/atoms/NavLink';

import * as S from './styles';

export interface INavItem extends MainMenuItemFragment {
  children?: Array<INavItem | null> | null;
  imageUrl?: string;
  arrow?: boolean;
  hideOverlay?: () => void;
}

const NavItemParent: React.FC<INavItem> = ({
  children,
  hideOverlay,
  arrow,
  ...item
}) => (
  /*
  const imageUrl =
    item.collection?.backgroundImage?.url ||
    item.category?.backgroundImage?.url ||
    NoPhoto;
  const css = { width: '100%', height: '100%' };
  */
  <NavLink
    item={item}
    onClick={hideOverlay}
    className="side-nav__menu-item-link"
  >
    {/* imageUrl && (
        <S.NavItemImage>
          <Image src={imageUrl} width="150" height="173" style={css} />
        </S.NavItemImage>
      ) */}
    <S.NavItemTitle>{translate(item, 'name')}</S.NavItemTitle>
  </NavLink>
);
const NavItem: React.FC<INavItem> = ({
  children,
  hideOverlay,
  arrow,
  ...item
}) => {
  const content =
    children && children.length ? (
      <S.NavItem arrow={false}>
        {children.map(
          (subItem, i) =>
            subItem && (
              <NavItemParent
                key={i}
                {...subItem}
                hideOverlay={hideOverlay}
                arrow={false}
              />
            )
        )}
      </S.NavItem>
    ) : null;

  // const imageUrl =
  //   item.collection?.backgroundImage?.url ||
  //   item.category?.backgroundImage?.url ||
  //   NoPhoto;

  // const css = { maxWidth: '100%', height: 'auto' };
  // const css = { width: '100%', height: '100%' };

  return (
    <S.NavItem arrow>
      <NavLink
        item={item}
        onClick={hideOverlay}
        className="side-nav__menu-item-link"
      >
        {/* imageUrl && (
          <S.NavItemImage>
            <Image src={imageUrl} width="150" height="173" style={css} />
          </S.NavItemImage>
        ) */}
        <S.NavItemTitle>{translate(item, 'name')}</S.NavItemTitle>
      </NavLink>
      {content}
    </S.NavItem>
  );
};

export default NavItem;
