import { ButtonHTMLAttributes } from 'react';

import { ReactSVG } from 'react-svg';

import { media, styled } from '@styles';
import { keyframes, css } from 'styled-components';

import cartImg from 'images/cart.svg';
import heartImg from 'images/heart.svg';
import userImg from 'images/user.svg';
import userImgLogged from 'images/userLogged.svg';
import closeImg from 'images/close-black.svg';
import arrowImg from 'images/left.svg';

interface NavIconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon: 'user' | 'cart' | 'wishlist' | 'loggedin' | 'close' | 'arrow';
  counter?: number;
}

const ring = keyframes`
  0% { transform: rotate(0); }
  2% { transform: rotate(30deg); }
  9% { transform: rotate(-28deg); }
  10% { transform: rotate(34deg); }
  14% { transform: rotate(-32deg); }
  18% { transform: rotate(30deg); }
  22% { transform: rotate(-28deg); }
  26% { transform: rotate(26deg); }
  30% { transform: rotate(-24deg); }
  44% { transform: rotate(22deg); }
  38% { transform: rotate(-20deg); }
  42% { transform: rotate(18deg); }
  46% { transform: rotate(-16deg); }
  50% { transform: rotate(14deg); }
  54% { transform: rotate(-12deg); }
  59% { transform: rotate(10deg); }
  62% { transform: rotate(-8deg); }
  66% { transform: rotate(6deg); }
  70% { transform: rotate(-4deg); }
  74% { transform: rotate(2deg); }
  78% { transform: rotate(-1deg); }
  82% { transform: rotate(1deg); }
  86% { transform: rotate(0); }
  100% { transform: rotate(0); }
`;

const Button = styled.button`
  position: relative;
  padding: 19px;
  cursor: pointer;
  ${media.largeScreen`
    svg {
      width: auto;
      height: auto;
    }
  `}
  ${media.mediumScreen`
    padding: 19px 15px;
  `}
  ${media.smallScreen`
    padding: 15px;
  `}
  ${media.smallestScreen`
    padding: 10px;
  `}
  svg * {
    transition: 0.3s;
  }
  svg {
    vertical-align: bottom;
  }
  &:before {
    content: '';
    display: block;
    height: 2px;
    width: 0;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: ${(props) => props.theme.colors.red};
    transition: width 0.6s;
  }
  &:hover {
    &:before {
      width: 75%;
    }
  }
`;

const Counter = styled.span<{ animate?: boolean }>`
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: #ca9c66;
  color: #ffffff;
  font-size: ${(props) => props.theme.typography.labelFontSize};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  ${media.largeScreen`
    width: 16px;
    height: 16px;
  `}
  ${media.mediumScreen`
    top: -3px;
    right: 2px;
  `}
  ${media.smallScreen`
    top: -2px;
    right: -5px;
  `}
  ${(props) =>
    props.animate &&
    css`
      animation: ${ring} 2s 0s ease-in-out 1;
    `}
`;

const getIcon = (iconName: NavIconButtonProps['icon']) => {
  switch (iconName) {
    case 'user':
      return <ReactSVG src={userImg} />;
    case 'loggedin':
      return <ReactSVG src={userImgLogged} />;
    case 'cart':
      return <ReactSVG src={cartImg} />;
    case 'wishlist':
      return <ReactSVG src={heartImg} />;
    case 'close':
      return <ReactSVG src={closeImg} />;
    case 'arrow':
      return <ReactSVG src={arrowImg} />;
    default:
      return iconName;
  }
};

function NavIconButton({ icon, counter, ...rest }: NavIconButtonProps) {
  return (
    <Button type="button" {...rest}>
      {getIcon(icon)}
      {!!counter && <Counter animate>{counter}</Counter>}
    </Button>
  );
}

export default NavIconButton;
