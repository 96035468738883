import { Button } from '@components/atoms/Button';
import { media, styled } from '@styles';

export const DiscountForm = styled.form`
  margin-bottom: ${(props) => props.theme.spacing.spacer};
  width: 100%;
`;

export const Input = styled.div`
  margin-bottom: ${(props) => props.theme.spacing.spacer};
`;

export const InputWithButton = styled.div`
  display: flex;
`;

export const InputWrapper = styled.div`
  flex: 1;
  input {
    padding: 1rem;
    ${media.largeScreen`
      padding: 0.75rem;
    `}
  }
`;

export const ButtonWrapper = styled.div`
  width: auto;
  min-width: 110px;
`;

export const StyledButton = styled(Button)<{
  active: boolean;
}>`
  padding: 0.8rem 1rem;
  height: 100%;
  width: 196px;
  background-color: ${(props) =>
    props.active ? props.theme.colors.red : '#DADEE0'};
  pointer-events: ${(props) => (props.active ? 'all' : 'none')};
  ${media.largeScreen`
    width: 140px;
  `}
  ${media.smMediumScreen`
    width: 167px;
  `}
  ${media.smallScreen`
    width: 107px;
  `}
`;

export const PromoCodeValue = styled.div`
  display: flex;
  justify-content: space-between;

  > span {
    display: flex;
    align-items: center;
    font-family: ${(props) => props.theme.typography.extraFontFamily};
    font-size: ${(props) => props.theme.typography.smallFontSize};
    font-weight: 500;
    line-height: 100%;
    color: #4f6e7c;
    opacity: 0.8;
  }
`;

export const ChipsWrapper = styled.div`
  div {
    padding: 0;
    background-color: transparent;
    color: ${(props) => props.theme.colors.red};

    > span {
      padding-bottom: 2px;
      font-family: ${(props) => props.theme.typography.extraFontFamily};
      font-size: ${(props) => props.theme.typography.smallFontSize};
      font-weight: 500;
      line-height: 100%;
      color: #4f6e7c;
    }

    button {
      opacity: 0.2;
      svg {
        path {
          fill: #2c2c2c;
        }
      }
    }
  }
`;

export const Spinner = styled.img<{
  cHidden?: boolean;
}>`
  display: ${(props) => (props.cHidden ? 'block' : 'none')};
  width: 100%;
  max-width: 30px;
  height: auto;
`;
