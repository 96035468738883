import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { NumberField } from '@components/molecules/NumberField';
import { commonMessages } from '@temp/intl';

export interface IQuantityInput {
  quantity: number;
  maxQuantity: number;
  disabled: boolean;
  onQuantityChange: (value: number) => void;
  hideErrors: boolean;
}

export const QuantityInput: React.FC<IQuantityInput> = ({
  disabled,
  quantity,
  maxQuantity,
  onQuantityChange,
  hideErrors,
}) => {
  const [isTooMuch, setIsTooMuch] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    setIsTooMuch(!Number.isNaN(quantity) && quantity > maxQuantity);
  }, [quantity, maxQuantity]);

  const handleQuantityChange = (newQuantity: number) => {
    if (newQuantity < 0) {
      return;
    }
    if (quantity !== newQuantity) {
      onQuantityChange(newQuantity);
    }
    setIsTooMuch(!Number.isNaN(newQuantity) && newQuantity > maxQuantity);
  };

  const quantityErrors =
    !hideErrors && isTooMuch
      ? [
          {
            message: intl.formatMessage(commonMessages.maxQtyIs, {
              maxQuantity,
            }),
          },
        ]
      : undefined;

  return (
    <NumberField
      value={quantity}
      disabled={disabled}
      onChange={handleQuantityChange}
      errors={quantityErrors}
    />
  );
};
QuantityInput.displayName = 'QuantityInput';
