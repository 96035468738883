/* tslint:disable */
/* eslint-disable */
/* THIS FILE IS GENERATED WITH `npm run codegen-prismic` */
import * as Types from './prismicOperations';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { FieldPolicy, FieldReadFunction, TypePolicies, TypePolicy } from '@apollo/client/cache';

import { useRouter } from 'next/router';
import { localeToPrismicQuery } from '@utils/regions';
import { prismicApolloClient } from '@prismic/prismic-config';
        
const defaultOptions =  {}
export const ArchitectBodyArchitectReviewsFragmentDoc = gql`
    fragment ArchitectBodyArchitectReviewsFragment on ArchitectBodyArchitectreviews {
  type
}
    `;
export const ArchitectBodyBenefitsProgramFragmentDoc = gql`
    fragment ArchitectBodyBenefitsProgramFragment on ArchitectBodyBenefitsprogram {
  type
  primary {
    title
    btn_text
  }
  fields {
    title
    description
    image
  }
}
    `;
export const ExternalLinkFragmentDoc = gql`
    fragment ExternalLinkFragment on _ExternalLink {
  _linkType
  __typename
  url
}
    `;
export const DocumentLinkFragmentDoc = gql`
    fragment DocumentLinkFragment on _Document {
  __typename
  _meta {
    type
    id
    uid
  }
}
    `;
export const FileLinkFragmentDoc = gql`
    fragment FileLinkFragment on _FileLink {
  name
  url
}
    `;
export const LinkableFragmentDoc = gql`
    fragment LinkableFragment on _Linkable {
  ...ExternalLinkFragment
  ...DocumentLinkFragment
  ...FileLinkFragment
}
    ${ExternalLinkFragmentDoc}
${DocumentLinkFragmentDoc}
${FileLinkFragmentDoc}`;
export const ArchitectBodyConsultToolboxFragmentDoc = gql`
    fragment ArchitectBodyConsultToolboxFragment on ArchitectBodyConsulttoolbox {
  type
  primary {
    title
    description
  }
  fields {
    title1
    icon
    link {
      ...LinkableFragment
    }
  }
}
    ${LinkableFragmentDoc}`;
export const ArchitectBodyFranchiseContactFragmentDoc = gql`
    fragment ArchitectBodyFranchiseContactFragment on ArchitectBodyFranchisecontact {
  type
  fields {
    title
    btn_text
  }
}
    `;
export const ArchitectBodyInspirationFragmentDoc = gql`
    fragment ArchitectBodyInspirationFragment on ArchitectBodyInspiration {
  type
  label
  fields {
    image
  }
}
    `;
export const ArchitectBodyInstagramPostsFragmentDoc = gql`
    fragment ArchitectBodyInstagramPostsFragment on ArchitectBodyInstagram_posts {
  type
  label
  primary {
    htitle: title_2
    user_name
    text
  }
  fields {
    instagram_link {
      ...LinkableFragment
    }
  }
}
    ${LinkableFragmentDoc}`;
export const ArchitectBodyShowroomFragmentDoc = gql`
    fragment ArchitectBodyShowroomFragment on ArchitectBodyShowroom {
  type
  primary {
    title
    list_title
    partner_list_title
  }
  fields {
    location
    name
    address
    web
    is_partner
  }
}
    `;
export const MetaBaseFragmentDoc = gql`
    fragment MetaBaseFragment on Meta {
  id
  uid
  type
}
    `;
export const BlogHomeIconBannerFragmentDoc = gql`
    fragment BlogHomeIconBannerFragment on Blog_homeBody1Iconbanner {
  label
  type
  primary {
    title
  }
  fields {
    img
    title
    link {
      _linkType
      ... on Page {
        _meta {
          ...MetaBaseFragment
        }
      }
      ... on Blog_post {
        _meta {
          ...MetaBaseFragment
        }
      }
    }
  }
}
    ${MetaBaseFragmentDoc}`;
export const BlogHomeBodyFragmentDoc = gql`
    fragment BlogHomeBodyFragment on Blog_homeBody1 {
  __typename
  ... on Blog_homeBody1Iconbanner {
    label
    type
    primary {
      title
    }
    fields {
      img
      title
      link {
        _linkType
        ... on Page {
          _meta {
            ...MetaBaseFragment
          }
        }
        ... on Blog_post {
          _meta {
            ...MetaBaseFragment
          }
        }
      }
    }
  }
}
    ${MetaBaseFragmentDoc}`;
export const BlogPostBodyAccordeonFragmentDoc = gql`
    fragment BlogPostBodyAccordeonFragment on Blog_postBodyAccordeon {
  type
  fields {
    title
    rich_text
  }
}
    `;
export const BlogPostBodyContentPhotoPhotoFragmentDoc = gql`
    fragment BlogPostBodyContentPhotoPhotoFragment on Blog_postBodyContent_photo_photo {
  type
  fields {
    content_photo
    photo_link
  }
}
    `;
export const BlogPostBodyContentPhotoPhoto_50FragmentDoc = gql`
    fragment BlogPostBodyContentPhotoPhoto_50Fragment on Blog_postBodyContent_photo_photo_50 {
  type
  fields {
    content_photo_left
    content_photo_right
    photo_left_link
    photo_right_link
  }
}
    `;
export const BlogPostBodyContentPhotoTextFragmentDoc = gql`
    fragment BlogPostBodyContentPhotoTextFragment on Blog_postBodyContent_photo_text {
  type
  label
  primary {
    content_photo
    content_text
    photo_link
  }
}
    `;
export const BlogPostBodyContentPhotoText_30_60FragmentDoc = gql`
    fragment BlogPostBodyContentPhotoText_30_60Fragment on Blog_postBodyContent_photo_text_30_60 {
  type
  fields {
    content_photo
    content_text
    photo_link
  }
}
    `;
export const BlogPostBodyContentTextPhoto_60_30FragmentDoc = gql`
    fragment BlogPostBodyContentTextPhoto_60_30Fragment on Blog_postBodyContent_text_photo_60_30 {
  type
  fields {
    content_photo
    content_text
    photo_link
  }
}
    `;
export const BlogPostBodyContentTextText_50FragmentDoc = gql`
    fragment BlogPostBodyContentTextText_50Fragment on Blog_postBodyContent_text_text_50 {
  type
  fields {
    content_text_left
    content_text_right
  }
}
    `;
export const BlogPostBodyDownloadFilesFragmentDoc = gql`
    fragment BlogPostBodyDownloadFilesFragment on Blog_postBodyDownloadfiles {
  type
  label
  fields {
    filelink {
      ... on _ExternalLink {
        url
      }
      ... on _FileLink {
        url
        size
      }
    }
    filetitle
    filedescription
    filecategory
  }
}
    `;
export const BlogPostBodyExcerptFragmentDoc = gql`
    fragment BlogPostBodyExcerptFragment on Blog_postBodyExcerpt {
  type
  primary {
    text
  }
}
    `;
export const BlogPostBodyFormEmbdedFragmentDoc = gql`
    fragment BlogPostBodyFormEmbdedFragment on Blog_postBodyFormembded {
  type
  primary {
    embded_link {
      ... on _ExternalLink {
        url
      }
    }
  }
}
    `;
export const BlogPostBodyHeroImgFragmentDoc = gql`
    fragment BlogPostBodyHeroImgFragment on Blog_postBodyHeroimg {
  type
  primary {
    img
    hero_image_link
  }
}
    `;
export const BlogPostBodyHtmlEmbdedFragmentDoc = gql`
    fragment BlogPostBodyHtmlEmbdedFragment on Blog_postBodyHtmlembded {
  type
  primary {
    html
  }
}
    `;
export const BlogPostBodyIconBannerFragmentDoc = gql`
    fragment BlogPostBodyIconBannerFragment on Blog_postBodyIconbanner {
  type
  primary {
    title
  }
  fields {
    img
    title
    link {
      _linkType
      ... on Page {
        _meta {
          ...MetaBaseFragment
        }
      }
    }
  }
}
    ${MetaBaseFragmentDoc}`;
export const BlogPostBodyIconCtaFragmentDoc = gql`
    fragment BlogPostBodyIconCtaFragment on Blog_postBodyIconcta {
  type
  fields {
    title
    link {
      ... on _FileLink {
        name
        url
      }
    }
    img
  }
}
    `;
export const BlogPostBodyImageCardFragmentDoc = gql`
    fragment BlogPostBodyImageCardFragment on Blog_postBodyImagecard {
  type
  label
  primary {
    header_title
  }
  fields {
    card_image
    card_title
    card_content
    card_btn_label
    card_btn_link {
      ...LinkableFragment
    }
    card_centered
  }
}
    ${LinkableFragmentDoc}`;
export const BlogPostBodyImageCtaFragmentDoc = gql`
    fragment BlogPostBodyImageCtaFragment on Blog_postBodyImagecta {
  type
  label
  primary {
    bgcolor
    content_photo
    content_text
    button_link {
      ...LinkableFragment
    }
    button_label
  }
}
    ${LinkableFragmentDoc}`;
export const BlogPostBodyLinkButtonFragmentDoc = gql`
    fragment BlogPostBodyLinkButtonFragment on Blog_postBodyLinkbutton {
  type
  label
  primary {
    button_label
    button_link {
      ...LinkableFragment
    }
  }
}
    ${LinkableFragmentDoc}`;
export const BlogPostBodyMediaSliderFragmentDoc = gql`
    fragment BlogPostBodyMediaSliderFragment on Blog_postBodyMediaslider {
  type
  fields {
    media_type
    media {
      ... on _FileLink {
        name
        url
      }
      ... on _ImageLink {
        name
        url
      }
    }
  }
}
    `;
export const BlogPostBodyOurPartnersFragmentDoc = gql`
    fragment BlogPostBodyOurPartnersFragment on Blog_postBodyOurpartners {
  type
  label
  primary {
    ... on Blog_postBodyOurpartnersPrimary {
      our_partners_title
    }
  }
  fields {
    ... on Blog_postBodyOurpartnersFields {
      logo_image
    }
  }
}
    `;
export const BlogPostBodyQuoteFragmentDoc = gql`
    fragment BlogPostBodyQuoteFragment on Blog_postBodyQuote {
  type
  primary {
    quote
  }
}
    `;
export const BlogPostBodyRelatedProductsFragmentDoc = gql`
    fragment BlogPostBodyRelatedProductsFragment on Blog_postBodyRelatedproducts {
  type
  primary {
    slicetitle
  }
  fields {
    productid
  }
}
    `;
export const BlogPostBodyRichtextFragmentDoc = gql`
    fragment BlogPostBodyRichtextFragment on Blog_postBodyRichtext {
  type
  primary {
    rich_text
    classes
  }
}
    `;
export const BlogPostBodyTagImageFragmentDoc = gql`
    fragment BlogPostBodyTagImageFragment on Blog_postBodyTagimage {
  type
  primary {
    subtitle
  }
  fields {
    img
    imgmobile
    image_width
    image_height
    tag_image_link
  }
}
    `;
export const BlogPostCardFragmentDoc = gql`
    fragment BlogPostCardFragment on Blog_post {
  _linkType
  title
  preview_description
  image
  tags {
    tag {
      _linkType
      ... on Tag {
        _linkType
        name
      }
    }
  }
  _meta {
    ...MetaBaseFragment
  }
}
    ${MetaBaseFragmentDoc}`;
export const MetaDetailsFragmentDoc = gql`
    fragment MetaDetailsFragment on Meta {
  ...MetaBaseFragment
  tags
  lang
  alternateLanguages {
    id
    uid
    type
    lang
  }
  firstPublicationDate
  lastPublicationDate
}
    ${MetaBaseFragmentDoc}`;
export const BlogPostTagFragmentDoc = gql`
    fragment BlogPostTagFragment on Blog_postTags {
  tag {
    _linkType
    ... on Tag {
      _linkType
      name
    }
  }
}
    `;
export const BlogPostFragmentDoc = gql`
    fragment BlogPostFragment on Blog_post {
  title
  image
  preview_description
  hide_for_blog_index_nav
  meta_title
  meta_description
  canonical_url
  isfeatured
  _linkType
  _meta {
    ...MetaDetailsFragment
  }
  category {
    _linkType
    __typename
    ... on Category {
      name
    }
  }
  tags {
    ...BlogPostTagFragment
  }
  image
}
    ${MetaDetailsFragmentDoc}
${BlogPostTagFragmentDoc}`;
export const CategoryHeaderFragmentDoc = gql`
    fragment CategoryHeaderFragment on Category {
  name
  excerpt
}
    `;
export const CategoryBannerFragmentDoc = gql`
    fragment CategoryBannerFragment on CategoryBanners {
  image: banner_image
  title: banner_title
  color: banner_title_color
  label: banner_label
  link: banner_link {
    ...LinkableFragment
  }
  offset: banner_offset
  overlay: banner_overlay
}
    ${LinkableFragmentDoc}`;
export const CategoryFragmentDoc = gql`
    fragment CategoryFragment on Category {
  ...CategoryHeaderFragment
  banners {
    ...CategoryBannerFragment
  }
  meta_title
  meta_image
  meta_description
  _meta {
    ...MetaDetailsFragment
  }
}
    ${CategoryHeaderFragmentDoc}
${CategoryBannerFragmentDoc}
${MetaDetailsFragmentDoc}`;
export const ContactsFragmentDoc = gql`
    fragment ContactsFragment on Contacts {
  meta_title
  meta_description
  title
  description
  contacts_title
  form_title
  address_title
  address
  registration_details
}
    `;
export const HomepageBodyBenefitsFragmentDoc = gql`
    fragment HomepageBodyBenefitsFragment on HomepageBodyBenefits {
  type
  label
  fields {
    img_default
    img_shade
    img_hover
    title
    text
    more_text
  }
}
    `;
export const CategorySliceFragmentDoc = gql`
    fragment CategorySliceFragment on Category {
  name
  items {
    item_image
    item_title
    item_text
    item_link {
      ...LinkableFragment
    }
  }
  _meta {
    ...MetaBaseFragment
  }
}
    ${LinkableFragmentDoc}
${MetaBaseFragmentDoc}`;
export const HomepageBodyCollectionsFragmentDoc = gql`
    fragment HomepageBodyCollectionsFragment on HomepageBodyCollections {
  type
  label
  primary {
    title
    link {
      ...LinkableFragment
    }
  }
  fields {
    category {
      ...CategorySliceFragment
    }
    title
    text
    image
    link {
      ...LinkableFragment
    }
  }
}
    ${LinkableFragmentDoc}
${CategorySliceFragmentDoc}`;
export const HomepageBodyCtaFragmentDoc = gql`
    fragment HomepageBodyCtaFragment on HomepageBodyCta {
  type
  label
  primary {
    title
    text
    button_label
    button_link {
      ...LinkableFragment
    }
    video
    image
  }
}
    ${LinkableFragmentDoc}`;
export const HomepageBodyImageCardFragmentDoc = gql`
    fragment HomepageBodyImageCardFragment on HomepageBodyImagecard {
  type
  label
  primary {
    header_title
  }
  fields {
    card_image
    card_title
    card_content
    card_btn_label
    card_btn_link {
      ...LinkableFragment
    }
    card_centered
  }
}
    ${LinkableFragmentDoc}`;
export const HomepageBodyImageCtaFragmentDoc = gql`
    fragment HomepageBodyImageCtaFragment on HomepageBodyImagecta {
  type
  label
  primary {
    bgcolor
    content_photo
    content_text
    button_link {
      ...LinkableFragment
    }
    button_label
  }
}
    ${LinkableFragmentDoc}`;
export const HomepageBodyInspirationFragmentDoc = gql`
    fragment HomepageBodyInspirationFragment on HomepageBodyInspiration {
  type
  label
  fields {
    image
    link {
      ...LinkableFragment
    }
  }
}
    ${LinkableFragmentDoc}`;
export const HomepageBodyInstagramPostsFragmentDoc = gql`
    fragment HomepageBodyInstagramPostsFragment on HomepageBodyInstagram_posts {
  type
  label
  primary {
    htitle: title
    user_name
    text
  }
  fields {
    instagram_link {
      ...LinkableFragment
    }
  }
}
    ${LinkableFragmentDoc}`;
export const HomepageBodyLinkButtonFragmentDoc = gql`
    fragment HomepageBodyLinkButtonFragment on HomepageBodyLinkbutton {
  type
  label
  primary {
    button_label
    button_link {
      ...LinkableFragment
    }
  }
}
    ${LinkableFragmentDoc}`;
export const HomepageBodyReviewsFragmentDoc = gql`
    fragment HomepageBodyReviewsFragment on HomepageBodyReviews {
  type
}
    `;
export const HomepageBodyTrustedFragmentDoc = gql`
    fragment HomepageBodyTrustedFragment on HomepageBodyTrusted {
  type
  label
  fields {
    image
    text
  }
}
    `;
export const InstagramPostsFragmentDoc = gql`
    fragment InstagramPostsFragment on InstagramPosts {
  image
  user_avatar
  post_title
  post_user_name
  instagram_link {
    ...LinkableFragment
  }
}
    ${LinkableFragmentDoc}`;
export const InstagramFragmentDoc = gql`
    fragment InstagramFragment on Instagram {
  title
  user_name
  text
  posts {
    ...InstagramPostsFragment
  }
}
    ${InstagramPostsFragmentDoc}`;
export const LayoutHeaderNaviguationFragmentDoc = gql`
    fragment LayoutHeaderNaviguationFragment on LayoutHeader_navigationNav_item {
  type
  label
  primary {
    label
    link {
      ...LinkableFragment
    }
  }
  fields {
    label
    image
    link {
      ...LinkableFragment
    }
  }
}
    ${LinkableFragmentDoc}`;
export const LayoutFooterSocialFragmentDoc = gql`
    fragment LayoutFooterSocialFragment on LayoutFooter_social {
  footer_social_type
  footer_social_link {
    ...LinkableFragment
  }
}
    ${LinkableFragmentDoc}`;
export const LayoutContactFragmentDoc = gql`
    fragment LayoutContactFragment on LayoutContact {
  type
  link {
    ...LinkableFragment
  }
  label
  show_in_nav
}
    ${LinkableFragmentDoc}`;
export const LayoutFooterNaviguationFragmentDoc = gql`
    fragment LayoutFooterNaviguationFragment on LayoutFooter_navigationNav_group {
  type
  primary {
    title
  }
  fields {
    label
    link {
      ...LinkableFragment
    }
  }
}
    ${LinkableFragmentDoc}`;
export const LayoutModalFragmentDoc = gql`
    fragment LayoutModalFragment on LayoutModals {
  id
  title
  text
  content
}
    `;
export const LayoutFragmentDoc = gql`
    fragment LayoutFragment on Layout {
  header_message
  header_navigation {
    ...LayoutHeaderNaviguationFragment
  }
  header_navigation_all_types_image
  footer_contact_title
  footer_contact_text
  footer_social {
    ...LayoutFooterSocialFragment
  }
  footer_trust_box
  footer_copyright
  footer_terms
  contact {
    ...LayoutContactFragment
  }
  footer_navigation {
    ...LayoutFooterNaviguationFragment
  }
  gdrp_text
  region_title
  region_description
  regions {
    region_title
    flag
    link {
      ...LinkableFragment
    }
  }
  modals {
    ...LayoutModalFragment
  }
}
    ${LayoutHeaderNaviguationFragmentDoc}
${LayoutFooterSocialFragmentDoc}
${LayoutContactFragmentDoc}
${LayoutFooterNaviguationFragmentDoc}
${LinkableFragmentDoc}
${LayoutModalFragmentDoc}`;
export const PageBodyAccordionFragmentDoc = gql`
    fragment PageBodyAccordionFragment on PageBodyAccordion {
  type
  fields {
    title
    rich_text
  }
}
    `;
export const PageBodyContentPhotoText_30_60FragmentDoc = gql`
    fragment PageBodyContentPhotoText_30_60Fragment on PageBodyContent_photo_text_30_60 {
  type
  fields {
    content_photo
    content_text
    photo_link
  }
}
    `;
export const PageBodyContentTextPhoto_60_30FragmentDoc = gql`
    fragment PageBodyContentTextPhoto_60_30Fragment on PageBodyContent_text_photo_60_30 {
  type
  fields {
    content_photo
    content_text
    photo_link
  }
}
    `;
export const PageBodyContentTextText_50FragmentDoc = gql`
    fragment PageBodyContentTextText_50Fragment on PageBodyContent_text_text_50 {
  type
  fields {
    content_text_left
    content_text_right
  }
}
    `;
export const PageBodyContentPhotoPhotoFragmentDoc = gql`
    fragment PageBodyContentPhotoPhotoFragment on PageBodyContent_photo_photo {
  type
  fields {
    content_photo
    photo_link
  }
}
    `;
export const PageBodyContentPhotoPhoto_50FragmentDoc = gql`
    fragment PageBodyContentPhotoPhoto_50Fragment on PageBodyContent_photo_photo_50 {
  type
  fields {
    content_photo_left
    content_photo_right
    photo_left_link
    photo_right_link
  }
}
    `;
export const PageBodyContentPhotoTextFragmentDoc = gql`
    fragment PageBodyContentPhotoTextFragment on PageBodyContent_photo_text {
  type
  label
  primary {
    content_photo
    content_text
    photo_link
  }
}
    `;
export const PageBodyDownloadFilesFragmentDoc = gql`
    fragment PageBodyDownloadFilesFragment on PageBodyDownloadfiles {
  type
  label
  fields {
    filelink {
      ... on _ExternalLink {
        url
      }
      ... on _FileLink {
        url
        size
      }
    }
    filelogo
    filetitle
    filedescription
    filecategory
  }
}
    `;
export const PageBodyExcerptFragmentDoc = gql`
    fragment PageBodyExcerptFragment on PageBodyExcerpt {
  type
  primary {
    text
  }
}
    `;
export const PageBodyFormEmbdedFragmentDoc = gql`
    fragment PageBodyFormEmbdedFragment on PageBodyFormembded {
  type
  primary {
    embded_link {
      ... on _ExternalLink {
        url
      }
    }
  }
}
    `;
export const PageBodyHeroImgFragmentDoc = gql`
    fragment PageBodyHeroImgFragment on PageBodyHeroimg {
  type
  primary {
    img
  }
}
    `;
export const PageBodyHtmlEmbdedFragmentDoc = gql`
    fragment PageBodyHtmlEmbdedFragment on PageBodyHtmlembded {
  type
  primary {
    html
  }
}
    `;
export const PageBodyIconBannerFragmentDoc = gql`
    fragment PageBodyIconBannerFragment on PageBodyIconbanner {
  type
  primary {
    title
  }
  fields {
    img
    title
    link {
      _linkType
      ... on Page {
        _meta {
          ...MetaBaseFragment
        }
      }
    }
  }
}
    ${MetaBaseFragmentDoc}`;
export const PageBodyIconCtaFragmentDoc = gql`
    fragment PageBodyIconCtaFragment on PageBodyIconcta {
  type
  fields {
    title
    link {
      ...LinkableFragment
    }
    img
  }
}
    ${LinkableFragmentDoc}`;
export const PageBodyImageCardFragmentDoc = gql`
    fragment PageBodyImageCardFragment on PageBodyImagecard {
  type
  label
  primary {
    header_title
  }
  fields {
    card_image
    card_title
    card_content
    card_btn_label
    card_btn_link {
      ...LinkableFragment
    }
    card_centered
  }
}
    ${LinkableFragmentDoc}`;
export const PageBodyImageCtaFragmentDoc = gql`
    fragment PageBodyImageCtaFragment on PageBodyImagecta {
  type
  label
  primary {
    bgcolor
    content_photo
    content_text
    button_link {
      ...LinkableFragment
    }
    button_label
  }
}
    ${LinkableFragmentDoc}`;
export const PageBodyLinkButtonFragmentDoc = gql`
    fragment PageBodyLinkButtonFragment on PageBodyLinkbutton {
  type
  label
  primary {
    button_label
    button_link {
      ...LinkableFragment
    }
  }
}
    ${LinkableFragmentDoc}`;
export const PageBodyMediaSliderFragmentDoc = gql`
    fragment PageBodyMediaSliderFragment on PageBodyMediaslider {
  type
  fields {
    media_type
    media {
      ... on _FileLink {
        name
        url
      }
      ... on _ImageLink {
        name
        url
      }
    }
  }
}
    `;
export const PageBodyOurPartnersFragmentDoc = gql`
    fragment PageBodyOurPartnersFragment on PageBodyOurpartners {
  label
  type
  primary {
    our_partners_title
  }
  fields {
    logo_image
  }
}
    `;
export const PageBodyQuoteFragmentDoc = gql`
    fragment PageBodyQuoteFragment on PageBodyQuote {
  type
  primary {
    quote
  }
}
    `;
export const PageBodyRelatedProductsFragmentDoc = gql`
    fragment PageBodyRelatedProductsFragment on PageBodyRelatedproducts {
  type
  primary {
    slicetitle
  }
  fields {
    productid
  }
}
    `;
export const PageBodyRichtextFragmentDoc = gql`
    fragment PageBodyRichtextFragment on PageBodyRichtext {
  type
  primary {
    rich_text
    classes
  }
}
    `;
export const PageBodyTagImageFragmentDoc = gql`
    fragment PageBodyTagImageFragment on PageBodyTagimage {
  type
  primary {
    subtitle
  }
  fields {
    img
    imgmobile
  }
}
    `;
export const PageLinkBlogPostFragmentDoc = gql`
    fragment PageLinkBlogPostFragment on Blog_post {
  image
  title
  tags {
    tag {
      ... on Tag {
        name
      }
    }
  }
  _meta {
    ...MetaBaseFragment
  }
}
    ${MetaBaseFragmentDoc}`;
export const PageLinkPageFragmentDoc = gql`
    fragment PageLinkPageFragment on Page {
  _meta {
    ...MetaBaseFragment
  }
}
    ${MetaBaseFragmentDoc}`;
export const ProjectCategoryFragmentDoc = gql`
    fragment ProjectCategoryFragment on Projectcategory {
  name
  image
  _meta {
    ...MetaBaseFragment
  }
}
    ${MetaBaseFragmentDoc}`;
export const ProjectCategoryListFragmentDoc = gql`
    fragment ProjectCategoryListFragment on ProjectCategory_list {
  category {
    ...ProjectCategoryFragment
  }
}
    ${ProjectCategoryFragmentDoc}`;
export const ProjectTagFragmentDoc = gql`
    fragment ProjectTagFragment on Projecttag {
  name
  _meta {
    ...MetaBaseFragment
  }
}
    ${MetaBaseFragmentDoc}`;
export const PageLinkProjectFragmentDoc = gql`
    fragment PageLinkProjectFragment on Project {
  image
  title
  category_list {
    ...ProjectCategoryListFragment
  }
  tag_list {
    ... on ProjectTag_list {
      tag_item {
        ...ProjectTagFragment
      }
    }
  }
  _meta {
    ...MetaBaseFragment
  }
}
    ${ProjectCategoryListFragmentDoc}
${ProjectTagFragmentDoc}
${MetaBaseFragmentDoc}`;
export const ProductAttributesBodyTabsFragmentDoc = gql`
    fragment ProductAttributesBodyTabsFragment on ProductattributesBodyTabs {
  primary {
    tab_title
    tab_message
  }
  fields {
    tab_image
    tab_name
    tab_icon
    tab_content
    tab_footer
  }
}
    `;
export const ProductAttributesFragmentDoc = gql`
    fragment ProductAttributesFragment on Productattributes {
  title
  message
  header
  body {
    ...ProductAttributesBodyTabsFragment
  }
}
    ${ProductAttributesBodyTabsFragmentDoc}`;
export const ProductBodyLabeledIconsFragmentDoc = gql`
    fragment ProductBodyLabeledIconsFragment on ProductBodyLabeled_icons {
  type
  label
  fields {
    icon
    label
    type
  }
}
    `;
export const ProductBodyInspirationFragmentDoc = gql`
    fragment ProductBodyInspirationFragment on ProductBodyInspiration {
  type
  label
  fields {
    image
  }
}
    `;
export const ProductBodyProductFeaturesFragmentDoc = gql`
    fragment ProductBodyProductFeaturesFragment on ProductBodyProduct_features {
  type
  label
  primary {
    title
    icon
    subtitle
    text
  }
  fields {
    image
    video
    sub_title
    text
  }
}
    `;
export const ProductBodyProductDetailsFragmentDoc = gql`
    fragment ProductBodyProductDetailsFragment on ProductBodyProduct_details {
  type
  label
  primary {
    title
  }
  fields {
    title
    subtitle
    rich_text
    rich_text_2
  }
}
    `;
export const ProductBodyColorSampleFragmentDoc = gql`
    fragment ProductBodyColorSampleFragment on ProductBodyColor_sample {
  type
  label
  primary {
    title
    text
    cta_label
    cta_link {
      ...LinkableFragment
    }
  }
  fields {
    color
  }
}
    ${LinkableFragmentDoc}`;
export const ProductBodyInstagramPostsFragmentDoc = gql`
    fragment ProductBodyInstagramPostsFragment on ProductBodyInstagram_posts {
  type
  label
  primary {
    htitle: title
    user_name
    text
  }
  fields {
    instagram_link {
      ...LinkableFragment
    }
  }
}
    ${LinkableFragmentDoc}`;
export const ProductBodyFragmentDoc = gql`
    fragment ProductBodyFragment on ProductBody {
  ...ProductBodyLabeledIconsFragment
  ...ProductBodyInspirationFragment
  ...ProductBodyProductFeaturesFragment
  ...ProductBodyProductDetailsFragment
  ...ProductBodyColorSampleFragment
  ...ProductBodyInstagramPostsFragment
}
    ${ProductBodyLabeledIconsFragmentDoc}
${ProductBodyInspirationFragmentDoc}
${ProductBodyProductFeaturesFragmentDoc}
${ProductBodyProductDetailsFragmentDoc}
${ProductBodyColorSampleFragmentDoc}
${ProductBodyInstagramPostsFragmentDoc}`;
export const ProductMediaFragmentDoc = gql`
    fragment ProductMediaFragment on ProductMedia {
  image
  video
}
    `;
export const ProjectBodyRelatedProductsFragmentDoc = gql`
    fragment ProjectBodyRelatedProductsFragment on ProjectBodyRelatedproducts {
  type
  primary {
    slicetitle
  }
  fields {
    productid
  }
}
    `;
export const ProjectBodyLinkedProductsFragmentDoc = gql`
    fragment ProjectBodyLinkedProductsFragment on ProjectBodyLinkedproducts {
  type
  primary {
    slicetitle
  }
  fields {
    productname
    productprice
    productimage
    productcategory
    productlink {
      ...LinkableFragment
    }
  }
}
    ${LinkableFragmentDoc}`;
export const ProjectCardFragmentDoc = gql`
    fragment ProjectCardFragment on Project {
  meta_title
  meta_description
  meta_image
  title
  description
  image
  sort_order
  creation_date
  category_list {
    ...ProjectCategoryListFragment
  }
  tag_list {
    ... on ProjectTag_list {
      tag_item {
        ...ProjectTagFragment
      }
    }
  }
}
    ${ProjectCategoryListFragmentDoc}
${ProjectTagFragmentDoc}`;
export const ProjectMediaFragmentDoc = gql`
    fragment ProjectMediaFragment on ProjectMedia {
  image: image1
  top
  left
  text
  link {
    ...LinkableFragment
  }
  video
}
    ${LinkableFragmentDoc}`;
export const ProjectColorFragmentDoc = gql`
    fragment ProjectColorFragment on Projectcolor {
  name
  _meta {
    ...MetaBaseFragment
  }
}
    ${MetaBaseFragmentDoc}`;
export const ProjectWidthFragmentDoc = gql`
    fragment ProjectWidthFragment on Projectwidth {
  width
  _meta {
    ...MetaBaseFragment
  }
}
    ${MetaBaseFragmentDoc}`;
export const ProjectDetailsFragmentDoc = gql`
    fragment ProjectDetailsFragment on Project {
  meta_title
  meta_description
  meta_image
  title
  description
  handle_style
  handle_color
  height
  glass_type
  door_size
  image
  interior_designer_link
  interior_designer_view_name
  product_link
  product_view_name
  sort_order
  creation_date
  media {
    ...ProjectMediaFragment
  }
  category_list {
    ...ProjectCategoryListFragment
  }
  color {
    ...ProjectColorFragment
  }
  width {
    ...ProjectWidthFragment
  }
  tag_list {
    ... on ProjectTag_list {
      tag_item {
        ...ProjectTagFragment
      }
    }
  }
}
    ${ProjectMediaFragmentDoc}
${ProjectCategoryListFragmentDoc}
${ProjectColorFragmentDoc}
${ProjectWidthFragmentDoc}
${ProjectTagFragmentDoc}`;
export const WishlistBodyColorSampleFragmentDoc = gql`
    fragment WishlistBodyColorSampleFragment on WishlistBodyColor_sample {
  type
  label
  primary {
    title
    text
    cta_label
    cta_link {
      ...LinkableFragment
    }
  }
  fields {
    color
  }
}
    ${LinkableFragmentDoc}`;
export const PrismicAllBlogPostsQueryDocument = gql`
    query PrismicAllBlogPostsQuery($lang: String = "en-us") {
  allBlog_posts(lang: $lang, sortBy: meta_firstPublicationDate_DESC) {
    edges {
      node {
        ...BlogPostFragment
      }
    }
    totalCount
  }
  allTags(lang: $lang) {
    edges {
      node {
        name
        _linkType
      }
    }
  }
  allBlog_homes(lang: $lang) {
    edges {
      node {
        title
        meta_title
        meta_description
        canonical_url
        showitemsperpage
        body1 {
          __typename
          ...BlogHomeIconBannerFragment
        }
      }
    }
  }
}
    ${BlogPostFragmentDoc}
${BlogHomeIconBannerFragmentDoc}`;

/**
 * __usePrismicAllBlogPostsQuery__
 *
 * To run a query within a React component, call `usePrismicAllBlogPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrismicAllBlogPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrismicAllBlogPostsQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function usePrismicAllBlogPostsQuery(baseOptions?: Apollo.QueryHookOptions<Types.PrismicAllBlogPostsQuery, Types.PrismicAllBlogPostsQueryVariables>) {
        const { locale } = useRouter();
        const prismicOptions = {
          client: prismicApolloClient,
        }
        const options = {...defaultOptions, ...prismicOptions, ...baseOptions}
        return Apollo.useQuery<Types.PrismicAllBlogPostsQuery, Types.PrismicAllBlogPostsQueryVariables>(PrismicAllBlogPostsQueryDocument, {
            ...options,
            fetchPolicy: 'cache-and-network',
            variables: {
              ...options?.variables,
              ...localeToPrismicQuery(locale),
            },
          });
      }
export function usePrismicAllBlogPostsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PrismicAllBlogPostsQuery, Types.PrismicAllBlogPostsQueryVariables>) {
          const { locale } = useRouter();
          const prismicOptions = {
            client: prismicApolloClient,
          }
          const options = {...defaultOptions, ...prismicOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PrismicAllBlogPostsQuery, Types.PrismicAllBlogPostsQueryVariables>(PrismicAllBlogPostsQueryDocument, {
              ...options,
              fetchPolicy: 'cache-and-network',
              variables: {
                ...options?.variables,
                ...localeToPrismicQuery(locale),
              },
            });
        }
export type PrismicAllBlogPostsQueryHookResult = ReturnType<typeof usePrismicAllBlogPostsQuery>;
export type PrismicAllBlogPostsQueryLazyQueryHookResult = ReturnType<typeof usePrismicAllBlogPostsQueryLazyQuery>;
export type PrismicAllBlogPostsQueryQueryResult = Apollo.QueryResult<Types.PrismicAllBlogPostsQuery, Types.PrismicAllBlogPostsQueryVariables>;
export const PrismicAllProjectsQueryDocument = gql`
    query PrismicAllProjectsQuery($lang: String = "en-us") {
  allProjects(lang: $lang, first: 1000, sortBy: sort_order_DESC) {
    edges {
      node {
        ...ProjectCardFragment
        _meta {
          ...MetaBaseFragment
        }
      }
    }
  }
}
    ${ProjectCardFragmentDoc}
${MetaBaseFragmentDoc}`;

/**
 * __usePrismicAllProjectsQuery__
 *
 * To run a query within a React component, call `usePrismicAllProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrismicAllProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrismicAllProjectsQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function usePrismicAllProjectsQuery(baseOptions?: Apollo.QueryHookOptions<Types.PrismicAllProjectsQuery, Types.PrismicAllProjectsQueryVariables>) {
        const { locale } = useRouter();
        const prismicOptions = {
          client: prismicApolloClient,
        }
        const options = {...defaultOptions, ...prismicOptions, ...baseOptions}
        return Apollo.useQuery<Types.PrismicAllProjectsQuery, Types.PrismicAllProjectsQueryVariables>(PrismicAllProjectsQueryDocument, {
            ...options,
            fetchPolicy: 'cache-and-network',
            variables: {
              ...options?.variables,
              ...localeToPrismicQuery(locale),
            },
          });
      }
export function usePrismicAllProjectsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PrismicAllProjectsQuery, Types.PrismicAllProjectsQueryVariables>) {
          const { locale } = useRouter();
          const prismicOptions = {
            client: prismicApolloClient,
          }
          const options = {...defaultOptions, ...prismicOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PrismicAllProjectsQuery, Types.PrismicAllProjectsQueryVariables>(PrismicAllProjectsQueryDocument, {
              ...options,
              fetchPolicy: 'cache-and-network',
              variables: {
                ...options?.variables,
                ...localeToPrismicQuery(locale),
              },
            });
        }
export type PrismicAllProjectsQueryHookResult = ReturnType<typeof usePrismicAllProjectsQuery>;
export type PrismicAllProjectsQueryLazyQueryHookResult = ReturnType<typeof usePrismicAllProjectsQueryLazyQuery>;
export type PrismicAllProjectsQueryQueryResult = Apollo.QueryResult<Types.PrismicAllProjectsQuery, Types.PrismicAllProjectsQueryVariables>;
export const PrismicArchitectQueryDocument = gql`
    query PrismicArchitectQuery($lang: String = "en-us") {
  architect(uid: "architect", lang: $lang) {
    meta_title
    meta_description
    meta_image
    title
    description
    btn_text
    body {
      ...ArchitectBodyInspirationFragment
      ...ArchitectBodyBenefitsProgramFragment
      ...ArchitectBodyArchitectReviewsFragment
      ...ArchitectBodyShowroomFragment
      ...ArchitectBodyFranchiseContactFragment
      ...ArchitectBodyConsultToolboxFragment
      ...ArchitectBodyInstagramPostsFragment
    }
    _meta {
      ...MetaDetailsFragment
    }
  }
}
    ${ArchitectBodyInspirationFragmentDoc}
${ArchitectBodyBenefitsProgramFragmentDoc}
${ArchitectBodyArchitectReviewsFragmentDoc}
${ArchitectBodyShowroomFragmentDoc}
${ArchitectBodyFranchiseContactFragmentDoc}
${ArchitectBodyConsultToolboxFragmentDoc}
${ArchitectBodyInstagramPostsFragmentDoc}
${MetaDetailsFragmentDoc}`;

/**
 * __usePrismicArchitectQuery__
 *
 * To run a query within a React component, call `usePrismicArchitectQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrismicArchitectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrismicArchitectQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function usePrismicArchitectQuery(baseOptions?: Apollo.QueryHookOptions<Types.PrismicArchitectQuery, Types.PrismicArchitectQueryVariables>) {
        const { locale } = useRouter();
        const prismicOptions = {
          client: prismicApolloClient,
        }
        const options = {...defaultOptions, ...prismicOptions, ...baseOptions}
        return Apollo.useQuery<Types.PrismicArchitectQuery, Types.PrismicArchitectQueryVariables>(PrismicArchitectQueryDocument, {
            ...options,
            fetchPolicy: 'cache-and-network',
            variables: {
              ...options?.variables,
              ...localeToPrismicQuery(locale),
            },
          });
      }
export function usePrismicArchitectQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PrismicArchitectQuery, Types.PrismicArchitectQueryVariables>) {
          const { locale } = useRouter();
          const prismicOptions = {
            client: prismicApolloClient,
          }
          const options = {...defaultOptions, ...prismicOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PrismicArchitectQuery, Types.PrismicArchitectQueryVariables>(PrismicArchitectQueryDocument, {
              ...options,
              fetchPolicy: 'cache-and-network',
              variables: {
                ...options?.variables,
                ...localeToPrismicQuery(locale),
              },
            });
        }
export type PrismicArchitectQueryHookResult = ReturnType<typeof usePrismicArchitectQuery>;
export type PrismicArchitectQueryLazyQueryHookResult = ReturnType<typeof usePrismicArchitectQueryLazyQuery>;
export type PrismicArchitectQueryQueryResult = Apollo.QueryResult<Types.PrismicArchitectQuery, Types.PrismicArchitectQueryVariables>;
export const PrismicBlogPageQueryDocument = gql`
    query PrismicBlogPageQuery($uid: String!, $lang: String = "en-us") {
  blog_post(uid: $uid, lang: $lang) {
    title
    meta_title
    meta_description
    meta_image
    tags {
      tag {
        _linkType
        ... on Tag {
          _linkType
          name
        }
      }
    }
    you_may_also_like {
      link_to_post {
        _linkType
        ...BlogPostCardFragment
      }
    }
    body {
      ...BlogPostBodyIconBannerFragment
      ...BlogPostBodyHeroImgFragment
      ...BlogPostBodyExcerptFragment
      ...BlogPostBodyRichtextFragment
      ...BlogPostBodyTagImageFragment
      ...BlogPostBodyQuoteFragment
      ...BlogPostBodyMediaSliderFragment
      ...BlogPostBodyIconCtaFragment
      ...BlogPostBodyContentTextPhoto_60_30Fragment
      ...BlogPostBodyContentPhotoText_30_60Fragment
      ...BlogPostBodyContentTextText_50Fragment
      ...BlogPostBodyContentPhotoPhoto_50Fragment
      ...BlogPostBodyContentPhotoPhotoFragment
      ...BlogPostBodyContentPhotoTextFragment
      ...BlogPostBodyFormEmbdedFragment
      ...BlogPostBodyAccordeonFragment
      ...BlogPostBodyLinkButtonFragment
      ...BlogPostBodyOurPartnersFragment
      ...BlogPostBodyDownloadFilesFragment
      ...BlogPostBodyHtmlEmbdedFragment
      ...BlogPostBodyRelatedProductsFragment
      ...BlogPostBodyImageCtaFragment
      ...BlogPostBodyImageCardFragment
    }
    _meta {
      ...MetaDetailsFragment
    }
  }
}
    ${BlogPostCardFragmentDoc}
${BlogPostBodyIconBannerFragmentDoc}
${BlogPostBodyHeroImgFragmentDoc}
${BlogPostBodyExcerptFragmentDoc}
${BlogPostBodyRichtextFragmentDoc}
${BlogPostBodyTagImageFragmentDoc}
${BlogPostBodyQuoteFragmentDoc}
${BlogPostBodyMediaSliderFragmentDoc}
${BlogPostBodyIconCtaFragmentDoc}
${BlogPostBodyContentTextPhoto_60_30FragmentDoc}
${BlogPostBodyContentPhotoText_30_60FragmentDoc}
${BlogPostBodyContentTextText_50FragmentDoc}
${BlogPostBodyContentPhotoPhoto_50FragmentDoc}
${BlogPostBodyContentPhotoPhotoFragmentDoc}
${BlogPostBodyContentPhotoTextFragmentDoc}
${BlogPostBodyFormEmbdedFragmentDoc}
${BlogPostBodyAccordeonFragmentDoc}
${BlogPostBodyLinkButtonFragmentDoc}
${BlogPostBodyOurPartnersFragmentDoc}
${BlogPostBodyDownloadFilesFragmentDoc}
${BlogPostBodyHtmlEmbdedFragmentDoc}
${BlogPostBodyRelatedProductsFragmentDoc}
${BlogPostBodyImageCtaFragmentDoc}
${BlogPostBodyImageCardFragmentDoc}
${MetaDetailsFragmentDoc}`;

/**
 * __usePrismicBlogPageQuery__
 *
 * To run a query within a React component, call `usePrismicBlogPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrismicBlogPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrismicBlogPageQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function usePrismicBlogPageQuery(baseOptions: Apollo.QueryHookOptions<Types.PrismicBlogPageQuery, Types.PrismicBlogPageQueryVariables>) {
        const { locale } = useRouter();
        const prismicOptions = {
          client: prismicApolloClient,
        }
        const options = {...defaultOptions, ...prismicOptions, ...baseOptions}
        return Apollo.useQuery<Types.PrismicBlogPageQuery, Types.PrismicBlogPageQueryVariables>(PrismicBlogPageQueryDocument, {
            ...options,
            fetchPolicy: 'cache-and-network',
            variables: {
              ...options?.variables,
              ...localeToPrismicQuery(locale),
            },
          });
      }
export function usePrismicBlogPageQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PrismicBlogPageQuery, Types.PrismicBlogPageQueryVariables>) {
          const { locale } = useRouter();
          const prismicOptions = {
            client: prismicApolloClient,
          }
          const options = {...defaultOptions, ...prismicOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PrismicBlogPageQuery, Types.PrismicBlogPageQueryVariables>(PrismicBlogPageQueryDocument, {
              ...options,
              fetchPolicy: 'cache-and-network',
              variables: {
                ...options?.variables,
                ...localeToPrismicQuery(locale),
              },
            });
        }
export type PrismicBlogPageQueryHookResult = ReturnType<typeof usePrismicBlogPageQuery>;
export type PrismicBlogPageQueryLazyQueryHookResult = ReturnType<typeof usePrismicBlogPageQueryLazyQuery>;
export type PrismicBlogPageQueryQueryResult = Apollo.QueryResult<Types.PrismicBlogPageQuery, Types.PrismicBlogPageQueryVariables>;
export const PrismicBlogPathsDocument = gql`
    query prismicBlogPaths($lang: String) {
  allBlog_posts(lang: $lang, sortBy: meta_firstPublicationDate_DESC) {
    edges {
      node {
        _meta {
          id
          uid
          lang
        }
      }
    }
  }
}
    `;

/**
 * __usePrismicBlogPathsQuery__
 *
 * To run a query within a React component, call `usePrismicBlogPathsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrismicBlogPathsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrismicBlogPathsQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function usePrismicBlogPathsQuery(baseOptions?: Apollo.QueryHookOptions<Types.PrismicBlogPathsQuery, Types.PrismicBlogPathsQueryVariables>) {
        const { locale } = useRouter();
        const prismicOptions = {
          client: prismicApolloClient,
        }
        const options = {...defaultOptions, ...prismicOptions, ...baseOptions}
        return Apollo.useQuery<Types.PrismicBlogPathsQuery, Types.PrismicBlogPathsQueryVariables>(PrismicBlogPathsDocument, {
            ...options,
            fetchPolicy: 'cache-and-network',
            variables: {
              ...options?.variables,
              ...localeToPrismicQuery(locale),
            },
          });
      }
export function usePrismicBlogPathsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PrismicBlogPathsQuery, Types.PrismicBlogPathsQueryVariables>) {
          const { locale } = useRouter();
          const prismicOptions = {
            client: prismicApolloClient,
          }
          const options = {...defaultOptions, ...prismicOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PrismicBlogPathsQuery, Types.PrismicBlogPathsQueryVariables>(PrismicBlogPathsDocument, {
              ...options,
              fetchPolicy: 'cache-and-network',
              variables: {
                ...options?.variables,
                ...localeToPrismicQuery(locale),
              },
            });
        }
export type PrismicBlogPathsQueryHookResult = ReturnType<typeof usePrismicBlogPathsQuery>;
export type PrismicBlogPathsLazyQueryHookResult = ReturnType<typeof usePrismicBlogPathsLazyQuery>;
export type PrismicBlogPathsQueryResult = Apollo.QueryResult<Types.PrismicBlogPathsQuery, Types.PrismicBlogPathsQueryVariables>;
export const PrismicCategoryQueryDocument = gql`
    query PrismicCategoryQuery($uid: String!, $lang: String = "en-us") {
  category(uid: $uid, lang: $lang) {
    ...CategoryFragment
  }
}
    ${CategoryFragmentDoc}`;

/**
 * __usePrismicCategoryQuery__
 *
 * To run a query within a React component, call `usePrismicCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrismicCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrismicCategoryQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function usePrismicCategoryQuery(baseOptions: Apollo.QueryHookOptions<Types.PrismicCategoryQuery, Types.PrismicCategoryQueryVariables>) {
        const { locale } = useRouter();
        const prismicOptions = {
          client: prismicApolloClient,
        }
        const options = {...defaultOptions, ...prismicOptions, ...baseOptions}
        return Apollo.useQuery<Types.PrismicCategoryQuery, Types.PrismicCategoryQueryVariables>(PrismicCategoryQueryDocument, {
            ...options,
            fetchPolicy: 'cache-and-network',
            variables: {
              ...options?.variables,
              ...localeToPrismicQuery(locale),
            },
          });
      }
export function usePrismicCategoryQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PrismicCategoryQuery, Types.PrismicCategoryQueryVariables>) {
          const { locale } = useRouter();
          const prismicOptions = {
            client: prismicApolloClient,
          }
          const options = {...defaultOptions, ...prismicOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PrismicCategoryQuery, Types.PrismicCategoryQueryVariables>(PrismicCategoryQueryDocument, {
              ...options,
              fetchPolicy: 'cache-and-network',
              variables: {
                ...options?.variables,
                ...localeToPrismicQuery(locale),
              },
            });
        }
export type PrismicCategoryQueryHookResult = ReturnType<typeof usePrismicCategoryQuery>;
export type PrismicCategoryQueryLazyQueryHookResult = ReturnType<typeof usePrismicCategoryQueryLazyQuery>;
export type PrismicCategoryQueryQueryResult = Apollo.QueryResult<Types.PrismicCategoryQuery, Types.PrismicCategoryQueryVariables>;
export const PrismicContactsQueryDocument = gql`
    query PrismicContactsQuery($lang: String = "en-us") {
  contacts(uid: "contacts", lang: $lang) {
    ...ContactsFragment
    _meta {
      ...MetaDetailsFragment
    }
  }
}
    ${ContactsFragmentDoc}
${MetaDetailsFragmentDoc}`;

/**
 * __usePrismicContactsQuery__
 *
 * To run a query within a React component, call `usePrismicContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrismicContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrismicContactsQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function usePrismicContactsQuery(baseOptions?: Apollo.QueryHookOptions<Types.PrismicContactsQuery, Types.PrismicContactsQueryVariables>) {
        const { locale } = useRouter();
        const prismicOptions = {
          client: prismicApolloClient,
        }
        const options = {...defaultOptions, ...prismicOptions, ...baseOptions}
        return Apollo.useQuery<Types.PrismicContactsQuery, Types.PrismicContactsQueryVariables>(PrismicContactsQueryDocument, {
            ...options,
            fetchPolicy: 'cache-and-network',
            variables: {
              ...options?.variables,
              ...localeToPrismicQuery(locale),
            },
          });
      }
export function usePrismicContactsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PrismicContactsQuery, Types.PrismicContactsQueryVariables>) {
          const { locale } = useRouter();
          const prismicOptions = {
            client: prismicApolloClient,
          }
          const options = {...defaultOptions, ...prismicOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PrismicContactsQuery, Types.PrismicContactsQueryVariables>(PrismicContactsQueryDocument, {
              ...options,
              fetchPolicy: 'cache-and-network',
              variables: {
                ...options?.variables,
                ...localeToPrismicQuery(locale),
              },
            });
        }
export type PrismicContactsQueryHookResult = ReturnType<typeof usePrismicContactsQuery>;
export type PrismicContactsQueryLazyQueryHookResult = ReturnType<typeof usePrismicContactsQueryLazyQuery>;
export type PrismicContactsQueryQueryResult = Apollo.QueryResult<Types.PrismicContactsQuery, Types.PrismicContactsQueryVariables>;
export const PrismicDefaultSeoQueryDocument = gql`
    query PrismicDefaultSeoQuery($lang: String = "en-us") {
  defaultseo(uid: "defaultseo", lang: $lang) {
    site_name
    title
    description
    image
  }
}
    `;

/**
 * __usePrismicDefaultSeoQuery__
 *
 * To run a query within a React component, call `usePrismicDefaultSeoQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrismicDefaultSeoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrismicDefaultSeoQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function usePrismicDefaultSeoQuery(baseOptions?: Apollo.QueryHookOptions<Types.PrismicDefaultSeoQuery, Types.PrismicDefaultSeoQueryVariables>) {
        const { locale } = useRouter();
        const prismicOptions = {
          client: prismicApolloClient,
        }
        const options = {...defaultOptions, ...prismicOptions, ...baseOptions}
        return Apollo.useQuery<Types.PrismicDefaultSeoQuery, Types.PrismicDefaultSeoQueryVariables>(PrismicDefaultSeoQueryDocument, {
            ...options,
            fetchPolicy: 'cache-and-network',
            variables: {
              ...options?.variables,
              ...localeToPrismicQuery(locale),
            },
          });
      }
export function usePrismicDefaultSeoQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PrismicDefaultSeoQuery, Types.PrismicDefaultSeoQueryVariables>) {
          const { locale } = useRouter();
          const prismicOptions = {
            client: prismicApolloClient,
          }
          const options = {...defaultOptions, ...prismicOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PrismicDefaultSeoQuery, Types.PrismicDefaultSeoQueryVariables>(PrismicDefaultSeoQueryDocument, {
              ...options,
              fetchPolicy: 'cache-and-network',
              variables: {
                ...options?.variables,
                ...localeToPrismicQuery(locale),
              },
            });
        }
export type PrismicDefaultSeoQueryHookResult = ReturnType<typeof usePrismicDefaultSeoQuery>;
export type PrismicDefaultSeoQueryLazyQueryHookResult = ReturnType<typeof usePrismicDefaultSeoQueryLazyQuery>;
export type PrismicDefaultSeoQueryQueryResult = Apollo.QueryResult<Types.PrismicDefaultSeoQuery, Types.PrismicDefaultSeoQueryVariables>;
export const PrismicHomePageQueryDocument = gql`
    query PrismicHomePageQuery($lang: String = "en-us") {
  homepage(uid: "homepage", lang: $lang) {
    hero {
      image
      banner_title
      text
      button_label
      button_link {
        ...LinkableFragment
      }
    }
    body {
      ...HomepageBodyTrustedFragment
      ...HomepageBodyInspirationFragment
      ...HomepageBodyBenefitsFragment
      ...HomepageBodyCollectionsFragment
      ...HomepageBodyReviewsFragment
      ...HomepageBodyInstagramPostsFragment
      ...HomepageBodyCtaFragment
      ...HomepageBodyImageCtaFragment
      ...HomepageBodyImageCardFragment
      ...HomepageBodyLinkButtonFragment
    }
    meta_title
    meta_description
    meta_image
    _meta {
      ...MetaDetailsFragment
    }
  }
}
    ${LinkableFragmentDoc}
${HomepageBodyTrustedFragmentDoc}
${HomepageBodyInspirationFragmentDoc}
${HomepageBodyBenefitsFragmentDoc}
${HomepageBodyCollectionsFragmentDoc}
${HomepageBodyReviewsFragmentDoc}
${HomepageBodyInstagramPostsFragmentDoc}
${HomepageBodyCtaFragmentDoc}
${HomepageBodyImageCtaFragmentDoc}
${HomepageBodyImageCardFragmentDoc}
${HomepageBodyLinkButtonFragmentDoc}
${MetaDetailsFragmentDoc}`;

/**
 * __usePrismicHomePageQuery__
 *
 * To run a query within a React component, call `usePrismicHomePageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrismicHomePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrismicHomePageQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function usePrismicHomePageQuery(baseOptions?: Apollo.QueryHookOptions<Types.PrismicHomePageQuery, Types.PrismicHomePageQueryVariables>) {
        const { locale } = useRouter();
        const prismicOptions = {
          client: prismicApolloClient,
        }
        const options = {...defaultOptions, ...prismicOptions, ...baseOptions}
        return Apollo.useQuery<Types.PrismicHomePageQuery, Types.PrismicHomePageQueryVariables>(PrismicHomePageQueryDocument, {
            ...options,
            fetchPolicy: 'cache-and-network',
            variables: {
              ...options?.variables,
              ...localeToPrismicQuery(locale),
            },
          });
      }
export function usePrismicHomePageQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PrismicHomePageQuery, Types.PrismicHomePageQueryVariables>) {
          const { locale } = useRouter();
          const prismicOptions = {
            client: prismicApolloClient,
          }
          const options = {...defaultOptions, ...prismicOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PrismicHomePageQuery, Types.PrismicHomePageQueryVariables>(PrismicHomePageQueryDocument, {
              ...options,
              fetchPolicy: 'cache-and-network',
              variables: {
                ...options?.variables,
                ...localeToPrismicQuery(locale),
              },
            });
        }
export type PrismicHomePageQueryHookResult = ReturnType<typeof usePrismicHomePageQuery>;
export type PrismicHomePageQueryLazyQueryHookResult = ReturnType<typeof usePrismicHomePageQueryLazyQuery>;
export type PrismicHomePageQueryQueryResult = Apollo.QueryResult<Types.PrismicHomePageQuery, Types.PrismicHomePageQueryVariables>;
export const PrismicLayoutQueryDocument = gql`
    query PrismicLayoutQuery($lang: String = "en-us") {
  layout(uid: "layout", lang: $lang) {
    ...LayoutFragment
  }
}
    ${LayoutFragmentDoc}`;

/**
 * __usePrismicLayoutQuery__
 *
 * To run a query within a React component, call `usePrismicLayoutQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrismicLayoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrismicLayoutQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function usePrismicLayoutQuery(baseOptions?: Apollo.QueryHookOptions<Types.PrismicLayoutQuery, Types.PrismicLayoutQueryVariables>) {
        const { locale } = useRouter();
        const prismicOptions = {
          client: prismicApolloClient,
        }
        const options = {...defaultOptions, ...prismicOptions, ...baseOptions}
        return Apollo.useQuery<Types.PrismicLayoutQuery, Types.PrismicLayoutQueryVariables>(PrismicLayoutQueryDocument, {
            ...options,
            fetchPolicy: 'cache-and-network',
            variables: {
              ...options?.variables,
              ...localeToPrismicQuery(locale),
            },
          });
      }
export function usePrismicLayoutQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PrismicLayoutQuery, Types.PrismicLayoutQueryVariables>) {
          const { locale } = useRouter();
          const prismicOptions = {
            client: prismicApolloClient,
          }
          const options = {...defaultOptions, ...prismicOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PrismicLayoutQuery, Types.PrismicLayoutQueryVariables>(PrismicLayoutQueryDocument, {
              ...options,
              fetchPolicy: 'cache-and-network',
              variables: {
                ...options?.variables,
                ...localeToPrismicQuery(locale),
              },
            });
        }
export type PrismicLayoutQueryHookResult = ReturnType<typeof usePrismicLayoutQuery>;
export type PrismicLayoutQueryLazyQueryHookResult = ReturnType<typeof usePrismicLayoutQueryLazyQuery>;
export type PrismicLayoutQueryQueryResult = Apollo.QueryResult<Types.PrismicLayoutQuery, Types.PrismicLayoutQueryVariables>;
export const PrismicPagePathsDocument = gql`
    query prismicPagePaths($lang: String) {
  allPages(lang: $lang, first: 1000) {
    edges {
      node {
        _meta {
          id
          uid
          lang
        }
      }
    }
  }
}
    `;

/**
 * __usePrismicPagePathsQuery__
 *
 * To run a query within a React component, call `usePrismicPagePathsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrismicPagePathsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrismicPagePathsQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function usePrismicPagePathsQuery(baseOptions?: Apollo.QueryHookOptions<Types.PrismicPagePathsQuery, Types.PrismicPagePathsQueryVariables>) {
        const { locale } = useRouter();
        const prismicOptions = {
          client: prismicApolloClient,
        }
        const options = {...defaultOptions, ...prismicOptions, ...baseOptions}
        return Apollo.useQuery<Types.PrismicPagePathsQuery, Types.PrismicPagePathsQueryVariables>(PrismicPagePathsDocument, {
            ...options,
            fetchPolicy: 'cache-and-network',
            variables: {
              ...options?.variables,
              ...localeToPrismicQuery(locale),
            },
          });
      }
export function usePrismicPagePathsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PrismicPagePathsQuery, Types.PrismicPagePathsQueryVariables>) {
          const { locale } = useRouter();
          const prismicOptions = {
            client: prismicApolloClient,
          }
          const options = {...defaultOptions, ...prismicOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PrismicPagePathsQuery, Types.PrismicPagePathsQueryVariables>(PrismicPagePathsDocument, {
              ...options,
              fetchPolicy: 'cache-and-network',
              variables: {
                ...options?.variables,
                ...localeToPrismicQuery(locale),
              },
            });
        }
export type PrismicPagePathsQueryHookResult = ReturnType<typeof usePrismicPagePathsQuery>;
export type PrismicPagePathsLazyQueryHookResult = ReturnType<typeof usePrismicPagePathsLazyQuery>;
export type PrismicPagePathsQueryResult = Apollo.QueryResult<Types.PrismicPagePathsQuery, Types.PrismicPagePathsQueryVariables>;
export const PrismicPageQueryDocument = gql`
    query PrismicPageQuery($uid: String!, $lang: String = "en-us") {
  page(uid: $uid, lang: $lang) {
    showbreadcrumbs
    body {
      ...PageBodyIconBannerFragment
      ...PageBodyHeroImgFragment
      ...PageBodyExcerptFragment
      ...PageBodyRichtextFragment
      ...PageBodyTagImageFragment
      ...PageBodyQuoteFragment
      ...PageBodyMediaSliderFragment
      ...PageBodyIconCtaFragment
      ...PageBodyContentTextPhoto_60_30Fragment
      ...PageBodyContentPhotoText_30_60Fragment
      ...PageBodyContentTextText_50Fragment
      ...PageBodyContentPhotoPhoto_50Fragment
      ...PageBodyContentPhotoTextFragment
      ...PageBodyContentPhotoPhotoFragment
      ...PageBodyFormEmbdedFragment
      ...PageBodyAccordionFragment
      ...PageBodyLinkButtonFragment
      ...PageBodyOurPartnersFragment
      ...PageBodyDownloadFilesFragment
      ...PageBodyHtmlEmbdedFragment
      ...PageBodyRelatedProductsFragment
      ...PageBodyImageCtaFragment
      ...PageBodyImageCardFragment
    }
    _meta {
      ...MetaDetailsFragment
    }
  }
}
    ${PageBodyIconBannerFragmentDoc}
${PageBodyHeroImgFragmentDoc}
${PageBodyExcerptFragmentDoc}
${PageBodyRichtextFragmentDoc}
${PageBodyTagImageFragmentDoc}
${PageBodyQuoteFragmentDoc}
${PageBodyMediaSliderFragmentDoc}
${PageBodyIconCtaFragmentDoc}
${PageBodyContentTextPhoto_60_30FragmentDoc}
${PageBodyContentPhotoText_30_60FragmentDoc}
${PageBodyContentTextText_50FragmentDoc}
${PageBodyContentPhotoPhoto_50FragmentDoc}
${PageBodyContentPhotoTextFragmentDoc}
${PageBodyContentPhotoPhotoFragmentDoc}
${PageBodyFormEmbdedFragmentDoc}
${PageBodyAccordionFragmentDoc}
${PageBodyLinkButtonFragmentDoc}
${PageBodyOurPartnersFragmentDoc}
${PageBodyDownloadFilesFragmentDoc}
${PageBodyHtmlEmbdedFragmentDoc}
${PageBodyRelatedProductsFragmentDoc}
${PageBodyImageCtaFragmentDoc}
${PageBodyImageCardFragmentDoc}
${MetaDetailsFragmentDoc}`;

/**
 * __usePrismicPageQuery__
 *
 * To run a query within a React component, call `usePrismicPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrismicPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrismicPageQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function usePrismicPageQuery(baseOptions: Apollo.QueryHookOptions<Types.PrismicPageQuery, Types.PrismicPageQueryVariables>) {
        const { locale } = useRouter();
        const prismicOptions = {
          client: prismicApolloClient,
        }
        const options = {...defaultOptions, ...prismicOptions, ...baseOptions}
        return Apollo.useQuery<Types.PrismicPageQuery, Types.PrismicPageQueryVariables>(PrismicPageQueryDocument, {
            ...options,
            fetchPolicy: 'cache-and-network',
            variables: {
              ...options?.variables,
              ...localeToPrismicQuery(locale),
            },
          });
      }
export function usePrismicPageQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PrismicPageQuery, Types.PrismicPageQueryVariables>) {
          const { locale } = useRouter();
          const prismicOptions = {
            client: prismicApolloClient,
          }
          const options = {...defaultOptions, ...prismicOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PrismicPageQuery, Types.PrismicPageQueryVariables>(PrismicPageQueryDocument, {
              ...options,
              fetchPolicy: 'cache-and-network',
              variables: {
                ...options?.variables,
                ...localeToPrismicQuery(locale),
              },
            });
        }
export type PrismicPageQueryHookResult = ReturnType<typeof usePrismicPageQuery>;
export type PrismicPageQueryLazyQueryHookResult = ReturnType<typeof usePrismicPageQueryLazyQuery>;
export type PrismicPageQueryQueryResult = Apollo.QueryResult<Types.PrismicPageQuery, Types.PrismicPageQueryVariables>;
export const PrismicProductImagesQueryDocument = gql`
    query PrismicProductImagesQuery($productUids: [String!], $lang: String = "en-us") {
  allProductimagess(uid_in: $productUids, lang: $lang) {
    edges {
      node {
        images {
          image
        }
        _meta {
          ...MetaDetailsFragment
        }
      }
    }
  }
}
    ${MetaDetailsFragmentDoc}`;

/**
 * __usePrismicProductImagesQuery__
 *
 * To run a query within a React component, call `usePrismicProductImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrismicProductImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrismicProductImagesQuery({
 *   variables: {
 *      productUids: // value for 'productUids'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function usePrismicProductImagesQuery(baseOptions?: Apollo.QueryHookOptions<Types.PrismicProductImagesQuery, Types.PrismicProductImagesQueryVariables>) {
        const { locale } = useRouter();
        const prismicOptions = {
          client: prismicApolloClient,
        }
        const options = {...defaultOptions, ...prismicOptions, ...baseOptions}
        return Apollo.useQuery<Types.PrismicProductImagesQuery, Types.PrismicProductImagesQueryVariables>(PrismicProductImagesQueryDocument, {
            ...options,
            fetchPolicy: 'cache-and-network',
            variables: {
              ...options?.variables,
              ...localeToPrismicQuery(locale),
            },
          });
      }
export function usePrismicProductImagesQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PrismicProductImagesQuery, Types.PrismicProductImagesQueryVariables>) {
          const { locale } = useRouter();
          const prismicOptions = {
            client: prismicApolloClient,
          }
          const options = {...defaultOptions, ...prismicOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PrismicProductImagesQuery, Types.PrismicProductImagesQueryVariables>(PrismicProductImagesQueryDocument, {
              ...options,
              fetchPolicy: 'cache-and-network',
              variables: {
                ...options?.variables,
                ...localeToPrismicQuery(locale),
              },
            });
        }
export type PrismicProductImagesQueryHookResult = ReturnType<typeof usePrismicProductImagesQuery>;
export type PrismicProductImagesQueryLazyQueryHookResult = ReturnType<typeof usePrismicProductImagesQueryLazyQuery>;
export type PrismicProductImagesQueryQueryResult = Apollo.QueryResult<Types.PrismicProductImagesQuery, Types.PrismicProductImagesQueryVariables>;
export const PrismicProductPageQueryDocument = gql`
    query PrismicProductPageQuery($productUid: String!, $lang: String = "en-us") {
  product(uid: $productUid, lang: $lang) {
    attributes {
      attribute_slug
      attribute_content {
        ...ProductAttributesFragment
      }
    }
    media {
      ...ProductMediaFragment
    }
    body {
      ...ProductBodyFragment
    }
    _meta {
      ...MetaDetailsFragment
    }
  }
}
    ${ProductAttributesFragmentDoc}
${ProductMediaFragmentDoc}
${ProductBodyFragmentDoc}
${MetaDetailsFragmentDoc}`;

/**
 * __usePrismicProductPageQuery__
 *
 * To run a query within a React component, call `usePrismicProductPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrismicProductPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrismicProductPageQuery({
 *   variables: {
 *      productUid: // value for 'productUid'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function usePrismicProductPageQuery(baseOptions: Apollo.QueryHookOptions<Types.PrismicProductPageQuery, Types.PrismicProductPageQueryVariables>) {
        const { locale } = useRouter();
        const prismicOptions = {
          client: prismicApolloClient,
        }
        const options = {...defaultOptions, ...prismicOptions, ...baseOptions}
        return Apollo.useQuery<Types.PrismicProductPageQuery, Types.PrismicProductPageQueryVariables>(PrismicProductPageQueryDocument, {
            ...options,
            fetchPolicy: 'cache-and-network',
            variables: {
              ...options?.variables,
              ...localeToPrismicQuery(locale),
            },
          });
      }
export function usePrismicProductPageQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PrismicProductPageQuery, Types.PrismicProductPageQueryVariables>) {
          const { locale } = useRouter();
          const prismicOptions = {
            client: prismicApolloClient,
          }
          const options = {...defaultOptions, ...prismicOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PrismicProductPageQuery, Types.PrismicProductPageQueryVariables>(PrismicProductPageQueryDocument, {
              ...options,
              fetchPolicy: 'cache-and-network',
              variables: {
                ...options?.variables,
                ...localeToPrismicQuery(locale),
              },
            });
        }
export type PrismicProductPageQueryHookResult = ReturnType<typeof usePrismicProductPageQuery>;
export type PrismicProductPageQueryLazyQueryHookResult = ReturnType<typeof usePrismicProductPageQueryLazyQuery>;
export type PrismicProductPageQueryQueryResult = Apollo.QueryResult<Types.PrismicProductPageQuery, Types.PrismicProductPageQueryVariables>;
export const PrismicProductQueryDocument = gql`
    query PrismicProductQuery($uid: String!, $lang: String = "en-us") {
  product(uid: $uid, lang: $lang) {
    body {
      ...ProductBodyInspirationFragment
    }
    _meta {
      ...MetaDetailsFragment
    }
  }
}
    ${ProductBodyInspirationFragmentDoc}
${MetaDetailsFragmentDoc}`;

/**
 * __usePrismicProductQuery__
 *
 * To run a query within a React component, call `usePrismicProductQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrismicProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrismicProductQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function usePrismicProductQuery(baseOptions: Apollo.QueryHookOptions<Types.PrismicProductQuery, Types.PrismicProductQueryVariables>) {
        const { locale } = useRouter();
        const prismicOptions = {
          client: prismicApolloClient,
        }
        const options = {...defaultOptions, ...prismicOptions, ...baseOptions}
        return Apollo.useQuery<Types.PrismicProductQuery, Types.PrismicProductQueryVariables>(PrismicProductQueryDocument, {
            ...options,
            fetchPolicy: 'cache-and-network',
            variables: {
              ...options?.variables,
              ...localeToPrismicQuery(locale),
            },
          });
      }
export function usePrismicProductQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PrismicProductQuery, Types.PrismicProductQueryVariables>) {
          const { locale } = useRouter();
          const prismicOptions = {
            client: prismicApolloClient,
          }
          const options = {...defaultOptions, ...prismicOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PrismicProductQuery, Types.PrismicProductQueryVariables>(PrismicProductQueryDocument, {
              ...options,
              fetchPolicy: 'cache-and-network',
              variables: {
                ...options?.variables,
                ...localeToPrismicQuery(locale),
              },
            });
        }
export type PrismicProductQueryHookResult = ReturnType<typeof usePrismicProductQuery>;
export type PrismicProductQueryLazyQueryHookResult = ReturnType<typeof usePrismicProductQueryLazyQuery>;
export type PrismicProductQueryQueryResult = Apollo.QueryResult<Types.PrismicProductQuery, Types.PrismicProductQueryVariables>;
export const PrismicProjectFilterQueryDocument = gql`
    query PrismicProjectFilterQuery($lang: String = "en-us") {
  allProjectcategorys(lang: $lang) {
    edges {
      node {
        ...ProjectCategoryFragment
      }
    }
  }
  allProjecttags(lang: $lang) {
    edges {
      node {
        ...ProjectTagFragment
      }
    }
  }
}
    ${ProjectCategoryFragmentDoc}
${ProjectTagFragmentDoc}`;

/**
 * __usePrismicProjectFilterQuery__
 *
 * To run a query within a React component, call `usePrismicProjectFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrismicProjectFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrismicProjectFilterQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function usePrismicProjectFilterQuery(baseOptions?: Apollo.QueryHookOptions<Types.PrismicProjectFilterQuery, Types.PrismicProjectFilterQueryVariables>) {
        const { locale } = useRouter();
        const prismicOptions = {
          client: prismicApolloClient,
        }
        const options = {...defaultOptions, ...prismicOptions, ...baseOptions}
        return Apollo.useQuery<Types.PrismicProjectFilterQuery, Types.PrismicProjectFilterQueryVariables>(PrismicProjectFilterQueryDocument, {
            ...options,
            fetchPolicy: 'cache-and-network',
            variables: {
              ...options?.variables,
              ...localeToPrismicQuery(locale),
            },
          });
      }
export function usePrismicProjectFilterQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PrismicProjectFilterQuery, Types.PrismicProjectFilterQueryVariables>) {
          const { locale } = useRouter();
          const prismicOptions = {
            client: prismicApolloClient,
          }
          const options = {...defaultOptions, ...prismicOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PrismicProjectFilterQuery, Types.PrismicProjectFilterQueryVariables>(PrismicProjectFilterQueryDocument, {
              ...options,
              fetchPolicy: 'cache-and-network',
              variables: {
                ...options?.variables,
                ...localeToPrismicQuery(locale),
              },
            });
        }
export type PrismicProjectFilterQueryHookResult = ReturnType<typeof usePrismicProjectFilterQuery>;
export type PrismicProjectFilterQueryLazyQueryHookResult = ReturnType<typeof usePrismicProjectFilterQueryLazyQuery>;
export type PrismicProjectFilterQueryQueryResult = Apollo.QueryResult<Types.PrismicProjectFilterQuery, Types.PrismicProjectFilterQueryVariables>;
export const PrismicProjectPathsDocument = gql`
    query prismicProjectPaths($lang: String) {
  allProjects(lang: $lang, first: 1000, sortBy: sort_order_DESC) {
    edges {
      node {
        _meta {
          id
          uid
          lang
        }
      }
    }
  }
}
    `;

/**
 * __usePrismicProjectPathsQuery__
 *
 * To run a query within a React component, call `usePrismicProjectPathsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrismicProjectPathsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrismicProjectPathsQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function usePrismicProjectPathsQuery(baseOptions?: Apollo.QueryHookOptions<Types.PrismicProjectPathsQuery, Types.PrismicProjectPathsQueryVariables>) {
        const { locale } = useRouter();
        const prismicOptions = {
          client: prismicApolloClient,
        }
        const options = {...defaultOptions, ...prismicOptions, ...baseOptions}
        return Apollo.useQuery<Types.PrismicProjectPathsQuery, Types.PrismicProjectPathsQueryVariables>(PrismicProjectPathsDocument, {
            ...options,
            fetchPolicy: 'cache-and-network',
            variables: {
              ...options?.variables,
              ...localeToPrismicQuery(locale),
            },
          });
      }
export function usePrismicProjectPathsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PrismicProjectPathsQuery, Types.PrismicProjectPathsQueryVariables>) {
          const { locale } = useRouter();
          const prismicOptions = {
            client: prismicApolloClient,
          }
          const options = {...defaultOptions, ...prismicOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PrismicProjectPathsQuery, Types.PrismicProjectPathsQueryVariables>(PrismicProjectPathsDocument, {
              ...options,
              fetchPolicy: 'cache-and-network',
              variables: {
                ...options?.variables,
                ...localeToPrismicQuery(locale),
              },
            });
        }
export type PrismicProjectPathsQueryHookResult = ReturnType<typeof usePrismicProjectPathsQuery>;
export type PrismicProjectPathsLazyQueryHookResult = ReturnType<typeof usePrismicProjectPathsLazyQuery>;
export type PrismicProjectPathsQueryResult = Apollo.QueryResult<Types.PrismicProjectPathsQuery, Types.PrismicProjectPathsQueryVariables>;
export const PrismicProjectQueryDocument = gql`
    query PrismicProjectQuery($uid: String!, $lang: String = "en-us") {
  project(uid: $uid, lang: $lang) {
    ...ProjectDetailsFragment
    _meta {
      ...MetaDetailsFragment
    }
    body {
      ...ProjectBodyRelatedProductsFragment
      ...ProjectBodyLinkedProductsFragment
    }
  }
}
    ${ProjectDetailsFragmentDoc}
${MetaDetailsFragmentDoc}
${ProjectBodyRelatedProductsFragmentDoc}
${ProjectBodyLinkedProductsFragmentDoc}`;

/**
 * __usePrismicProjectQuery__
 *
 * To run a query within a React component, call `usePrismicProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrismicProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrismicProjectQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function usePrismicProjectQuery(baseOptions: Apollo.QueryHookOptions<Types.PrismicProjectQuery, Types.PrismicProjectQueryVariables>) {
        const { locale } = useRouter();
        const prismicOptions = {
          client: prismicApolloClient,
        }
        const options = {...defaultOptions, ...prismicOptions, ...baseOptions}
        return Apollo.useQuery<Types.PrismicProjectQuery, Types.PrismicProjectQueryVariables>(PrismicProjectQueryDocument, {
            ...options,
            fetchPolicy: 'cache-and-network',
            variables: {
              ...options?.variables,
              ...localeToPrismicQuery(locale),
            },
          });
      }
export function usePrismicProjectQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PrismicProjectQuery, Types.PrismicProjectQueryVariables>) {
          const { locale } = useRouter();
          const prismicOptions = {
            client: prismicApolloClient,
          }
          const options = {...defaultOptions, ...prismicOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PrismicProjectQuery, Types.PrismicProjectQueryVariables>(PrismicProjectQueryDocument, {
              ...options,
              fetchPolicy: 'cache-and-network',
              variables: {
                ...options?.variables,
                ...localeToPrismicQuery(locale),
              },
            });
        }
export type PrismicProjectQueryHookResult = ReturnType<typeof usePrismicProjectQuery>;
export type PrismicProjectQueryLazyQueryHookResult = ReturnType<typeof usePrismicProjectQueryLazyQuery>;
export type PrismicProjectQueryQueryResult = Apollo.QueryResult<Types.PrismicProjectQuery, Types.PrismicProjectQueryVariables>;
export const PrismicRealisationQueryDocument = gql`
    query PrismicRealisationQuery($lang: String = "en-us") {
  realisation(uid: "realisation", lang: $lang) {
    meta_title
    meta_description
    meta_image
    _meta {
      ...MetaDetailsFragment
    }
  }
}
    ${MetaDetailsFragmentDoc}`;

/**
 * __usePrismicRealisationQuery__
 *
 * To run a query within a React component, call `usePrismicRealisationQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrismicRealisationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrismicRealisationQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function usePrismicRealisationQuery(baseOptions?: Apollo.QueryHookOptions<Types.PrismicRealisationQuery, Types.PrismicRealisationQueryVariables>) {
        const { locale } = useRouter();
        const prismicOptions = {
          client: prismicApolloClient,
        }
        const options = {...defaultOptions, ...prismicOptions, ...baseOptions}
        return Apollo.useQuery<Types.PrismicRealisationQuery, Types.PrismicRealisationQueryVariables>(PrismicRealisationQueryDocument, {
            ...options,
            fetchPolicy: 'cache-and-network',
            variables: {
              ...options?.variables,
              ...localeToPrismicQuery(locale),
            },
          });
      }
export function usePrismicRealisationQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PrismicRealisationQuery, Types.PrismicRealisationQueryVariables>) {
          const { locale } = useRouter();
          const prismicOptions = {
            client: prismicApolloClient,
          }
          const options = {...defaultOptions, ...prismicOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PrismicRealisationQuery, Types.PrismicRealisationQueryVariables>(PrismicRealisationQueryDocument, {
              ...options,
              fetchPolicy: 'cache-and-network',
              variables: {
                ...options?.variables,
                ...localeToPrismicQuery(locale),
              },
            });
        }
export type PrismicRealisationQueryHookResult = ReturnType<typeof usePrismicRealisationQuery>;
export type PrismicRealisationQueryLazyQueryHookResult = ReturnType<typeof usePrismicRealisationQueryLazyQuery>;
export type PrismicRealisationQueryQueryResult = Apollo.QueryResult<Types.PrismicRealisationQuery, Types.PrismicRealisationQueryVariables>;
export const PrismicReviewsQueryDocument = gql`
    query PrismicReviewsQuery($lang: String = "en-us") {
  reviews(uid: "reviews", lang: $lang) {
    meta_title
    meta_description
    meta_image
    _meta {
      ...MetaDetailsFragment
    }
  }
}
    ${MetaDetailsFragmentDoc}`;

/**
 * __usePrismicReviewsQuery__
 *
 * To run a query within a React component, call `usePrismicReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrismicReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrismicReviewsQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function usePrismicReviewsQuery(baseOptions?: Apollo.QueryHookOptions<Types.PrismicReviewsQuery, Types.PrismicReviewsQueryVariables>) {
        const { locale } = useRouter();
        const prismicOptions = {
          client: prismicApolloClient,
        }
        const options = {...defaultOptions, ...prismicOptions, ...baseOptions}
        return Apollo.useQuery<Types.PrismicReviewsQuery, Types.PrismicReviewsQueryVariables>(PrismicReviewsQueryDocument, {
            ...options,
            fetchPolicy: 'cache-and-network',
            variables: {
              ...options?.variables,
              ...localeToPrismicQuery(locale),
            },
          });
      }
export function usePrismicReviewsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PrismicReviewsQuery, Types.PrismicReviewsQueryVariables>) {
          const { locale } = useRouter();
          const prismicOptions = {
            client: prismicApolloClient,
          }
          const options = {...defaultOptions, ...prismicOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PrismicReviewsQuery, Types.PrismicReviewsQueryVariables>(PrismicReviewsQueryDocument, {
              ...options,
              fetchPolicy: 'cache-and-network',
              variables: {
                ...options?.variables,
                ...localeToPrismicQuery(locale),
              },
            });
        }
export type PrismicReviewsQueryHookResult = ReturnType<typeof usePrismicReviewsQuery>;
export type PrismicReviewsQueryLazyQueryHookResult = ReturnType<typeof usePrismicReviewsQueryLazyQuery>;
export type PrismicReviewsQueryQueryResult = Apollo.QueryResult<Types.PrismicReviewsQuery, Types.PrismicReviewsQueryVariables>;
export const PrismicWishlistPageQueryDocument = gql`
    query PrismicWishlistPageQuery($lang: String = "en-us") {
  wishlist(uid: "wishlist", lang: $lang) {
    body {
      ...WishlistBodyColorSampleFragment
    }
    _meta {
      ...MetaDetailsFragment
    }
  }
}
    ${WishlistBodyColorSampleFragmentDoc}
${MetaDetailsFragmentDoc}`;

/**
 * __usePrismicWishlistPageQuery__
 *
 * To run a query within a React component, call `usePrismicWishlistPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrismicWishlistPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrismicWishlistPageQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function usePrismicWishlistPageQuery(baseOptions?: Apollo.QueryHookOptions<Types.PrismicWishlistPageQuery, Types.PrismicWishlistPageQueryVariables>) {
        const { locale } = useRouter();
        const prismicOptions = {
          client: prismicApolloClient,
        }
        const options = {...defaultOptions, ...prismicOptions, ...baseOptions}
        return Apollo.useQuery<Types.PrismicWishlistPageQuery, Types.PrismicWishlistPageQueryVariables>(PrismicWishlistPageQueryDocument, {
            ...options,
            fetchPolicy: 'cache-and-network',
            variables: {
              ...options?.variables,
              ...localeToPrismicQuery(locale),
            },
          });
      }
export function usePrismicWishlistPageQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PrismicWishlistPageQuery, Types.PrismicWishlistPageQueryVariables>) {
          const { locale } = useRouter();
          const prismicOptions = {
            client: prismicApolloClient,
          }
          const options = {...defaultOptions, ...prismicOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PrismicWishlistPageQuery, Types.PrismicWishlistPageQueryVariables>(PrismicWishlistPageQueryDocument, {
              ...options,
              fetchPolicy: 'cache-and-network',
              variables: {
                ...options?.variables,
                ...localeToPrismicQuery(locale),
              },
            });
        }
export type PrismicWishlistPageQueryHookResult = ReturnType<typeof usePrismicWishlistPageQuery>;
export type PrismicWishlistPageQueryLazyQueryHookResult = ReturnType<typeof usePrismicWishlistPageQueryLazyQuery>;
export type PrismicWishlistPageQueryQueryResult = Apollo.QueryResult<Types.PrismicWishlistPageQuery, Types.PrismicWishlistPageQueryVariables>;
export type ArchitectKeySpecifier = ('_linkType' | '_meta' | 'body' | 'btn_text' | 'description' | 'meta_description' | 'meta_image' | 'meta_title' | 'title' | ArchitectKeySpecifier)[];
export type ArchitectFieldPolicy = {
	_linkType?: FieldPolicy<any> | FieldReadFunction<any>,
	_meta?: FieldPolicy<any> | FieldReadFunction<any>,
	body?: FieldPolicy<any> | FieldReadFunction<any>,
	btn_text?: FieldPolicy<any> | FieldReadFunction<any>,
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	meta_description?: FieldPolicy<any> | FieldReadFunction<any>,
	meta_image?: FieldPolicy<any> | FieldReadFunction<any>,
	meta_title?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ArchitectBodyArchitectreviewsKeySpecifier = ('label' | 'type' | ArchitectBodyArchitectreviewsKeySpecifier)[];
export type ArchitectBodyArchitectreviewsFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ArchitectBodyBenefitsprogramKeySpecifier = ('fields' | 'label' | 'primary' | 'type' | ArchitectBodyBenefitsprogramKeySpecifier)[];
export type ArchitectBodyBenefitsprogramFieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ArchitectBodyBenefitsprogramFieldsKeySpecifier = ('description' | 'image' | 'title' | ArchitectBodyBenefitsprogramFieldsKeySpecifier)[];
export type ArchitectBodyBenefitsprogramFieldsFieldPolicy = {
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	image?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ArchitectBodyBenefitsprogramPrimaryKeySpecifier = ('btn_text' | 'title' | ArchitectBodyBenefitsprogramPrimaryKeySpecifier)[];
export type ArchitectBodyBenefitsprogramPrimaryFieldPolicy = {
	btn_text?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ArchitectBodyConsulttoolboxKeySpecifier = ('fields' | 'label' | 'primary' | 'type' | ArchitectBodyConsulttoolboxKeySpecifier)[];
export type ArchitectBodyConsulttoolboxFieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ArchitectBodyConsulttoolboxFieldsKeySpecifier = ('icon' | 'link' | 'title1' | ArchitectBodyConsulttoolboxFieldsKeySpecifier)[];
export type ArchitectBodyConsulttoolboxFieldsFieldPolicy = {
	icon?: FieldPolicy<any> | FieldReadFunction<any>,
	link?: FieldPolicy<any> | FieldReadFunction<any>,
	title1?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ArchitectBodyConsulttoolboxPrimaryKeySpecifier = ('btn_text' | 'description' | 'title' | ArchitectBodyConsulttoolboxPrimaryKeySpecifier)[];
export type ArchitectBodyConsulttoolboxPrimaryFieldPolicy = {
	btn_text?: FieldPolicy<any> | FieldReadFunction<any>,
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ArchitectBodyFranchisecontactKeySpecifier = ('fields' | 'label' | 'type' | ArchitectBodyFranchisecontactKeySpecifier)[];
export type ArchitectBodyFranchisecontactFieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ArchitectBodyFranchisecontactFieldsKeySpecifier = ('btn_text' | 'title' | ArchitectBodyFranchisecontactFieldsKeySpecifier)[];
export type ArchitectBodyFranchisecontactFieldsFieldPolicy = {
	btn_text?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ArchitectBodyInspirationKeySpecifier = ('fields' | 'label' | 'type' | ArchitectBodyInspirationKeySpecifier)[];
export type ArchitectBodyInspirationFieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ArchitectBodyInspirationFieldsKeySpecifier = ('image' | ArchitectBodyInspirationFieldsKeySpecifier)[];
export type ArchitectBodyInspirationFieldsFieldPolicy = {
	image?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ArchitectBodyInstagram_postsKeySpecifier = ('fields' | 'label' | 'primary' | 'type' | ArchitectBodyInstagram_postsKeySpecifier)[];
export type ArchitectBodyInstagram_postsFieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ArchitectBodyInstagram_postsFieldsKeySpecifier = ('instagram_link' | ArchitectBodyInstagram_postsFieldsKeySpecifier)[];
export type ArchitectBodyInstagram_postsFieldsFieldPolicy = {
	instagram_link?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ArchitectBodyInstagram_postsPrimaryKeySpecifier = ('text' | 'title_2' | 'user_name' | ArchitectBodyInstagram_postsPrimaryKeySpecifier)[];
export type ArchitectBodyInstagram_postsPrimaryFieldPolicy = {
	text?: FieldPolicy<any> | FieldReadFunction<any>,
	title_2?: FieldPolicy<any> | FieldReadFunction<any>,
	user_name?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ArchitectBodyShowroomKeySpecifier = ('fields' | 'label' | 'primary' | 'type' | ArchitectBodyShowroomKeySpecifier)[];
export type ArchitectBodyShowroomFieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ArchitectBodyShowroomFieldsKeySpecifier = ('address' | 'is_partner' | 'location' | 'name' | 'web' | ArchitectBodyShowroomFieldsKeySpecifier)[];
export type ArchitectBodyShowroomFieldsFieldPolicy = {
	address?: FieldPolicy<any> | FieldReadFunction<any>,
	is_partner?: FieldPolicy<any> | FieldReadFunction<any>,
	location?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	web?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ArchitectBodyShowroomPrimaryKeySpecifier = ('list_title' | 'partner_list_title' | 'title' | ArchitectBodyShowroomPrimaryKeySpecifier)[];
export type ArchitectBodyShowroomPrimaryFieldPolicy = {
	list_title?: FieldPolicy<any> | FieldReadFunction<any>,
	partner_list_title?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ArchitectConnectionConnectionKeySpecifier = ('edges' | 'pageInfo' | 'totalCount' | ArchitectConnectionConnectionKeySpecifier)[];
export type ArchitectConnectionConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ArchitectConnectionEdgeKeySpecifier = ('cursor' | 'node' | ArchitectConnectionEdgeKeySpecifier)[];
export type ArchitectConnectionEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type BannersKeySpecifier = ('_linkType' | '_meta' | 'body' | BannersKeySpecifier)[];
export type BannersFieldPolicy = {
	_linkType?: FieldPolicy<any> | FieldReadFunction<any>,
	_meta?: FieldPolicy<any> | FieldReadFunction<any>,
	body?: FieldPolicy<any> | FieldReadFunction<any>
};
export type BannersBodyBenefitKeySpecifier = ('label' | 'primary' | 'type' | BannersBodyBenefitKeySpecifier)[];
export type BannersBodyBenefitFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type BannersBodyBenefitPrimaryKeySpecifier = ('image' | 'link' | 'link_label' | 'offset' | 'step' | 'text' | 'title' | BannersBodyBenefitPrimaryKeySpecifier)[];
export type BannersBodyBenefitPrimaryFieldPolicy = {
	image?: FieldPolicy<any> | FieldReadFunction<any>,
	link?: FieldPolicy<any> | FieldReadFunction<any>,
	link_label?: FieldPolicy<any> | FieldReadFunction<any>,
	offset?: FieldPolicy<any> | FieldReadFunction<any>,
	step?: FieldPolicy<any> | FieldReadFunction<any>,
	text?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type BannersConnectionConnectionKeySpecifier = ('edges' | 'pageInfo' | 'totalCount' | BannersConnectionConnectionKeySpecifier)[];
export type BannersConnectionConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type BannersConnectionEdgeKeySpecifier = ('cursor' | 'node' | BannersConnectionEdgeKeySpecifier)[];
export type BannersConnectionEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_categoryKeySpecifier = ('_linkType' | '_meta' | 'name' | Blog_categoryKeySpecifier)[];
export type Blog_categoryFieldPolicy = {
	_linkType?: FieldPolicy<any> | FieldReadFunction<any>,
	_meta?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_categoryConnectionConnectionKeySpecifier = ('edges' | 'pageInfo' | 'totalCount' | Blog_categoryConnectionConnectionKeySpecifier)[];
export type Blog_categoryConnectionConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_categoryConnectionEdgeKeySpecifier = ('cursor' | 'node' | Blog_categoryConnectionEdgeKeySpecifier)[];
export type Blog_categoryConnectionEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_contentKeySpecifier = ('_linkType' | '_meta' | 'blog_content_size' | Blog_contentKeySpecifier)[];
export type Blog_contentFieldPolicy = {
	_linkType?: FieldPolicy<any> | FieldReadFunction<any>,
	_meta?: FieldPolicy<any> | FieldReadFunction<any>,
	blog_content_size?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_contentConnectionConnectionKeySpecifier = ('edges' | 'pageInfo' | 'totalCount' | Blog_contentConnectionConnectionKeySpecifier)[];
export type Blog_contentConnectionConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_contentConnectionEdgeKeySpecifier = ('cursor' | 'node' | Blog_contentConnectionEdgeKeySpecifier)[];
export type Blog_contentConnectionEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_homeKeySpecifier = ('_linkType' | '_meta' | 'body1' | 'canonical_url' | 'meta_description' | 'meta_image' | 'meta_title' | 'showitemsperpage' | 'title' | Blog_homeKeySpecifier)[];
export type Blog_homeFieldPolicy = {
	_linkType?: FieldPolicy<any> | FieldReadFunction<any>,
	_meta?: FieldPolicy<any> | FieldReadFunction<any>,
	body1?: FieldPolicy<any> | FieldReadFunction<any>,
	canonical_url?: FieldPolicy<any> | FieldReadFunction<any>,
	meta_description?: FieldPolicy<any> | FieldReadFunction<any>,
	meta_image?: FieldPolicy<any> | FieldReadFunction<any>,
	meta_title?: FieldPolicy<any> | FieldReadFunction<any>,
	showitemsperpage?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_homeBody1IconbannerKeySpecifier = ('fields' | 'label' | 'primary' | 'type' | Blog_homeBody1IconbannerKeySpecifier)[];
export type Blog_homeBody1IconbannerFieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_homeBody1IconbannerFieldsKeySpecifier = ('img' | 'link' | 'title' | Blog_homeBody1IconbannerFieldsKeySpecifier)[];
export type Blog_homeBody1IconbannerFieldsFieldPolicy = {
	img?: FieldPolicy<any> | FieldReadFunction<any>,
	link?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_homeBody1IconbannerPrimaryKeySpecifier = ('title' | Blog_homeBody1IconbannerPrimaryKeySpecifier)[];
export type Blog_homeBody1IconbannerPrimaryFieldPolicy = {
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_homeConnectionConnectionKeySpecifier = ('edges' | 'pageInfo' | 'totalCount' | Blog_homeConnectionConnectionKeySpecifier)[];
export type Blog_homeConnectionConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_homeConnectionEdgeKeySpecifier = ('cursor' | 'node' | Blog_homeConnectionEdgeKeySpecifier)[];
export type Blog_homeConnectionEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_postKeySpecifier = ('_linkType' | '_meta' | 'also_like' | 'body' | 'body1' | 'canonical_url' | 'category' | 'hide_for_blog_index_nav' | 'image' | 'isfeatured' | 'meta_description' | 'meta_image' | 'meta_title' | 'preview_description' | 'tags' | 'title' | 'you_may_also_like' | Blog_postKeySpecifier)[];
export type Blog_postFieldPolicy = {
	_linkType?: FieldPolicy<any> | FieldReadFunction<any>,
	_meta?: FieldPolicy<any> | FieldReadFunction<any>,
	also_like?: FieldPolicy<any> | FieldReadFunction<any>,
	body?: FieldPolicy<any> | FieldReadFunction<any>,
	body1?: FieldPolicy<any> | FieldReadFunction<any>,
	canonical_url?: FieldPolicy<any> | FieldReadFunction<any>,
	category?: FieldPolicy<any> | FieldReadFunction<any>,
	hide_for_blog_index_nav?: FieldPolicy<any> | FieldReadFunction<any>,
	image?: FieldPolicy<any> | FieldReadFunction<any>,
	isfeatured?: FieldPolicy<any> | FieldReadFunction<any>,
	meta_description?: FieldPolicy<any> | FieldReadFunction<any>,
	meta_image?: FieldPolicy<any> | FieldReadFunction<any>,
	meta_title?: FieldPolicy<any> | FieldReadFunction<any>,
	preview_description?: FieldPolicy<any> | FieldReadFunction<any>,
	tags?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>,
	you_may_also_like?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_postBody1Facebook__open_graph_KeySpecifier = ('label' | 'type' | Blog_postBody1Facebook__open_graph_KeySpecifier)[];
export type Blog_postBody1Facebook__open_graph_FieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_postBody1Twitter_cardKeySpecifier = ('label' | 'primary' | 'type' | Blog_postBody1Twitter_cardKeySpecifier)[];
export type Blog_postBody1Twitter_cardFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_postBody1Twitter_cardPrimaryKeySpecifier = ('card_description' | 'card_image' | 'card_title' | Blog_postBody1Twitter_cardPrimaryKeySpecifier)[];
export type Blog_postBody1Twitter_cardPrimaryFieldPolicy = {
	card_description?: FieldPolicy<any> | FieldReadFunction<any>,
	card_image?: FieldPolicy<any> | FieldReadFunction<any>,
	card_title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_postBodyAccordeonKeySpecifier = ('fields' | 'label' | 'type' | Blog_postBodyAccordeonKeySpecifier)[];
export type Blog_postBodyAccordeonFieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_postBodyAccordeonFieldsKeySpecifier = ('rich_text' | 'title' | Blog_postBodyAccordeonFieldsKeySpecifier)[];
export type Blog_postBodyAccordeonFieldsFieldPolicy = {
	rich_text?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_postBodyContent_photo_photoKeySpecifier = ('fields' | 'label' | 'type' | Blog_postBodyContent_photo_photoKeySpecifier)[];
export type Blog_postBodyContent_photo_photoFieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_postBodyContent_photo_photoFieldsKeySpecifier = ('content_photo' | 'photo_link' | Blog_postBodyContent_photo_photoFieldsKeySpecifier)[];
export type Blog_postBodyContent_photo_photoFieldsFieldPolicy = {
	content_photo?: FieldPolicy<any> | FieldReadFunction<any>,
	photo_link?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_postBodyContent_photo_photo_50KeySpecifier = ('fields' | 'label' | 'type' | Blog_postBodyContent_photo_photo_50KeySpecifier)[];
export type Blog_postBodyContent_photo_photo_50FieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_postBodyContent_photo_photo_50FieldsKeySpecifier = ('content_photo_left' | 'content_photo_right' | 'photo_left_link' | 'photo_right_link' | Blog_postBodyContent_photo_photo_50FieldsKeySpecifier)[];
export type Blog_postBodyContent_photo_photo_50FieldsFieldPolicy = {
	content_photo_left?: FieldPolicy<any> | FieldReadFunction<any>,
	content_photo_right?: FieldPolicy<any> | FieldReadFunction<any>,
	photo_left_link?: FieldPolicy<any> | FieldReadFunction<any>,
	photo_right_link?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_postBodyContent_photo_textKeySpecifier = ('label' | 'primary' | 'type' | Blog_postBodyContent_photo_textKeySpecifier)[];
export type Blog_postBodyContent_photo_textFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_postBodyContent_photo_textPrimaryKeySpecifier = ('content_photo' | 'content_text' | 'photo_link' | Blog_postBodyContent_photo_textPrimaryKeySpecifier)[];
export type Blog_postBodyContent_photo_textPrimaryFieldPolicy = {
	content_photo?: FieldPolicy<any> | FieldReadFunction<any>,
	content_text?: FieldPolicy<any> | FieldReadFunction<any>,
	photo_link?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_postBodyContent_photo_text_30_60KeySpecifier = ('fields' | 'label' | 'type' | Blog_postBodyContent_photo_text_30_60KeySpecifier)[];
export type Blog_postBodyContent_photo_text_30_60FieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_postBodyContent_photo_text_30_60FieldsKeySpecifier = ('content_photo' | 'content_text' | 'photo_link' | Blog_postBodyContent_photo_text_30_60FieldsKeySpecifier)[];
export type Blog_postBodyContent_photo_text_30_60FieldsFieldPolicy = {
	content_photo?: FieldPolicy<any> | FieldReadFunction<any>,
	content_text?: FieldPolicy<any> | FieldReadFunction<any>,
	photo_link?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_postBodyContent_text_photo_60_30KeySpecifier = ('fields' | 'label' | 'type' | Blog_postBodyContent_text_photo_60_30KeySpecifier)[];
export type Blog_postBodyContent_text_photo_60_30FieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_postBodyContent_text_photo_60_30FieldsKeySpecifier = ('content_photo' | 'content_text' | 'photo_link' | Blog_postBodyContent_text_photo_60_30FieldsKeySpecifier)[];
export type Blog_postBodyContent_text_photo_60_30FieldsFieldPolicy = {
	content_photo?: FieldPolicy<any> | FieldReadFunction<any>,
	content_text?: FieldPolicy<any> | FieldReadFunction<any>,
	photo_link?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_postBodyContent_text_text_50KeySpecifier = ('fields' | 'label' | 'type' | Blog_postBodyContent_text_text_50KeySpecifier)[];
export type Blog_postBodyContent_text_text_50FieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_postBodyContent_text_text_50FieldsKeySpecifier = ('content_text_left' | 'content_text_right' | Blog_postBodyContent_text_text_50FieldsKeySpecifier)[];
export type Blog_postBodyContent_text_text_50FieldsFieldPolicy = {
	content_text_left?: FieldPolicy<any> | FieldReadFunction<any>,
	content_text_right?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_postBodyDownloadfilesKeySpecifier = ('fields' | 'label' | 'type' | Blog_postBodyDownloadfilesKeySpecifier)[];
export type Blog_postBodyDownloadfilesFieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_postBodyDownloadfilesFieldsKeySpecifier = ('filecategory' | 'filedescription' | 'filelink' | 'filetitle' | Blog_postBodyDownloadfilesFieldsKeySpecifier)[];
export type Blog_postBodyDownloadfilesFieldsFieldPolicy = {
	filecategory?: FieldPolicy<any> | FieldReadFunction<any>,
	filedescription?: FieldPolicy<any> | FieldReadFunction<any>,
	filelink?: FieldPolicy<any> | FieldReadFunction<any>,
	filetitle?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_postBodyExcerptKeySpecifier = ('label' | 'primary' | 'type' | Blog_postBodyExcerptKeySpecifier)[];
export type Blog_postBodyExcerptFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_postBodyExcerptPrimaryKeySpecifier = ('text' | Blog_postBodyExcerptPrimaryKeySpecifier)[];
export type Blog_postBodyExcerptPrimaryFieldPolicy = {
	text?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_postBodyFormembdedKeySpecifier = ('label' | 'primary' | 'type' | Blog_postBodyFormembdedKeySpecifier)[];
export type Blog_postBodyFormembdedFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_postBodyFormembdedPrimaryKeySpecifier = ('embded_link' | Blog_postBodyFormembdedPrimaryKeySpecifier)[];
export type Blog_postBodyFormembdedPrimaryFieldPolicy = {
	embded_link?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_postBodyHeroimgKeySpecifier = ('label' | 'primary' | 'type' | Blog_postBodyHeroimgKeySpecifier)[];
export type Blog_postBodyHeroimgFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_postBodyHeroimgPrimaryKeySpecifier = ('hero_image_link' | 'img' | Blog_postBodyHeroimgPrimaryKeySpecifier)[];
export type Blog_postBodyHeroimgPrimaryFieldPolicy = {
	hero_image_link?: FieldPolicy<any> | FieldReadFunction<any>,
	img?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_postBodyHtmlembdedKeySpecifier = ('label' | 'primary' | 'type' | Blog_postBodyHtmlembdedKeySpecifier)[];
export type Blog_postBodyHtmlembdedFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_postBodyHtmlembdedPrimaryKeySpecifier = ('html' | Blog_postBodyHtmlembdedPrimaryKeySpecifier)[];
export type Blog_postBodyHtmlembdedPrimaryFieldPolicy = {
	html?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_postBodyIconbannerKeySpecifier = ('fields' | 'label' | 'primary' | 'type' | Blog_postBodyIconbannerKeySpecifier)[];
export type Blog_postBodyIconbannerFieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_postBodyIconbannerFieldsKeySpecifier = ('img' | 'link' | 'title' | Blog_postBodyIconbannerFieldsKeySpecifier)[];
export type Blog_postBodyIconbannerFieldsFieldPolicy = {
	img?: FieldPolicy<any> | FieldReadFunction<any>,
	link?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_postBodyIconbannerPrimaryKeySpecifier = ('title' | Blog_postBodyIconbannerPrimaryKeySpecifier)[];
export type Blog_postBodyIconbannerPrimaryFieldPolicy = {
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_postBodyIconctaKeySpecifier = ('fields' | 'label' | 'type' | Blog_postBodyIconctaKeySpecifier)[];
export type Blog_postBodyIconctaFieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_postBodyIconctaFieldsKeySpecifier = ('img' | 'link' | 'title' | Blog_postBodyIconctaFieldsKeySpecifier)[];
export type Blog_postBodyIconctaFieldsFieldPolicy = {
	img?: FieldPolicy<any> | FieldReadFunction<any>,
	link?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_postBodyImagecardKeySpecifier = ('fields' | 'label' | 'primary' | 'type' | Blog_postBodyImagecardKeySpecifier)[];
export type Blog_postBodyImagecardFieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_postBodyImagecardFieldsKeySpecifier = ('card_btn_label' | 'card_btn_link' | 'card_centered' | 'card_content' | 'card_image' | 'card_title' | Blog_postBodyImagecardFieldsKeySpecifier)[];
export type Blog_postBodyImagecardFieldsFieldPolicy = {
	card_btn_label?: FieldPolicy<any> | FieldReadFunction<any>,
	card_btn_link?: FieldPolicy<any> | FieldReadFunction<any>,
	card_centered?: FieldPolicy<any> | FieldReadFunction<any>,
	card_content?: FieldPolicy<any> | FieldReadFunction<any>,
	card_image?: FieldPolicy<any> | FieldReadFunction<any>,
	card_title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_postBodyImagecardPrimaryKeySpecifier = ('header_title' | Blog_postBodyImagecardPrimaryKeySpecifier)[];
export type Blog_postBodyImagecardPrimaryFieldPolicy = {
	header_title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_postBodyImagectaKeySpecifier = ('label' | 'primary' | 'type' | Blog_postBodyImagectaKeySpecifier)[];
export type Blog_postBodyImagectaFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_postBodyImagectaPrimaryKeySpecifier = ('bgcolor' | 'button_label' | 'button_link' | 'content_photo' | 'content_text' | 'content_video' | Blog_postBodyImagectaPrimaryKeySpecifier)[];
export type Blog_postBodyImagectaPrimaryFieldPolicy = {
	bgcolor?: FieldPolicy<any> | FieldReadFunction<any>,
	button_label?: FieldPolicy<any> | FieldReadFunction<any>,
	button_link?: FieldPolicy<any> | FieldReadFunction<any>,
	content_photo?: FieldPolicy<any> | FieldReadFunction<any>,
	content_text?: FieldPolicy<any> | FieldReadFunction<any>,
	content_video?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_postBodyLinkbuttonKeySpecifier = ('label' | 'primary' | 'type' | Blog_postBodyLinkbuttonKeySpecifier)[];
export type Blog_postBodyLinkbuttonFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_postBodyLinkbuttonPrimaryKeySpecifier = ('button_label' | 'button_link' | Blog_postBodyLinkbuttonPrimaryKeySpecifier)[];
export type Blog_postBodyLinkbuttonPrimaryFieldPolicy = {
	button_label?: FieldPolicy<any> | FieldReadFunction<any>,
	button_link?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_postBodyMediasliderKeySpecifier = ('fields' | 'label' | 'type' | Blog_postBodyMediasliderKeySpecifier)[];
export type Blog_postBodyMediasliderFieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_postBodyMediasliderFieldsKeySpecifier = ('media' | 'media_type' | Blog_postBodyMediasliderFieldsKeySpecifier)[];
export type Blog_postBodyMediasliderFieldsFieldPolicy = {
	media?: FieldPolicy<any> | FieldReadFunction<any>,
	media_type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_postBodyOurpartnersKeySpecifier = ('fields' | 'label' | 'primary' | 'type' | Blog_postBodyOurpartnersKeySpecifier)[];
export type Blog_postBodyOurpartnersFieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_postBodyOurpartnersFieldsKeySpecifier = ('logo_image' | Blog_postBodyOurpartnersFieldsKeySpecifier)[];
export type Blog_postBodyOurpartnersFieldsFieldPolicy = {
	logo_image?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_postBodyOurpartnersPrimaryKeySpecifier = ('our_partners_title' | Blog_postBodyOurpartnersPrimaryKeySpecifier)[];
export type Blog_postBodyOurpartnersPrimaryFieldPolicy = {
	our_partners_title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_postBodyQuoteKeySpecifier = ('label' | 'primary' | 'type' | Blog_postBodyQuoteKeySpecifier)[];
export type Blog_postBodyQuoteFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_postBodyQuotePrimaryKeySpecifier = ('quote' | Blog_postBodyQuotePrimaryKeySpecifier)[];
export type Blog_postBodyQuotePrimaryFieldPolicy = {
	quote?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_postBodyRelatedproductsKeySpecifier = ('fields' | 'label' | 'primary' | 'type' | Blog_postBodyRelatedproductsKeySpecifier)[];
export type Blog_postBodyRelatedproductsFieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_postBodyRelatedproductsFieldsKeySpecifier = ('productid' | Blog_postBodyRelatedproductsFieldsKeySpecifier)[];
export type Blog_postBodyRelatedproductsFieldsFieldPolicy = {
	productid?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_postBodyRelatedproductsPrimaryKeySpecifier = ('slicetitle' | Blog_postBodyRelatedproductsPrimaryKeySpecifier)[];
export type Blog_postBodyRelatedproductsPrimaryFieldPolicy = {
	slicetitle?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_postBodyRichtextKeySpecifier = ('label' | 'primary' | 'type' | Blog_postBodyRichtextKeySpecifier)[];
export type Blog_postBodyRichtextFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_postBodyRichtextPrimaryKeySpecifier = ('classes' | 'rich_text' | Blog_postBodyRichtextPrimaryKeySpecifier)[];
export type Blog_postBodyRichtextPrimaryFieldPolicy = {
	classes?: FieldPolicy<any> | FieldReadFunction<any>,
	rich_text?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_postBodyTagimageKeySpecifier = ('fields' | 'label' | 'primary' | 'type' | Blog_postBodyTagimageKeySpecifier)[];
export type Blog_postBodyTagimageFieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_postBodyTagimageFieldsKeySpecifier = ('image_height' | 'image_width' | 'img' | 'imgmobile' | 'tag_image_link' | Blog_postBodyTagimageFieldsKeySpecifier)[];
export type Blog_postBodyTagimageFieldsFieldPolicy = {
	image_height?: FieldPolicy<any> | FieldReadFunction<any>,
	image_width?: FieldPolicy<any> | FieldReadFunction<any>,
	img?: FieldPolicy<any> | FieldReadFunction<any>,
	imgmobile?: FieldPolicy<any> | FieldReadFunction<any>,
	tag_image_link?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_postBodyTagimagePrimaryKeySpecifier = ('subtitle' | Blog_postBodyTagimagePrimaryKeySpecifier)[];
export type Blog_postBodyTagimagePrimaryFieldPolicy = {
	subtitle?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_postConnectionConnectionKeySpecifier = ('edges' | 'pageInfo' | 'totalCount' | Blog_postConnectionConnectionKeySpecifier)[];
export type Blog_postConnectionConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_postConnectionEdgeKeySpecifier = ('cursor' | 'node' | Blog_postConnectionEdgeKeySpecifier)[];
export type Blog_postConnectionEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_postTagsKeySpecifier = ('tag' | Blog_postTagsKeySpecifier)[];
export type Blog_postTagsFieldPolicy = {
	tag?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Blog_postYou_may_also_likeKeySpecifier = ('link_to_post' | Blog_postYou_may_also_likeKeySpecifier)[];
export type Blog_postYou_may_also_likeFieldPolicy = {
	link_to_post?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CartsliderKeySpecifier = ('_linkType' | '_meta' | 'body' | CartsliderKeySpecifier)[];
export type CartsliderFieldPolicy = {
	_linkType?: FieldPolicy<any> | FieldReadFunction<any>,
	_meta?: FieldPolicy<any> | FieldReadFunction<any>,
	body?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CartsliderBodySlidelistKeySpecifier = ('fields' | 'label' | 'type' | CartsliderBodySlidelistKeySpecifier)[];
export type CartsliderBodySlidelistFieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CartsliderBodySlidelistFieldsKeySpecifier = ('image' | 'link' | 'title' | CartsliderBodySlidelistFieldsKeySpecifier)[];
export type CartsliderBodySlidelistFieldsFieldPolicy = {
	image?: FieldPolicy<any> | FieldReadFunction<any>,
	link?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CartsliderConnectionConnectionKeySpecifier = ('edges' | 'pageInfo' | 'totalCount' | CartsliderConnectionConnectionKeySpecifier)[];
export type CartsliderConnectionConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CartsliderConnectionEdgeKeySpecifier = ('cursor' | 'node' | CartsliderConnectionEdgeKeySpecifier)[];
export type CartsliderConnectionEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CategoryKeySpecifier = ('_linkType' | '_meta' | 'banners' | 'display_name' | 'excerpt' | 'image' | 'index_settings' | 'items' | 'meta_description' | 'meta_image' | 'meta_title' | 'name' | 'share_on_facebook' | 'share_on_twitter' | 'social' | CategoryKeySpecifier)[];
export type CategoryFieldPolicy = {
	_linkType?: FieldPolicy<any> | FieldReadFunction<any>,
	_meta?: FieldPolicy<any> | FieldReadFunction<any>,
	banners?: FieldPolicy<any> | FieldReadFunction<any>,
	display_name?: FieldPolicy<any> | FieldReadFunction<any>,
	excerpt?: FieldPolicy<any> | FieldReadFunction<any>,
	image?: FieldPolicy<any> | FieldReadFunction<any>,
	index_settings?: FieldPolicy<any> | FieldReadFunction<any>,
	items?: FieldPolicy<any> | FieldReadFunction<any>,
	meta_description?: FieldPolicy<any> | FieldReadFunction<any>,
	meta_image?: FieldPolicy<any> | FieldReadFunction<any>,
	meta_title?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	share_on_facebook?: FieldPolicy<any> | FieldReadFunction<any>,
	share_on_twitter?: FieldPolicy<any> | FieldReadFunction<any>,
	social?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CategoryBannersKeySpecifier = ('banner_image' | 'banner_label' | 'banner_link' | 'banner_offset' | 'banner_overlay' | 'banner_title' | 'banner_title_color' | CategoryBannersKeySpecifier)[];
export type CategoryBannersFieldPolicy = {
	banner_image?: FieldPolicy<any> | FieldReadFunction<any>,
	banner_label?: FieldPolicy<any> | FieldReadFunction<any>,
	banner_link?: FieldPolicy<any> | FieldReadFunction<any>,
	banner_offset?: FieldPolicy<any> | FieldReadFunction<any>,
	banner_overlay?: FieldPolicy<any> | FieldReadFunction<any>,
	banner_title?: FieldPolicy<any> | FieldReadFunction<any>,
	banner_title_color?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CategoryConnectionConnectionKeySpecifier = ('edges' | 'pageInfo' | 'totalCount' | CategoryConnectionConnectionKeySpecifier)[];
export type CategoryConnectionConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CategoryConnectionEdgeKeySpecifier = ('cursor' | 'node' | CategoryConnectionEdgeKeySpecifier)[];
export type CategoryConnectionEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CategoryItemsKeySpecifier = ('item_image' | 'item_link' | 'item_text' | 'item_title' | CategoryItemsKeySpecifier)[];
export type CategoryItemsFieldPolicy = {
	item_image?: FieldPolicy<any> | FieldReadFunction<any>,
	item_link?: FieldPolicy<any> | FieldReadFunction<any>,
	item_text?: FieldPolicy<any> | FieldReadFunction<any>,
	item_title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CategorySocialOpen_graphKeySpecifier = ('label' | 'primary' | 'type' | CategorySocialOpen_graphKeySpecifier)[];
export type CategorySocialOpen_graphFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CategorySocialOpen_graphPrimaryKeySpecifier = ('og_description' | 'og_image' | 'og_title' | CategorySocialOpen_graphPrimaryKeySpecifier)[];
export type CategorySocialOpen_graphPrimaryFieldPolicy = {
	og_description?: FieldPolicy<any> | FieldReadFunction<any>,
	og_image?: FieldPolicy<any> | FieldReadFunction<any>,
	og_title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CategorySocialPinterestKeySpecifier = ('label' | 'primary' | 'type' | CategorySocialPinterestKeySpecifier)[];
export type CategorySocialPinterestFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CategorySocialPinterestPrimaryKeySpecifier = ('pinterest_post_title' | CategorySocialPinterestPrimaryKeySpecifier)[];
export type CategorySocialPinterestPrimaryFieldPolicy = {
	pinterest_post_title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CategorySocialTwitter_cardKeySpecifier = ('label' | 'primary' | 'type' | CategorySocialTwitter_cardKeySpecifier)[];
export type CategorySocialTwitter_cardFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CategorySocialTwitter_cardPrimaryKeySpecifier = ('twitter_description' | 'twitter_image' | 'twitter_title' | CategorySocialTwitter_cardPrimaryKeySpecifier)[];
export type CategorySocialTwitter_cardPrimaryFieldPolicy = {
	twitter_description?: FieldPolicy<any> | FieldReadFunction<any>,
	twitter_image?: FieldPolicy<any> | FieldReadFunction<any>,
	twitter_title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ContactKeySpecifier = ('_linkType' | '_meta' | 'body' | 'category' | 'display_name' | 'excerpt' | 'image' | 'index_settings' | 'meta_description' | 'meta_title' | 'share_on_facebook' | 'share_on_twitter' | 'social' | 'taxomanies' | 'title' | ContactKeySpecifier)[];
export type ContactFieldPolicy = {
	_linkType?: FieldPolicy<any> | FieldReadFunction<any>,
	_meta?: FieldPolicy<any> | FieldReadFunction<any>,
	body?: FieldPolicy<any> | FieldReadFunction<any>,
	category?: FieldPolicy<any> | FieldReadFunction<any>,
	display_name?: FieldPolicy<any> | FieldReadFunction<any>,
	excerpt?: FieldPolicy<any> | FieldReadFunction<any>,
	image?: FieldPolicy<any> | FieldReadFunction<any>,
	index_settings?: FieldPolicy<any> | FieldReadFunction<any>,
	meta_description?: FieldPolicy<any> | FieldReadFunction<any>,
	meta_title?: FieldPolicy<any> | FieldReadFunction<any>,
	share_on_facebook?: FieldPolicy<any> | FieldReadFunction<any>,
	share_on_twitter?: FieldPolicy<any> | FieldReadFunction<any>,
	social?: FieldPolicy<any> | FieldReadFunction<any>,
	taxomanies?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ContactBodyGalleryKeySpecifier = ('fields' | 'label' | 'primary' | 'type' | ContactBodyGalleryKeySpecifier)[];
export type ContactBodyGalleryFieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ContactBodyGalleryFieldsKeySpecifier = ('image' | ContactBodyGalleryFieldsKeySpecifier)[];
export type ContactBodyGalleryFieldsFieldPolicy = {
	image?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ContactBodyGalleryPrimaryKeySpecifier = ('link' | 'title' | ContactBodyGalleryPrimaryKeySpecifier)[];
export type ContactBodyGalleryPrimaryFieldPolicy = {
	link?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ContactBodySeparatorKeySpecifier = ('label' | 'type' | ContactBodySeparatorKeySpecifier)[];
export type ContactBodySeparatorFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ContactBodyText_blockKeySpecifier = ('label' | 'primary' | 'type' | ContactBodyText_blockKeySpecifier)[];
export type ContactBodyText_blockFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ContactBodyText_blockPrimaryKeySpecifier = ('rich_text' | 'title' | ContactBodyText_blockPrimaryKeySpecifier)[];
export type ContactBodyText_blockPrimaryFieldPolicy = {
	rich_text?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ContactBodyText_featuredKeySpecifier = ('fields' | 'label' | 'primary' | 'type' | ContactBodyText_featuredKeySpecifier)[];
export type ContactBodyText_featuredFieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ContactBodyText_featuredFieldsKeySpecifier = ('featured_image' | 'featured_position' | 'featured_text' | ContactBodyText_featuredFieldsKeySpecifier)[];
export type ContactBodyText_featuredFieldsFieldPolicy = {
	featured_image?: FieldPolicy<any> | FieldReadFunction<any>,
	featured_position?: FieldPolicy<any> | FieldReadFunction<any>,
	featured_text?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ContactBodyText_featuredPrimaryKeySpecifier = ('link' | 'title' | ContactBodyText_featuredPrimaryKeySpecifier)[];
export type ContactBodyText_featuredPrimaryFieldPolicy = {
	link?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ContactBodyVideoKeySpecifier = ('label' | 'primary' | 'type' | ContactBodyVideoKeySpecifier)[];
export type ContactBodyVideoFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ContactBodyVideoPrimaryKeySpecifier = ('embed' | ContactBodyVideoPrimaryKeySpecifier)[];
export type ContactBodyVideoPrimaryFieldPolicy = {
	embed?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ContactConnectionConnectionKeySpecifier = ('edges' | 'pageInfo' | 'totalCount' | ContactConnectionConnectionKeySpecifier)[];
export type ContactConnectionConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ContactConnectionEdgeKeySpecifier = ('cursor' | 'node' | ContactConnectionEdgeKeySpecifier)[];
export type ContactConnectionEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ContactSocialOpen_graphKeySpecifier = ('label' | 'primary' | 'type' | ContactSocialOpen_graphKeySpecifier)[];
export type ContactSocialOpen_graphFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ContactSocialOpen_graphPrimaryKeySpecifier = ('og_description' | 'og_image' | 'og_title' | ContactSocialOpen_graphPrimaryKeySpecifier)[];
export type ContactSocialOpen_graphPrimaryFieldPolicy = {
	og_description?: FieldPolicy<any> | FieldReadFunction<any>,
	og_image?: FieldPolicy<any> | FieldReadFunction<any>,
	og_title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ContactSocialPinterestKeySpecifier = ('label' | 'primary' | 'type' | ContactSocialPinterestKeySpecifier)[];
export type ContactSocialPinterestFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ContactSocialPinterestPrimaryKeySpecifier = ('pinterest_post_title' | ContactSocialPinterestPrimaryKeySpecifier)[];
export type ContactSocialPinterestPrimaryFieldPolicy = {
	pinterest_post_title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ContactSocialTwitter_cardKeySpecifier = ('label' | 'primary' | 'type' | ContactSocialTwitter_cardKeySpecifier)[];
export type ContactSocialTwitter_cardFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ContactSocialTwitter_cardPrimaryKeySpecifier = ('twitter_description' | 'twitter_image' | 'twitter_title' | ContactSocialTwitter_cardPrimaryKeySpecifier)[];
export type ContactSocialTwitter_cardPrimaryFieldPolicy = {
	twitter_description?: FieldPolicy<any> | FieldReadFunction<any>,
	twitter_image?: FieldPolicy<any> | FieldReadFunction<any>,
	twitter_title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ContactTaxomaniesKeySpecifier = ('taxomany' | ContactTaxomaniesKeySpecifier)[];
export type ContactTaxomaniesFieldPolicy = {
	taxomany?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ContactsKeySpecifier = ('_linkType' | '_meta' | 'address' | 'address_title' | 'contacts_title' | 'description' | 'form_title' | 'meta_description' | 'meta_image' | 'meta_title' | 'registration_details' | 'title' | ContactsKeySpecifier)[];
export type ContactsFieldPolicy = {
	_linkType?: FieldPolicy<any> | FieldReadFunction<any>,
	_meta?: FieldPolicy<any> | FieldReadFunction<any>,
	address?: FieldPolicy<any> | FieldReadFunction<any>,
	address_title?: FieldPolicy<any> | FieldReadFunction<any>,
	contacts_title?: FieldPolicy<any> | FieldReadFunction<any>,
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	form_title?: FieldPolicy<any> | FieldReadFunction<any>,
	meta_description?: FieldPolicy<any> | FieldReadFunction<any>,
	meta_image?: FieldPolicy<any> | FieldReadFunction<any>,
	meta_title?: FieldPolicy<any> | FieldReadFunction<any>,
	registration_details?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ContactsConnectionConnectionKeySpecifier = ('edges' | 'pageInfo' | 'totalCount' | ContactsConnectionConnectionKeySpecifier)[];
export type ContactsConnectionConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ContactsConnectionEdgeKeySpecifier = ('cursor' | 'node' | ContactsConnectionEdgeKeySpecifier)[];
export type ContactsConnectionEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DefaultseoKeySpecifier = ('_linkType' | '_meta' | 'description' | 'image' | 'site_name' | 'title' | DefaultseoKeySpecifier)[];
export type DefaultseoFieldPolicy = {
	_linkType?: FieldPolicy<any> | FieldReadFunction<any>,
	_meta?: FieldPolicy<any> | FieldReadFunction<any>,
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	image?: FieldPolicy<any> | FieldReadFunction<any>,
	site_name?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DefaultseoConnectionConnectionKeySpecifier = ('edges' | 'pageInfo' | 'totalCount' | DefaultseoConnectionConnectionKeySpecifier)[];
export type DefaultseoConnectionConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DefaultseoConnectionEdgeKeySpecifier = ('cursor' | 'node' | DefaultseoConnectionEdgeKeySpecifier)[];
export type DefaultseoConnectionEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DoorsKeySpecifier = ('_linkType' | '_meta' | DoorsKeySpecifier)[];
export type DoorsFieldPolicy = {
	_linkType?: FieldPolicy<any> | FieldReadFunction<any>,
	_meta?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DoorsConnectionConnectionKeySpecifier = ('edges' | 'pageInfo' | 'totalCount' | DoorsConnectionConnectionKeySpecifier)[];
export type DoorsConnectionConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DoorsConnectionEdgeKeySpecifier = ('cursor' | 'node' | DoorsConnectionEdgeKeySpecifier)[];
export type DoorsConnectionEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FaqKeySpecifier = ('_linkType' | '_meta' | 'body' | 'category' | 'display_name' | 'excerpt' | 'index_settings' | 'meta_description' | 'meta_title' | 'related_articles' | 'share_on_facebook' | 'share_on_twitter' | 'social' | 'taxonomies' | 'title' | FaqKeySpecifier)[];
export type FaqFieldPolicy = {
	_linkType?: FieldPolicy<any> | FieldReadFunction<any>,
	_meta?: FieldPolicy<any> | FieldReadFunction<any>,
	body?: FieldPolicy<any> | FieldReadFunction<any>,
	category?: FieldPolicy<any> | FieldReadFunction<any>,
	display_name?: FieldPolicy<any> | FieldReadFunction<any>,
	excerpt?: FieldPolicy<any> | FieldReadFunction<any>,
	index_settings?: FieldPolicy<any> | FieldReadFunction<any>,
	meta_description?: FieldPolicy<any> | FieldReadFunction<any>,
	meta_title?: FieldPolicy<any> | FieldReadFunction<any>,
	related_articles?: FieldPolicy<any> | FieldReadFunction<any>,
	share_on_facebook?: FieldPolicy<any> | FieldReadFunction<any>,
	share_on_twitter?: FieldPolicy<any> | FieldReadFunction<any>,
	social?: FieldPolicy<any> | FieldReadFunction<any>,
	taxonomies?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FaqBodyGalleryKeySpecifier = ('fields' | 'label' | 'primary' | 'type' | FaqBodyGalleryKeySpecifier)[];
export type FaqBodyGalleryFieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FaqBodyGalleryFieldsKeySpecifier = ('alt_text' | 'image' | FaqBodyGalleryFieldsKeySpecifier)[];
export type FaqBodyGalleryFieldsFieldPolicy = {
	alt_text?: FieldPolicy<any> | FieldReadFunction<any>,
	image?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FaqBodyGalleryPrimaryKeySpecifier = ('link' | 'title' | FaqBodyGalleryPrimaryKeySpecifier)[];
export type FaqBodyGalleryPrimaryFieldPolicy = {
	link?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FaqBodySeparatorKeySpecifier = ('label' | 'type' | FaqBodySeparatorKeySpecifier)[];
export type FaqBodySeparatorFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FaqBodyText_blockKeySpecifier = ('label' | 'primary' | 'type' | FaqBodyText_blockKeySpecifier)[];
export type FaqBodyText_blockFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FaqBodyText_blockPrimaryKeySpecifier = ('rich_text' | 'title' | FaqBodyText_blockPrimaryKeySpecifier)[];
export type FaqBodyText_blockPrimaryFieldPolicy = {
	rich_text?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FaqBodyText_featuredKeySpecifier = ('fields' | 'label' | 'primary' | 'type' | FaqBodyText_featuredKeySpecifier)[];
export type FaqBodyText_featuredFieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FaqBodyText_featuredFieldsKeySpecifier = ('featured_image' | 'featured_position' | 'featured_text' | FaqBodyText_featuredFieldsKeySpecifier)[];
export type FaqBodyText_featuredFieldsFieldPolicy = {
	featured_image?: FieldPolicy<any> | FieldReadFunction<any>,
	featured_position?: FieldPolicy<any> | FieldReadFunction<any>,
	featured_text?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FaqBodyText_featuredPrimaryKeySpecifier = ('link' | 'title' | FaqBodyText_featuredPrimaryKeySpecifier)[];
export type FaqBodyText_featuredPrimaryFieldPolicy = {
	link?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FaqBodyVideoKeySpecifier = ('label' | 'primary' | 'type' | FaqBodyVideoKeySpecifier)[];
export type FaqBodyVideoFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FaqBodyVideoPrimaryKeySpecifier = ('embed' | FaqBodyVideoPrimaryKeySpecifier)[];
export type FaqBodyVideoPrimaryFieldPolicy = {
	embed?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FaqConnectionConnectionKeySpecifier = ('edges' | 'pageInfo' | 'totalCount' | FaqConnectionConnectionKeySpecifier)[];
export type FaqConnectionConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FaqConnectionEdgeKeySpecifier = ('cursor' | 'node' | FaqConnectionEdgeKeySpecifier)[];
export type FaqConnectionEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FaqRelated_articlesKeySpecifier = ('article' | FaqRelated_articlesKeySpecifier)[];
export type FaqRelated_articlesFieldPolicy = {
	article?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FaqSocialOpen_graphKeySpecifier = ('label' | 'primary' | 'type' | FaqSocialOpen_graphKeySpecifier)[];
export type FaqSocialOpen_graphFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FaqSocialOpen_graphPrimaryKeySpecifier = ('og_description' | 'og_image' | 'og_title' | FaqSocialOpen_graphPrimaryKeySpecifier)[];
export type FaqSocialOpen_graphPrimaryFieldPolicy = {
	og_description?: FieldPolicy<any> | FieldReadFunction<any>,
	og_image?: FieldPolicy<any> | FieldReadFunction<any>,
	og_title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FaqSocialPinterestKeySpecifier = ('label' | 'primary' | 'type' | FaqSocialPinterestKeySpecifier)[];
export type FaqSocialPinterestFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FaqSocialPinterestPrimaryKeySpecifier = ('pinterest_post_title' | FaqSocialPinterestPrimaryKeySpecifier)[];
export type FaqSocialPinterestPrimaryFieldPolicy = {
	pinterest_post_title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FaqSocialTwitter_cardKeySpecifier = ('label' | 'primary' | 'type' | FaqSocialTwitter_cardKeySpecifier)[];
export type FaqSocialTwitter_cardFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FaqSocialTwitter_cardPrimaryKeySpecifier = ('twitter_description' | 'twitter_image' | 'twitter_title' | FaqSocialTwitter_cardPrimaryKeySpecifier)[];
export type FaqSocialTwitter_cardPrimaryFieldPolicy = {
	twitter_description?: FieldPolicy<any> | FieldReadFunction<any>,
	twitter_image?: FieldPolicy<any> | FieldReadFunction<any>,
	twitter_title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FaqTaxonomiesKeySpecifier = ('taxonomy' | FaqTaxonomiesKeySpecifier)[];
export type FaqTaxonomiesFieldPolicy = {
	taxonomy?: FieldPolicy<any> | FieldReadFunction<any>
};
export type HelpKeySpecifier = ('_linkType' | '_meta' | 'body' | 'title' | HelpKeySpecifier)[];
export type HelpFieldPolicy = {
	_linkType?: FieldPolicy<any> | FieldReadFunction<any>,
	_meta?: FieldPolicy<any> | FieldReadFunction<any>,
	body?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type HelpBodyCode_snippetKeySpecifier = ('label' | 'primary' | 'type' | HelpBodyCode_snippetKeySpecifier)[];
export type HelpBodyCode_snippetFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type HelpBodyCode_snippetPrimaryKeySpecifier = ('code_snippet' | HelpBodyCode_snippetPrimaryKeySpecifier)[];
export type HelpBodyCode_snippetPrimaryFieldPolicy = {
	code_snippet?: FieldPolicy<any> | FieldReadFunction<any>
};
export type HelpBodyHighlighted_textKeySpecifier = ('label' | 'primary' | 'type' | HelpBodyHighlighted_textKeySpecifier)[];
export type HelpBodyHighlighted_textFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type HelpBodyHighlighted_textPrimaryKeySpecifier = ('highlight_text' | 'highlight_title' | HelpBodyHighlighted_textPrimaryKeySpecifier)[];
export type HelpBodyHighlighted_textPrimaryFieldPolicy = {
	highlight_text?: FieldPolicy<any> | FieldReadFunction<any>,
	highlight_title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type HelpBodyImageKeySpecifier = ('label' | 'primary' | 'type' | HelpBodyImageKeySpecifier)[];
export type HelpBodyImageFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type HelpBodyImagePrimaryKeySpecifier = ('image' | HelpBodyImagePrimaryKeySpecifier)[];
export type HelpBodyImagePrimaryFieldPolicy = {
	image?: FieldPolicy<any> | FieldReadFunction<any>
};
export type HelpBodyTextKeySpecifier = ('label' | 'primary' | 'type' | HelpBodyTextKeySpecifier)[];
export type HelpBodyTextFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type HelpBodyTextPrimaryKeySpecifier = ('rich_text' | HelpBodyTextPrimaryKeySpecifier)[];
export type HelpBodyTextPrimaryFieldPolicy = {
	rich_text?: FieldPolicy<any> | FieldReadFunction<any>
};
export type HelpConnectionConnectionKeySpecifier = ('edges' | 'pageInfo' | 'totalCount' | HelpConnectionConnectionKeySpecifier)[];
export type HelpConnectionConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type HelpConnectionEdgeKeySpecifier = ('cursor' | 'node' | HelpConnectionEdgeKeySpecifier)[];
export type HelpConnectionEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type HomepageKeySpecifier = ('_linkType' | '_meta' | 'body' | 'display_name' | 'hero' | 'index_settings' | 'meta_description' | 'meta_image' | 'meta_title' | 'share_on_facebook' | 'share_on_twitter' | 'social' | HomepageKeySpecifier)[];
export type HomepageFieldPolicy = {
	_linkType?: FieldPolicy<any> | FieldReadFunction<any>,
	_meta?: FieldPolicy<any> | FieldReadFunction<any>,
	body?: FieldPolicy<any> | FieldReadFunction<any>,
	display_name?: FieldPolicy<any> | FieldReadFunction<any>,
	hero?: FieldPolicy<any> | FieldReadFunction<any>,
	index_settings?: FieldPolicy<any> | FieldReadFunction<any>,
	meta_description?: FieldPolicy<any> | FieldReadFunction<any>,
	meta_image?: FieldPolicy<any> | FieldReadFunction<any>,
	meta_title?: FieldPolicy<any> | FieldReadFunction<any>,
	share_on_facebook?: FieldPolicy<any> | FieldReadFunction<any>,
	share_on_twitter?: FieldPolicy<any> | FieldReadFunction<any>,
	social?: FieldPolicy<any> | FieldReadFunction<any>
};
export type HomepageBodyBenefitsKeySpecifier = ('fields' | 'label' | 'primary' | 'type' | HomepageBodyBenefitsKeySpecifier)[];
export type HomepageBodyBenefitsFieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type HomepageBodyBenefitsFieldsKeySpecifier = ('img_default' | 'img_hover' | 'img_shade' | 'more_text' | 'text' | 'title' | HomepageBodyBenefitsFieldsKeySpecifier)[];
export type HomepageBodyBenefitsFieldsFieldPolicy = {
	img_default?: FieldPolicy<any> | FieldReadFunction<any>,
	img_hover?: FieldPolicy<any> | FieldReadFunction<any>,
	img_shade?: FieldPolicy<any> | FieldReadFunction<any>,
	more_text?: FieldPolicy<any> | FieldReadFunction<any>,
	text?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type HomepageBodyBenefitsPrimaryKeySpecifier = ('title' | HomepageBodyBenefitsPrimaryKeySpecifier)[];
export type HomepageBodyBenefitsPrimaryFieldPolicy = {
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type HomepageBodyCollectionsKeySpecifier = ('fields' | 'label' | 'primary' | 'type' | HomepageBodyCollectionsKeySpecifier)[];
export type HomepageBodyCollectionsFieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type HomepageBodyCollectionsFieldsKeySpecifier = ('category' | 'image' | 'link' | 'text' | 'title' | HomepageBodyCollectionsFieldsKeySpecifier)[];
export type HomepageBodyCollectionsFieldsFieldPolicy = {
	category?: FieldPolicy<any> | FieldReadFunction<any>,
	image?: FieldPolicy<any> | FieldReadFunction<any>,
	link?: FieldPolicy<any> | FieldReadFunction<any>,
	text?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type HomepageBodyCollectionsPrimaryKeySpecifier = ('link' | 'title' | HomepageBodyCollectionsPrimaryKeySpecifier)[];
export type HomepageBodyCollectionsPrimaryFieldPolicy = {
	link?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type HomepageBodyCtaKeySpecifier = ('label' | 'primary' | 'type' | HomepageBodyCtaKeySpecifier)[];
export type HomepageBodyCtaFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type HomepageBodyCtaPrimaryKeySpecifier = ('button_label' | 'button_link' | 'image' | 'text' | 'title' | 'video' | HomepageBodyCtaPrimaryKeySpecifier)[];
export type HomepageBodyCtaPrimaryFieldPolicy = {
	button_label?: FieldPolicy<any> | FieldReadFunction<any>,
	button_link?: FieldPolicy<any> | FieldReadFunction<any>,
	image?: FieldPolicy<any> | FieldReadFunction<any>,
	text?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>,
	video?: FieldPolicy<any> | FieldReadFunction<any>
};
export type HomepageBodyImagecardKeySpecifier = ('fields' | 'label' | 'primary' | 'type' | HomepageBodyImagecardKeySpecifier)[];
export type HomepageBodyImagecardFieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type HomepageBodyImagecardFieldsKeySpecifier = ('card_btn_label' | 'card_btn_link' | 'card_centered' | 'card_content' | 'card_image' | 'card_title' | HomepageBodyImagecardFieldsKeySpecifier)[];
export type HomepageBodyImagecardFieldsFieldPolicy = {
	card_btn_label?: FieldPolicy<any> | FieldReadFunction<any>,
	card_btn_link?: FieldPolicy<any> | FieldReadFunction<any>,
	card_centered?: FieldPolicy<any> | FieldReadFunction<any>,
	card_content?: FieldPolicy<any> | FieldReadFunction<any>,
	card_image?: FieldPolicy<any> | FieldReadFunction<any>,
	card_title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type HomepageBodyImagecardPrimaryKeySpecifier = ('header_title' | HomepageBodyImagecardPrimaryKeySpecifier)[];
export type HomepageBodyImagecardPrimaryFieldPolicy = {
	header_title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type HomepageBodyImagectaKeySpecifier = ('label' | 'primary' | 'type' | HomepageBodyImagectaKeySpecifier)[];
export type HomepageBodyImagectaFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type HomepageBodyImagectaPrimaryKeySpecifier = ('bgcolor' | 'button_label' | 'button_link' | 'content_photo' | 'content_text' | 'content_video' | HomepageBodyImagectaPrimaryKeySpecifier)[];
export type HomepageBodyImagectaPrimaryFieldPolicy = {
	bgcolor?: FieldPolicy<any> | FieldReadFunction<any>,
	button_label?: FieldPolicy<any> | FieldReadFunction<any>,
	button_link?: FieldPolicy<any> | FieldReadFunction<any>,
	content_photo?: FieldPolicy<any> | FieldReadFunction<any>,
	content_text?: FieldPolicy<any> | FieldReadFunction<any>,
	content_video?: FieldPolicy<any> | FieldReadFunction<any>
};
export type HomepageBodyInspirationKeySpecifier = ('fields' | 'label' | 'type' | HomepageBodyInspirationKeySpecifier)[];
export type HomepageBodyInspirationFieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type HomepageBodyInspirationFieldsKeySpecifier = ('image' | 'link' | HomepageBodyInspirationFieldsKeySpecifier)[];
export type HomepageBodyInspirationFieldsFieldPolicy = {
	image?: FieldPolicy<any> | FieldReadFunction<any>,
	link?: FieldPolicy<any> | FieldReadFunction<any>
};
export type HomepageBodyInstagram_postsKeySpecifier = ('fields' | 'label' | 'primary' | 'type' | HomepageBodyInstagram_postsKeySpecifier)[];
export type HomepageBodyInstagram_postsFieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type HomepageBodyInstagram_postsFieldsKeySpecifier = ('instagram_link' | HomepageBodyInstagram_postsFieldsKeySpecifier)[];
export type HomepageBodyInstagram_postsFieldsFieldPolicy = {
	instagram_link?: FieldPolicy<any> | FieldReadFunction<any>
};
export type HomepageBodyInstagram_postsPrimaryKeySpecifier = ('text' | 'title' | 'user_name' | HomepageBodyInstagram_postsPrimaryKeySpecifier)[];
export type HomepageBodyInstagram_postsPrimaryFieldPolicy = {
	text?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>,
	user_name?: FieldPolicy<any> | FieldReadFunction<any>
};
export type HomepageBodyLinkbuttonKeySpecifier = ('label' | 'primary' | 'type' | HomepageBodyLinkbuttonKeySpecifier)[];
export type HomepageBodyLinkbuttonFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type HomepageBodyLinkbuttonPrimaryKeySpecifier = ('button_label' | 'button_link' | HomepageBodyLinkbuttonPrimaryKeySpecifier)[];
export type HomepageBodyLinkbuttonPrimaryFieldPolicy = {
	button_label?: FieldPolicy<any> | FieldReadFunction<any>,
	button_link?: FieldPolicy<any> | FieldReadFunction<any>
};
export type HomepageBodyReviewsKeySpecifier = ('label' | 'type' | HomepageBodyReviewsKeySpecifier)[];
export type HomepageBodyReviewsFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type HomepageBodyTrustedKeySpecifier = ('fields' | 'label' | 'type' | HomepageBodyTrustedKeySpecifier)[];
export type HomepageBodyTrustedFieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type HomepageBodyTrustedFieldsKeySpecifier = ('image' | 'text' | HomepageBodyTrustedFieldsKeySpecifier)[];
export type HomepageBodyTrustedFieldsFieldPolicy = {
	image?: FieldPolicy<any> | FieldReadFunction<any>,
	text?: FieldPolicy<any> | FieldReadFunction<any>
};
export type HomepageConnectionConnectionKeySpecifier = ('edges' | 'pageInfo' | 'totalCount' | HomepageConnectionConnectionKeySpecifier)[];
export type HomepageConnectionConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type HomepageConnectionEdgeKeySpecifier = ('cursor' | 'node' | HomepageConnectionEdgeKeySpecifier)[];
export type HomepageConnectionEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type HomepageHeroKeySpecifier = ('banner_title' | 'button_label' | 'button_link' | 'image' | 'text' | HomepageHeroKeySpecifier)[];
export type HomepageHeroFieldPolicy = {
	banner_title?: FieldPolicy<any> | FieldReadFunction<any>,
	button_label?: FieldPolicy<any> | FieldReadFunction<any>,
	button_link?: FieldPolicy<any> | FieldReadFunction<any>,
	image?: FieldPolicy<any> | FieldReadFunction<any>,
	text?: FieldPolicy<any> | FieldReadFunction<any>
};
export type HomepageSocialOpen_graphKeySpecifier = ('label' | 'primary' | 'type' | HomepageSocialOpen_graphKeySpecifier)[];
export type HomepageSocialOpen_graphFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type HomepageSocialOpen_graphPrimaryKeySpecifier = ('og_description' | 'og_image' | 'og_title' | HomepageSocialOpen_graphPrimaryKeySpecifier)[];
export type HomepageSocialOpen_graphPrimaryFieldPolicy = {
	og_description?: FieldPolicy<any> | FieldReadFunction<any>,
	og_image?: FieldPolicy<any> | FieldReadFunction<any>,
	og_title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type HomepageSocialPinterestKeySpecifier = ('label' | 'primary' | 'type' | HomepageSocialPinterestKeySpecifier)[];
export type HomepageSocialPinterestFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type HomepageSocialPinterestPrimaryKeySpecifier = ('pinterest_post_title' | HomepageSocialPinterestPrimaryKeySpecifier)[];
export type HomepageSocialPinterestPrimaryFieldPolicy = {
	pinterest_post_title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type HomepageSocialTwitter_cardKeySpecifier = ('label' | 'primary' | 'type' | HomepageSocialTwitter_cardKeySpecifier)[];
export type HomepageSocialTwitter_cardFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type HomepageSocialTwitter_cardPrimaryKeySpecifier = ('twitter_description' | 'twitter_image' | 'twitter_title' | HomepageSocialTwitter_cardPrimaryKeySpecifier)[];
export type HomepageSocialTwitter_cardPrimaryFieldPolicy = {
	twitter_description?: FieldPolicy<any> | FieldReadFunction<any>,
	twitter_image?: FieldPolicy<any> | FieldReadFunction<any>,
	twitter_title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type InstagramKeySpecifier = ('_linkType' | '_meta' | 'posts' | 'text' | 'title' | 'user_name' | InstagramKeySpecifier)[];
export type InstagramFieldPolicy = {
	_linkType?: FieldPolicy<any> | FieldReadFunction<any>,
	_meta?: FieldPolicy<any> | FieldReadFunction<any>,
	posts?: FieldPolicy<any> | FieldReadFunction<any>,
	text?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>,
	user_name?: FieldPolicy<any> | FieldReadFunction<any>
};
export type InstagramConnectionConnectionKeySpecifier = ('edges' | 'pageInfo' | 'totalCount' | InstagramConnectionConnectionKeySpecifier)[];
export type InstagramConnectionConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type InstagramConnectionEdgeKeySpecifier = ('cursor' | 'node' | InstagramConnectionEdgeKeySpecifier)[];
export type InstagramConnectionEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type InstagramPostsKeySpecifier = ('image' | 'instagram_link' | 'post_title' | 'post_user_name' | 'user_avatar' | InstagramPostsKeySpecifier)[];
export type InstagramPostsFieldPolicy = {
	image?: FieldPolicy<any> | FieldReadFunction<any>,
	instagram_link?: FieldPolicy<any> | FieldReadFunction<any>,
	post_title?: FieldPolicy<any> | FieldReadFunction<any>,
	post_user_name?: FieldPolicy<any> | FieldReadFunction<any>,
	user_avatar?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Landing_pageKeySpecifier = ('_linkType' | '_meta' | 'body' | 'body1' | 'canonical_url' | 'meta_description' | 'meta_title' | 'title' | Landing_pageKeySpecifier)[];
export type Landing_pageFieldPolicy = {
	_linkType?: FieldPolicy<any> | FieldReadFunction<any>,
	_meta?: FieldPolicy<any> | FieldReadFunction<any>,
	body?: FieldPolicy<any> | FieldReadFunction<any>,
	body1?: FieldPolicy<any> | FieldReadFunction<any>,
	canonical_url?: FieldPolicy<any> | FieldReadFunction<any>,
	meta_description?: FieldPolicy<any> | FieldReadFunction<any>,
	meta_title?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Landing_pageBody1Facebook__open_graph_KeySpecifier = ('label' | 'type' | Landing_pageBody1Facebook__open_graph_KeySpecifier)[];
export type Landing_pageBody1Facebook__open_graph_FieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Landing_pageBody1Twitter_cardKeySpecifier = ('label' | 'primary' | 'type' | Landing_pageBody1Twitter_cardKeySpecifier)[];
export type Landing_pageBody1Twitter_cardFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Landing_pageBody1Twitter_cardPrimaryKeySpecifier = ('card_description' | 'card_image' | 'card_title' | Landing_pageBody1Twitter_cardPrimaryKeySpecifier)[];
export type Landing_pageBody1Twitter_cardPrimaryFieldPolicy = {
	card_description?: FieldPolicy<any> | FieldReadFunction<any>,
	card_image?: FieldPolicy<any> | FieldReadFunction<any>,
	card_title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Landing_pageBodyBanner_splitKeySpecifier = ('label' | 'primary' | 'type' | Landing_pageBodyBanner_splitKeySpecifier)[];
export type Landing_pageBodyBanner_splitFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Landing_pageBodyBanner_splitPrimaryKeySpecifier = ('banner_image' | 'banner_text' | 'banner_title' | Landing_pageBodyBanner_splitPrimaryKeySpecifier)[];
export type Landing_pageBodyBanner_splitPrimaryFieldPolicy = {
	banner_image?: FieldPolicy<any> | FieldReadFunction<any>,
	banner_text?: FieldPolicy<any> | FieldReadFunction<any>,
	banner_title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Landing_pageBodyBig_bullet_itemKeySpecifier = ('fields' | 'label' | 'primary' | 'type' | Landing_pageBodyBig_bullet_itemKeySpecifier)[];
export type Landing_pageBodyBig_bullet_itemFieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Landing_pageBodyBig_bullet_itemFieldsKeySpecifier = ('description_paragraph' | Landing_pageBodyBig_bullet_itemFieldsKeySpecifier)[];
export type Landing_pageBodyBig_bullet_itemFieldsFieldPolicy = {
	description_paragraph?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Landing_pageBodyBig_bullet_itemPrimaryKeySpecifier = ('title_section' | Landing_pageBodyBig_bullet_itemPrimaryKeySpecifier)[];
export type Landing_pageBodyBig_bullet_itemPrimaryFieldPolicy = {
	title_section?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Landing_pageBodyProduct_quoteKeySpecifier = ('label' | 'primary' | 'type' | Landing_pageBodyProduct_quoteKeySpecifier)[];
export type Landing_pageBodyProduct_quoteFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Landing_pageBodyProduct_quotePrimaryKeySpecifier = ('product_description' | 'product_image' | 'quote_author' | 'quote_background' | 'quote_text' | Landing_pageBodyProduct_quotePrimaryKeySpecifier)[];
export type Landing_pageBodyProduct_quotePrimaryFieldPolicy = {
	product_description?: FieldPolicy<any> | FieldReadFunction<any>,
	product_image?: FieldPolicy<any> | FieldReadFunction<any>,
	quote_author?: FieldPolicy<any> | FieldReadFunction<any>,
	quote_background?: FieldPolicy<any> | FieldReadFunction<any>,
	quote_text?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Landing_pageConnectionConnectionKeySpecifier = ('edges' | 'pageInfo' | 'totalCount' | Landing_pageConnectionConnectionKeySpecifier)[];
export type Landing_pageConnectionConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Landing_pageConnectionEdgeKeySpecifier = ('cursor' | 'node' | Landing_pageConnectionEdgeKeySpecifier)[];
export type Landing_pageConnectionEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type LayoutKeySpecifier = ('_linkType' | '_meta' | 'contact' | 'footer_contact_text' | 'footer_contact_title' | 'footer_copyright' | 'footer_navigation' | 'footer_navigation_group' | 'footer_social' | 'footer_terms' | 'footer_trust_box' | 'gdrp_text' | 'header_message' | 'header_navigation' | 'header_navigation_all_types_image' | 'modals' | 'region_description' | 'region_title' | 'regions' | 'social_facebook_link' | 'social_instagram_link' | 'social_messenger_link' | 'social_pinterest_link' | 'social_whatsapp_link' | 'social_youtube_link' | LayoutKeySpecifier)[];
export type LayoutFieldPolicy = {
	_linkType?: FieldPolicy<any> | FieldReadFunction<any>,
	_meta?: FieldPolicy<any> | FieldReadFunction<any>,
	contact?: FieldPolicy<any> | FieldReadFunction<any>,
	footer_contact_text?: FieldPolicy<any> | FieldReadFunction<any>,
	footer_contact_title?: FieldPolicy<any> | FieldReadFunction<any>,
	footer_copyright?: FieldPolicy<any> | FieldReadFunction<any>,
	footer_navigation?: FieldPolicy<any> | FieldReadFunction<any>,
	footer_navigation_group?: FieldPolicy<any> | FieldReadFunction<any>,
	footer_social?: FieldPolicy<any> | FieldReadFunction<any>,
	footer_terms?: FieldPolicy<any> | FieldReadFunction<any>,
	footer_trust_box?: FieldPolicy<any> | FieldReadFunction<any>,
	gdrp_text?: FieldPolicy<any> | FieldReadFunction<any>,
	header_message?: FieldPolicy<any> | FieldReadFunction<any>,
	header_navigation?: FieldPolicy<any> | FieldReadFunction<any>,
	header_navigation_all_types_image?: FieldPolicy<any> | FieldReadFunction<any>,
	modals?: FieldPolicy<any> | FieldReadFunction<any>,
	region_description?: FieldPolicy<any> | FieldReadFunction<any>,
	region_title?: FieldPolicy<any> | FieldReadFunction<any>,
	regions?: FieldPolicy<any> | FieldReadFunction<any>,
	social_facebook_link?: FieldPolicy<any> | FieldReadFunction<any>,
	social_instagram_link?: FieldPolicy<any> | FieldReadFunction<any>,
	social_messenger_link?: FieldPolicy<any> | FieldReadFunction<any>,
	social_pinterest_link?: FieldPolicy<any> | FieldReadFunction<any>,
	social_whatsapp_link?: FieldPolicy<any> | FieldReadFunction<any>,
	social_youtube_link?: FieldPolicy<any> | FieldReadFunction<any>
};
export type LayoutConnectionConnectionKeySpecifier = ('edges' | 'pageInfo' | 'totalCount' | LayoutConnectionConnectionKeySpecifier)[];
export type LayoutConnectionConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type LayoutConnectionEdgeKeySpecifier = ('cursor' | 'node' | LayoutConnectionEdgeKeySpecifier)[];
export type LayoutConnectionEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type LayoutContactKeySpecifier = ('label' | 'link' | 'show_in_nav' | 'type' | LayoutContactKeySpecifier)[];
export type LayoutContactFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	link?: FieldPolicy<any> | FieldReadFunction<any>,
	show_in_nav?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type LayoutFooter_navigationNav_groupKeySpecifier = ('fields' | 'label' | 'primary' | 'type' | LayoutFooter_navigationNav_groupKeySpecifier)[];
export type LayoutFooter_navigationNav_groupFieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type LayoutFooter_navigationNav_groupFieldsKeySpecifier = ('label' | 'link' | LayoutFooter_navigationNav_groupFieldsKeySpecifier)[];
export type LayoutFooter_navigationNav_groupFieldsFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	link?: FieldPolicy<any> | FieldReadFunction<any>
};
export type LayoutFooter_navigationNav_groupPrimaryKeySpecifier = ('title' | LayoutFooter_navigationNav_groupPrimaryKeySpecifier)[];
export type LayoutFooter_navigationNav_groupPrimaryFieldPolicy = {
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type LayoutFooter_navigation_groupKeySpecifier = ('footer_nav_group_links' | 'footer_nav_group_title' | LayoutFooter_navigation_groupKeySpecifier)[];
export type LayoutFooter_navigation_groupFieldPolicy = {
	footer_nav_group_links?: FieldPolicy<any> | FieldReadFunction<any>,
	footer_nav_group_title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type LayoutFooter_socialKeySpecifier = ('footer_social_link' | 'footer_social_type' | LayoutFooter_socialKeySpecifier)[];
export type LayoutFooter_socialFieldPolicy = {
	footer_social_link?: FieldPolicy<any> | FieldReadFunction<any>,
	footer_social_type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type LayoutHeader_navigationNav_itemKeySpecifier = ('fields' | 'label' | 'primary' | 'type' | LayoutHeader_navigationNav_itemKeySpecifier)[];
export type LayoutHeader_navigationNav_itemFieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type LayoutHeader_navigationNav_itemFieldsKeySpecifier = ('image' | 'label' | 'link' | LayoutHeader_navigationNav_itemFieldsKeySpecifier)[];
export type LayoutHeader_navigationNav_itemFieldsFieldPolicy = {
	image?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	link?: FieldPolicy<any> | FieldReadFunction<any>
};
export type LayoutHeader_navigationNav_itemPrimaryKeySpecifier = ('label' | 'link' | LayoutHeader_navigationNav_itemPrimaryKeySpecifier)[];
export type LayoutHeader_navigationNav_itemPrimaryFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	link?: FieldPolicy<any> | FieldReadFunction<any>
};
export type LayoutModalsKeySpecifier = ('content' | 'id' | 'text' | 'title' | LayoutModalsKeySpecifier)[];
export type LayoutModalsFieldPolicy = {
	content?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	text?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type LayoutRegionsKeySpecifier = ('flag' | 'link' | 'region_title' | LayoutRegionsKeySpecifier)[];
export type LayoutRegionsFieldPolicy = {
	flag?: FieldPolicy<any> | FieldReadFunction<any>,
	link?: FieldPolicy<any> | FieldReadFunction<any>,
	region_title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MetaKeySpecifier = ('alternateLanguages' | 'firstPublicationDate' | 'id' | 'lang' | 'lastPublicationDate' | 'tags' | 'type' | 'uid' | MetaKeySpecifier)[];
export type MetaFieldPolicy = {
	alternateLanguages?: FieldPolicy<any> | FieldReadFunction<any>,
	firstPublicationDate?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	lang?: FieldPolicy<any> | FieldReadFunction<any>,
	lastPublicationDate?: FieldPolicy<any> | FieldReadFunction<any>,
	tags?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>,
	uid?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageKeySpecifier = ('_linkType' | '_meta' | 'body' | 'showbreadcrumbs' | PageKeySpecifier)[];
export type PageFieldPolicy = {
	_linkType?: FieldPolicy<any> | FieldReadFunction<any>,
	_meta?: FieldPolicy<any> | FieldReadFunction<any>,
	body?: FieldPolicy<any> | FieldReadFunction<any>,
	showbreadcrumbs?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageBodyAccordionKeySpecifier = ('fields' | 'label' | 'type' | PageBodyAccordionKeySpecifier)[];
export type PageBodyAccordionFieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageBodyAccordionFieldsKeySpecifier = ('rich_text' | 'title' | PageBodyAccordionFieldsKeySpecifier)[];
export type PageBodyAccordionFieldsFieldPolicy = {
	rich_text?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageBodyContent_photo_photoKeySpecifier = ('fields' | 'label' | 'type' | PageBodyContent_photo_photoKeySpecifier)[];
export type PageBodyContent_photo_photoFieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageBodyContent_photo_photoFieldsKeySpecifier = ('content_photo' | 'photo_link' | PageBodyContent_photo_photoFieldsKeySpecifier)[];
export type PageBodyContent_photo_photoFieldsFieldPolicy = {
	content_photo?: FieldPolicy<any> | FieldReadFunction<any>,
	photo_link?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageBodyContent_photo_photo_50KeySpecifier = ('fields' | 'label' | 'type' | PageBodyContent_photo_photo_50KeySpecifier)[];
export type PageBodyContent_photo_photo_50FieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageBodyContent_photo_photo_50FieldsKeySpecifier = ('content_photo_left' | 'content_photo_right' | 'photo_left_link' | 'photo_right_link' | PageBodyContent_photo_photo_50FieldsKeySpecifier)[];
export type PageBodyContent_photo_photo_50FieldsFieldPolicy = {
	content_photo_left?: FieldPolicy<any> | FieldReadFunction<any>,
	content_photo_right?: FieldPolicy<any> | FieldReadFunction<any>,
	photo_left_link?: FieldPolicy<any> | FieldReadFunction<any>,
	photo_right_link?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageBodyContent_photo_textKeySpecifier = ('label' | 'primary' | 'type' | PageBodyContent_photo_textKeySpecifier)[];
export type PageBodyContent_photo_textFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageBodyContent_photo_textPrimaryKeySpecifier = ('content_photo' | 'content_text' | 'photo_link' | PageBodyContent_photo_textPrimaryKeySpecifier)[];
export type PageBodyContent_photo_textPrimaryFieldPolicy = {
	content_photo?: FieldPolicy<any> | FieldReadFunction<any>,
	content_text?: FieldPolicy<any> | FieldReadFunction<any>,
	photo_link?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageBodyContent_photo_text_30_60KeySpecifier = ('fields' | 'label' | 'type' | PageBodyContent_photo_text_30_60KeySpecifier)[];
export type PageBodyContent_photo_text_30_60FieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageBodyContent_photo_text_30_60FieldsKeySpecifier = ('content_photo' | 'content_text' | 'photo_link' | PageBodyContent_photo_text_30_60FieldsKeySpecifier)[];
export type PageBodyContent_photo_text_30_60FieldsFieldPolicy = {
	content_photo?: FieldPolicy<any> | FieldReadFunction<any>,
	content_text?: FieldPolicy<any> | FieldReadFunction<any>,
	photo_link?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageBodyContent_text_photo_60_30KeySpecifier = ('fields' | 'label' | 'type' | PageBodyContent_text_photo_60_30KeySpecifier)[];
export type PageBodyContent_text_photo_60_30FieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageBodyContent_text_photo_60_30FieldsKeySpecifier = ('content_photo' | 'content_text' | 'photo_link' | PageBodyContent_text_photo_60_30FieldsKeySpecifier)[];
export type PageBodyContent_text_photo_60_30FieldsFieldPolicy = {
	content_photo?: FieldPolicy<any> | FieldReadFunction<any>,
	content_text?: FieldPolicy<any> | FieldReadFunction<any>,
	photo_link?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageBodyContent_text_text_50KeySpecifier = ('fields' | 'label' | 'type' | PageBodyContent_text_text_50KeySpecifier)[];
export type PageBodyContent_text_text_50FieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageBodyContent_text_text_50FieldsKeySpecifier = ('content_text_left' | 'content_text_right' | PageBodyContent_text_text_50FieldsKeySpecifier)[];
export type PageBodyContent_text_text_50FieldsFieldPolicy = {
	content_text_left?: FieldPolicy<any> | FieldReadFunction<any>,
	content_text_right?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageBodyDownloadfilesKeySpecifier = ('fields' | 'label' | 'type' | PageBodyDownloadfilesKeySpecifier)[];
export type PageBodyDownloadfilesFieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageBodyDownloadfilesFieldsKeySpecifier = ('filecategory' | 'filedescription' | 'filelink' | 'filelogo' | 'filetitle' | PageBodyDownloadfilesFieldsKeySpecifier)[];
export type PageBodyDownloadfilesFieldsFieldPolicy = {
	filecategory?: FieldPolicy<any> | FieldReadFunction<any>,
	filedescription?: FieldPolicy<any> | FieldReadFunction<any>,
	filelink?: FieldPolicy<any> | FieldReadFunction<any>,
	filelogo?: FieldPolicy<any> | FieldReadFunction<any>,
	filetitle?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageBodyExcerptKeySpecifier = ('label' | 'primary' | 'type' | PageBodyExcerptKeySpecifier)[];
export type PageBodyExcerptFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageBodyExcerptPrimaryKeySpecifier = ('text' | PageBodyExcerptPrimaryKeySpecifier)[];
export type PageBodyExcerptPrimaryFieldPolicy = {
	text?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageBodyFormembdedKeySpecifier = ('label' | 'primary' | 'type' | PageBodyFormembdedKeySpecifier)[];
export type PageBodyFormembdedFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageBodyFormembdedPrimaryKeySpecifier = ('embded_link' | PageBodyFormembdedPrimaryKeySpecifier)[];
export type PageBodyFormembdedPrimaryFieldPolicy = {
	embded_link?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageBodyHeroimgKeySpecifier = ('label' | 'primary' | 'type' | PageBodyHeroimgKeySpecifier)[];
export type PageBodyHeroimgFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageBodyHeroimgPrimaryKeySpecifier = ('img' | PageBodyHeroimgPrimaryKeySpecifier)[];
export type PageBodyHeroimgPrimaryFieldPolicy = {
	img?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageBodyHtmlembdedKeySpecifier = ('label' | 'primary' | 'type' | PageBodyHtmlembdedKeySpecifier)[];
export type PageBodyHtmlembdedFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageBodyHtmlembdedPrimaryKeySpecifier = ('html' | PageBodyHtmlembdedPrimaryKeySpecifier)[];
export type PageBodyHtmlembdedPrimaryFieldPolicy = {
	html?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageBodyIconbannerKeySpecifier = ('fields' | 'label' | 'primary' | 'type' | PageBodyIconbannerKeySpecifier)[];
export type PageBodyIconbannerFieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageBodyIconbannerFieldsKeySpecifier = ('img' | 'link' | 'title' | PageBodyIconbannerFieldsKeySpecifier)[];
export type PageBodyIconbannerFieldsFieldPolicy = {
	img?: FieldPolicy<any> | FieldReadFunction<any>,
	link?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageBodyIconbannerPrimaryKeySpecifier = ('title' | PageBodyIconbannerPrimaryKeySpecifier)[];
export type PageBodyIconbannerPrimaryFieldPolicy = {
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageBodyIconctaKeySpecifier = ('fields' | 'label' | 'type' | PageBodyIconctaKeySpecifier)[];
export type PageBodyIconctaFieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageBodyIconctaFieldsKeySpecifier = ('img' | 'link' | 'title' | PageBodyIconctaFieldsKeySpecifier)[];
export type PageBodyIconctaFieldsFieldPolicy = {
	img?: FieldPolicy<any> | FieldReadFunction<any>,
	link?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageBodyImagecardKeySpecifier = ('fields' | 'label' | 'primary' | 'type' | PageBodyImagecardKeySpecifier)[];
export type PageBodyImagecardFieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageBodyImagecardFieldsKeySpecifier = ('card_btn_label' | 'card_btn_link' | 'card_centered' | 'card_content' | 'card_image' | 'card_title' | PageBodyImagecardFieldsKeySpecifier)[];
export type PageBodyImagecardFieldsFieldPolicy = {
	card_btn_label?: FieldPolicy<any> | FieldReadFunction<any>,
	card_btn_link?: FieldPolicy<any> | FieldReadFunction<any>,
	card_centered?: FieldPolicy<any> | FieldReadFunction<any>,
	card_content?: FieldPolicy<any> | FieldReadFunction<any>,
	card_image?: FieldPolicy<any> | FieldReadFunction<any>,
	card_title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageBodyImagecardPrimaryKeySpecifier = ('header_title' | PageBodyImagecardPrimaryKeySpecifier)[];
export type PageBodyImagecardPrimaryFieldPolicy = {
	header_title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageBodyImagectaKeySpecifier = ('label' | 'primary' | 'type' | PageBodyImagectaKeySpecifier)[];
export type PageBodyImagectaFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageBodyImagectaPrimaryKeySpecifier = ('bgcolor' | 'button_label' | 'button_link' | 'content_photo' | 'content_text' | 'content_video' | PageBodyImagectaPrimaryKeySpecifier)[];
export type PageBodyImagectaPrimaryFieldPolicy = {
	bgcolor?: FieldPolicy<any> | FieldReadFunction<any>,
	button_label?: FieldPolicy<any> | FieldReadFunction<any>,
	button_link?: FieldPolicy<any> | FieldReadFunction<any>,
	content_photo?: FieldPolicy<any> | FieldReadFunction<any>,
	content_text?: FieldPolicy<any> | FieldReadFunction<any>,
	content_video?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageBodyLinkbuttonKeySpecifier = ('label' | 'primary' | 'type' | PageBodyLinkbuttonKeySpecifier)[];
export type PageBodyLinkbuttonFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageBodyLinkbuttonPrimaryKeySpecifier = ('button_label' | 'button_link' | PageBodyLinkbuttonPrimaryKeySpecifier)[];
export type PageBodyLinkbuttonPrimaryFieldPolicy = {
	button_label?: FieldPolicy<any> | FieldReadFunction<any>,
	button_link?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageBodyMediasliderKeySpecifier = ('fields' | 'label' | 'type' | PageBodyMediasliderKeySpecifier)[];
export type PageBodyMediasliderFieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageBodyMediasliderFieldsKeySpecifier = ('media' | 'media_type' | PageBodyMediasliderFieldsKeySpecifier)[];
export type PageBodyMediasliderFieldsFieldPolicy = {
	media?: FieldPolicy<any> | FieldReadFunction<any>,
	media_type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageBodyOurpartnersKeySpecifier = ('fields' | 'label' | 'primary' | 'type' | PageBodyOurpartnersKeySpecifier)[];
export type PageBodyOurpartnersFieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageBodyOurpartnersFieldsKeySpecifier = ('logo_image' | PageBodyOurpartnersFieldsKeySpecifier)[];
export type PageBodyOurpartnersFieldsFieldPolicy = {
	logo_image?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageBodyOurpartnersPrimaryKeySpecifier = ('our_partners_title' | PageBodyOurpartnersPrimaryKeySpecifier)[];
export type PageBodyOurpartnersPrimaryFieldPolicy = {
	our_partners_title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageBodyQuoteKeySpecifier = ('label' | 'primary' | 'type' | PageBodyQuoteKeySpecifier)[];
export type PageBodyQuoteFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageBodyQuotePrimaryKeySpecifier = ('quote' | PageBodyQuotePrimaryKeySpecifier)[];
export type PageBodyQuotePrimaryFieldPolicy = {
	quote?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageBodyRelatedproductsKeySpecifier = ('fields' | 'label' | 'primary' | 'type' | PageBodyRelatedproductsKeySpecifier)[];
export type PageBodyRelatedproductsFieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageBodyRelatedproductsFieldsKeySpecifier = ('productid' | PageBodyRelatedproductsFieldsKeySpecifier)[];
export type PageBodyRelatedproductsFieldsFieldPolicy = {
	productid?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageBodyRelatedproductsPrimaryKeySpecifier = ('slicetitle' | PageBodyRelatedproductsPrimaryKeySpecifier)[];
export type PageBodyRelatedproductsPrimaryFieldPolicy = {
	slicetitle?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageBodyRichtextKeySpecifier = ('label' | 'primary' | 'type' | PageBodyRichtextKeySpecifier)[];
export type PageBodyRichtextFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageBodyRichtextPrimaryKeySpecifier = ('classes' | 'rich_text' | PageBodyRichtextPrimaryKeySpecifier)[];
export type PageBodyRichtextPrimaryFieldPolicy = {
	classes?: FieldPolicy<any> | FieldReadFunction<any>,
	rich_text?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageBodyTagimageKeySpecifier = ('fields' | 'label' | 'primary' | 'type' | PageBodyTagimageKeySpecifier)[];
export type PageBodyTagimageFieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageBodyTagimageFieldsKeySpecifier = ('img' | 'imgmobile' | PageBodyTagimageFieldsKeySpecifier)[];
export type PageBodyTagimageFieldsFieldPolicy = {
	img?: FieldPolicy<any> | FieldReadFunction<any>,
	imgmobile?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageBodyTagimagePrimaryKeySpecifier = ('subtitle' | PageBodyTagimagePrimaryKeySpecifier)[];
export type PageBodyTagimagePrimaryFieldPolicy = {
	subtitle?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageConnectionConnectionKeySpecifier = ('edges' | 'pageInfo' | 'totalCount' | PageConnectionConnectionKeySpecifier)[];
export type PageConnectionConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageConnectionEdgeKeySpecifier = ('cursor' | 'node' | PageConnectionEdgeKeySpecifier)[];
export type PageConnectionEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageInfoKeySpecifier = ('endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | PageInfoKeySpecifier)[];
export type PageInfoFieldPolicy = {
	endCursor?: FieldPolicy<any> | FieldReadFunction<any>,
	hasNextPage?: FieldPolicy<any> | FieldReadFunction<any>,
	hasPreviousPage?: FieldPolicy<any> | FieldReadFunction<any>,
	startCursor?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Pageschema_1KeySpecifier = ('_linkType' | '_meta' | 'title' | Pageschema_1KeySpecifier)[];
export type Pageschema_1FieldPolicy = {
	_linkType?: FieldPolicy<any> | FieldReadFunction<any>,
	_meta?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Pageschema_1ConnectionConnectionKeySpecifier = ('edges' | 'pageInfo' | 'totalCount' | Pageschema_1ConnectionConnectionKeySpecifier)[];
export type Pageschema_1ConnectionConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type Pageschema_1ConnectionEdgeKeySpecifier = ('cursor' | 'node' | Pageschema_1ConnectionEdgeKeySpecifier)[];
export type Pageschema_1ConnectionEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PagetestKeySpecifier = ('_linkType' | '_meta' | 'test' | 'test_title' | PagetestKeySpecifier)[];
export type PagetestFieldPolicy = {
	_linkType?: FieldPolicy<any> | FieldReadFunction<any>,
	_meta?: FieldPolicy<any> | FieldReadFunction<any>,
	test?: FieldPolicy<any> | FieldReadFunction<any>,
	test_title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PagetestConnectionConnectionKeySpecifier = ('edges' | 'pageInfo' | 'totalCount' | PagetestConnectionConnectionKeySpecifier)[];
export type PagetestConnectionConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PagetestConnectionEdgeKeySpecifier = ('cursor' | 'node' | PagetestConnectionEdgeKeySpecifier)[];
export type PagetestConnectionEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PostKeySpecifier = ('_linkType' | '_meta' | 'body' | 'category' | 'display_name' | 'excerpt' | 'image' | 'index_settings' | 'meta_description' | 'meta_title' | 'share_on_facebook' | 'share_on_twitter' | 'social' | 'taxonomies' | 'title' | PostKeySpecifier)[];
export type PostFieldPolicy = {
	_linkType?: FieldPolicy<any> | FieldReadFunction<any>,
	_meta?: FieldPolicy<any> | FieldReadFunction<any>,
	body?: FieldPolicy<any> | FieldReadFunction<any>,
	category?: FieldPolicy<any> | FieldReadFunction<any>,
	display_name?: FieldPolicy<any> | FieldReadFunction<any>,
	excerpt?: FieldPolicy<any> | FieldReadFunction<any>,
	image?: FieldPolicy<any> | FieldReadFunction<any>,
	index_settings?: FieldPolicy<any> | FieldReadFunction<any>,
	meta_description?: FieldPolicy<any> | FieldReadFunction<any>,
	meta_title?: FieldPolicy<any> | FieldReadFunction<any>,
	share_on_facebook?: FieldPolicy<any> | FieldReadFunction<any>,
	share_on_twitter?: FieldPolicy<any> | FieldReadFunction<any>,
	social?: FieldPolicy<any> | FieldReadFunction<any>,
	taxonomies?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PostBodyGalleryKeySpecifier = ('fields' | 'label' | 'primary' | 'type' | PostBodyGalleryKeySpecifier)[];
export type PostBodyGalleryFieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PostBodyGalleryFieldsKeySpecifier = ('image' | PostBodyGalleryFieldsKeySpecifier)[];
export type PostBodyGalleryFieldsFieldPolicy = {
	image?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PostBodyGalleryPrimaryKeySpecifier = ('link' | 'title' | PostBodyGalleryPrimaryKeySpecifier)[];
export type PostBodyGalleryPrimaryFieldPolicy = {
	link?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PostBodySeparatorKeySpecifier = ('label' | 'type' | PostBodySeparatorKeySpecifier)[];
export type PostBodySeparatorFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PostBodyText_blockKeySpecifier = ('label' | 'primary' | 'type' | PostBodyText_blockKeySpecifier)[];
export type PostBodyText_blockFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PostBodyText_blockPrimaryKeySpecifier = ('rich_text' | 'title' | PostBodyText_blockPrimaryKeySpecifier)[];
export type PostBodyText_blockPrimaryFieldPolicy = {
	rich_text?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PostBodyText_featuredKeySpecifier = ('fields' | 'label' | 'primary' | 'type' | PostBodyText_featuredKeySpecifier)[];
export type PostBodyText_featuredFieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PostBodyText_featuredFieldsKeySpecifier = ('featured_image' | 'featured_position' | 'featured_text' | PostBodyText_featuredFieldsKeySpecifier)[];
export type PostBodyText_featuredFieldsFieldPolicy = {
	featured_image?: FieldPolicy<any> | FieldReadFunction<any>,
	featured_position?: FieldPolicy<any> | FieldReadFunction<any>,
	featured_text?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PostBodyText_featuredPrimaryKeySpecifier = ('link' | 'title' | PostBodyText_featuredPrimaryKeySpecifier)[];
export type PostBodyText_featuredPrimaryFieldPolicy = {
	link?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PostBodyVideoKeySpecifier = ('label' | 'primary' | 'type' | PostBodyVideoKeySpecifier)[];
export type PostBodyVideoFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PostBodyVideoPrimaryKeySpecifier = ('embed' | PostBodyVideoPrimaryKeySpecifier)[];
export type PostBodyVideoPrimaryFieldPolicy = {
	embed?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PostConnectionConnectionKeySpecifier = ('edges' | 'pageInfo' | 'totalCount' | PostConnectionConnectionKeySpecifier)[];
export type PostConnectionConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PostConnectionEdgeKeySpecifier = ('cursor' | 'node' | PostConnectionEdgeKeySpecifier)[];
export type PostConnectionEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PostSocialOpen_graphKeySpecifier = ('label' | 'primary' | 'type' | PostSocialOpen_graphKeySpecifier)[];
export type PostSocialOpen_graphFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PostSocialOpen_graphPrimaryKeySpecifier = ('og_description' | 'og_image' | 'og_title' | PostSocialOpen_graphPrimaryKeySpecifier)[];
export type PostSocialOpen_graphPrimaryFieldPolicy = {
	og_description?: FieldPolicy<any> | FieldReadFunction<any>,
	og_image?: FieldPolicy<any> | FieldReadFunction<any>,
	og_title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PostSocialPinterestKeySpecifier = ('label' | 'primary' | 'type' | PostSocialPinterestKeySpecifier)[];
export type PostSocialPinterestFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PostSocialPinterestPrimaryKeySpecifier = ('pinterest_post_title' | PostSocialPinterestPrimaryKeySpecifier)[];
export type PostSocialPinterestPrimaryFieldPolicy = {
	pinterest_post_title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PostSocialTwitter_cardKeySpecifier = ('label' | 'primary' | 'type' | PostSocialTwitter_cardKeySpecifier)[];
export type PostSocialTwitter_cardFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PostSocialTwitter_cardPrimaryKeySpecifier = ('twitter_description' | 'twitter_image' | 'twitter_title' | PostSocialTwitter_cardPrimaryKeySpecifier)[];
export type PostSocialTwitter_cardPrimaryFieldPolicy = {
	twitter_description?: FieldPolicy<any> | FieldReadFunction<any>,
	twitter_image?: FieldPolicy<any> | FieldReadFunction<any>,
	twitter_title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PostTaxonomiesKeySpecifier = ('taxonomy' | PostTaxonomiesKeySpecifier)[];
export type PostTaxonomiesFieldPolicy = {
	taxonomy?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PrismicpageKeySpecifier = ('_linkType' | '_meta' | 'body' | PrismicpageKeySpecifier)[];
export type PrismicpageFieldPolicy = {
	_linkType?: FieldPolicy<any> | FieldReadFunction<any>,
	_meta?: FieldPolicy<any> | FieldReadFunction<any>,
	body?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PrismicpageBodyBenefitsKeySpecifier = ('fields' | 'label' | 'primary' | 'type' | PrismicpageBodyBenefitsKeySpecifier)[];
export type PrismicpageBodyBenefitsFieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PrismicpageBodyBenefitsFieldsKeySpecifier = ('img_default' | 'img_hover' | 'img_shade' | 'more_text' | 'text' | 'title' | PrismicpageBodyBenefitsFieldsKeySpecifier)[];
export type PrismicpageBodyBenefitsFieldsFieldPolicy = {
	img_default?: FieldPolicy<any> | FieldReadFunction<any>,
	img_hover?: FieldPolicy<any> | FieldReadFunction<any>,
	img_shade?: FieldPolicy<any> | FieldReadFunction<any>,
	more_text?: FieldPolicy<any> | FieldReadFunction<any>,
	text?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PrismicpageBodyBenefitsPrimaryKeySpecifier = ('title' | PrismicpageBodyBenefitsPrimaryKeySpecifier)[];
export type PrismicpageBodyBenefitsPrimaryFieldPolicy = {
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PrismicpageBodyColor_sampleKeySpecifier = ('fields' | 'label' | 'primary' | 'type' | PrismicpageBodyColor_sampleKeySpecifier)[];
export type PrismicpageBodyColor_sampleFieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PrismicpageBodyColor_sampleFieldsKeySpecifier = ('color' | PrismicpageBodyColor_sampleFieldsKeySpecifier)[];
export type PrismicpageBodyColor_sampleFieldsFieldPolicy = {
	color?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PrismicpageBodyColor_samplePrimaryKeySpecifier = ('cta_label' | 'cta_link' | 'text' | 'title' | PrismicpageBodyColor_samplePrimaryKeySpecifier)[];
export type PrismicpageBodyColor_samplePrimaryFieldPolicy = {
	cta_label?: FieldPolicy<any> | FieldReadFunction<any>,
	cta_link?: FieldPolicy<any> | FieldReadFunction<any>,
	text?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PrismicpageBodyCtaKeySpecifier = ('label' | 'primary' | 'type' | PrismicpageBodyCtaKeySpecifier)[];
export type PrismicpageBodyCtaFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PrismicpageBodyCtaPrimaryKeySpecifier = ('button_label' | 'button_link' | 'image' | 'text' | 'title' | 'video' | PrismicpageBodyCtaPrimaryKeySpecifier)[];
export type PrismicpageBodyCtaPrimaryFieldPolicy = {
	button_label?: FieldPolicy<any> | FieldReadFunction<any>,
	button_link?: FieldPolicy<any> | FieldReadFunction<any>,
	image?: FieldPolicy<any> | FieldReadFunction<any>,
	text?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>,
	video?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PrismicpageBodyInspirationKeySpecifier = ('fields' | 'label' | 'type' | PrismicpageBodyInspirationKeySpecifier)[];
export type PrismicpageBodyInspirationFieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PrismicpageBodyInspirationFieldsKeySpecifier = ('image' | PrismicpageBodyInspirationFieldsKeySpecifier)[];
export type PrismicpageBodyInspirationFieldsFieldPolicy = {
	image?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PrismicpageBodyInstagramKeySpecifier = ('label' | 'primary' | 'type' | PrismicpageBodyInstagramKeySpecifier)[];
export type PrismicpageBodyInstagramFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PrismicpageBodyInstagramPrimaryKeySpecifier = ('content_relationship' | PrismicpageBodyInstagramPrimaryKeySpecifier)[];
export type PrismicpageBodyInstagramPrimaryFieldPolicy = {
	content_relationship?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PrismicpageBodyTrustedKeySpecifier = ('fields' | 'label' | 'type' | PrismicpageBodyTrustedKeySpecifier)[];
export type PrismicpageBodyTrustedFieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PrismicpageBodyTrustedFieldsKeySpecifier = ('image' | 'text' | PrismicpageBodyTrustedFieldsKeySpecifier)[];
export type PrismicpageBodyTrustedFieldsFieldPolicy = {
	image?: FieldPolicy<any> | FieldReadFunction<any>,
	text?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PrismicpageConnectionConnectionKeySpecifier = ('edges' | 'pageInfo' | 'totalCount' | PrismicpageConnectionConnectionKeySpecifier)[];
export type PrismicpageConnectionConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PrismicpageConnectionEdgeKeySpecifier = ('cursor' | 'node' | PrismicpageConnectionEdgeKeySpecifier)[];
export type PrismicpageConnectionEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProductKeySpecifier = ('_linkType' | '_meta' | 'attributes' | 'body' | 'media' | 'product_name' | ProductKeySpecifier)[];
export type ProductFieldPolicy = {
	_linkType?: FieldPolicy<any> | FieldReadFunction<any>,
	_meta?: FieldPolicy<any> | FieldReadFunction<any>,
	attributes?: FieldPolicy<any> | FieldReadFunction<any>,
	body?: FieldPolicy<any> | FieldReadFunction<any>,
	media?: FieldPolicy<any> | FieldReadFunction<any>,
	product_name?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProductAttributesKeySpecifier = ('attribute_content' | 'attribute_slug' | ProductAttributesKeySpecifier)[];
export type ProductAttributesFieldPolicy = {
	attribute_content?: FieldPolicy<any> | FieldReadFunction<any>,
	attribute_slug?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProductBodyColor_sampleKeySpecifier = ('fields' | 'label' | 'primary' | 'type' | ProductBodyColor_sampleKeySpecifier)[];
export type ProductBodyColor_sampleFieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProductBodyColor_sampleFieldsKeySpecifier = ('color' | ProductBodyColor_sampleFieldsKeySpecifier)[];
export type ProductBodyColor_sampleFieldsFieldPolicy = {
	color?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProductBodyColor_samplePrimaryKeySpecifier = ('cta_label' | 'cta_link' | 'text' | 'title' | ProductBodyColor_samplePrimaryKeySpecifier)[];
export type ProductBodyColor_samplePrimaryFieldPolicy = {
	cta_label?: FieldPolicy<any> | FieldReadFunction<any>,
	cta_link?: FieldPolicy<any> | FieldReadFunction<any>,
	text?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProductBodyInspirationKeySpecifier = ('fields' | 'label' | 'type' | ProductBodyInspirationKeySpecifier)[];
export type ProductBodyInspirationFieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProductBodyInspirationFieldsKeySpecifier = ('image' | ProductBodyInspirationFieldsKeySpecifier)[];
export type ProductBodyInspirationFieldsFieldPolicy = {
	image?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProductBodyInstagram_postsKeySpecifier = ('fields' | 'label' | 'primary' | 'type' | ProductBodyInstagram_postsKeySpecifier)[];
export type ProductBodyInstagram_postsFieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProductBodyInstagram_postsFieldsKeySpecifier = ('instagram_link' | ProductBodyInstagram_postsFieldsKeySpecifier)[];
export type ProductBodyInstagram_postsFieldsFieldPolicy = {
	instagram_link?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProductBodyInstagram_postsPrimaryKeySpecifier = ('text' | 'title' | 'user_name' | ProductBodyInstagram_postsPrimaryKeySpecifier)[];
export type ProductBodyInstagram_postsPrimaryFieldPolicy = {
	text?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>,
	user_name?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProductBodyLabeled_iconsKeySpecifier = ('fields' | 'label' | 'type' | ProductBodyLabeled_iconsKeySpecifier)[];
export type ProductBodyLabeled_iconsFieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProductBodyLabeled_iconsFieldsKeySpecifier = ('icon' | 'label' | 'type' | ProductBodyLabeled_iconsFieldsKeySpecifier)[];
export type ProductBodyLabeled_iconsFieldsFieldPolicy = {
	icon?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProductBodyProduct_detailsKeySpecifier = ('fields' | 'label' | 'primary' | 'type' | ProductBodyProduct_detailsKeySpecifier)[];
export type ProductBodyProduct_detailsFieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProductBodyProduct_detailsFieldsKeySpecifier = ('rich_text' | 'rich_text_2' | 'subtitle' | 'title' | ProductBodyProduct_detailsFieldsKeySpecifier)[];
export type ProductBodyProduct_detailsFieldsFieldPolicy = {
	rich_text?: FieldPolicy<any> | FieldReadFunction<any>,
	rich_text_2?: FieldPolicy<any> | FieldReadFunction<any>,
	subtitle?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProductBodyProduct_detailsPrimaryKeySpecifier = ('title' | ProductBodyProduct_detailsPrimaryKeySpecifier)[];
export type ProductBodyProduct_detailsPrimaryFieldPolicy = {
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProductBodyProduct_featuresKeySpecifier = ('fields' | 'label' | 'primary' | 'type' | ProductBodyProduct_featuresKeySpecifier)[];
export type ProductBodyProduct_featuresFieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProductBodyProduct_featuresFieldsKeySpecifier = ('image' | 'sub_title' | 'text' | 'video' | ProductBodyProduct_featuresFieldsKeySpecifier)[];
export type ProductBodyProduct_featuresFieldsFieldPolicy = {
	image?: FieldPolicy<any> | FieldReadFunction<any>,
	sub_title?: FieldPolicy<any> | FieldReadFunction<any>,
	text?: FieldPolicy<any> | FieldReadFunction<any>,
	video?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProductBodyProduct_featuresPrimaryKeySpecifier = ('icon' | 'subtitle' | 'text' | 'title' | ProductBodyProduct_featuresPrimaryKeySpecifier)[];
export type ProductBodyProduct_featuresPrimaryFieldPolicy = {
	icon?: FieldPolicy<any> | FieldReadFunction<any>,
	subtitle?: FieldPolicy<any> | FieldReadFunction<any>,
	text?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProductConnectionConnectionKeySpecifier = ('edges' | 'pageInfo' | 'totalCount' | ProductConnectionConnectionKeySpecifier)[];
export type ProductConnectionConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProductConnectionEdgeKeySpecifier = ('cursor' | 'node' | ProductConnectionEdgeKeySpecifier)[];
export type ProductConnectionEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProductMediaKeySpecifier = ('image' | 'video' | ProductMediaKeySpecifier)[];
export type ProductMediaFieldPolicy = {
	image?: FieldPolicy<any> | FieldReadFunction<any>,
	video?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProductattributesKeySpecifier = ('_linkType' | '_meta' | 'body' | 'header' | 'message' | 'title' | ProductattributesKeySpecifier)[];
export type ProductattributesFieldPolicy = {
	_linkType?: FieldPolicy<any> | FieldReadFunction<any>,
	_meta?: FieldPolicy<any> | FieldReadFunction<any>,
	body?: FieldPolicy<any> | FieldReadFunction<any>,
	header?: FieldPolicy<any> | FieldReadFunction<any>,
	message?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProductattributesBodyTabsKeySpecifier = ('fields' | 'label' | 'primary' | 'type' | ProductattributesBodyTabsKeySpecifier)[];
export type ProductattributesBodyTabsFieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProductattributesBodyTabsFieldsKeySpecifier = ('tab_content' | 'tab_footer' | 'tab_icon' | 'tab_image' | 'tab_name' | ProductattributesBodyTabsFieldsKeySpecifier)[];
export type ProductattributesBodyTabsFieldsFieldPolicy = {
	tab_content?: FieldPolicy<any> | FieldReadFunction<any>,
	tab_footer?: FieldPolicy<any> | FieldReadFunction<any>,
	tab_icon?: FieldPolicy<any> | FieldReadFunction<any>,
	tab_image?: FieldPolicy<any> | FieldReadFunction<any>,
	tab_name?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProductattributesBodyTabsPrimaryKeySpecifier = ('tab_message' | 'tab_title' | ProductattributesBodyTabsPrimaryKeySpecifier)[];
export type ProductattributesBodyTabsPrimaryFieldPolicy = {
	tab_message?: FieldPolicy<any> | FieldReadFunction<any>,
	tab_title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProductattributesConnectionConnectionKeySpecifier = ('edges' | 'pageInfo' | 'totalCount' | ProductattributesConnectionConnectionKeySpecifier)[];
export type ProductattributesConnectionConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProductattributesConnectionEdgeKeySpecifier = ('cursor' | 'node' | ProductattributesConnectionEdgeKeySpecifier)[];
export type ProductattributesConnectionEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProductimagesKeySpecifier = ('_linkType' | '_meta' | 'images' | ProductimagesKeySpecifier)[];
export type ProductimagesFieldPolicy = {
	_linkType?: FieldPolicy<any> | FieldReadFunction<any>,
	_meta?: FieldPolicy<any> | FieldReadFunction<any>,
	images?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProductimagesConnectionConnectionKeySpecifier = ('edges' | 'pageInfo' | 'totalCount' | ProductimagesConnectionConnectionKeySpecifier)[];
export type ProductimagesConnectionConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProductimagesConnectionEdgeKeySpecifier = ('cursor' | 'node' | ProductimagesConnectionEdgeKeySpecifier)[];
export type ProductimagesConnectionEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProductimagesImagesKeySpecifier = ('image' | ProductimagesImagesKeySpecifier)[];
export type ProductimagesImagesFieldPolicy = {
	image?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProductsKeySpecifier = ('_linkType' | '_meta' | 'body' | 'other_products_title' | 'product_page' | 'reviews_link' | 'reviews_link_label' | 'reviews_title' | ProductsKeySpecifier)[];
export type ProductsFieldPolicy = {
	_linkType?: FieldPolicy<any> | FieldReadFunction<any>,
	_meta?: FieldPolicy<any> | FieldReadFunction<any>,
	body?: FieldPolicy<any> | FieldReadFunction<any>,
	other_products_title?: FieldPolicy<any> | FieldReadFunction<any>,
	product_page?: FieldPolicy<any> | FieldReadFunction<any>,
	reviews_link?: FieldPolicy<any> | FieldReadFunction<any>,
	reviews_link_label?: FieldPolicy<any> | FieldReadFunction<any>,
	reviews_title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProductsBodyBenefitKeySpecifier = ('label' | 'primary' | 'type' | ProductsBodyBenefitKeySpecifier)[];
export type ProductsBodyBenefitFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProductsBodyBenefitPrimaryKeySpecifier = ('image' | 'link' | 'link_label' | 'offset' | 'step' | 'text' | 'title' | ProductsBodyBenefitPrimaryKeySpecifier)[];
export type ProductsBodyBenefitPrimaryFieldPolicy = {
	image?: FieldPolicy<any> | FieldReadFunction<any>,
	link?: FieldPolicy<any> | FieldReadFunction<any>,
	link_label?: FieldPolicy<any> | FieldReadFunction<any>,
	offset?: FieldPolicy<any> | FieldReadFunction<any>,
	step?: FieldPolicy<any> | FieldReadFunction<any>,
	text?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProductsConnectionConnectionKeySpecifier = ('edges' | 'pageInfo' | 'totalCount' | ProductsConnectionConnectionKeySpecifier)[];
export type ProductsConnectionConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProductsConnectionEdgeKeySpecifier = ('cursor' | 'node' | ProductsConnectionEdgeKeySpecifier)[];
export type ProductsConnectionEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProductsProduct_pageColor_sampleKeySpecifier = ('fields' | 'label' | 'primary' | 'type' | ProductsProduct_pageColor_sampleKeySpecifier)[];
export type ProductsProduct_pageColor_sampleFieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProductsProduct_pageColor_sampleFieldsKeySpecifier = ('color' | ProductsProduct_pageColor_sampleFieldsKeySpecifier)[];
export type ProductsProduct_pageColor_sampleFieldsFieldPolicy = {
	color?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProductsProduct_pageColor_samplePrimaryKeySpecifier = ('cta_label' | 'cta_link' | 'text' | 'title' | ProductsProduct_pageColor_samplePrimaryKeySpecifier)[];
export type ProductsProduct_pageColor_samplePrimaryFieldPolicy = {
	cta_label?: FieldPolicy<any> | FieldReadFunction<any>,
	cta_link?: FieldPolicy<any> | FieldReadFunction<any>,
	text?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProductsProduct_pageInstagramKeySpecifier = ('label' | 'primary' | 'type' | ProductsProduct_pageInstagramKeySpecifier)[];
export type ProductsProduct_pageInstagramFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProductsProduct_pageInstagramPrimaryKeySpecifier = ('content_relationship' | ProductsProduct_pageInstagramPrimaryKeySpecifier)[];
export type ProductsProduct_pageInstagramPrimaryFieldPolicy = {
	content_relationship?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProjectKeySpecifier = ('_linkType' | '_meta' | 'body' | 'category_list' | 'color' | 'creation_date' | 'description' | 'door_size' | 'glass_type' | 'handle_color' | 'handle_style' | 'height' | 'image' | 'interior_designer_link' | 'interior_designer_view_name' | 'media' | 'meta_description' | 'meta_image' | 'meta_title' | 'product_link' | 'product_view_name' | 'sort_order' | 'tag_list' | 'title' | 'width' | ProjectKeySpecifier)[];
export type ProjectFieldPolicy = {
	_linkType?: FieldPolicy<any> | FieldReadFunction<any>,
	_meta?: FieldPolicy<any> | FieldReadFunction<any>,
	body?: FieldPolicy<any> | FieldReadFunction<any>,
	category_list?: FieldPolicy<any> | FieldReadFunction<any>,
	color?: FieldPolicy<any> | FieldReadFunction<any>,
	creation_date?: FieldPolicy<any> | FieldReadFunction<any>,
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	door_size?: FieldPolicy<any> | FieldReadFunction<any>,
	glass_type?: FieldPolicy<any> | FieldReadFunction<any>,
	handle_color?: FieldPolicy<any> | FieldReadFunction<any>,
	handle_style?: FieldPolicy<any> | FieldReadFunction<any>,
	height?: FieldPolicy<any> | FieldReadFunction<any>,
	image?: FieldPolicy<any> | FieldReadFunction<any>,
	interior_designer_link?: FieldPolicy<any> | FieldReadFunction<any>,
	interior_designer_view_name?: FieldPolicy<any> | FieldReadFunction<any>,
	media?: FieldPolicy<any> | FieldReadFunction<any>,
	meta_description?: FieldPolicy<any> | FieldReadFunction<any>,
	meta_image?: FieldPolicy<any> | FieldReadFunction<any>,
	meta_title?: FieldPolicy<any> | FieldReadFunction<any>,
	product_link?: FieldPolicy<any> | FieldReadFunction<any>,
	product_view_name?: FieldPolicy<any> | FieldReadFunction<any>,
	sort_order?: FieldPolicy<any> | FieldReadFunction<any>,
	tag_list?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>,
	width?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProjectBodyLinkedproductsKeySpecifier = ('fields' | 'label' | 'primary' | 'type' | ProjectBodyLinkedproductsKeySpecifier)[];
export type ProjectBodyLinkedproductsFieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProjectBodyLinkedproductsFieldsKeySpecifier = ('productcategory' | 'productimage' | 'productlink' | 'productname' | 'productprice' | ProjectBodyLinkedproductsFieldsKeySpecifier)[];
export type ProjectBodyLinkedproductsFieldsFieldPolicy = {
	productcategory?: FieldPolicy<any> | FieldReadFunction<any>,
	productimage?: FieldPolicy<any> | FieldReadFunction<any>,
	productlink?: FieldPolicy<any> | FieldReadFunction<any>,
	productname?: FieldPolicy<any> | FieldReadFunction<any>,
	productprice?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProjectBodyLinkedproductsPrimaryKeySpecifier = ('slicetitle' | ProjectBodyLinkedproductsPrimaryKeySpecifier)[];
export type ProjectBodyLinkedproductsPrimaryFieldPolicy = {
	slicetitle?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProjectBodyRelatedproductsKeySpecifier = ('fields' | 'label' | 'primary' | 'type' | ProjectBodyRelatedproductsKeySpecifier)[];
export type ProjectBodyRelatedproductsFieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProjectBodyRelatedproductsFieldsKeySpecifier = ('productid' | ProjectBodyRelatedproductsFieldsKeySpecifier)[];
export type ProjectBodyRelatedproductsFieldsFieldPolicy = {
	productid?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProjectBodyRelatedproductsPrimaryKeySpecifier = ('slicetitle' | ProjectBodyRelatedproductsPrimaryKeySpecifier)[];
export type ProjectBodyRelatedproductsPrimaryFieldPolicy = {
	slicetitle?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProjectCategory_listKeySpecifier = ('category' | ProjectCategory_listKeySpecifier)[];
export type ProjectCategory_listFieldPolicy = {
	category?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProjectConnectionConnectionKeySpecifier = ('edges' | 'pageInfo' | 'totalCount' | ProjectConnectionConnectionKeySpecifier)[];
export type ProjectConnectionConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProjectConnectionEdgeKeySpecifier = ('cursor' | 'node' | ProjectConnectionEdgeKeySpecifier)[];
export type ProjectConnectionEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProjectMediaKeySpecifier = ('image1' | 'left' | 'link' | 'text' | 'top' | 'video' | ProjectMediaKeySpecifier)[];
export type ProjectMediaFieldPolicy = {
	image1?: FieldPolicy<any> | FieldReadFunction<any>,
	left?: FieldPolicy<any> | FieldReadFunction<any>,
	link?: FieldPolicy<any> | FieldReadFunction<any>,
	text?: FieldPolicy<any> | FieldReadFunction<any>,
	top?: FieldPolicy<any> | FieldReadFunction<any>,
	video?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProjectTag_listKeySpecifier = ('tag_item' | ProjectTag_listKeySpecifier)[];
export type ProjectTag_listFieldPolicy = {
	tag_item?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProjectcategoryKeySpecifier = ('_linkType' | '_meta' | 'description' | 'image' | 'name' | ProjectcategoryKeySpecifier)[];
export type ProjectcategoryFieldPolicy = {
	_linkType?: FieldPolicy<any> | FieldReadFunction<any>,
	_meta?: FieldPolicy<any> | FieldReadFunction<any>,
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	image?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProjectcategoryConnectionConnectionKeySpecifier = ('edges' | 'pageInfo' | 'totalCount' | ProjectcategoryConnectionConnectionKeySpecifier)[];
export type ProjectcategoryConnectionConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProjectcategoryConnectionEdgeKeySpecifier = ('cursor' | 'node' | ProjectcategoryConnectionEdgeKeySpecifier)[];
export type ProjectcategoryConnectionEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProjectcolorKeySpecifier = ('_linkType' | '_meta' | 'name' | ProjectcolorKeySpecifier)[];
export type ProjectcolorFieldPolicy = {
	_linkType?: FieldPolicy<any> | FieldReadFunction<any>,
	_meta?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProjectcolorConnectionConnectionKeySpecifier = ('edges' | 'pageInfo' | 'totalCount' | ProjectcolorConnectionConnectionKeySpecifier)[];
export type ProjectcolorConnectionConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProjectcolorConnectionEdgeKeySpecifier = ('cursor' | 'node' | ProjectcolorConnectionEdgeKeySpecifier)[];
export type ProjectcolorConnectionEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProjectdesignerlinkKeySpecifier = ('_linkType' | '_meta' | 'interior_designer_title' | 'interior_designer_uri' | ProjectdesignerlinkKeySpecifier)[];
export type ProjectdesignerlinkFieldPolicy = {
	_linkType?: FieldPolicy<any> | FieldReadFunction<any>,
	_meta?: FieldPolicy<any> | FieldReadFunction<any>,
	interior_designer_title?: FieldPolicy<any> | FieldReadFunction<any>,
	interior_designer_uri?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProjectdesignerlinkConnectionConnectionKeySpecifier = ('edges' | 'pageInfo' | 'totalCount' | ProjectdesignerlinkConnectionConnectionKeySpecifier)[];
export type ProjectdesignerlinkConnectionConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProjectdesignerlinkConnectionEdgeKeySpecifier = ('cursor' | 'node' | ProjectdesignerlinkConnectionEdgeKeySpecifier)[];
export type ProjectdesignerlinkConnectionEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProjectglasstypeKeySpecifier = ('_linkType' | '_meta' | 'name' | ProjectglasstypeKeySpecifier)[];
export type ProjectglasstypeFieldPolicy = {
	_linkType?: FieldPolicy<any> | FieldReadFunction<any>,
	_meta?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProjectglasstypeConnectionConnectionKeySpecifier = ('edges' | 'pageInfo' | 'totalCount' | ProjectglasstypeConnectionConnectionKeySpecifier)[];
export type ProjectglasstypeConnectionConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProjectglasstypeConnectionEdgeKeySpecifier = ('cursor' | 'node' | ProjectglasstypeConnectionEdgeKeySpecifier)[];
export type ProjectglasstypeConnectionEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProjectsKeySpecifier = ('_linkType' | '_meta' | 'body' | 'title' | ProjectsKeySpecifier)[];
export type ProjectsFieldPolicy = {
	_linkType?: FieldPolicy<any> | FieldReadFunction<any>,
	_meta?: FieldPolicy<any> | FieldReadFunction<any>,
	body?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProjectsBodyGeneral_cardKeySpecifier = ('label' | 'primary' | 'type' | ProjectsBodyGeneral_cardKeySpecifier)[];
export type ProjectsBodyGeneral_cardFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProjectsBodyGeneral_cardPrimaryKeySpecifier = ('description' | 'image' | 'title' | ProjectsBodyGeneral_cardPrimaryKeySpecifier)[];
export type ProjectsBodyGeneral_cardPrimaryFieldPolicy = {
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	image?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProjectsBodyPinterest_product_pinKeySpecifier = ('label' | 'primary' | 'type' | ProjectsBodyPinterest_product_pinKeySpecifier)[];
export type ProjectsBodyPinterest_product_pinFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProjectsBodyPinterest_product_pinPrimaryKeySpecifier = ('availability' | 'currency' | 'description' | 'price' | 'title' | ProjectsBodyPinterest_product_pinPrimaryKeySpecifier)[];
export type ProjectsBodyPinterest_product_pinPrimaryFieldPolicy = {
	availability?: FieldPolicy<any> | FieldReadFunction<any>,
	currency?: FieldPolicy<any> | FieldReadFunction<any>,
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	price?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProjectsBodyTwitter_cardKeySpecifier = ('label' | 'primary' | 'type' | ProjectsBodyTwitter_cardKeySpecifier)[];
export type ProjectsBodyTwitter_cardFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProjectsBodyTwitter_cardPrimaryKeySpecifier = ('card_type' | 'description' | 'image' | 'title' | 'twitter_handle' | ProjectsBodyTwitter_cardPrimaryKeySpecifier)[];
export type ProjectsBodyTwitter_cardPrimaryFieldPolicy = {
	card_type?: FieldPolicy<any> | FieldReadFunction<any>,
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	image?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>,
	twitter_handle?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProjectsConnectionConnectionKeySpecifier = ('edges' | 'pageInfo' | 'totalCount' | ProjectsConnectionConnectionKeySpecifier)[];
export type ProjectsConnectionConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProjectsConnectionEdgeKeySpecifier = ('cursor' | 'node' | ProjectsConnectionEdgeKeySpecifier)[];
export type ProjectsConnectionEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProjecttagKeySpecifier = ('_linkType' | '_meta' | 'name' | ProjecttagKeySpecifier)[];
export type ProjecttagFieldPolicy = {
	_linkType?: FieldPolicy<any> | FieldReadFunction<any>,
	_meta?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProjecttagConnectionConnectionKeySpecifier = ('edges' | 'pageInfo' | 'totalCount' | ProjecttagConnectionConnectionKeySpecifier)[];
export type ProjecttagConnectionConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProjecttagConnectionEdgeKeySpecifier = ('cursor' | 'node' | ProjecttagConnectionEdgeKeySpecifier)[];
export type ProjecttagConnectionEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProjectwidthKeySpecifier = ('_linkType' | '_meta' | 'width' | ProjectwidthKeySpecifier)[];
export type ProjectwidthFieldPolicy = {
	_linkType?: FieldPolicy<any> | FieldReadFunction<any>,
	_meta?: FieldPolicy<any> | FieldReadFunction<any>,
	width?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProjectwidthConnectionConnectionKeySpecifier = ('edges' | 'pageInfo' | 'totalCount' | ProjectwidthConnectionConnectionKeySpecifier)[];
export type ProjectwidthConnectionConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProjectwidthConnectionEdgeKeySpecifier = ('cursor' | 'node' | ProjectwidthConnectionEdgeKeySpecifier)[];
export type ProjectwidthConnectionEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type QueryKeySpecifier = ('_allDocuments' | 'allArchitects' | 'allBannerss' | 'allBlog_categorys' | 'allBlog_contents' | 'allBlog_homes' | 'allBlog_posts' | 'allCartsliders' | 'allCategorys' | 'allContacts' | 'allContactss' | 'allDefaultseos' | 'allDoorss' | 'allFaqs' | 'allHelps' | 'allHomepages' | 'allInstagrams' | 'allLanding_pages' | 'allLayouts' | 'allPages' | 'allPageschema_1s' | 'allPagetests' | 'allPosts' | 'allPrismicpages' | 'allProductattributess' | 'allProductimagess' | 'allProducts' | 'allProductss' | 'allProjectcategorys' | 'allProjectcolors' | 'allProjectdesignerlinks' | 'allProjectglasstypes' | 'allProjects' | 'allProjectss' | 'allProjecttags' | 'allProjectwidths' | 'allRealisations' | 'allReviewss' | 'allSlices' | 'allTags' | 'allTaxonomys' | 'allTests' | 'allWishlists' | 'architect' | 'banners' | 'blog_home' | 'blog_post' | 'cartslider' | 'category' | 'contact' | 'contacts' | 'defaultseo' | 'faq' | 'help' | 'homepage' | 'landing_page' | 'layout' | 'page' | 'pageschema_1' | 'pagetest' | 'post' | 'prismicpage' | 'product' | 'productattributes' | 'productimages' | 'products' | 'project' | 'projectcategory' | 'projectcolor' | 'projectdesignerlink' | 'projectglasstype' | 'projects' | 'projecttag' | 'projectwidth' | 'realisation' | 'reviews' | 'taxonomy' | 'wishlist' | QueryKeySpecifier)[];
export type QueryFieldPolicy = {
	_allDocuments?: FieldPolicy<any> | FieldReadFunction<any>,
	allArchitects?: FieldPolicy<any> | FieldReadFunction<any>,
	allBannerss?: FieldPolicy<any> | FieldReadFunction<any>,
	allBlog_categorys?: FieldPolicy<any> | FieldReadFunction<any>,
	allBlog_contents?: FieldPolicy<any> | FieldReadFunction<any>,
	allBlog_homes?: FieldPolicy<any> | FieldReadFunction<any>,
	allBlog_posts?: FieldPolicy<any> | FieldReadFunction<any>,
	allCartsliders?: FieldPolicy<any> | FieldReadFunction<any>,
	allCategorys?: FieldPolicy<any> | FieldReadFunction<any>,
	allContacts?: FieldPolicy<any> | FieldReadFunction<any>,
	allContactss?: FieldPolicy<any> | FieldReadFunction<any>,
	allDefaultseos?: FieldPolicy<any> | FieldReadFunction<any>,
	allDoorss?: FieldPolicy<any> | FieldReadFunction<any>,
	allFaqs?: FieldPolicy<any> | FieldReadFunction<any>,
	allHelps?: FieldPolicy<any> | FieldReadFunction<any>,
	allHomepages?: FieldPolicy<any> | FieldReadFunction<any>,
	allInstagrams?: FieldPolicy<any> | FieldReadFunction<any>,
	allLanding_pages?: FieldPolicy<any> | FieldReadFunction<any>,
	allLayouts?: FieldPolicy<any> | FieldReadFunction<any>,
	allPages?: FieldPolicy<any> | FieldReadFunction<any>,
	allPageschema_1s?: FieldPolicy<any> | FieldReadFunction<any>,
	allPagetests?: FieldPolicy<any> | FieldReadFunction<any>,
	allPosts?: FieldPolicy<any> | FieldReadFunction<any>,
	allPrismicpages?: FieldPolicy<any> | FieldReadFunction<any>,
	allProductattributess?: FieldPolicy<any> | FieldReadFunction<any>,
	allProductimagess?: FieldPolicy<any> | FieldReadFunction<any>,
	allProducts?: FieldPolicy<any> | FieldReadFunction<any>,
	allProductss?: FieldPolicy<any> | FieldReadFunction<any>,
	allProjectcategorys?: FieldPolicy<any> | FieldReadFunction<any>,
	allProjectcolors?: FieldPolicy<any> | FieldReadFunction<any>,
	allProjectdesignerlinks?: FieldPolicy<any> | FieldReadFunction<any>,
	allProjectglasstypes?: FieldPolicy<any> | FieldReadFunction<any>,
	allProjects?: FieldPolicy<any> | FieldReadFunction<any>,
	allProjectss?: FieldPolicy<any> | FieldReadFunction<any>,
	allProjecttags?: FieldPolicy<any> | FieldReadFunction<any>,
	allProjectwidths?: FieldPolicy<any> | FieldReadFunction<any>,
	allRealisations?: FieldPolicy<any> | FieldReadFunction<any>,
	allReviewss?: FieldPolicy<any> | FieldReadFunction<any>,
	allSlices?: FieldPolicy<any> | FieldReadFunction<any>,
	allTags?: FieldPolicy<any> | FieldReadFunction<any>,
	allTaxonomys?: FieldPolicy<any> | FieldReadFunction<any>,
	allTests?: FieldPolicy<any> | FieldReadFunction<any>,
	allWishlists?: FieldPolicy<any> | FieldReadFunction<any>,
	architect?: FieldPolicy<any> | FieldReadFunction<any>,
	banners?: FieldPolicy<any> | FieldReadFunction<any>,
	blog_home?: FieldPolicy<any> | FieldReadFunction<any>,
	blog_post?: FieldPolicy<any> | FieldReadFunction<any>,
	cartslider?: FieldPolicy<any> | FieldReadFunction<any>,
	category?: FieldPolicy<any> | FieldReadFunction<any>,
	contact?: FieldPolicy<any> | FieldReadFunction<any>,
	contacts?: FieldPolicy<any> | FieldReadFunction<any>,
	defaultseo?: FieldPolicy<any> | FieldReadFunction<any>,
	faq?: FieldPolicy<any> | FieldReadFunction<any>,
	help?: FieldPolicy<any> | FieldReadFunction<any>,
	homepage?: FieldPolicy<any> | FieldReadFunction<any>,
	landing_page?: FieldPolicy<any> | FieldReadFunction<any>,
	layout?: FieldPolicy<any> | FieldReadFunction<any>,
	page?: FieldPolicy<any> | FieldReadFunction<any>,
	pageschema_1?: FieldPolicy<any> | FieldReadFunction<any>,
	pagetest?: FieldPolicy<any> | FieldReadFunction<any>,
	post?: FieldPolicy<any> | FieldReadFunction<any>,
	prismicpage?: FieldPolicy<any> | FieldReadFunction<any>,
	product?: FieldPolicy<any> | FieldReadFunction<any>,
	productattributes?: FieldPolicy<any> | FieldReadFunction<any>,
	productimages?: FieldPolicy<any> | FieldReadFunction<any>,
	products?: FieldPolicy<any> | FieldReadFunction<any>,
	project?: FieldPolicy<any> | FieldReadFunction<any>,
	projectcategory?: FieldPolicy<any> | FieldReadFunction<any>,
	projectcolor?: FieldPolicy<any> | FieldReadFunction<any>,
	projectdesignerlink?: FieldPolicy<any> | FieldReadFunction<any>,
	projectglasstype?: FieldPolicy<any> | FieldReadFunction<any>,
	projects?: FieldPolicy<any> | FieldReadFunction<any>,
	projecttag?: FieldPolicy<any> | FieldReadFunction<any>,
	projectwidth?: FieldPolicy<any> | FieldReadFunction<any>,
	realisation?: FieldPolicy<any> | FieldReadFunction<any>,
	reviews?: FieldPolicy<any> | FieldReadFunction<any>,
	taxonomy?: FieldPolicy<any> | FieldReadFunction<any>,
	wishlist?: FieldPolicy<any> | FieldReadFunction<any>
};
export type RealisationKeySpecifier = ('_linkType' | '_meta' | 'meta_description' | 'meta_image' | 'meta_title' | RealisationKeySpecifier)[];
export type RealisationFieldPolicy = {
	_linkType?: FieldPolicy<any> | FieldReadFunction<any>,
	_meta?: FieldPolicy<any> | FieldReadFunction<any>,
	meta_description?: FieldPolicy<any> | FieldReadFunction<any>,
	meta_image?: FieldPolicy<any> | FieldReadFunction<any>,
	meta_title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type RealisationConnectionConnectionKeySpecifier = ('edges' | 'pageInfo' | 'totalCount' | RealisationConnectionConnectionKeySpecifier)[];
export type RealisationConnectionConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type RealisationConnectionEdgeKeySpecifier = ('cursor' | 'node' | RealisationConnectionEdgeKeySpecifier)[];
export type RealisationConnectionEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type RelatedDocumentKeySpecifier = ('id' | 'lang' | 'type' | 'uid' | RelatedDocumentKeySpecifier)[];
export type RelatedDocumentFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	lang?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>,
	uid?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ReviewsKeySpecifier = ('_linkType' | '_meta' | 'meta_description' | 'meta_image' | 'meta_title' | ReviewsKeySpecifier)[];
export type ReviewsFieldPolicy = {
	_linkType?: FieldPolicy<any> | FieldReadFunction<any>,
	_meta?: FieldPolicy<any> | FieldReadFunction<any>,
	meta_description?: FieldPolicy<any> | FieldReadFunction<any>,
	meta_image?: FieldPolicy<any> | FieldReadFunction<any>,
	meta_title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ReviewsConnectionConnectionKeySpecifier = ('edges' | 'pageInfo' | 'totalCount' | ReviewsConnectionConnectionKeySpecifier)[];
export type ReviewsConnectionConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ReviewsConnectionEdgeKeySpecifier = ('cursor' | 'node' | ReviewsConnectionEdgeKeySpecifier)[];
export type ReviewsConnectionEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SliceKeySpecifier = ('_linkType' | '_meta' | 'body' | SliceKeySpecifier)[];
export type SliceFieldPolicy = {
	_linkType?: FieldPolicy<any> | FieldReadFunction<any>,
	_meta?: FieldPolicy<any> | FieldReadFunction<any>,
	body?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SliceBodyTestsliceKeySpecifier = ('label' | 'type' | SliceBodyTestsliceKeySpecifier)[];
export type SliceBodyTestsliceFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SliceConnectionConnectionKeySpecifier = ('edges' | 'pageInfo' | 'totalCount' | SliceConnectionConnectionKeySpecifier)[];
export type SliceConnectionConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SliceConnectionEdgeKeySpecifier = ('cursor' | 'node' | SliceConnectionEdgeKeySpecifier)[];
export type SliceConnectionEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TagKeySpecifier = ('_linkType' | '_meta' | 'name' | TagKeySpecifier)[];
export type TagFieldPolicy = {
	_linkType?: FieldPolicy<any> | FieldReadFunction<any>,
	_meta?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TagConnectionConnectionKeySpecifier = ('edges' | 'pageInfo' | 'totalCount' | TagConnectionConnectionKeySpecifier)[];
export type TagConnectionConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TagConnectionEdgeKeySpecifier = ('cursor' | 'node' | TagConnectionEdgeKeySpecifier)[];
export type TagConnectionEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TaxonomyKeySpecifier = ('_linkType' | '_meta' | 'excerpt' | 'name' | TaxonomyKeySpecifier)[];
export type TaxonomyFieldPolicy = {
	_linkType?: FieldPolicy<any> | FieldReadFunction<any>,
	_meta?: FieldPolicy<any> | FieldReadFunction<any>,
	excerpt?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TaxonomyConnectionConnectionKeySpecifier = ('edges' | 'pageInfo' | 'totalCount' | TaxonomyConnectionConnectionKeySpecifier)[];
export type TaxonomyConnectionConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TaxonomyConnectionEdgeKeySpecifier = ('cursor' | 'node' | TaxonomyConnectionEdgeKeySpecifier)[];
export type TaxonomyConnectionEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TestKeySpecifier = ('_linkType' | '_meta' | TestKeySpecifier)[];
export type TestFieldPolicy = {
	_linkType?: FieldPolicy<any> | FieldReadFunction<any>,
	_meta?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TestConnectionConnectionKeySpecifier = ('edges' | 'pageInfo' | 'totalCount' | TestConnectionConnectionKeySpecifier)[];
export type TestConnectionConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TestConnectionEdgeKeySpecifier = ('cursor' | 'node' | TestConnectionEdgeKeySpecifier)[];
export type TestConnectionEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type WishlistKeySpecifier = ('_linkType' | '_meta' | 'body' | WishlistKeySpecifier)[];
export type WishlistFieldPolicy = {
	_linkType?: FieldPolicy<any> | FieldReadFunction<any>,
	_meta?: FieldPolicy<any> | FieldReadFunction<any>,
	body?: FieldPolicy<any> | FieldReadFunction<any>
};
export type WishlistBodyColor_sampleKeySpecifier = ('fields' | 'label' | 'primary' | 'type' | WishlistBodyColor_sampleKeySpecifier)[];
export type WishlistBodyColor_sampleFieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	primary?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type WishlistBodyColor_sampleFieldsKeySpecifier = ('color' | WishlistBodyColor_sampleFieldsKeySpecifier)[];
export type WishlistBodyColor_sampleFieldsFieldPolicy = {
	color?: FieldPolicy<any> | FieldReadFunction<any>
};
export type WishlistBodyColor_samplePrimaryKeySpecifier = ('cta_label' | 'cta_link' | 'text' | 'title' | WishlistBodyColor_samplePrimaryKeySpecifier)[];
export type WishlistBodyColor_samplePrimaryFieldPolicy = {
	cta_label?: FieldPolicy<any> | FieldReadFunction<any>,
	cta_link?: FieldPolicy<any> | FieldReadFunction<any>,
	text?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type WishlistConnectionConnectionKeySpecifier = ('edges' | 'pageInfo' | 'totalCount' | WishlistConnectionConnectionKeySpecifier)[];
export type WishlistConnectionConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type WishlistConnectionEdgeKeySpecifier = ('cursor' | 'node' | WishlistConnectionEdgeKeySpecifier)[];
export type WishlistConnectionEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type _DocumentKeySpecifier = ('_meta' | _DocumentKeySpecifier)[];
export type _DocumentFieldPolicy = {
	_meta?: FieldPolicy<any> | FieldReadFunction<any>
};
export type _DocumentConnectionKeySpecifier = ('edges' | 'pageInfo' | 'totalCount' | _DocumentConnectionKeySpecifier)[];
export type _DocumentConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type _DocumentEdgeKeySpecifier = ('cursor' | 'node' | _DocumentEdgeKeySpecifier)[];
export type _DocumentEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type _ExternalLinkKeySpecifier = ('_linkType' | 'target' | 'url' | _ExternalLinkKeySpecifier)[];
export type _ExternalLinkFieldPolicy = {
	_linkType?: FieldPolicy<any> | FieldReadFunction<any>,
	target?: FieldPolicy<any> | FieldReadFunction<any>,
	url?: FieldPolicy<any> | FieldReadFunction<any>
};
export type _FileLinkKeySpecifier = ('_linkType' | 'name' | 'size' | 'url' | _FileLinkKeySpecifier)[];
export type _FileLinkFieldPolicy = {
	_linkType?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	size?: FieldPolicy<any> | FieldReadFunction<any>,
	url?: FieldPolicy<any> | FieldReadFunction<any>
};
export type _ImageLinkKeySpecifier = ('_linkType' | 'height' | 'name' | 'size' | 'url' | 'width' | _ImageLinkKeySpecifier)[];
export type _ImageLinkFieldPolicy = {
	_linkType?: FieldPolicy<any> | FieldReadFunction<any>,
	height?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	size?: FieldPolicy<any> | FieldReadFunction<any>,
	url?: FieldPolicy<any> | FieldReadFunction<any>,
	width?: FieldPolicy<any> | FieldReadFunction<any>
};
export type _LinkableKeySpecifier = ('_linkType' | _LinkableKeySpecifier)[];
export type _LinkableFieldPolicy = {
	_linkType?: FieldPolicy<any> | FieldReadFunction<any>
};
export type StrictTypedTypePolicies = {
	Architect?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ArchitectKeySpecifier | (() => undefined | ArchitectKeySpecifier),
		fields?: ArchitectFieldPolicy,
	},
	ArchitectBodyArchitectreviews?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ArchitectBodyArchitectreviewsKeySpecifier | (() => undefined | ArchitectBodyArchitectreviewsKeySpecifier),
		fields?: ArchitectBodyArchitectreviewsFieldPolicy,
	},
	ArchitectBodyBenefitsprogram?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ArchitectBodyBenefitsprogramKeySpecifier | (() => undefined | ArchitectBodyBenefitsprogramKeySpecifier),
		fields?: ArchitectBodyBenefitsprogramFieldPolicy,
	},
	ArchitectBodyBenefitsprogramFields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ArchitectBodyBenefitsprogramFieldsKeySpecifier | (() => undefined | ArchitectBodyBenefitsprogramFieldsKeySpecifier),
		fields?: ArchitectBodyBenefitsprogramFieldsFieldPolicy,
	},
	ArchitectBodyBenefitsprogramPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ArchitectBodyBenefitsprogramPrimaryKeySpecifier | (() => undefined | ArchitectBodyBenefitsprogramPrimaryKeySpecifier),
		fields?: ArchitectBodyBenefitsprogramPrimaryFieldPolicy,
	},
	ArchitectBodyConsulttoolbox?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ArchitectBodyConsulttoolboxKeySpecifier | (() => undefined | ArchitectBodyConsulttoolboxKeySpecifier),
		fields?: ArchitectBodyConsulttoolboxFieldPolicy,
	},
	ArchitectBodyConsulttoolboxFields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ArchitectBodyConsulttoolboxFieldsKeySpecifier | (() => undefined | ArchitectBodyConsulttoolboxFieldsKeySpecifier),
		fields?: ArchitectBodyConsulttoolboxFieldsFieldPolicy,
	},
	ArchitectBodyConsulttoolboxPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ArchitectBodyConsulttoolboxPrimaryKeySpecifier | (() => undefined | ArchitectBodyConsulttoolboxPrimaryKeySpecifier),
		fields?: ArchitectBodyConsulttoolboxPrimaryFieldPolicy,
	},
	ArchitectBodyFranchisecontact?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ArchitectBodyFranchisecontactKeySpecifier | (() => undefined | ArchitectBodyFranchisecontactKeySpecifier),
		fields?: ArchitectBodyFranchisecontactFieldPolicy,
	},
	ArchitectBodyFranchisecontactFields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ArchitectBodyFranchisecontactFieldsKeySpecifier | (() => undefined | ArchitectBodyFranchisecontactFieldsKeySpecifier),
		fields?: ArchitectBodyFranchisecontactFieldsFieldPolicy,
	},
	ArchitectBodyInspiration?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ArchitectBodyInspirationKeySpecifier | (() => undefined | ArchitectBodyInspirationKeySpecifier),
		fields?: ArchitectBodyInspirationFieldPolicy,
	},
	ArchitectBodyInspirationFields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ArchitectBodyInspirationFieldsKeySpecifier | (() => undefined | ArchitectBodyInspirationFieldsKeySpecifier),
		fields?: ArchitectBodyInspirationFieldsFieldPolicy,
	},
	ArchitectBodyInstagram_posts?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ArchitectBodyInstagram_postsKeySpecifier | (() => undefined | ArchitectBodyInstagram_postsKeySpecifier),
		fields?: ArchitectBodyInstagram_postsFieldPolicy,
	},
	ArchitectBodyInstagram_postsFields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ArchitectBodyInstagram_postsFieldsKeySpecifier | (() => undefined | ArchitectBodyInstagram_postsFieldsKeySpecifier),
		fields?: ArchitectBodyInstagram_postsFieldsFieldPolicy,
	},
	ArchitectBodyInstagram_postsPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ArchitectBodyInstagram_postsPrimaryKeySpecifier | (() => undefined | ArchitectBodyInstagram_postsPrimaryKeySpecifier),
		fields?: ArchitectBodyInstagram_postsPrimaryFieldPolicy,
	},
	ArchitectBodyShowroom?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ArchitectBodyShowroomKeySpecifier | (() => undefined | ArchitectBodyShowroomKeySpecifier),
		fields?: ArchitectBodyShowroomFieldPolicy,
	},
	ArchitectBodyShowroomFields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ArchitectBodyShowroomFieldsKeySpecifier | (() => undefined | ArchitectBodyShowroomFieldsKeySpecifier),
		fields?: ArchitectBodyShowroomFieldsFieldPolicy,
	},
	ArchitectBodyShowroomPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ArchitectBodyShowroomPrimaryKeySpecifier | (() => undefined | ArchitectBodyShowroomPrimaryKeySpecifier),
		fields?: ArchitectBodyShowroomPrimaryFieldPolicy,
	},
	ArchitectConnectionConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ArchitectConnectionConnectionKeySpecifier | (() => undefined | ArchitectConnectionConnectionKeySpecifier),
		fields?: ArchitectConnectionConnectionFieldPolicy,
	},
	ArchitectConnectionEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ArchitectConnectionEdgeKeySpecifier | (() => undefined | ArchitectConnectionEdgeKeySpecifier),
		fields?: ArchitectConnectionEdgeFieldPolicy,
	},
	Banners?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | BannersKeySpecifier | (() => undefined | BannersKeySpecifier),
		fields?: BannersFieldPolicy,
	},
	BannersBodyBenefit?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | BannersBodyBenefitKeySpecifier | (() => undefined | BannersBodyBenefitKeySpecifier),
		fields?: BannersBodyBenefitFieldPolicy,
	},
	BannersBodyBenefitPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | BannersBodyBenefitPrimaryKeySpecifier | (() => undefined | BannersBodyBenefitPrimaryKeySpecifier),
		fields?: BannersBodyBenefitPrimaryFieldPolicy,
	},
	BannersConnectionConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | BannersConnectionConnectionKeySpecifier | (() => undefined | BannersConnectionConnectionKeySpecifier),
		fields?: BannersConnectionConnectionFieldPolicy,
	},
	BannersConnectionEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | BannersConnectionEdgeKeySpecifier | (() => undefined | BannersConnectionEdgeKeySpecifier),
		fields?: BannersConnectionEdgeFieldPolicy,
	},
	Blog_category?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_categoryKeySpecifier | (() => undefined | Blog_categoryKeySpecifier),
		fields?: Blog_categoryFieldPolicy,
	},
	Blog_categoryConnectionConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_categoryConnectionConnectionKeySpecifier | (() => undefined | Blog_categoryConnectionConnectionKeySpecifier),
		fields?: Blog_categoryConnectionConnectionFieldPolicy,
	},
	Blog_categoryConnectionEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_categoryConnectionEdgeKeySpecifier | (() => undefined | Blog_categoryConnectionEdgeKeySpecifier),
		fields?: Blog_categoryConnectionEdgeFieldPolicy,
	},
	Blog_content?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_contentKeySpecifier | (() => undefined | Blog_contentKeySpecifier),
		fields?: Blog_contentFieldPolicy,
	},
	Blog_contentConnectionConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_contentConnectionConnectionKeySpecifier | (() => undefined | Blog_contentConnectionConnectionKeySpecifier),
		fields?: Blog_contentConnectionConnectionFieldPolicy,
	},
	Blog_contentConnectionEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_contentConnectionEdgeKeySpecifier | (() => undefined | Blog_contentConnectionEdgeKeySpecifier),
		fields?: Blog_contentConnectionEdgeFieldPolicy,
	},
	Blog_home?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_homeKeySpecifier | (() => undefined | Blog_homeKeySpecifier),
		fields?: Blog_homeFieldPolicy,
	},
	Blog_homeBody1Iconbanner?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_homeBody1IconbannerKeySpecifier | (() => undefined | Blog_homeBody1IconbannerKeySpecifier),
		fields?: Blog_homeBody1IconbannerFieldPolicy,
	},
	Blog_homeBody1IconbannerFields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_homeBody1IconbannerFieldsKeySpecifier | (() => undefined | Blog_homeBody1IconbannerFieldsKeySpecifier),
		fields?: Blog_homeBody1IconbannerFieldsFieldPolicy,
	},
	Blog_homeBody1IconbannerPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_homeBody1IconbannerPrimaryKeySpecifier | (() => undefined | Blog_homeBody1IconbannerPrimaryKeySpecifier),
		fields?: Blog_homeBody1IconbannerPrimaryFieldPolicy,
	},
	Blog_homeConnectionConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_homeConnectionConnectionKeySpecifier | (() => undefined | Blog_homeConnectionConnectionKeySpecifier),
		fields?: Blog_homeConnectionConnectionFieldPolicy,
	},
	Blog_homeConnectionEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_homeConnectionEdgeKeySpecifier | (() => undefined | Blog_homeConnectionEdgeKeySpecifier),
		fields?: Blog_homeConnectionEdgeFieldPolicy,
	},
	Blog_post?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_postKeySpecifier | (() => undefined | Blog_postKeySpecifier),
		fields?: Blog_postFieldPolicy,
	},
	Blog_postBody1Facebook__open_graph_?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_postBody1Facebook__open_graph_KeySpecifier | (() => undefined | Blog_postBody1Facebook__open_graph_KeySpecifier),
		fields?: Blog_postBody1Facebook__open_graph_FieldPolicy,
	},
	Blog_postBody1Twitter_card?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_postBody1Twitter_cardKeySpecifier | (() => undefined | Blog_postBody1Twitter_cardKeySpecifier),
		fields?: Blog_postBody1Twitter_cardFieldPolicy,
	},
	Blog_postBody1Twitter_cardPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_postBody1Twitter_cardPrimaryKeySpecifier | (() => undefined | Blog_postBody1Twitter_cardPrimaryKeySpecifier),
		fields?: Blog_postBody1Twitter_cardPrimaryFieldPolicy,
	},
	Blog_postBodyAccordeon?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_postBodyAccordeonKeySpecifier | (() => undefined | Blog_postBodyAccordeonKeySpecifier),
		fields?: Blog_postBodyAccordeonFieldPolicy,
	},
	Blog_postBodyAccordeonFields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_postBodyAccordeonFieldsKeySpecifier | (() => undefined | Blog_postBodyAccordeonFieldsKeySpecifier),
		fields?: Blog_postBodyAccordeonFieldsFieldPolicy,
	},
	Blog_postBodyContent_photo_photo?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_postBodyContent_photo_photoKeySpecifier | (() => undefined | Blog_postBodyContent_photo_photoKeySpecifier),
		fields?: Blog_postBodyContent_photo_photoFieldPolicy,
	},
	Blog_postBodyContent_photo_photoFields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_postBodyContent_photo_photoFieldsKeySpecifier | (() => undefined | Blog_postBodyContent_photo_photoFieldsKeySpecifier),
		fields?: Blog_postBodyContent_photo_photoFieldsFieldPolicy,
	},
	Blog_postBodyContent_photo_photo_50?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_postBodyContent_photo_photo_50KeySpecifier | (() => undefined | Blog_postBodyContent_photo_photo_50KeySpecifier),
		fields?: Blog_postBodyContent_photo_photo_50FieldPolicy,
	},
	Blog_postBodyContent_photo_photo_50Fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_postBodyContent_photo_photo_50FieldsKeySpecifier | (() => undefined | Blog_postBodyContent_photo_photo_50FieldsKeySpecifier),
		fields?: Blog_postBodyContent_photo_photo_50FieldsFieldPolicy,
	},
	Blog_postBodyContent_photo_text?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_postBodyContent_photo_textKeySpecifier | (() => undefined | Blog_postBodyContent_photo_textKeySpecifier),
		fields?: Blog_postBodyContent_photo_textFieldPolicy,
	},
	Blog_postBodyContent_photo_textPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_postBodyContent_photo_textPrimaryKeySpecifier | (() => undefined | Blog_postBodyContent_photo_textPrimaryKeySpecifier),
		fields?: Blog_postBodyContent_photo_textPrimaryFieldPolicy,
	},
	Blog_postBodyContent_photo_text_30_60?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_postBodyContent_photo_text_30_60KeySpecifier | (() => undefined | Blog_postBodyContent_photo_text_30_60KeySpecifier),
		fields?: Blog_postBodyContent_photo_text_30_60FieldPolicy,
	},
	Blog_postBodyContent_photo_text_30_60Fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_postBodyContent_photo_text_30_60FieldsKeySpecifier | (() => undefined | Blog_postBodyContent_photo_text_30_60FieldsKeySpecifier),
		fields?: Blog_postBodyContent_photo_text_30_60FieldsFieldPolicy,
	},
	Blog_postBodyContent_text_photo_60_30?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_postBodyContent_text_photo_60_30KeySpecifier | (() => undefined | Blog_postBodyContent_text_photo_60_30KeySpecifier),
		fields?: Blog_postBodyContent_text_photo_60_30FieldPolicy,
	},
	Blog_postBodyContent_text_photo_60_30Fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_postBodyContent_text_photo_60_30FieldsKeySpecifier | (() => undefined | Blog_postBodyContent_text_photo_60_30FieldsKeySpecifier),
		fields?: Blog_postBodyContent_text_photo_60_30FieldsFieldPolicy,
	},
	Blog_postBodyContent_text_text_50?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_postBodyContent_text_text_50KeySpecifier | (() => undefined | Blog_postBodyContent_text_text_50KeySpecifier),
		fields?: Blog_postBodyContent_text_text_50FieldPolicy,
	},
	Blog_postBodyContent_text_text_50Fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_postBodyContent_text_text_50FieldsKeySpecifier | (() => undefined | Blog_postBodyContent_text_text_50FieldsKeySpecifier),
		fields?: Blog_postBodyContent_text_text_50FieldsFieldPolicy,
	},
	Blog_postBodyDownloadfiles?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_postBodyDownloadfilesKeySpecifier | (() => undefined | Blog_postBodyDownloadfilesKeySpecifier),
		fields?: Blog_postBodyDownloadfilesFieldPolicy,
	},
	Blog_postBodyDownloadfilesFields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_postBodyDownloadfilesFieldsKeySpecifier | (() => undefined | Blog_postBodyDownloadfilesFieldsKeySpecifier),
		fields?: Blog_postBodyDownloadfilesFieldsFieldPolicy,
	},
	Blog_postBodyExcerpt?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_postBodyExcerptKeySpecifier | (() => undefined | Blog_postBodyExcerptKeySpecifier),
		fields?: Blog_postBodyExcerptFieldPolicy,
	},
	Blog_postBodyExcerptPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_postBodyExcerptPrimaryKeySpecifier | (() => undefined | Blog_postBodyExcerptPrimaryKeySpecifier),
		fields?: Blog_postBodyExcerptPrimaryFieldPolicy,
	},
	Blog_postBodyFormembded?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_postBodyFormembdedKeySpecifier | (() => undefined | Blog_postBodyFormembdedKeySpecifier),
		fields?: Blog_postBodyFormembdedFieldPolicy,
	},
	Blog_postBodyFormembdedPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_postBodyFormembdedPrimaryKeySpecifier | (() => undefined | Blog_postBodyFormembdedPrimaryKeySpecifier),
		fields?: Blog_postBodyFormembdedPrimaryFieldPolicy,
	},
	Blog_postBodyHeroimg?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_postBodyHeroimgKeySpecifier | (() => undefined | Blog_postBodyHeroimgKeySpecifier),
		fields?: Blog_postBodyHeroimgFieldPolicy,
	},
	Blog_postBodyHeroimgPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_postBodyHeroimgPrimaryKeySpecifier | (() => undefined | Blog_postBodyHeroimgPrimaryKeySpecifier),
		fields?: Blog_postBodyHeroimgPrimaryFieldPolicy,
	},
	Blog_postBodyHtmlembded?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_postBodyHtmlembdedKeySpecifier | (() => undefined | Blog_postBodyHtmlembdedKeySpecifier),
		fields?: Blog_postBodyHtmlembdedFieldPolicy,
	},
	Blog_postBodyHtmlembdedPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_postBodyHtmlembdedPrimaryKeySpecifier | (() => undefined | Blog_postBodyHtmlembdedPrimaryKeySpecifier),
		fields?: Blog_postBodyHtmlembdedPrimaryFieldPolicy,
	},
	Blog_postBodyIconbanner?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_postBodyIconbannerKeySpecifier | (() => undefined | Blog_postBodyIconbannerKeySpecifier),
		fields?: Blog_postBodyIconbannerFieldPolicy,
	},
	Blog_postBodyIconbannerFields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_postBodyIconbannerFieldsKeySpecifier | (() => undefined | Blog_postBodyIconbannerFieldsKeySpecifier),
		fields?: Blog_postBodyIconbannerFieldsFieldPolicy,
	},
	Blog_postBodyIconbannerPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_postBodyIconbannerPrimaryKeySpecifier | (() => undefined | Blog_postBodyIconbannerPrimaryKeySpecifier),
		fields?: Blog_postBodyIconbannerPrimaryFieldPolicy,
	},
	Blog_postBodyIconcta?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_postBodyIconctaKeySpecifier | (() => undefined | Blog_postBodyIconctaKeySpecifier),
		fields?: Blog_postBodyIconctaFieldPolicy,
	},
	Blog_postBodyIconctaFields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_postBodyIconctaFieldsKeySpecifier | (() => undefined | Blog_postBodyIconctaFieldsKeySpecifier),
		fields?: Blog_postBodyIconctaFieldsFieldPolicy,
	},
	Blog_postBodyImagecard?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_postBodyImagecardKeySpecifier | (() => undefined | Blog_postBodyImagecardKeySpecifier),
		fields?: Blog_postBodyImagecardFieldPolicy,
	},
	Blog_postBodyImagecardFields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_postBodyImagecardFieldsKeySpecifier | (() => undefined | Blog_postBodyImagecardFieldsKeySpecifier),
		fields?: Blog_postBodyImagecardFieldsFieldPolicy,
	},
	Blog_postBodyImagecardPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_postBodyImagecardPrimaryKeySpecifier | (() => undefined | Blog_postBodyImagecardPrimaryKeySpecifier),
		fields?: Blog_postBodyImagecardPrimaryFieldPolicy,
	},
	Blog_postBodyImagecta?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_postBodyImagectaKeySpecifier | (() => undefined | Blog_postBodyImagectaKeySpecifier),
		fields?: Blog_postBodyImagectaFieldPolicy,
	},
	Blog_postBodyImagectaPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_postBodyImagectaPrimaryKeySpecifier | (() => undefined | Blog_postBodyImagectaPrimaryKeySpecifier),
		fields?: Blog_postBodyImagectaPrimaryFieldPolicy,
	},
	Blog_postBodyLinkbutton?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_postBodyLinkbuttonKeySpecifier | (() => undefined | Blog_postBodyLinkbuttonKeySpecifier),
		fields?: Blog_postBodyLinkbuttonFieldPolicy,
	},
	Blog_postBodyLinkbuttonPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_postBodyLinkbuttonPrimaryKeySpecifier | (() => undefined | Blog_postBodyLinkbuttonPrimaryKeySpecifier),
		fields?: Blog_postBodyLinkbuttonPrimaryFieldPolicy,
	},
	Blog_postBodyMediaslider?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_postBodyMediasliderKeySpecifier | (() => undefined | Blog_postBodyMediasliderKeySpecifier),
		fields?: Blog_postBodyMediasliderFieldPolicy,
	},
	Blog_postBodyMediasliderFields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_postBodyMediasliderFieldsKeySpecifier | (() => undefined | Blog_postBodyMediasliderFieldsKeySpecifier),
		fields?: Blog_postBodyMediasliderFieldsFieldPolicy,
	},
	Blog_postBodyOurpartners?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_postBodyOurpartnersKeySpecifier | (() => undefined | Blog_postBodyOurpartnersKeySpecifier),
		fields?: Blog_postBodyOurpartnersFieldPolicy,
	},
	Blog_postBodyOurpartnersFields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_postBodyOurpartnersFieldsKeySpecifier | (() => undefined | Blog_postBodyOurpartnersFieldsKeySpecifier),
		fields?: Blog_postBodyOurpartnersFieldsFieldPolicy,
	},
	Blog_postBodyOurpartnersPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_postBodyOurpartnersPrimaryKeySpecifier | (() => undefined | Blog_postBodyOurpartnersPrimaryKeySpecifier),
		fields?: Blog_postBodyOurpartnersPrimaryFieldPolicy,
	},
	Blog_postBodyQuote?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_postBodyQuoteKeySpecifier | (() => undefined | Blog_postBodyQuoteKeySpecifier),
		fields?: Blog_postBodyQuoteFieldPolicy,
	},
	Blog_postBodyQuotePrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_postBodyQuotePrimaryKeySpecifier | (() => undefined | Blog_postBodyQuotePrimaryKeySpecifier),
		fields?: Blog_postBodyQuotePrimaryFieldPolicy,
	},
	Blog_postBodyRelatedproducts?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_postBodyRelatedproductsKeySpecifier | (() => undefined | Blog_postBodyRelatedproductsKeySpecifier),
		fields?: Blog_postBodyRelatedproductsFieldPolicy,
	},
	Blog_postBodyRelatedproductsFields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_postBodyRelatedproductsFieldsKeySpecifier | (() => undefined | Blog_postBodyRelatedproductsFieldsKeySpecifier),
		fields?: Blog_postBodyRelatedproductsFieldsFieldPolicy,
	},
	Blog_postBodyRelatedproductsPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_postBodyRelatedproductsPrimaryKeySpecifier | (() => undefined | Blog_postBodyRelatedproductsPrimaryKeySpecifier),
		fields?: Blog_postBodyRelatedproductsPrimaryFieldPolicy,
	},
	Blog_postBodyRichtext?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_postBodyRichtextKeySpecifier | (() => undefined | Blog_postBodyRichtextKeySpecifier),
		fields?: Blog_postBodyRichtextFieldPolicy,
	},
	Blog_postBodyRichtextPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_postBodyRichtextPrimaryKeySpecifier | (() => undefined | Blog_postBodyRichtextPrimaryKeySpecifier),
		fields?: Blog_postBodyRichtextPrimaryFieldPolicy,
	},
	Blog_postBodyTagimage?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_postBodyTagimageKeySpecifier | (() => undefined | Blog_postBodyTagimageKeySpecifier),
		fields?: Blog_postBodyTagimageFieldPolicy,
	},
	Blog_postBodyTagimageFields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_postBodyTagimageFieldsKeySpecifier | (() => undefined | Blog_postBodyTagimageFieldsKeySpecifier),
		fields?: Blog_postBodyTagimageFieldsFieldPolicy,
	},
	Blog_postBodyTagimagePrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_postBodyTagimagePrimaryKeySpecifier | (() => undefined | Blog_postBodyTagimagePrimaryKeySpecifier),
		fields?: Blog_postBodyTagimagePrimaryFieldPolicy,
	},
	Blog_postConnectionConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_postConnectionConnectionKeySpecifier | (() => undefined | Blog_postConnectionConnectionKeySpecifier),
		fields?: Blog_postConnectionConnectionFieldPolicy,
	},
	Blog_postConnectionEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_postConnectionEdgeKeySpecifier | (() => undefined | Blog_postConnectionEdgeKeySpecifier),
		fields?: Blog_postConnectionEdgeFieldPolicy,
	},
	Blog_postTags?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_postTagsKeySpecifier | (() => undefined | Blog_postTagsKeySpecifier),
		fields?: Blog_postTagsFieldPolicy,
	},
	Blog_postYou_may_also_like?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Blog_postYou_may_also_likeKeySpecifier | (() => undefined | Blog_postYou_may_also_likeKeySpecifier),
		fields?: Blog_postYou_may_also_likeFieldPolicy,
	},
	Cartslider?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CartsliderKeySpecifier | (() => undefined | CartsliderKeySpecifier),
		fields?: CartsliderFieldPolicy,
	},
	CartsliderBodySlidelist?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CartsliderBodySlidelistKeySpecifier | (() => undefined | CartsliderBodySlidelistKeySpecifier),
		fields?: CartsliderBodySlidelistFieldPolicy,
	},
	CartsliderBodySlidelistFields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CartsliderBodySlidelistFieldsKeySpecifier | (() => undefined | CartsliderBodySlidelistFieldsKeySpecifier),
		fields?: CartsliderBodySlidelistFieldsFieldPolicy,
	},
	CartsliderConnectionConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CartsliderConnectionConnectionKeySpecifier | (() => undefined | CartsliderConnectionConnectionKeySpecifier),
		fields?: CartsliderConnectionConnectionFieldPolicy,
	},
	CartsliderConnectionEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CartsliderConnectionEdgeKeySpecifier | (() => undefined | CartsliderConnectionEdgeKeySpecifier),
		fields?: CartsliderConnectionEdgeFieldPolicy,
	},
	Category?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CategoryKeySpecifier | (() => undefined | CategoryKeySpecifier),
		fields?: CategoryFieldPolicy,
	},
	CategoryBanners?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CategoryBannersKeySpecifier | (() => undefined | CategoryBannersKeySpecifier),
		fields?: CategoryBannersFieldPolicy,
	},
	CategoryConnectionConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CategoryConnectionConnectionKeySpecifier | (() => undefined | CategoryConnectionConnectionKeySpecifier),
		fields?: CategoryConnectionConnectionFieldPolicy,
	},
	CategoryConnectionEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CategoryConnectionEdgeKeySpecifier | (() => undefined | CategoryConnectionEdgeKeySpecifier),
		fields?: CategoryConnectionEdgeFieldPolicy,
	},
	CategoryItems?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CategoryItemsKeySpecifier | (() => undefined | CategoryItemsKeySpecifier),
		fields?: CategoryItemsFieldPolicy,
	},
	CategorySocialOpen_graph?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CategorySocialOpen_graphKeySpecifier | (() => undefined | CategorySocialOpen_graphKeySpecifier),
		fields?: CategorySocialOpen_graphFieldPolicy,
	},
	CategorySocialOpen_graphPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CategorySocialOpen_graphPrimaryKeySpecifier | (() => undefined | CategorySocialOpen_graphPrimaryKeySpecifier),
		fields?: CategorySocialOpen_graphPrimaryFieldPolicy,
	},
	CategorySocialPinterest?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CategorySocialPinterestKeySpecifier | (() => undefined | CategorySocialPinterestKeySpecifier),
		fields?: CategorySocialPinterestFieldPolicy,
	},
	CategorySocialPinterestPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CategorySocialPinterestPrimaryKeySpecifier | (() => undefined | CategorySocialPinterestPrimaryKeySpecifier),
		fields?: CategorySocialPinterestPrimaryFieldPolicy,
	},
	CategorySocialTwitter_card?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CategorySocialTwitter_cardKeySpecifier | (() => undefined | CategorySocialTwitter_cardKeySpecifier),
		fields?: CategorySocialTwitter_cardFieldPolicy,
	},
	CategorySocialTwitter_cardPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CategorySocialTwitter_cardPrimaryKeySpecifier | (() => undefined | CategorySocialTwitter_cardPrimaryKeySpecifier),
		fields?: CategorySocialTwitter_cardPrimaryFieldPolicy,
	},
	Contact?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ContactKeySpecifier | (() => undefined | ContactKeySpecifier),
		fields?: ContactFieldPolicy,
	},
	ContactBodyGallery?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ContactBodyGalleryKeySpecifier | (() => undefined | ContactBodyGalleryKeySpecifier),
		fields?: ContactBodyGalleryFieldPolicy,
	},
	ContactBodyGalleryFields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ContactBodyGalleryFieldsKeySpecifier | (() => undefined | ContactBodyGalleryFieldsKeySpecifier),
		fields?: ContactBodyGalleryFieldsFieldPolicy,
	},
	ContactBodyGalleryPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ContactBodyGalleryPrimaryKeySpecifier | (() => undefined | ContactBodyGalleryPrimaryKeySpecifier),
		fields?: ContactBodyGalleryPrimaryFieldPolicy,
	},
	ContactBodySeparator?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ContactBodySeparatorKeySpecifier | (() => undefined | ContactBodySeparatorKeySpecifier),
		fields?: ContactBodySeparatorFieldPolicy,
	},
	ContactBodyText_block?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ContactBodyText_blockKeySpecifier | (() => undefined | ContactBodyText_blockKeySpecifier),
		fields?: ContactBodyText_blockFieldPolicy,
	},
	ContactBodyText_blockPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ContactBodyText_blockPrimaryKeySpecifier | (() => undefined | ContactBodyText_blockPrimaryKeySpecifier),
		fields?: ContactBodyText_blockPrimaryFieldPolicy,
	},
	ContactBodyText_featured?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ContactBodyText_featuredKeySpecifier | (() => undefined | ContactBodyText_featuredKeySpecifier),
		fields?: ContactBodyText_featuredFieldPolicy,
	},
	ContactBodyText_featuredFields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ContactBodyText_featuredFieldsKeySpecifier | (() => undefined | ContactBodyText_featuredFieldsKeySpecifier),
		fields?: ContactBodyText_featuredFieldsFieldPolicy,
	},
	ContactBodyText_featuredPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ContactBodyText_featuredPrimaryKeySpecifier | (() => undefined | ContactBodyText_featuredPrimaryKeySpecifier),
		fields?: ContactBodyText_featuredPrimaryFieldPolicy,
	},
	ContactBodyVideo?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ContactBodyVideoKeySpecifier | (() => undefined | ContactBodyVideoKeySpecifier),
		fields?: ContactBodyVideoFieldPolicy,
	},
	ContactBodyVideoPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ContactBodyVideoPrimaryKeySpecifier | (() => undefined | ContactBodyVideoPrimaryKeySpecifier),
		fields?: ContactBodyVideoPrimaryFieldPolicy,
	},
	ContactConnectionConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ContactConnectionConnectionKeySpecifier | (() => undefined | ContactConnectionConnectionKeySpecifier),
		fields?: ContactConnectionConnectionFieldPolicy,
	},
	ContactConnectionEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ContactConnectionEdgeKeySpecifier | (() => undefined | ContactConnectionEdgeKeySpecifier),
		fields?: ContactConnectionEdgeFieldPolicy,
	},
	ContactSocialOpen_graph?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ContactSocialOpen_graphKeySpecifier | (() => undefined | ContactSocialOpen_graphKeySpecifier),
		fields?: ContactSocialOpen_graphFieldPolicy,
	},
	ContactSocialOpen_graphPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ContactSocialOpen_graphPrimaryKeySpecifier | (() => undefined | ContactSocialOpen_graphPrimaryKeySpecifier),
		fields?: ContactSocialOpen_graphPrimaryFieldPolicy,
	},
	ContactSocialPinterest?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ContactSocialPinterestKeySpecifier | (() => undefined | ContactSocialPinterestKeySpecifier),
		fields?: ContactSocialPinterestFieldPolicy,
	},
	ContactSocialPinterestPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ContactSocialPinterestPrimaryKeySpecifier | (() => undefined | ContactSocialPinterestPrimaryKeySpecifier),
		fields?: ContactSocialPinterestPrimaryFieldPolicy,
	},
	ContactSocialTwitter_card?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ContactSocialTwitter_cardKeySpecifier | (() => undefined | ContactSocialTwitter_cardKeySpecifier),
		fields?: ContactSocialTwitter_cardFieldPolicy,
	},
	ContactSocialTwitter_cardPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ContactSocialTwitter_cardPrimaryKeySpecifier | (() => undefined | ContactSocialTwitter_cardPrimaryKeySpecifier),
		fields?: ContactSocialTwitter_cardPrimaryFieldPolicy,
	},
	ContactTaxomanies?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ContactTaxomaniesKeySpecifier | (() => undefined | ContactTaxomaniesKeySpecifier),
		fields?: ContactTaxomaniesFieldPolicy,
	},
	Contacts?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ContactsKeySpecifier | (() => undefined | ContactsKeySpecifier),
		fields?: ContactsFieldPolicy,
	},
	ContactsConnectionConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ContactsConnectionConnectionKeySpecifier | (() => undefined | ContactsConnectionConnectionKeySpecifier),
		fields?: ContactsConnectionConnectionFieldPolicy,
	},
	ContactsConnectionEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ContactsConnectionEdgeKeySpecifier | (() => undefined | ContactsConnectionEdgeKeySpecifier),
		fields?: ContactsConnectionEdgeFieldPolicy,
	},
	Defaultseo?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DefaultseoKeySpecifier | (() => undefined | DefaultseoKeySpecifier),
		fields?: DefaultseoFieldPolicy,
	},
	DefaultseoConnectionConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DefaultseoConnectionConnectionKeySpecifier | (() => undefined | DefaultseoConnectionConnectionKeySpecifier),
		fields?: DefaultseoConnectionConnectionFieldPolicy,
	},
	DefaultseoConnectionEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DefaultseoConnectionEdgeKeySpecifier | (() => undefined | DefaultseoConnectionEdgeKeySpecifier),
		fields?: DefaultseoConnectionEdgeFieldPolicy,
	},
	Doors?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DoorsKeySpecifier | (() => undefined | DoorsKeySpecifier),
		fields?: DoorsFieldPolicy,
	},
	DoorsConnectionConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DoorsConnectionConnectionKeySpecifier | (() => undefined | DoorsConnectionConnectionKeySpecifier),
		fields?: DoorsConnectionConnectionFieldPolicy,
	},
	DoorsConnectionEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DoorsConnectionEdgeKeySpecifier | (() => undefined | DoorsConnectionEdgeKeySpecifier),
		fields?: DoorsConnectionEdgeFieldPolicy,
	},
	Faq?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FaqKeySpecifier | (() => undefined | FaqKeySpecifier),
		fields?: FaqFieldPolicy,
	},
	FaqBodyGallery?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FaqBodyGalleryKeySpecifier | (() => undefined | FaqBodyGalleryKeySpecifier),
		fields?: FaqBodyGalleryFieldPolicy,
	},
	FaqBodyGalleryFields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FaqBodyGalleryFieldsKeySpecifier | (() => undefined | FaqBodyGalleryFieldsKeySpecifier),
		fields?: FaqBodyGalleryFieldsFieldPolicy,
	},
	FaqBodyGalleryPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FaqBodyGalleryPrimaryKeySpecifier | (() => undefined | FaqBodyGalleryPrimaryKeySpecifier),
		fields?: FaqBodyGalleryPrimaryFieldPolicy,
	},
	FaqBodySeparator?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FaqBodySeparatorKeySpecifier | (() => undefined | FaqBodySeparatorKeySpecifier),
		fields?: FaqBodySeparatorFieldPolicy,
	},
	FaqBodyText_block?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FaqBodyText_blockKeySpecifier | (() => undefined | FaqBodyText_blockKeySpecifier),
		fields?: FaqBodyText_blockFieldPolicy,
	},
	FaqBodyText_blockPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FaqBodyText_blockPrimaryKeySpecifier | (() => undefined | FaqBodyText_blockPrimaryKeySpecifier),
		fields?: FaqBodyText_blockPrimaryFieldPolicy,
	},
	FaqBodyText_featured?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FaqBodyText_featuredKeySpecifier | (() => undefined | FaqBodyText_featuredKeySpecifier),
		fields?: FaqBodyText_featuredFieldPolicy,
	},
	FaqBodyText_featuredFields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FaqBodyText_featuredFieldsKeySpecifier | (() => undefined | FaqBodyText_featuredFieldsKeySpecifier),
		fields?: FaqBodyText_featuredFieldsFieldPolicy,
	},
	FaqBodyText_featuredPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FaqBodyText_featuredPrimaryKeySpecifier | (() => undefined | FaqBodyText_featuredPrimaryKeySpecifier),
		fields?: FaqBodyText_featuredPrimaryFieldPolicy,
	},
	FaqBodyVideo?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FaqBodyVideoKeySpecifier | (() => undefined | FaqBodyVideoKeySpecifier),
		fields?: FaqBodyVideoFieldPolicy,
	},
	FaqBodyVideoPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FaqBodyVideoPrimaryKeySpecifier | (() => undefined | FaqBodyVideoPrimaryKeySpecifier),
		fields?: FaqBodyVideoPrimaryFieldPolicy,
	},
	FaqConnectionConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FaqConnectionConnectionKeySpecifier | (() => undefined | FaqConnectionConnectionKeySpecifier),
		fields?: FaqConnectionConnectionFieldPolicy,
	},
	FaqConnectionEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FaqConnectionEdgeKeySpecifier | (() => undefined | FaqConnectionEdgeKeySpecifier),
		fields?: FaqConnectionEdgeFieldPolicy,
	},
	FaqRelated_articles?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FaqRelated_articlesKeySpecifier | (() => undefined | FaqRelated_articlesKeySpecifier),
		fields?: FaqRelated_articlesFieldPolicy,
	},
	FaqSocialOpen_graph?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FaqSocialOpen_graphKeySpecifier | (() => undefined | FaqSocialOpen_graphKeySpecifier),
		fields?: FaqSocialOpen_graphFieldPolicy,
	},
	FaqSocialOpen_graphPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FaqSocialOpen_graphPrimaryKeySpecifier | (() => undefined | FaqSocialOpen_graphPrimaryKeySpecifier),
		fields?: FaqSocialOpen_graphPrimaryFieldPolicy,
	},
	FaqSocialPinterest?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FaqSocialPinterestKeySpecifier | (() => undefined | FaqSocialPinterestKeySpecifier),
		fields?: FaqSocialPinterestFieldPolicy,
	},
	FaqSocialPinterestPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FaqSocialPinterestPrimaryKeySpecifier | (() => undefined | FaqSocialPinterestPrimaryKeySpecifier),
		fields?: FaqSocialPinterestPrimaryFieldPolicy,
	},
	FaqSocialTwitter_card?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FaqSocialTwitter_cardKeySpecifier | (() => undefined | FaqSocialTwitter_cardKeySpecifier),
		fields?: FaqSocialTwitter_cardFieldPolicy,
	},
	FaqSocialTwitter_cardPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FaqSocialTwitter_cardPrimaryKeySpecifier | (() => undefined | FaqSocialTwitter_cardPrimaryKeySpecifier),
		fields?: FaqSocialTwitter_cardPrimaryFieldPolicy,
	},
	FaqTaxonomies?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FaqTaxonomiesKeySpecifier | (() => undefined | FaqTaxonomiesKeySpecifier),
		fields?: FaqTaxonomiesFieldPolicy,
	},
	Help?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | HelpKeySpecifier | (() => undefined | HelpKeySpecifier),
		fields?: HelpFieldPolicy,
	},
	HelpBodyCode_snippet?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | HelpBodyCode_snippetKeySpecifier | (() => undefined | HelpBodyCode_snippetKeySpecifier),
		fields?: HelpBodyCode_snippetFieldPolicy,
	},
	HelpBodyCode_snippetPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | HelpBodyCode_snippetPrimaryKeySpecifier | (() => undefined | HelpBodyCode_snippetPrimaryKeySpecifier),
		fields?: HelpBodyCode_snippetPrimaryFieldPolicy,
	},
	HelpBodyHighlighted_text?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | HelpBodyHighlighted_textKeySpecifier | (() => undefined | HelpBodyHighlighted_textKeySpecifier),
		fields?: HelpBodyHighlighted_textFieldPolicy,
	},
	HelpBodyHighlighted_textPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | HelpBodyHighlighted_textPrimaryKeySpecifier | (() => undefined | HelpBodyHighlighted_textPrimaryKeySpecifier),
		fields?: HelpBodyHighlighted_textPrimaryFieldPolicy,
	},
	HelpBodyImage?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | HelpBodyImageKeySpecifier | (() => undefined | HelpBodyImageKeySpecifier),
		fields?: HelpBodyImageFieldPolicy,
	},
	HelpBodyImagePrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | HelpBodyImagePrimaryKeySpecifier | (() => undefined | HelpBodyImagePrimaryKeySpecifier),
		fields?: HelpBodyImagePrimaryFieldPolicy,
	},
	HelpBodyText?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | HelpBodyTextKeySpecifier | (() => undefined | HelpBodyTextKeySpecifier),
		fields?: HelpBodyTextFieldPolicy,
	},
	HelpBodyTextPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | HelpBodyTextPrimaryKeySpecifier | (() => undefined | HelpBodyTextPrimaryKeySpecifier),
		fields?: HelpBodyTextPrimaryFieldPolicy,
	},
	HelpConnectionConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | HelpConnectionConnectionKeySpecifier | (() => undefined | HelpConnectionConnectionKeySpecifier),
		fields?: HelpConnectionConnectionFieldPolicy,
	},
	HelpConnectionEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | HelpConnectionEdgeKeySpecifier | (() => undefined | HelpConnectionEdgeKeySpecifier),
		fields?: HelpConnectionEdgeFieldPolicy,
	},
	Homepage?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | HomepageKeySpecifier | (() => undefined | HomepageKeySpecifier),
		fields?: HomepageFieldPolicy,
	},
	HomepageBodyBenefits?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | HomepageBodyBenefitsKeySpecifier | (() => undefined | HomepageBodyBenefitsKeySpecifier),
		fields?: HomepageBodyBenefitsFieldPolicy,
	},
	HomepageBodyBenefitsFields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | HomepageBodyBenefitsFieldsKeySpecifier | (() => undefined | HomepageBodyBenefitsFieldsKeySpecifier),
		fields?: HomepageBodyBenefitsFieldsFieldPolicy,
	},
	HomepageBodyBenefitsPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | HomepageBodyBenefitsPrimaryKeySpecifier | (() => undefined | HomepageBodyBenefitsPrimaryKeySpecifier),
		fields?: HomepageBodyBenefitsPrimaryFieldPolicy,
	},
	HomepageBodyCollections?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | HomepageBodyCollectionsKeySpecifier | (() => undefined | HomepageBodyCollectionsKeySpecifier),
		fields?: HomepageBodyCollectionsFieldPolicy,
	},
	HomepageBodyCollectionsFields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | HomepageBodyCollectionsFieldsKeySpecifier | (() => undefined | HomepageBodyCollectionsFieldsKeySpecifier),
		fields?: HomepageBodyCollectionsFieldsFieldPolicy,
	},
	HomepageBodyCollectionsPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | HomepageBodyCollectionsPrimaryKeySpecifier | (() => undefined | HomepageBodyCollectionsPrimaryKeySpecifier),
		fields?: HomepageBodyCollectionsPrimaryFieldPolicy,
	},
	HomepageBodyCta?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | HomepageBodyCtaKeySpecifier | (() => undefined | HomepageBodyCtaKeySpecifier),
		fields?: HomepageBodyCtaFieldPolicy,
	},
	HomepageBodyCtaPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | HomepageBodyCtaPrimaryKeySpecifier | (() => undefined | HomepageBodyCtaPrimaryKeySpecifier),
		fields?: HomepageBodyCtaPrimaryFieldPolicy,
	},
	HomepageBodyImagecard?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | HomepageBodyImagecardKeySpecifier | (() => undefined | HomepageBodyImagecardKeySpecifier),
		fields?: HomepageBodyImagecardFieldPolicy,
	},
	HomepageBodyImagecardFields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | HomepageBodyImagecardFieldsKeySpecifier | (() => undefined | HomepageBodyImagecardFieldsKeySpecifier),
		fields?: HomepageBodyImagecardFieldsFieldPolicy,
	},
	HomepageBodyImagecardPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | HomepageBodyImagecardPrimaryKeySpecifier | (() => undefined | HomepageBodyImagecardPrimaryKeySpecifier),
		fields?: HomepageBodyImagecardPrimaryFieldPolicy,
	},
	HomepageBodyImagecta?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | HomepageBodyImagectaKeySpecifier | (() => undefined | HomepageBodyImagectaKeySpecifier),
		fields?: HomepageBodyImagectaFieldPolicy,
	},
	HomepageBodyImagectaPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | HomepageBodyImagectaPrimaryKeySpecifier | (() => undefined | HomepageBodyImagectaPrimaryKeySpecifier),
		fields?: HomepageBodyImagectaPrimaryFieldPolicy,
	},
	HomepageBodyInspiration?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | HomepageBodyInspirationKeySpecifier | (() => undefined | HomepageBodyInspirationKeySpecifier),
		fields?: HomepageBodyInspirationFieldPolicy,
	},
	HomepageBodyInspirationFields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | HomepageBodyInspirationFieldsKeySpecifier | (() => undefined | HomepageBodyInspirationFieldsKeySpecifier),
		fields?: HomepageBodyInspirationFieldsFieldPolicy,
	},
	HomepageBodyInstagram_posts?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | HomepageBodyInstagram_postsKeySpecifier | (() => undefined | HomepageBodyInstagram_postsKeySpecifier),
		fields?: HomepageBodyInstagram_postsFieldPolicy,
	},
	HomepageBodyInstagram_postsFields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | HomepageBodyInstagram_postsFieldsKeySpecifier | (() => undefined | HomepageBodyInstagram_postsFieldsKeySpecifier),
		fields?: HomepageBodyInstagram_postsFieldsFieldPolicy,
	},
	HomepageBodyInstagram_postsPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | HomepageBodyInstagram_postsPrimaryKeySpecifier | (() => undefined | HomepageBodyInstagram_postsPrimaryKeySpecifier),
		fields?: HomepageBodyInstagram_postsPrimaryFieldPolicy,
	},
	HomepageBodyLinkbutton?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | HomepageBodyLinkbuttonKeySpecifier | (() => undefined | HomepageBodyLinkbuttonKeySpecifier),
		fields?: HomepageBodyLinkbuttonFieldPolicy,
	},
	HomepageBodyLinkbuttonPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | HomepageBodyLinkbuttonPrimaryKeySpecifier | (() => undefined | HomepageBodyLinkbuttonPrimaryKeySpecifier),
		fields?: HomepageBodyLinkbuttonPrimaryFieldPolicy,
	},
	HomepageBodyReviews?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | HomepageBodyReviewsKeySpecifier | (() => undefined | HomepageBodyReviewsKeySpecifier),
		fields?: HomepageBodyReviewsFieldPolicy,
	},
	HomepageBodyTrusted?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | HomepageBodyTrustedKeySpecifier | (() => undefined | HomepageBodyTrustedKeySpecifier),
		fields?: HomepageBodyTrustedFieldPolicy,
	},
	HomepageBodyTrustedFields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | HomepageBodyTrustedFieldsKeySpecifier | (() => undefined | HomepageBodyTrustedFieldsKeySpecifier),
		fields?: HomepageBodyTrustedFieldsFieldPolicy,
	},
	HomepageConnectionConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | HomepageConnectionConnectionKeySpecifier | (() => undefined | HomepageConnectionConnectionKeySpecifier),
		fields?: HomepageConnectionConnectionFieldPolicy,
	},
	HomepageConnectionEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | HomepageConnectionEdgeKeySpecifier | (() => undefined | HomepageConnectionEdgeKeySpecifier),
		fields?: HomepageConnectionEdgeFieldPolicy,
	},
	HomepageHero?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | HomepageHeroKeySpecifier | (() => undefined | HomepageHeroKeySpecifier),
		fields?: HomepageHeroFieldPolicy,
	},
	HomepageSocialOpen_graph?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | HomepageSocialOpen_graphKeySpecifier | (() => undefined | HomepageSocialOpen_graphKeySpecifier),
		fields?: HomepageSocialOpen_graphFieldPolicy,
	},
	HomepageSocialOpen_graphPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | HomepageSocialOpen_graphPrimaryKeySpecifier | (() => undefined | HomepageSocialOpen_graphPrimaryKeySpecifier),
		fields?: HomepageSocialOpen_graphPrimaryFieldPolicy,
	},
	HomepageSocialPinterest?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | HomepageSocialPinterestKeySpecifier | (() => undefined | HomepageSocialPinterestKeySpecifier),
		fields?: HomepageSocialPinterestFieldPolicy,
	},
	HomepageSocialPinterestPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | HomepageSocialPinterestPrimaryKeySpecifier | (() => undefined | HomepageSocialPinterestPrimaryKeySpecifier),
		fields?: HomepageSocialPinterestPrimaryFieldPolicy,
	},
	HomepageSocialTwitter_card?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | HomepageSocialTwitter_cardKeySpecifier | (() => undefined | HomepageSocialTwitter_cardKeySpecifier),
		fields?: HomepageSocialTwitter_cardFieldPolicy,
	},
	HomepageSocialTwitter_cardPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | HomepageSocialTwitter_cardPrimaryKeySpecifier | (() => undefined | HomepageSocialTwitter_cardPrimaryKeySpecifier),
		fields?: HomepageSocialTwitter_cardPrimaryFieldPolicy,
	},
	Instagram?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | InstagramKeySpecifier | (() => undefined | InstagramKeySpecifier),
		fields?: InstagramFieldPolicy,
	},
	InstagramConnectionConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | InstagramConnectionConnectionKeySpecifier | (() => undefined | InstagramConnectionConnectionKeySpecifier),
		fields?: InstagramConnectionConnectionFieldPolicy,
	},
	InstagramConnectionEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | InstagramConnectionEdgeKeySpecifier | (() => undefined | InstagramConnectionEdgeKeySpecifier),
		fields?: InstagramConnectionEdgeFieldPolicy,
	},
	InstagramPosts?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | InstagramPostsKeySpecifier | (() => undefined | InstagramPostsKeySpecifier),
		fields?: InstagramPostsFieldPolicy,
	},
	Landing_page?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Landing_pageKeySpecifier | (() => undefined | Landing_pageKeySpecifier),
		fields?: Landing_pageFieldPolicy,
	},
	Landing_pageBody1Facebook__open_graph_?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Landing_pageBody1Facebook__open_graph_KeySpecifier | (() => undefined | Landing_pageBody1Facebook__open_graph_KeySpecifier),
		fields?: Landing_pageBody1Facebook__open_graph_FieldPolicy,
	},
	Landing_pageBody1Twitter_card?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Landing_pageBody1Twitter_cardKeySpecifier | (() => undefined | Landing_pageBody1Twitter_cardKeySpecifier),
		fields?: Landing_pageBody1Twitter_cardFieldPolicy,
	},
	Landing_pageBody1Twitter_cardPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Landing_pageBody1Twitter_cardPrimaryKeySpecifier | (() => undefined | Landing_pageBody1Twitter_cardPrimaryKeySpecifier),
		fields?: Landing_pageBody1Twitter_cardPrimaryFieldPolicy,
	},
	Landing_pageBodyBanner_split?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Landing_pageBodyBanner_splitKeySpecifier | (() => undefined | Landing_pageBodyBanner_splitKeySpecifier),
		fields?: Landing_pageBodyBanner_splitFieldPolicy,
	},
	Landing_pageBodyBanner_splitPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Landing_pageBodyBanner_splitPrimaryKeySpecifier | (() => undefined | Landing_pageBodyBanner_splitPrimaryKeySpecifier),
		fields?: Landing_pageBodyBanner_splitPrimaryFieldPolicy,
	},
	Landing_pageBodyBig_bullet_item?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Landing_pageBodyBig_bullet_itemKeySpecifier | (() => undefined | Landing_pageBodyBig_bullet_itemKeySpecifier),
		fields?: Landing_pageBodyBig_bullet_itemFieldPolicy,
	},
	Landing_pageBodyBig_bullet_itemFields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Landing_pageBodyBig_bullet_itemFieldsKeySpecifier | (() => undefined | Landing_pageBodyBig_bullet_itemFieldsKeySpecifier),
		fields?: Landing_pageBodyBig_bullet_itemFieldsFieldPolicy,
	},
	Landing_pageBodyBig_bullet_itemPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Landing_pageBodyBig_bullet_itemPrimaryKeySpecifier | (() => undefined | Landing_pageBodyBig_bullet_itemPrimaryKeySpecifier),
		fields?: Landing_pageBodyBig_bullet_itemPrimaryFieldPolicy,
	},
	Landing_pageBodyProduct_quote?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Landing_pageBodyProduct_quoteKeySpecifier | (() => undefined | Landing_pageBodyProduct_quoteKeySpecifier),
		fields?: Landing_pageBodyProduct_quoteFieldPolicy,
	},
	Landing_pageBodyProduct_quotePrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Landing_pageBodyProduct_quotePrimaryKeySpecifier | (() => undefined | Landing_pageBodyProduct_quotePrimaryKeySpecifier),
		fields?: Landing_pageBodyProduct_quotePrimaryFieldPolicy,
	},
	Landing_pageConnectionConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Landing_pageConnectionConnectionKeySpecifier | (() => undefined | Landing_pageConnectionConnectionKeySpecifier),
		fields?: Landing_pageConnectionConnectionFieldPolicy,
	},
	Landing_pageConnectionEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Landing_pageConnectionEdgeKeySpecifier | (() => undefined | Landing_pageConnectionEdgeKeySpecifier),
		fields?: Landing_pageConnectionEdgeFieldPolicy,
	},
	Layout?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | LayoutKeySpecifier | (() => undefined | LayoutKeySpecifier),
		fields?: LayoutFieldPolicy,
	},
	LayoutConnectionConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | LayoutConnectionConnectionKeySpecifier | (() => undefined | LayoutConnectionConnectionKeySpecifier),
		fields?: LayoutConnectionConnectionFieldPolicy,
	},
	LayoutConnectionEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | LayoutConnectionEdgeKeySpecifier | (() => undefined | LayoutConnectionEdgeKeySpecifier),
		fields?: LayoutConnectionEdgeFieldPolicy,
	},
	LayoutContact?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | LayoutContactKeySpecifier | (() => undefined | LayoutContactKeySpecifier),
		fields?: LayoutContactFieldPolicy,
	},
	LayoutFooter_navigationNav_group?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | LayoutFooter_navigationNav_groupKeySpecifier | (() => undefined | LayoutFooter_navigationNav_groupKeySpecifier),
		fields?: LayoutFooter_navigationNav_groupFieldPolicy,
	},
	LayoutFooter_navigationNav_groupFields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | LayoutFooter_navigationNav_groupFieldsKeySpecifier | (() => undefined | LayoutFooter_navigationNav_groupFieldsKeySpecifier),
		fields?: LayoutFooter_navigationNav_groupFieldsFieldPolicy,
	},
	LayoutFooter_navigationNav_groupPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | LayoutFooter_navigationNav_groupPrimaryKeySpecifier | (() => undefined | LayoutFooter_navigationNav_groupPrimaryKeySpecifier),
		fields?: LayoutFooter_navigationNav_groupPrimaryFieldPolicy,
	},
	LayoutFooter_navigation_group?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | LayoutFooter_navigation_groupKeySpecifier | (() => undefined | LayoutFooter_navigation_groupKeySpecifier),
		fields?: LayoutFooter_navigation_groupFieldPolicy,
	},
	LayoutFooter_social?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | LayoutFooter_socialKeySpecifier | (() => undefined | LayoutFooter_socialKeySpecifier),
		fields?: LayoutFooter_socialFieldPolicy,
	},
	LayoutHeader_navigationNav_item?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | LayoutHeader_navigationNav_itemKeySpecifier | (() => undefined | LayoutHeader_navigationNav_itemKeySpecifier),
		fields?: LayoutHeader_navigationNav_itemFieldPolicy,
	},
	LayoutHeader_navigationNav_itemFields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | LayoutHeader_navigationNav_itemFieldsKeySpecifier | (() => undefined | LayoutHeader_navigationNav_itemFieldsKeySpecifier),
		fields?: LayoutHeader_navigationNav_itemFieldsFieldPolicy,
	},
	LayoutHeader_navigationNav_itemPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | LayoutHeader_navigationNav_itemPrimaryKeySpecifier | (() => undefined | LayoutHeader_navigationNav_itemPrimaryKeySpecifier),
		fields?: LayoutHeader_navigationNav_itemPrimaryFieldPolicy,
	},
	LayoutModals?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | LayoutModalsKeySpecifier | (() => undefined | LayoutModalsKeySpecifier),
		fields?: LayoutModalsFieldPolicy,
	},
	LayoutRegions?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | LayoutRegionsKeySpecifier | (() => undefined | LayoutRegionsKeySpecifier),
		fields?: LayoutRegionsFieldPolicy,
	},
	Meta?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MetaKeySpecifier | (() => undefined | MetaKeySpecifier),
		fields?: MetaFieldPolicy,
	},
	Page?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageKeySpecifier | (() => undefined | PageKeySpecifier),
		fields?: PageFieldPolicy,
	},
	PageBodyAccordion?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageBodyAccordionKeySpecifier | (() => undefined | PageBodyAccordionKeySpecifier),
		fields?: PageBodyAccordionFieldPolicy,
	},
	PageBodyAccordionFields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageBodyAccordionFieldsKeySpecifier | (() => undefined | PageBodyAccordionFieldsKeySpecifier),
		fields?: PageBodyAccordionFieldsFieldPolicy,
	},
	PageBodyContent_photo_photo?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageBodyContent_photo_photoKeySpecifier | (() => undefined | PageBodyContent_photo_photoKeySpecifier),
		fields?: PageBodyContent_photo_photoFieldPolicy,
	},
	PageBodyContent_photo_photoFields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageBodyContent_photo_photoFieldsKeySpecifier | (() => undefined | PageBodyContent_photo_photoFieldsKeySpecifier),
		fields?: PageBodyContent_photo_photoFieldsFieldPolicy,
	},
	PageBodyContent_photo_photo_50?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageBodyContent_photo_photo_50KeySpecifier | (() => undefined | PageBodyContent_photo_photo_50KeySpecifier),
		fields?: PageBodyContent_photo_photo_50FieldPolicy,
	},
	PageBodyContent_photo_photo_50Fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageBodyContent_photo_photo_50FieldsKeySpecifier | (() => undefined | PageBodyContent_photo_photo_50FieldsKeySpecifier),
		fields?: PageBodyContent_photo_photo_50FieldsFieldPolicy,
	},
	PageBodyContent_photo_text?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageBodyContent_photo_textKeySpecifier | (() => undefined | PageBodyContent_photo_textKeySpecifier),
		fields?: PageBodyContent_photo_textFieldPolicy,
	},
	PageBodyContent_photo_textPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageBodyContent_photo_textPrimaryKeySpecifier | (() => undefined | PageBodyContent_photo_textPrimaryKeySpecifier),
		fields?: PageBodyContent_photo_textPrimaryFieldPolicy,
	},
	PageBodyContent_photo_text_30_60?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageBodyContent_photo_text_30_60KeySpecifier | (() => undefined | PageBodyContent_photo_text_30_60KeySpecifier),
		fields?: PageBodyContent_photo_text_30_60FieldPolicy,
	},
	PageBodyContent_photo_text_30_60Fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageBodyContent_photo_text_30_60FieldsKeySpecifier | (() => undefined | PageBodyContent_photo_text_30_60FieldsKeySpecifier),
		fields?: PageBodyContent_photo_text_30_60FieldsFieldPolicy,
	},
	PageBodyContent_text_photo_60_30?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageBodyContent_text_photo_60_30KeySpecifier | (() => undefined | PageBodyContent_text_photo_60_30KeySpecifier),
		fields?: PageBodyContent_text_photo_60_30FieldPolicy,
	},
	PageBodyContent_text_photo_60_30Fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageBodyContent_text_photo_60_30FieldsKeySpecifier | (() => undefined | PageBodyContent_text_photo_60_30FieldsKeySpecifier),
		fields?: PageBodyContent_text_photo_60_30FieldsFieldPolicy,
	},
	PageBodyContent_text_text_50?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageBodyContent_text_text_50KeySpecifier | (() => undefined | PageBodyContent_text_text_50KeySpecifier),
		fields?: PageBodyContent_text_text_50FieldPolicy,
	},
	PageBodyContent_text_text_50Fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageBodyContent_text_text_50FieldsKeySpecifier | (() => undefined | PageBodyContent_text_text_50FieldsKeySpecifier),
		fields?: PageBodyContent_text_text_50FieldsFieldPolicy,
	},
	PageBodyDownloadfiles?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageBodyDownloadfilesKeySpecifier | (() => undefined | PageBodyDownloadfilesKeySpecifier),
		fields?: PageBodyDownloadfilesFieldPolicy,
	},
	PageBodyDownloadfilesFields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageBodyDownloadfilesFieldsKeySpecifier | (() => undefined | PageBodyDownloadfilesFieldsKeySpecifier),
		fields?: PageBodyDownloadfilesFieldsFieldPolicy,
	},
	PageBodyExcerpt?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageBodyExcerptKeySpecifier | (() => undefined | PageBodyExcerptKeySpecifier),
		fields?: PageBodyExcerptFieldPolicy,
	},
	PageBodyExcerptPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageBodyExcerptPrimaryKeySpecifier | (() => undefined | PageBodyExcerptPrimaryKeySpecifier),
		fields?: PageBodyExcerptPrimaryFieldPolicy,
	},
	PageBodyFormembded?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageBodyFormembdedKeySpecifier | (() => undefined | PageBodyFormembdedKeySpecifier),
		fields?: PageBodyFormembdedFieldPolicy,
	},
	PageBodyFormembdedPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageBodyFormembdedPrimaryKeySpecifier | (() => undefined | PageBodyFormembdedPrimaryKeySpecifier),
		fields?: PageBodyFormembdedPrimaryFieldPolicy,
	},
	PageBodyHeroimg?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageBodyHeroimgKeySpecifier | (() => undefined | PageBodyHeroimgKeySpecifier),
		fields?: PageBodyHeroimgFieldPolicy,
	},
	PageBodyHeroimgPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageBodyHeroimgPrimaryKeySpecifier | (() => undefined | PageBodyHeroimgPrimaryKeySpecifier),
		fields?: PageBodyHeroimgPrimaryFieldPolicy,
	},
	PageBodyHtmlembded?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageBodyHtmlembdedKeySpecifier | (() => undefined | PageBodyHtmlembdedKeySpecifier),
		fields?: PageBodyHtmlembdedFieldPolicy,
	},
	PageBodyHtmlembdedPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageBodyHtmlembdedPrimaryKeySpecifier | (() => undefined | PageBodyHtmlembdedPrimaryKeySpecifier),
		fields?: PageBodyHtmlembdedPrimaryFieldPolicy,
	},
	PageBodyIconbanner?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageBodyIconbannerKeySpecifier | (() => undefined | PageBodyIconbannerKeySpecifier),
		fields?: PageBodyIconbannerFieldPolicy,
	},
	PageBodyIconbannerFields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageBodyIconbannerFieldsKeySpecifier | (() => undefined | PageBodyIconbannerFieldsKeySpecifier),
		fields?: PageBodyIconbannerFieldsFieldPolicy,
	},
	PageBodyIconbannerPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageBodyIconbannerPrimaryKeySpecifier | (() => undefined | PageBodyIconbannerPrimaryKeySpecifier),
		fields?: PageBodyIconbannerPrimaryFieldPolicy,
	},
	PageBodyIconcta?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageBodyIconctaKeySpecifier | (() => undefined | PageBodyIconctaKeySpecifier),
		fields?: PageBodyIconctaFieldPolicy,
	},
	PageBodyIconctaFields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageBodyIconctaFieldsKeySpecifier | (() => undefined | PageBodyIconctaFieldsKeySpecifier),
		fields?: PageBodyIconctaFieldsFieldPolicy,
	},
	PageBodyImagecard?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageBodyImagecardKeySpecifier | (() => undefined | PageBodyImagecardKeySpecifier),
		fields?: PageBodyImagecardFieldPolicy,
	},
	PageBodyImagecardFields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageBodyImagecardFieldsKeySpecifier | (() => undefined | PageBodyImagecardFieldsKeySpecifier),
		fields?: PageBodyImagecardFieldsFieldPolicy,
	},
	PageBodyImagecardPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageBodyImagecardPrimaryKeySpecifier | (() => undefined | PageBodyImagecardPrimaryKeySpecifier),
		fields?: PageBodyImagecardPrimaryFieldPolicy,
	},
	PageBodyImagecta?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageBodyImagectaKeySpecifier | (() => undefined | PageBodyImagectaKeySpecifier),
		fields?: PageBodyImagectaFieldPolicy,
	},
	PageBodyImagectaPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageBodyImagectaPrimaryKeySpecifier | (() => undefined | PageBodyImagectaPrimaryKeySpecifier),
		fields?: PageBodyImagectaPrimaryFieldPolicy,
	},
	PageBodyLinkbutton?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageBodyLinkbuttonKeySpecifier | (() => undefined | PageBodyLinkbuttonKeySpecifier),
		fields?: PageBodyLinkbuttonFieldPolicy,
	},
	PageBodyLinkbuttonPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageBodyLinkbuttonPrimaryKeySpecifier | (() => undefined | PageBodyLinkbuttonPrimaryKeySpecifier),
		fields?: PageBodyLinkbuttonPrimaryFieldPolicy,
	},
	PageBodyMediaslider?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageBodyMediasliderKeySpecifier | (() => undefined | PageBodyMediasliderKeySpecifier),
		fields?: PageBodyMediasliderFieldPolicy,
	},
	PageBodyMediasliderFields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageBodyMediasliderFieldsKeySpecifier | (() => undefined | PageBodyMediasliderFieldsKeySpecifier),
		fields?: PageBodyMediasliderFieldsFieldPolicy,
	},
	PageBodyOurpartners?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageBodyOurpartnersKeySpecifier | (() => undefined | PageBodyOurpartnersKeySpecifier),
		fields?: PageBodyOurpartnersFieldPolicy,
	},
	PageBodyOurpartnersFields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageBodyOurpartnersFieldsKeySpecifier | (() => undefined | PageBodyOurpartnersFieldsKeySpecifier),
		fields?: PageBodyOurpartnersFieldsFieldPolicy,
	},
	PageBodyOurpartnersPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageBodyOurpartnersPrimaryKeySpecifier | (() => undefined | PageBodyOurpartnersPrimaryKeySpecifier),
		fields?: PageBodyOurpartnersPrimaryFieldPolicy,
	},
	PageBodyQuote?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageBodyQuoteKeySpecifier | (() => undefined | PageBodyQuoteKeySpecifier),
		fields?: PageBodyQuoteFieldPolicy,
	},
	PageBodyQuotePrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageBodyQuotePrimaryKeySpecifier | (() => undefined | PageBodyQuotePrimaryKeySpecifier),
		fields?: PageBodyQuotePrimaryFieldPolicy,
	},
	PageBodyRelatedproducts?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageBodyRelatedproductsKeySpecifier | (() => undefined | PageBodyRelatedproductsKeySpecifier),
		fields?: PageBodyRelatedproductsFieldPolicy,
	},
	PageBodyRelatedproductsFields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageBodyRelatedproductsFieldsKeySpecifier | (() => undefined | PageBodyRelatedproductsFieldsKeySpecifier),
		fields?: PageBodyRelatedproductsFieldsFieldPolicy,
	},
	PageBodyRelatedproductsPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageBodyRelatedproductsPrimaryKeySpecifier | (() => undefined | PageBodyRelatedproductsPrimaryKeySpecifier),
		fields?: PageBodyRelatedproductsPrimaryFieldPolicy,
	},
	PageBodyRichtext?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageBodyRichtextKeySpecifier | (() => undefined | PageBodyRichtextKeySpecifier),
		fields?: PageBodyRichtextFieldPolicy,
	},
	PageBodyRichtextPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageBodyRichtextPrimaryKeySpecifier | (() => undefined | PageBodyRichtextPrimaryKeySpecifier),
		fields?: PageBodyRichtextPrimaryFieldPolicy,
	},
	PageBodyTagimage?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageBodyTagimageKeySpecifier | (() => undefined | PageBodyTagimageKeySpecifier),
		fields?: PageBodyTagimageFieldPolicy,
	},
	PageBodyTagimageFields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageBodyTagimageFieldsKeySpecifier | (() => undefined | PageBodyTagimageFieldsKeySpecifier),
		fields?: PageBodyTagimageFieldsFieldPolicy,
	},
	PageBodyTagimagePrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageBodyTagimagePrimaryKeySpecifier | (() => undefined | PageBodyTagimagePrimaryKeySpecifier),
		fields?: PageBodyTagimagePrimaryFieldPolicy,
	},
	PageConnectionConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageConnectionConnectionKeySpecifier | (() => undefined | PageConnectionConnectionKeySpecifier),
		fields?: PageConnectionConnectionFieldPolicy,
	},
	PageConnectionEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageConnectionEdgeKeySpecifier | (() => undefined | PageConnectionEdgeKeySpecifier),
		fields?: PageConnectionEdgeFieldPolicy,
	},
	PageInfo?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageInfoKeySpecifier | (() => undefined | PageInfoKeySpecifier),
		fields?: PageInfoFieldPolicy,
	},
	Pageschema_1?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Pageschema_1KeySpecifier | (() => undefined | Pageschema_1KeySpecifier),
		fields?: Pageschema_1FieldPolicy,
	},
	Pageschema_1ConnectionConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Pageschema_1ConnectionConnectionKeySpecifier | (() => undefined | Pageschema_1ConnectionConnectionKeySpecifier),
		fields?: Pageschema_1ConnectionConnectionFieldPolicy,
	},
	Pageschema_1ConnectionEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | Pageschema_1ConnectionEdgeKeySpecifier | (() => undefined | Pageschema_1ConnectionEdgeKeySpecifier),
		fields?: Pageschema_1ConnectionEdgeFieldPolicy,
	},
	Pagetest?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PagetestKeySpecifier | (() => undefined | PagetestKeySpecifier),
		fields?: PagetestFieldPolicy,
	},
	PagetestConnectionConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PagetestConnectionConnectionKeySpecifier | (() => undefined | PagetestConnectionConnectionKeySpecifier),
		fields?: PagetestConnectionConnectionFieldPolicy,
	},
	PagetestConnectionEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PagetestConnectionEdgeKeySpecifier | (() => undefined | PagetestConnectionEdgeKeySpecifier),
		fields?: PagetestConnectionEdgeFieldPolicy,
	},
	Post?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PostKeySpecifier | (() => undefined | PostKeySpecifier),
		fields?: PostFieldPolicy,
	},
	PostBodyGallery?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PostBodyGalleryKeySpecifier | (() => undefined | PostBodyGalleryKeySpecifier),
		fields?: PostBodyGalleryFieldPolicy,
	},
	PostBodyGalleryFields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PostBodyGalleryFieldsKeySpecifier | (() => undefined | PostBodyGalleryFieldsKeySpecifier),
		fields?: PostBodyGalleryFieldsFieldPolicy,
	},
	PostBodyGalleryPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PostBodyGalleryPrimaryKeySpecifier | (() => undefined | PostBodyGalleryPrimaryKeySpecifier),
		fields?: PostBodyGalleryPrimaryFieldPolicy,
	},
	PostBodySeparator?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PostBodySeparatorKeySpecifier | (() => undefined | PostBodySeparatorKeySpecifier),
		fields?: PostBodySeparatorFieldPolicy,
	},
	PostBodyText_block?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PostBodyText_blockKeySpecifier | (() => undefined | PostBodyText_blockKeySpecifier),
		fields?: PostBodyText_blockFieldPolicy,
	},
	PostBodyText_blockPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PostBodyText_blockPrimaryKeySpecifier | (() => undefined | PostBodyText_blockPrimaryKeySpecifier),
		fields?: PostBodyText_blockPrimaryFieldPolicy,
	},
	PostBodyText_featured?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PostBodyText_featuredKeySpecifier | (() => undefined | PostBodyText_featuredKeySpecifier),
		fields?: PostBodyText_featuredFieldPolicy,
	},
	PostBodyText_featuredFields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PostBodyText_featuredFieldsKeySpecifier | (() => undefined | PostBodyText_featuredFieldsKeySpecifier),
		fields?: PostBodyText_featuredFieldsFieldPolicy,
	},
	PostBodyText_featuredPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PostBodyText_featuredPrimaryKeySpecifier | (() => undefined | PostBodyText_featuredPrimaryKeySpecifier),
		fields?: PostBodyText_featuredPrimaryFieldPolicy,
	},
	PostBodyVideo?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PostBodyVideoKeySpecifier | (() => undefined | PostBodyVideoKeySpecifier),
		fields?: PostBodyVideoFieldPolicy,
	},
	PostBodyVideoPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PostBodyVideoPrimaryKeySpecifier | (() => undefined | PostBodyVideoPrimaryKeySpecifier),
		fields?: PostBodyVideoPrimaryFieldPolicy,
	},
	PostConnectionConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PostConnectionConnectionKeySpecifier | (() => undefined | PostConnectionConnectionKeySpecifier),
		fields?: PostConnectionConnectionFieldPolicy,
	},
	PostConnectionEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PostConnectionEdgeKeySpecifier | (() => undefined | PostConnectionEdgeKeySpecifier),
		fields?: PostConnectionEdgeFieldPolicy,
	},
	PostSocialOpen_graph?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PostSocialOpen_graphKeySpecifier | (() => undefined | PostSocialOpen_graphKeySpecifier),
		fields?: PostSocialOpen_graphFieldPolicy,
	},
	PostSocialOpen_graphPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PostSocialOpen_graphPrimaryKeySpecifier | (() => undefined | PostSocialOpen_graphPrimaryKeySpecifier),
		fields?: PostSocialOpen_graphPrimaryFieldPolicy,
	},
	PostSocialPinterest?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PostSocialPinterestKeySpecifier | (() => undefined | PostSocialPinterestKeySpecifier),
		fields?: PostSocialPinterestFieldPolicy,
	},
	PostSocialPinterestPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PostSocialPinterestPrimaryKeySpecifier | (() => undefined | PostSocialPinterestPrimaryKeySpecifier),
		fields?: PostSocialPinterestPrimaryFieldPolicy,
	},
	PostSocialTwitter_card?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PostSocialTwitter_cardKeySpecifier | (() => undefined | PostSocialTwitter_cardKeySpecifier),
		fields?: PostSocialTwitter_cardFieldPolicy,
	},
	PostSocialTwitter_cardPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PostSocialTwitter_cardPrimaryKeySpecifier | (() => undefined | PostSocialTwitter_cardPrimaryKeySpecifier),
		fields?: PostSocialTwitter_cardPrimaryFieldPolicy,
	},
	PostTaxonomies?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PostTaxonomiesKeySpecifier | (() => undefined | PostTaxonomiesKeySpecifier),
		fields?: PostTaxonomiesFieldPolicy,
	},
	Prismicpage?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PrismicpageKeySpecifier | (() => undefined | PrismicpageKeySpecifier),
		fields?: PrismicpageFieldPolicy,
	},
	PrismicpageBodyBenefits?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PrismicpageBodyBenefitsKeySpecifier | (() => undefined | PrismicpageBodyBenefitsKeySpecifier),
		fields?: PrismicpageBodyBenefitsFieldPolicy,
	},
	PrismicpageBodyBenefitsFields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PrismicpageBodyBenefitsFieldsKeySpecifier | (() => undefined | PrismicpageBodyBenefitsFieldsKeySpecifier),
		fields?: PrismicpageBodyBenefitsFieldsFieldPolicy,
	},
	PrismicpageBodyBenefitsPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PrismicpageBodyBenefitsPrimaryKeySpecifier | (() => undefined | PrismicpageBodyBenefitsPrimaryKeySpecifier),
		fields?: PrismicpageBodyBenefitsPrimaryFieldPolicy,
	},
	PrismicpageBodyColor_sample?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PrismicpageBodyColor_sampleKeySpecifier | (() => undefined | PrismicpageBodyColor_sampleKeySpecifier),
		fields?: PrismicpageBodyColor_sampleFieldPolicy,
	},
	PrismicpageBodyColor_sampleFields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PrismicpageBodyColor_sampleFieldsKeySpecifier | (() => undefined | PrismicpageBodyColor_sampleFieldsKeySpecifier),
		fields?: PrismicpageBodyColor_sampleFieldsFieldPolicy,
	},
	PrismicpageBodyColor_samplePrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PrismicpageBodyColor_samplePrimaryKeySpecifier | (() => undefined | PrismicpageBodyColor_samplePrimaryKeySpecifier),
		fields?: PrismicpageBodyColor_samplePrimaryFieldPolicy,
	},
	PrismicpageBodyCta?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PrismicpageBodyCtaKeySpecifier | (() => undefined | PrismicpageBodyCtaKeySpecifier),
		fields?: PrismicpageBodyCtaFieldPolicy,
	},
	PrismicpageBodyCtaPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PrismicpageBodyCtaPrimaryKeySpecifier | (() => undefined | PrismicpageBodyCtaPrimaryKeySpecifier),
		fields?: PrismicpageBodyCtaPrimaryFieldPolicy,
	},
	PrismicpageBodyInspiration?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PrismicpageBodyInspirationKeySpecifier | (() => undefined | PrismicpageBodyInspirationKeySpecifier),
		fields?: PrismicpageBodyInspirationFieldPolicy,
	},
	PrismicpageBodyInspirationFields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PrismicpageBodyInspirationFieldsKeySpecifier | (() => undefined | PrismicpageBodyInspirationFieldsKeySpecifier),
		fields?: PrismicpageBodyInspirationFieldsFieldPolicy,
	},
	PrismicpageBodyInstagram?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PrismicpageBodyInstagramKeySpecifier | (() => undefined | PrismicpageBodyInstagramKeySpecifier),
		fields?: PrismicpageBodyInstagramFieldPolicy,
	},
	PrismicpageBodyInstagramPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PrismicpageBodyInstagramPrimaryKeySpecifier | (() => undefined | PrismicpageBodyInstagramPrimaryKeySpecifier),
		fields?: PrismicpageBodyInstagramPrimaryFieldPolicy,
	},
	PrismicpageBodyTrusted?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PrismicpageBodyTrustedKeySpecifier | (() => undefined | PrismicpageBodyTrustedKeySpecifier),
		fields?: PrismicpageBodyTrustedFieldPolicy,
	},
	PrismicpageBodyTrustedFields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PrismicpageBodyTrustedFieldsKeySpecifier | (() => undefined | PrismicpageBodyTrustedFieldsKeySpecifier),
		fields?: PrismicpageBodyTrustedFieldsFieldPolicy,
	},
	PrismicpageConnectionConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PrismicpageConnectionConnectionKeySpecifier | (() => undefined | PrismicpageConnectionConnectionKeySpecifier),
		fields?: PrismicpageConnectionConnectionFieldPolicy,
	},
	PrismicpageConnectionEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PrismicpageConnectionEdgeKeySpecifier | (() => undefined | PrismicpageConnectionEdgeKeySpecifier),
		fields?: PrismicpageConnectionEdgeFieldPolicy,
	},
	Product?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProductKeySpecifier | (() => undefined | ProductKeySpecifier),
		fields?: ProductFieldPolicy,
	},
	ProductAttributes?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProductAttributesKeySpecifier | (() => undefined | ProductAttributesKeySpecifier),
		fields?: ProductAttributesFieldPolicy,
	},
	ProductBodyColor_sample?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProductBodyColor_sampleKeySpecifier | (() => undefined | ProductBodyColor_sampleKeySpecifier),
		fields?: ProductBodyColor_sampleFieldPolicy,
	},
	ProductBodyColor_sampleFields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProductBodyColor_sampleFieldsKeySpecifier | (() => undefined | ProductBodyColor_sampleFieldsKeySpecifier),
		fields?: ProductBodyColor_sampleFieldsFieldPolicy,
	},
	ProductBodyColor_samplePrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProductBodyColor_samplePrimaryKeySpecifier | (() => undefined | ProductBodyColor_samplePrimaryKeySpecifier),
		fields?: ProductBodyColor_samplePrimaryFieldPolicy,
	},
	ProductBodyInspiration?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProductBodyInspirationKeySpecifier | (() => undefined | ProductBodyInspirationKeySpecifier),
		fields?: ProductBodyInspirationFieldPolicy,
	},
	ProductBodyInspirationFields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProductBodyInspirationFieldsKeySpecifier | (() => undefined | ProductBodyInspirationFieldsKeySpecifier),
		fields?: ProductBodyInspirationFieldsFieldPolicy,
	},
	ProductBodyInstagram_posts?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProductBodyInstagram_postsKeySpecifier | (() => undefined | ProductBodyInstagram_postsKeySpecifier),
		fields?: ProductBodyInstagram_postsFieldPolicy,
	},
	ProductBodyInstagram_postsFields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProductBodyInstagram_postsFieldsKeySpecifier | (() => undefined | ProductBodyInstagram_postsFieldsKeySpecifier),
		fields?: ProductBodyInstagram_postsFieldsFieldPolicy,
	},
	ProductBodyInstagram_postsPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProductBodyInstagram_postsPrimaryKeySpecifier | (() => undefined | ProductBodyInstagram_postsPrimaryKeySpecifier),
		fields?: ProductBodyInstagram_postsPrimaryFieldPolicy,
	},
	ProductBodyLabeled_icons?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProductBodyLabeled_iconsKeySpecifier | (() => undefined | ProductBodyLabeled_iconsKeySpecifier),
		fields?: ProductBodyLabeled_iconsFieldPolicy,
	},
	ProductBodyLabeled_iconsFields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProductBodyLabeled_iconsFieldsKeySpecifier | (() => undefined | ProductBodyLabeled_iconsFieldsKeySpecifier),
		fields?: ProductBodyLabeled_iconsFieldsFieldPolicy,
	},
	ProductBodyProduct_details?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProductBodyProduct_detailsKeySpecifier | (() => undefined | ProductBodyProduct_detailsKeySpecifier),
		fields?: ProductBodyProduct_detailsFieldPolicy,
	},
	ProductBodyProduct_detailsFields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProductBodyProduct_detailsFieldsKeySpecifier | (() => undefined | ProductBodyProduct_detailsFieldsKeySpecifier),
		fields?: ProductBodyProduct_detailsFieldsFieldPolicy,
	},
	ProductBodyProduct_detailsPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProductBodyProduct_detailsPrimaryKeySpecifier | (() => undefined | ProductBodyProduct_detailsPrimaryKeySpecifier),
		fields?: ProductBodyProduct_detailsPrimaryFieldPolicy,
	},
	ProductBodyProduct_features?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProductBodyProduct_featuresKeySpecifier | (() => undefined | ProductBodyProduct_featuresKeySpecifier),
		fields?: ProductBodyProduct_featuresFieldPolicy,
	},
	ProductBodyProduct_featuresFields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProductBodyProduct_featuresFieldsKeySpecifier | (() => undefined | ProductBodyProduct_featuresFieldsKeySpecifier),
		fields?: ProductBodyProduct_featuresFieldsFieldPolicy,
	},
	ProductBodyProduct_featuresPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProductBodyProduct_featuresPrimaryKeySpecifier | (() => undefined | ProductBodyProduct_featuresPrimaryKeySpecifier),
		fields?: ProductBodyProduct_featuresPrimaryFieldPolicy,
	},
	ProductConnectionConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProductConnectionConnectionKeySpecifier | (() => undefined | ProductConnectionConnectionKeySpecifier),
		fields?: ProductConnectionConnectionFieldPolicy,
	},
	ProductConnectionEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProductConnectionEdgeKeySpecifier | (() => undefined | ProductConnectionEdgeKeySpecifier),
		fields?: ProductConnectionEdgeFieldPolicy,
	},
	ProductMedia?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProductMediaKeySpecifier | (() => undefined | ProductMediaKeySpecifier),
		fields?: ProductMediaFieldPolicy,
	},
	Productattributes?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProductattributesKeySpecifier | (() => undefined | ProductattributesKeySpecifier),
		fields?: ProductattributesFieldPolicy,
	},
	ProductattributesBodyTabs?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProductattributesBodyTabsKeySpecifier | (() => undefined | ProductattributesBodyTabsKeySpecifier),
		fields?: ProductattributesBodyTabsFieldPolicy,
	},
	ProductattributesBodyTabsFields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProductattributesBodyTabsFieldsKeySpecifier | (() => undefined | ProductattributesBodyTabsFieldsKeySpecifier),
		fields?: ProductattributesBodyTabsFieldsFieldPolicy,
	},
	ProductattributesBodyTabsPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProductattributesBodyTabsPrimaryKeySpecifier | (() => undefined | ProductattributesBodyTabsPrimaryKeySpecifier),
		fields?: ProductattributesBodyTabsPrimaryFieldPolicy,
	},
	ProductattributesConnectionConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProductattributesConnectionConnectionKeySpecifier | (() => undefined | ProductattributesConnectionConnectionKeySpecifier),
		fields?: ProductattributesConnectionConnectionFieldPolicy,
	},
	ProductattributesConnectionEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProductattributesConnectionEdgeKeySpecifier | (() => undefined | ProductattributesConnectionEdgeKeySpecifier),
		fields?: ProductattributesConnectionEdgeFieldPolicy,
	},
	Productimages?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProductimagesKeySpecifier | (() => undefined | ProductimagesKeySpecifier),
		fields?: ProductimagesFieldPolicy,
	},
	ProductimagesConnectionConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProductimagesConnectionConnectionKeySpecifier | (() => undefined | ProductimagesConnectionConnectionKeySpecifier),
		fields?: ProductimagesConnectionConnectionFieldPolicy,
	},
	ProductimagesConnectionEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProductimagesConnectionEdgeKeySpecifier | (() => undefined | ProductimagesConnectionEdgeKeySpecifier),
		fields?: ProductimagesConnectionEdgeFieldPolicy,
	},
	ProductimagesImages?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProductimagesImagesKeySpecifier | (() => undefined | ProductimagesImagesKeySpecifier),
		fields?: ProductimagesImagesFieldPolicy,
	},
	Products?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProductsKeySpecifier | (() => undefined | ProductsKeySpecifier),
		fields?: ProductsFieldPolicy,
	},
	ProductsBodyBenefit?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProductsBodyBenefitKeySpecifier | (() => undefined | ProductsBodyBenefitKeySpecifier),
		fields?: ProductsBodyBenefitFieldPolicy,
	},
	ProductsBodyBenefitPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProductsBodyBenefitPrimaryKeySpecifier | (() => undefined | ProductsBodyBenefitPrimaryKeySpecifier),
		fields?: ProductsBodyBenefitPrimaryFieldPolicy,
	},
	ProductsConnectionConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProductsConnectionConnectionKeySpecifier | (() => undefined | ProductsConnectionConnectionKeySpecifier),
		fields?: ProductsConnectionConnectionFieldPolicy,
	},
	ProductsConnectionEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProductsConnectionEdgeKeySpecifier | (() => undefined | ProductsConnectionEdgeKeySpecifier),
		fields?: ProductsConnectionEdgeFieldPolicy,
	},
	ProductsProduct_pageColor_sample?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProductsProduct_pageColor_sampleKeySpecifier | (() => undefined | ProductsProduct_pageColor_sampleKeySpecifier),
		fields?: ProductsProduct_pageColor_sampleFieldPolicy,
	},
	ProductsProduct_pageColor_sampleFields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProductsProduct_pageColor_sampleFieldsKeySpecifier | (() => undefined | ProductsProduct_pageColor_sampleFieldsKeySpecifier),
		fields?: ProductsProduct_pageColor_sampleFieldsFieldPolicy,
	},
	ProductsProduct_pageColor_samplePrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProductsProduct_pageColor_samplePrimaryKeySpecifier | (() => undefined | ProductsProduct_pageColor_samplePrimaryKeySpecifier),
		fields?: ProductsProduct_pageColor_samplePrimaryFieldPolicy,
	},
	ProductsProduct_pageInstagram?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProductsProduct_pageInstagramKeySpecifier | (() => undefined | ProductsProduct_pageInstagramKeySpecifier),
		fields?: ProductsProduct_pageInstagramFieldPolicy,
	},
	ProductsProduct_pageInstagramPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProductsProduct_pageInstagramPrimaryKeySpecifier | (() => undefined | ProductsProduct_pageInstagramPrimaryKeySpecifier),
		fields?: ProductsProduct_pageInstagramPrimaryFieldPolicy,
	},
	Project?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProjectKeySpecifier | (() => undefined | ProjectKeySpecifier),
		fields?: ProjectFieldPolicy,
	},
	ProjectBodyLinkedproducts?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProjectBodyLinkedproductsKeySpecifier | (() => undefined | ProjectBodyLinkedproductsKeySpecifier),
		fields?: ProjectBodyLinkedproductsFieldPolicy,
	},
	ProjectBodyLinkedproductsFields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProjectBodyLinkedproductsFieldsKeySpecifier | (() => undefined | ProjectBodyLinkedproductsFieldsKeySpecifier),
		fields?: ProjectBodyLinkedproductsFieldsFieldPolicy,
	},
	ProjectBodyLinkedproductsPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProjectBodyLinkedproductsPrimaryKeySpecifier | (() => undefined | ProjectBodyLinkedproductsPrimaryKeySpecifier),
		fields?: ProjectBodyLinkedproductsPrimaryFieldPolicy,
	},
	ProjectBodyRelatedproducts?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProjectBodyRelatedproductsKeySpecifier | (() => undefined | ProjectBodyRelatedproductsKeySpecifier),
		fields?: ProjectBodyRelatedproductsFieldPolicy,
	},
	ProjectBodyRelatedproductsFields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProjectBodyRelatedproductsFieldsKeySpecifier | (() => undefined | ProjectBodyRelatedproductsFieldsKeySpecifier),
		fields?: ProjectBodyRelatedproductsFieldsFieldPolicy,
	},
	ProjectBodyRelatedproductsPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProjectBodyRelatedproductsPrimaryKeySpecifier | (() => undefined | ProjectBodyRelatedproductsPrimaryKeySpecifier),
		fields?: ProjectBodyRelatedproductsPrimaryFieldPolicy,
	},
	ProjectCategory_list?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProjectCategory_listKeySpecifier | (() => undefined | ProjectCategory_listKeySpecifier),
		fields?: ProjectCategory_listFieldPolicy,
	},
	ProjectConnectionConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProjectConnectionConnectionKeySpecifier | (() => undefined | ProjectConnectionConnectionKeySpecifier),
		fields?: ProjectConnectionConnectionFieldPolicy,
	},
	ProjectConnectionEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProjectConnectionEdgeKeySpecifier | (() => undefined | ProjectConnectionEdgeKeySpecifier),
		fields?: ProjectConnectionEdgeFieldPolicy,
	},
	ProjectMedia?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProjectMediaKeySpecifier | (() => undefined | ProjectMediaKeySpecifier),
		fields?: ProjectMediaFieldPolicy,
	},
	ProjectTag_list?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProjectTag_listKeySpecifier | (() => undefined | ProjectTag_listKeySpecifier),
		fields?: ProjectTag_listFieldPolicy,
	},
	Projectcategory?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProjectcategoryKeySpecifier | (() => undefined | ProjectcategoryKeySpecifier),
		fields?: ProjectcategoryFieldPolicy,
	},
	ProjectcategoryConnectionConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProjectcategoryConnectionConnectionKeySpecifier | (() => undefined | ProjectcategoryConnectionConnectionKeySpecifier),
		fields?: ProjectcategoryConnectionConnectionFieldPolicy,
	},
	ProjectcategoryConnectionEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProjectcategoryConnectionEdgeKeySpecifier | (() => undefined | ProjectcategoryConnectionEdgeKeySpecifier),
		fields?: ProjectcategoryConnectionEdgeFieldPolicy,
	},
	Projectcolor?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProjectcolorKeySpecifier | (() => undefined | ProjectcolorKeySpecifier),
		fields?: ProjectcolorFieldPolicy,
	},
	ProjectcolorConnectionConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProjectcolorConnectionConnectionKeySpecifier | (() => undefined | ProjectcolorConnectionConnectionKeySpecifier),
		fields?: ProjectcolorConnectionConnectionFieldPolicy,
	},
	ProjectcolorConnectionEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProjectcolorConnectionEdgeKeySpecifier | (() => undefined | ProjectcolorConnectionEdgeKeySpecifier),
		fields?: ProjectcolorConnectionEdgeFieldPolicy,
	},
	Projectdesignerlink?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProjectdesignerlinkKeySpecifier | (() => undefined | ProjectdesignerlinkKeySpecifier),
		fields?: ProjectdesignerlinkFieldPolicy,
	},
	ProjectdesignerlinkConnectionConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProjectdesignerlinkConnectionConnectionKeySpecifier | (() => undefined | ProjectdesignerlinkConnectionConnectionKeySpecifier),
		fields?: ProjectdesignerlinkConnectionConnectionFieldPolicy,
	},
	ProjectdesignerlinkConnectionEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProjectdesignerlinkConnectionEdgeKeySpecifier | (() => undefined | ProjectdesignerlinkConnectionEdgeKeySpecifier),
		fields?: ProjectdesignerlinkConnectionEdgeFieldPolicy,
	},
	Projectglasstype?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProjectglasstypeKeySpecifier | (() => undefined | ProjectglasstypeKeySpecifier),
		fields?: ProjectglasstypeFieldPolicy,
	},
	ProjectglasstypeConnectionConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProjectglasstypeConnectionConnectionKeySpecifier | (() => undefined | ProjectglasstypeConnectionConnectionKeySpecifier),
		fields?: ProjectglasstypeConnectionConnectionFieldPolicy,
	},
	ProjectglasstypeConnectionEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProjectglasstypeConnectionEdgeKeySpecifier | (() => undefined | ProjectglasstypeConnectionEdgeKeySpecifier),
		fields?: ProjectglasstypeConnectionEdgeFieldPolicy,
	},
	Projects?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProjectsKeySpecifier | (() => undefined | ProjectsKeySpecifier),
		fields?: ProjectsFieldPolicy,
	},
	ProjectsBodyGeneral_card?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProjectsBodyGeneral_cardKeySpecifier | (() => undefined | ProjectsBodyGeneral_cardKeySpecifier),
		fields?: ProjectsBodyGeneral_cardFieldPolicy,
	},
	ProjectsBodyGeneral_cardPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProjectsBodyGeneral_cardPrimaryKeySpecifier | (() => undefined | ProjectsBodyGeneral_cardPrimaryKeySpecifier),
		fields?: ProjectsBodyGeneral_cardPrimaryFieldPolicy,
	},
	ProjectsBodyPinterest_product_pin?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProjectsBodyPinterest_product_pinKeySpecifier | (() => undefined | ProjectsBodyPinterest_product_pinKeySpecifier),
		fields?: ProjectsBodyPinterest_product_pinFieldPolicy,
	},
	ProjectsBodyPinterest_product_pinPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProjectsBodyPinterest_product_pinPrimaryKeySpecifier | (() => undefined | ProjectsBodyPinterest_product_pinPrimaryKeySpecifier),
		fields?: ProjectsBodyPinterest_product_pinPrimaryFieldPolicy,
	},
	ProjectsBodyTwitter_card?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProjectsBodyTwitter_cardKeySpecifier | (() => undefined | ProjectsBodyTwitter_cardKeySpecifier),
		fields?: ProjectsBodyTwitter_cardFieldPolicy,
	},
	ProjectsBodyTwitter_cardPrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProjectsBodyTwitter_cardPrimaryKeySpecifier | (() => undefined | ProjectsBodyTwitter_cardPrimaryKeySpecifier),
		fields?: ProjectsBodyTwitter_cardPrimaryFieldPolicy,
	},
	ProjectsConnectionConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProjectsConnectionConnectionKeySpecifier | (() => undefined | ProjectsConnectionConnectionKeySpecifier),
		fields?: ProjectsConnectionConnectionFieldPolicy,
	},
	ProjectsConnectionEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProjectsConnectionEdgeKeySpecifier | (() => undefined | ProjectsConnectionEdgeKeySpecifier),
		fields?: ProjectsConnectionEdgeFieldPolicy,
	},
	Projecttag?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProjecttagKeySpecifier | (() => undefined | ProjecttagKeySpecifier),
		fields?: ProjecttagFieldPolicy,
	},
	ProjecttagConnectionConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProjecttagConnectionConnectionKeySpecifier | (() => undefined | ProjecttagConnectionConnectionKeySpecifier),
		fields?: ProjecttagConnectionConnectionFieldPolicy,
	},
	ProjecttagConnectionEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProjecttagConnectionEdgeKeySpecifier | (() => undefined | ProjecttagConnectionEdgeKeySpecifier),
		fields?: ProjecttagConnectionEdgeFieldPolicy,
	},
	Projectwidth?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProjectwidthKeySpecifier | (() => undefined | ProjectwidthKeySpecifier),
		fields?: ProjectwidthFieldPolicy,
	},
	ProjectwidthConnectionConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProjectwidthConnectionConnectionKeySpecifier | (() => undefined | ProjectwidthConnectionConnectionKeySpecifier),
		fields?: ProjectwidthConnectionConnectionFieldPolicy,
	},
	ProjectwidthConnectionEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProjectwidthConnectionEdgeKeySpecifier | (() => undefined | ProjectwidthConnectionEdgeKeySpecifier),
		fields?: ProjectwidthConnectionEdgeFieldPolicy,
	},
	Query?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | QueryKeySpecifier | (() => undefined | QueryKeySpecifier),
		fields?: QueryFieldPolicy,
	},
	Realisation?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | RealisationKeySpecifier | (() => undefined | RealisationKeySpecifier),
		fields?: RealisationFieldPolicy,
	},
	RealisationConnectionConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | RealisationConnectionConnectionKeySpecifier | (() => undefined | RealisationConnectionConnectionKeySpecifier),
		fields?: RealisationConnectionConnectionFieldPolicy,
	},
	RealisationConnectionEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | RealisationConnectionEdgeKeySpecifier | (() => undefined | RealisationConnectionEdgeKeySpecifier),
		fields?: RealisationConnectionEdgeFieldPolicy,
	},
	RelatedDocument?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | RelatedDocumentKeySpecifier | (() => undefined | RelatedDocumentKeySpecifier),
		fields?: RelatedDocumentFieldPolicy,
	},
	Reviews?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ReviewsKeySpecifier | (() => undefined | ReviewsKeySpecifier),
		fields?: ReviewsFieldPolicy,
	},
	ReviewsConnectionConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ReviewsConnectionConnectionKeySpecifier | (() => undefined | ReviewsConnectionConnectionKeySpecifier),
		fields?: ReviewsConnectionConnectionFieldPolicy,
	},
	ReviewsConnectionEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ReviewsConnectionEdgeKeySpecifier | (() => undefined | ReviewsConnectionEdgeKeySpecifier),
		fields?: ReviewsConnectionEdgeFieldPolicy,
	},
	Slice?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SliceKeySpecifier | (() => undefined | SliceKeySpecifier),
		fields?: SliceFieldPolicy,
	},
	SliceBodyTestslice?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SliceBodyTestsliceKeySpecifier | (() => undefined | SliceBodyTestsliceKeySpecifier),
		fields?: SliceBodyTestsliceFieldPolicy,
	},
	SliceConnectionConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SliceConnectionConnectionKeySpecifier | (() => undefined | SliceConnectionConnectionKeySpecifier),
		fields?: SliceConnectionConnectionFieldPolicy,
	},
	SliceConnectionEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SliceConnectionEdgeKeySpecifier | (() => undefined | SliceConnectionEdgeKeySpecifier),
		fields?: SliceConnectionEdgeFieldPolicy,
	},
	Tag?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | TagKeySpecifier | (() => undefined | TagKeySpecifier),
		fields?: TagFieldPolicy,
	},
	TagConnectionConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | TagConnectionConnectionKeySpecifier | (() => undefined | TagConnectionConnectionKeySpecifier),
		fields?: TagConnectionConnectionFieldPolicy,
	},
	TagConnectionEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | TagConnectionEdgeKeySpecifier | (() => undefined | TagConnectionEdgeKeySpecifier),
		fields?: TagConnectionEdgeFieldPolicy,
	},
	Taxonomy?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | TaxonomyKeySpecifier | (() => undefined | TaxonomyKeySpecifier),
		fields?: TaxonomyFieldPolicy,
	},
	TaxonomyConnectionConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | TaxonomyConnectionConnectionKeySpecifier | (() => undefined | TaxonomyConnectionConnectionKeySpecifier),
		fields?: TaxonomyConnectionConnectionFieldPolicy,
	},
	TaxonomyConnectionEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | TaxonomyConnectionEdgeKeySpecifier | (() => undefined | TaxonomyConnectionEdgeKeySpecifier),
		fields?: TaxonomyConnectionEdgeFieldPolicy,
	},
	Test?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | TestKeySpecifier | (() => undefined | TestKeySpecifier),
		fields?: TestFieldPolicy,
	},
	TestConnectionConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | TestConnectionConnectionKeySpecifier | (() => undefined | TestConnectionConnectionKeySpecifier),
		fields?: TestConnectionConnectionFieldPolicy,
	},
	TestConnectionEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | TestConnectionEdgeKeySpecifier | (() => undefined | TestConnectionEdgeKeySpecifier),
		fields?: TestConnectionEdgeFieldPolicy,
	},
	Wishlist?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | WishlistKeySpecifier | (() => undefined | WishlistKeySpecifier),
		fields?: WishlistFieldPolicy,
	},
	WishlistBodyColor_sample?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | WishlistBodyColor_sampleKeySpecifier | (() => undefined | WishlistBodyColor_sampleKeySpecifier),
		fields?: WishlistBodyColor_sampleFieldPolicy,
	},
	WishlistBodyColor_sampleFields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | WishlistBodyColor_sampleFieldsKeySpecifier | (() => undefined | WishlistBodyColor_sampleFieldsKeySpecifier),
		fields?: WishlistBodyColor_sampleFieldsFieldPolicy,
	},
	WishlistBodyColor_samplePrimary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | WishlistBodyColor_samplePrimaryKeySpecifier | (() => undefined | WishlistBodyColor_samplePrimaryKeySpecifier),
		fields?: WishlistBodyColor_samplePrimaryFieldPolicy,
	},
	WishlistConnectionConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | WishlistConnectionConnectionKeySpecifier | (() => undefined | WishlistConnectionConnectionKeySpecifier),
		fields?: WishlistConnectionConnectionFieldPolicy,
	},
	WishlistConnectionEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | WishlistConnectionEdgeKeySpecifier | (() => undefined | WishlistConnectionEdgeKeySpecifier),
		fields?: WishlistConnectionEdgeFieldPolicy,
	},
	_Document?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | _DocumentKeySpecifier | (() => undefined | _DocumentKeySpecifier),
		fields?: _DocumentFieldPolicy,
	},
	_DocumentConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | _DocumentConnectionKeySpecifier | (() => undefined | _DocumentConnectionKeySpecifier),
		fields?: _DocumentConnectionFieldPolicy,
	},
	_DocumentEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | _DocumentEdgeKeySpecifier | (() => undefined | _DocumentEdgeKeySpecifier),
		fields?: _DocumentEdgeFieldPolicy,
	},
	_ExternalLink?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | _ExternalLinkKeySpecifier | (() => undefined | _ExternalLinkKeySpecifier),
		fields?: _ExternalLinkFieldPolicy,
	},
	_FileLink?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | _FileLinkKeySpecifier | (() => undefined | _FileLinkKeySpecifier),
		fields?: _FileLinkFieldPolicy,
	},
	_ImageLink?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | _ImageLinkKeySpecifier | (() => undefined | _ImageLinkKeySpecifier),
		fields?: _ImageLinkFieldPolicy,
	},
	_Linkable?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | _LinkableKeySpecifier | (() => undefined | _LinkableKeySpecifier),
		fields?: _LinkableFieldPolicy,
	}
};
export type TypedTypePolicies = StrictTypedTypePolicies & TypePolicies;