import { usePrismicLayout } from '@prismic/context';
import { useUser } from '@sdk/hook/useUser';
import { LocalePicker } from '@components/molecules/LocalePicker';
import { RegionPicker } from '@components/molecules/RegionPicker';

import { useRouter } from 'next/router';
import { localeToFlag } from '@utils/regions';

import { useState } from 'react';
import { useIntl } from 'react-intl';

import { useMediaQuery } from '@hooks/useMediaQuery';
import { smallScreen } from '@styles/constants';

import NiceModal from '@ebay/nice-modal-react';
import { LoginModal } from '@components/overlays/Login';

import chevronUrl from 'images/chevron-left.svg';

import { TabTypes } from './types';
import * as S from './styles';

interface NavLocalProps {
  hideOverlay(): void;
}

export const NavLocales: React.FC<NavLocalProps> = ({ hideOverlay }) => {
  const [showLocalesMenu, setShowLocalesMenu] = useState(false);
  const [currentTabPane, setCurrentTabPane] = useState<TabTypes>(
    TabTypes.region
  );
  const intl = useIntl();
  const { locale } = useRouter();
  const { layout } = usePrismicLayout();
  const { user } = useUser();
  const [isSmallScreen] = useMediaQuery(`(max-width: ${smallScreen}px)`);

  return (
    <S.LocaleWrapper>
      {!user && (
        <button
          type="button"
          onClick={() => {
            hideOverlay();
            NiceModal.show(LoginModal);
          }}
        >
          {intl.formatMessage({
            defaultMessage: 'Log in to account',
            id: 'q+zDsQ',
          })}
        </button>
      )}
      <button
        type="button"
        onClick={() => {
          setShowLocalesMenu(true);
        }}
      >
        <S.LocaleImage>
          <img src={localeToFlag(locale)} alt="" />
        </S.LocaleImage>
      </button>
      {isSmallScreen && showLocalesMenu && (
        <S.LocaleContainer>
          <S.LocaleTabsHeader>
            <S.LocaleTabsBackButton
              type="button"
              onClick={() => {
                setShowLocalesMenu(false);
              }}
            >
              <img src={chevronUrl} alt="" />
            </S.LocaleTabsBackButton>
            <S.LocaleTabsButton
              active={currentTabPane === TabTypes.region}
              type="button"
              onClick={() => setCurrentTabPane(TabTypes.region)}
            >
              {intl.formatMessage({
                defaultMessage: 'Region',
                id: 'lnaWo/',
              })}
            </S.LocaleTabsButton>
            <S.LocaleTabsButton
              active={currentTabPane === TabTypes.language}
              type="button"
              onClick={() => setCurrentTabPane(TabTypes.language)}
            >
              {intl.formatMessage({
                defaultMessage: 'Language',
                id: 'y1Z3or',
              })}
            </S.LocaleTabsButton>
          </S.LocaleTabsHeader>
          <S.LocaleTabsContent>
            {currentTabPane === TabTypes.region && (
              <div>
                <S.LocaleTabsDescription>
                  {/* intl.formatMessage({
                    defaultMessage:
                      'Select your region to see the relevant currency for you and shipping options.',
                    id: '9oTRz1',
                  }) */}
                  {layout?.region_description}
                </S.LocaleTabsDescription>
                <RegionPicker />
              </div>
            )}
            {currentTabPane === TabTypes.language && (
              <div>
                <S.LocaleTabsDescription />
                <LocalePicker onPicked={hideOverlay} />
              </div>
            )}
          </S.LocaleTabsContent>
        </S.LocaleContainer>
      )}
      {!isSmallScreen && showLocalesMenu && (
        <S.LocaleContainer>
          <button
            className="locales__close-btn"
            type="button"
            onClick={() => {
              setShowLocalesMenu(false);
            }}
          >
            <img src={chevronUrl} alt="" />
          </button>
          <S.LocaleRegion>
            <S.LocaleItemTop>
              <S.LocaleItemTitle>{layout?.region_title}</S.LocaleItemTitle>
              <S.LocaleItemSubTitle>
                {layout?.region_description}
              </S.LocaleItemSubTitle>
            </S.LocaleItemTop>
            <RegionPicker />
          </S.LocaleRegion>
          <S.LocaleLanguage>
            <S.LocaleItemTop>
              <S.LocaleItemTitle>
                {intl.formatMessage({
                  defaultMessage: 'Language',
                  id: 'y1Z3or',
                })}
              </S.LocaleItemTitle>
              <S.LocaleItemSubTitle>
                {/* layout?.language_description */}
              </S.LocaleItemSubTitle>
            </S.LocaleItemTop>
            <LocalePicker onPicked={hideOverlay} />
          </S.LocaleLanguage>
        </S.LocaleContainer>
      )}
    </S.LocaleWrapper>
  );
};
