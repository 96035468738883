import React from 'react';

import * as S from './styles';
import { IProps } from './types';

export const InputLabel: React.FC<IProps> = ({
  children,
  active,
  labelBackground,
  className,
}: IProps) => (
  <S.Label {...{ active, labelBackground, className }}>{children}</S.Label>
);
