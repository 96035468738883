export const PreloadedFonts = () => (
  <>
    {/* Google Fonts */}
    {/* <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link
      rel="preconnect"
      href="https://fonts.gstatic.com"
      crossOrigin="anonymous"
    />
    <link
      href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap"
      rel="stylesheet"
    /> */}

    {/* Custom Fonts */}
    <link
      rel="preload"
      href="/fonts/Inter-Regular.woff2"
      as="font"
      crossOrigin=""
    />
    <link
      rel="preload"
      href="/fonts/Inter-Regular.woff"
      as="font"
      crossOrigin=""
    />
    <link
      rel="preload"
      href="/fonts/Inter-Regular.ttf"
      as="font"
      crossOrigin=""
    />
    <link
      rel="preload"
      href="/fonts/SofiaProBold.woff2"
      as="font"
      crossOrigin=""
    />
    <link
      rel="preload"
      href="/fonts/SofiaProBold.woff"
      as="font"
      crossOrigin=""
    />
    <link
      rel="preload"
      href="/fonts/SofiaProBold.ttf"
      as="font"
      crossOrigin=""
    />
    <link
      rel="preload"
      href="/fonts/SofiaProLight.woff2"
      as="font"
      crossOrigin=""
    />
    <link
      rel="preload"
      href="/fonts/SofiaProLight.woff"
      as="font"
      crossOrigin=""
    />
    <link
      rel="preload"
      href="/fonts/SofiaProLight.ttf"
      as="font"
      crossOrigin=""
    />
    <link
      rel="preload"
      href="/fonts/SofiaProRegular.woff2"
      as="font"
      crossOrigin=""
    />
    <link
      rel="preload"
      href="/fonts/SofiaProRegular.woff"
      as="font"
      crossOrigin=""
    />
    <link
      rel="preload"
      href="/fonts/SofiaProRegular.ttf"
      as="font"
      crossOrigin=""
    />
  </>
);
