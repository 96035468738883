import { media, styled } from '@styles';
import { TitleSize } from './types';

export const Header = styled.div<{ divider: boolean }>`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  padding: ${(props) => `1.1rem ${props.theme.spacing.gutter}`};
`;

export const CloseImg = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const HeadImg = styled.div`
  margin: 1rem auto 2rem auto;
  ${media.xSmallScreen`
   // display: none;
   margin: auto;
  `}
`;

export const Title = styled.h4<{ size: TitleSize }>`
  font-size: ${({ size, theme: { typography } }) =>
    size === 'lg' ? typography.h3FontSize : typography.baseFontSize};
  color: ${(props) => props.theme.colors.baseFont};
  margin: 0;
  width: 70%;
  text-align: center;
  line-height: 1em;
  ${media.xSmallScreen`
    font-size: ${({
      size,
      theme: { typography },
    }: {
      size: string;
      theme: { typography: { baseFontSize: string } };
    }): string => (size === 'lg' ? '20px' : typography.baseFontSize)};
  `}
`;

export const Paragraph = styled.p`
  font-size: ${(props) => props.theme.typography.smallFontSize};
  color: ${(props) => props.theme.colors.lightFont};
  padding-right: 0.6rem;
  margin: 0;
`;
