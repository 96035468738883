import { DefaultTheme, styled } from '@styles';

type WrapperProps = {
  active: boolean;
  error: boolean;
  disabled: boolean;
  theme: DefaultTheme;
  whiteBg: boolean;
};

const getEdgeColor = (
  { active, error, disabled, theme }: WrapperProps,
  hovered = false
): string => {
  if (disabled) {
    return theme.colors.disabled;
  }

  if (error) {
    return theme.colors.error;
  }

  if (hovered) {
    return theme.colors.secondary;
  }

  return active ? theme.colors.secondary : theme.colors.grayDark;
};

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  border: 1px solid #cfd7da;
  color: ${(props) => getEdgeColor(props)};
  background-color: ${(props) => (props.whiteBg ? '#ffffff' : 'transparent')};
  outline: none;
  transition: all 0.3s ease;
  border-radius: 4px;
  margin-top: ${(props) => (props.whiteBg ? '0' : '10px')};
  &:hover {
  }
`;

export const Content = styled.span`
  display: flex;
  align-items: center;
`;

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  & > span {
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 15px;
  }
`;

export const Input = styled.input`
  padding: 0.75rem 1.25rem;
  margin: 0;
  border: none;
  width: 100%;
  font-size: ${(props) => props.theme.typography.labelFontSize};
  line-height: 1.6em;
  outline: none;
  background-color: transparent;
  color: ${(props) => props.theme.colors.baseFont};
  font-family: ${(props) => props.theme.typography.extraFontFamily};
  &::placeholder {
    color: rgba(44, 44, 44, 0.3);
  }
`;
