import React from 'react';

import { linkResolver } from '@prismic/prismic-config';
import facebookIcon from 'images/footer-icons/facebook.svg';
import instagramIcon from 'images/footer-icons/instagram.svg';
import pinterestIcon from 'images/footer-icons/pinterest.svg';
import * as S from './styles';
import { IProps } from './types';

export const SocialsNav: React.FC<IProps> = ({ social }) => (
  <S.Wrapper>
    {social?.map((item, i) => (
      <S.Link
        href={linkResolver(item.footer_social_link)}
        target="_blank"
        rel="noreferrer"
        key={`nav-social-${i}`}
      >
        {item.footer_social_type === 'facebook' && (
          <img
            src={facebookIcon}
            width="38px"
            height="38px"
            alt={item.footer_social_type || 'social'}
          />
        )}
        {item.footer_social_type === 'instagram' && (
          <img
            width="38px"
            height="38px"
            src={instagramIcon}
            alt={item.footer_social_type || 'social'}
          />
        )}
        {item.footer_social_type === 'pinterest' && (
          <img
            width="38px"
            height="38px"
            src={pinterestIcon}
            alt={item.footer_social_type || 'social'}
          />
        )}
      </S.Link>
    ))}
  </S.Wrapper>
);
