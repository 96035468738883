import {
  ProductDetailsFragment,
  CheckoutLineFragment,
} from '@sdk/gqlTypes/generatedOperations';
import { SelectedAttributeValueInput } from '@sdk/gqlTypes/generatedTypes';

import { PrismicProductPageQuery } from '@prismic/gqlTypes/prismicOperations';

export interface IPageProps {
  product: ProductDetailsFragment;
  productDetails: PrismicProductPageQuery['product'] | null;
  addToCart: (
    variantId: string,
    quantity: number,
    productAttributes: SelectedAttributeValueInput[]
  ) => void;
  items: CheckoutLineFragment[];
}

export const SAMPLE_PRODUCT_TYPES = [
  'sample-rustical',
  'sample-minimalist',
  'sample-color-oil',
  'sample-color-paint',
];

export const CONFIGURATOR_PRODUCT_TYPES = [
  'atelier-style',
  'barndoor-style-1',
  'barndoor-style-2',
  'dot-style',
  'industrial-style',
  'loft-style',
  'minimalist-style',
  'modern-style',
  'rattan-style',
  'rustic-style-1',
  'rustic-style-2',
  'wardrobe-style',
  'elegance-style',
  'mirror-style',
];
