import { styled } from '@styles';
import { keyframes } from 'styled-components';

import { getContentWindowHeight } from '@utils/styles';

const firstItem = keyframes`
  0% {
    left: 0;
  }
  12.5% {
    left: 2em;
  }
  25% {
    left: 4em;
  }
  37.5% {
    left: 2em;
  }
  50% {
    left: 0;
  }
  100% {
    left: 0;
  }
`;

const secondItem = keyframes`
  0% {
    left: 2em;
  }
  12.5% {
    left: 2em;
  }
  25% {
    left: 4em;
  }
  37.5% {
    left: 2em;
  }
  62.5% {
    left: 2em;
  }
  75% {
    left: 0;
  }
  87.5% {
    left: 2em;
  }
  100% {
    left: 2em;
  }
`;

const thirdItem = keyframes`
  0% {
    left: 4em;
  }
  50% {
    left: 4em;
  }
  62.5% {
    left: 2em;
  }
  75% {
    left: 0;
  }
  87.5% {
    left: 2em;
  }
  100% {
    left: 4em;
  }
`;

export const Wrapper = styled.div<{ fullScreen: boolean; inline: boolean }>`
  display: ${(props) => (props.inline ? 'inline' : 'flex')};
  align-items: center;
  width: 100%;
  height: ${(props) =>
    props.fullScreen ? `${getContentWindowHeight()}px` : '100%'};
  padding: ${(props) => props.theme.spacing.spacer} 0;
`;

export const Items = styled.div<{ color: 'primary' | 'secondary' }>`
  position: relative;
  width: 5em;
  height: 1em;
  margin: 0 auto;

  span {
    background-color: ${(props) =>
      props.theme.button.colors[props.color].background};
    width: 1em;
    height: 1em;
    border-radius: 1em;
    position: absolute;

    &:nth-child(1) {
      left: 0;
      animation: ${firstItem} 2s infinite;
      animation-timing-function: linear;
    }

    &:nth-child(2) {
      left: 2em;
      animation: ${secondItem} 2s infinite;
      animation-timing-function: linear;
    }

    &:nth-child(3) {
      right: 0;
      animation: ${thirdItem} 2s infinite;
      animation-timing-function: linear;
    }
  }
`;
