import React from 'react';
import { useRouter } from 'next/router';

import { IProps } from './types';

export const Money: React.FC<IProps> = ({
  money,
  defaultValue,
  ...props
}: IProps) => {
  const { locale } = useRouter();

  if (!money) {
    return <span {...props}>{defaultValue}</span>;
  }

  if (money.currency && money.currency !== '') {
    const str = money.amount.toLocaleString(locale, {
      currency: money.currency,
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
      style: 'currency',
    });

    return <span {...props}>{str}</span>;
  }

  return <span {...props}>{money.amount.toString()}</span>;
};

Money.displayName = 'Money';
export default Money;
