import { Overlay } from '@components/organisms/Overlay';

import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { NavList } from '@components/organisms/MobileNav';

import * as S from './styles';
import { IProps } from './types';

// import cartImg from "images/cart.svg";
const SideNav: React.FC<IProps> = ({ items, contact, prismicNav }) => {
  const modal = useModal();

  return (
    <Overlay position="left" show={modal.visible} hide={modal.remove}>
      <S.Modal>
        <NavList
          items={items}
          contact={contact}
          prismicNav={prismicNav}
          hideOverlay={modal.remove}
        />
      </S.Modal>
    </Overlay>
  );
};

export default NiceModal.create(SideNav);
