import { styled } from '@styles';

export const Label = styled.label<{
  active: boolean;
  labelBackground: string | null;
}>`
  position: absolute;
  left: ${(props) => (props.active ? '0.5rem' : '1rem')};
  padding: 0 ${(props) => (props.active ? 0.5 : 0)}rem;
  background-color: ${(props) => props.theme.colors.white};
  font-size: ${(props) =>
    props.active
      ? props.theme.input.labelFontSize
      : props.theme.typography.baseFontSize};
  top: ${(props) => (props.active ? 0 : '50%')};
  transform: translateY(-50%);
  font-family: ${(props) => props.theme.typography.extraFontFamily};
  transition: all 0.3s ease, color 0s;
  color: rgba(44, 44, 44, 0.3);
  pointer-events: none;
`;
